import { UserPresence, UserPresenceDevice, UserType } from "@shared/enums";

export interface ImportUser {
  Nome: string;
  Email: string;
  Tags: string;
  Ramal: string;
  SenhaRamal: string;
  Tipo?: UserType,
}

export interface User {
  id?: string;
  displayName: string;
  email: string;
  passwordExten?: string;
  authuser?: string;
  pass?: string;
  extension: string;
  photoUrl?: string;
  phoneNumber?: string;
  presence?: Presence;
  groups?: string[];
  firstLogin?: boolean;
  pbxId?: string;
  domainPbx?: string;
  favoriteList?: string[];
  terms?: {
    hasAccepted: boolean;
    version: string;
  };
  receive_call_while?: {
    Busy: boolean;
    DoNotDisturb: boolean;
    InMeeting: boolean;
    BeRightBack: boolean;
    Away: boolean;
  };
  callingWith?: string;
  pbxRecEnabled?: boolean;
  companyId?: string;
  hiddenRooms?: string[];
  selfChatId?: string;
  chatGPTRoomId?: string;
  imageBase64?: string;
  enableMultiCall?: boolean;
  userType?: string;
  disabled?: boolean;
  companySector?: string;
  companySectorId?: string;
}

export interface Presence {
  status: UserPresence;
  force: boolean;
}

export interface UserConnection {
  status: UserPresence;
  force: boolean;
  device: UserPresenceDevice,
  timestamp: any;
}
