import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthService,
  HistoryService,
  JsSIPService,
  ThemeService,
  UserPresenceService,
  UserService
} from '@core/services';
import { environment } from '@environments/environment';
import { UserPresence } from '@shared/enums';

@Component({
  selector: 'app-lgpd-dialog',
  templateUrl: './lgpd-dialog.component.html',
  styleUrls: ['./lgpd-dialog.component.scss'],
})
export class LgpdDialogComponent implements OnInit {
  checked = false;

  constructor(
    public auth: AuthService,
    public themeService: ThemeService,
    private router: Router,
    private jssip: JsSIPService,
    private userService: UserService,
    private userPresence: UserPresenceService,
    private historyService: HistoryService,
    public dialogRef: MatDialogRef<LgpdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { readOnly: boolean }
  ) { }

  ngOnInit(): void { }

  onDecline(): void {
    this.historyService.clear();
    this.jssip.unregister();
    this.auth.signOut().then(() => {
      this.userPresence.setPresence(UserPresence.OFFLINE);
      this.router.navigate(['/login']);
    });
    this.dialogRef.close(false);
  }

  onAccept(): void {
    const data = {
      terms: {
        hasAccepted: true,
        version: environment.termsVersion,
      },
    };

    const userId: string = this.auth.userId;
    this.userService.updateOnlyInFire(data, userId);
    this.dialogRef.close(true);
  }
}
