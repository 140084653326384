import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { JsSIPService, PlatformService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss'],
})
export class DialerComponent implements OnInit, OnDestroy {
  phone = '';
  dialPhone = '';
  focusInInput = false;

  @ViewChild('input') input: ElementRef;
  @Input() floaterExpanded = false;

  @ViewChild('voiceCallBtn', { read: ElementRef })
  voiceCallBtn: ElementRef<HTMLElement>;

  private destroy$ = new Subject<void>();

  constructor(
    private jssip: JsSIPService,
    public platform: PlatformService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.jssip
      .sideNavMenuEvent
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) this.setFocusInInput();
      });
  }

  /**
   * Set focus in input - After 500ms
   * @param {number} ms - Time in milliseconds
   */
  private setFocusInInput(ms: number = 500): void {
    setTimeout(() => this.input.nativeElement.focus(), ms); // Focus on input after 500ms
  }

  dial(ev: { target: HTMLElement; key: string }): void {
    ev.target.blur();
    if (ev.key !== 'call') {
      this.phone += ev.key;
      this.moveToLastCharacter();
    }
  }

  backspaceInput(): void {
    this.phone = this.phone.substring(0, this.phone.length - 1);
  }

  keyPressed(e: KeyboardEvent): boolean {
    if (e.key === 'Enter' && this.focusInInput) {
      (
        this.voiceCallBtn.nativeElement.children.item(0) as HTMLButtonElement
      ).click();
      this.voiceCallBtn.nativeElement.blur();
      this.focusInInput = false;
      return;
    }

    let allow_char: number[] = [35, 42, 43];
    this.range(48, 57).forEach((i) => allow_char.push(i));
    if (!allow_char.includes(e.key.charCodeAt(0))) {
      return false;
    }
  }

  private range(begin: number, end: number): number[] {
    const rangeRes = [];
    for (let i = begin; i <= end; i++) {
      rangeRes.push(i);
    }
    return rangeRes;
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData: DataTransfer = event.clipboardData;
    const pastedText: string = clipboardData.getData('text');
    const normalizedText = pastedText.replace(/\D/g, '');
    setTimeout(() => {
      this.phone = normalizedText;
    }, 100);
  }

  /**
   * Move input cursor to last position
   */
  private moveToLastCharacter(): void {
    setTimeout(() => {
      this.input.nativeElement.scrollLeft =
        this.input.nativeElement.scrollWidth;
    }, 100);
  }
}
