/**
  * Verify if can show external route
  * @returns {boolean} to show or not the external route value
  */
export const isExternalRouteExist = (externalRoute: number | string[]): boolean => {
  return !!(Array.isArray(externalRoute)
    ? externalRoute.length
    : `${externalRoute}` !== '' && externalRoute !== null)
}

/**
 * Remove external route
 * @param {string} target Number to call
 * @returns {string} target without external route
 */
export const removeExternalRoute = (target: string, routes: string[] = []): string => {
  // Available character
  const character1 = target.indexOf('*');
  const character2 = target.indexOf('#');

  // Remove external route with especial character
  if (character1 > -1 || character2 > -1) {
    return target.includes('*')
      ? target.substring(character1 + 1, target.length)
      : target.substring(character2 + 1, target.length)
  } else {

    // Remove known external routes
    for (let i = 0; i < routes.length; i++) {
      if (target.substring(0, routes[i].length).includes(routes[i])) {
        return target.substring(routes[i].length)
      }
    }
  }

  return target
}
