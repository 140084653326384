<div id="dialpad" [ngClass]="{ dark: themeService.isDarkTheme() }">
  <div class="item" *ngFor="let key of dialpadKeys">
    <button
      class="key extra-large-text-regular"
      (click)="dial.emit({ target: $event.target, key: key.value })"
    >
      {{ key.value }}
      <span class="normal-text-regular" style="width: 100%; display: block">
        {{ key.text ? key.text : "&#160;" }}
      </span>
    </button>
  </div>
</div>
