<!-- Main app component -->
<router-outlet></router-outlet>
<div class="dev-mode" *ngIf="canShowDevMode()">
    <mat-slide-toggle class="toggle-dev-mode" (change)="toggleHttpMonitor($event)" class="example-margin"
        color="primary" [checked]="checked">
    </mat-slide-toggle>
    <span (click)="openDevModal()" style="font-size: 15px;"> Dev Mode </span>
</div>


<!-- Remote audio -->
<audio src="" id="audio-remote"></audio>

<!-- Ringtone -->
<audio src="" id="audio-ring"></audio>
<audio src="" id="secondary-audio-ring"></audio>

<!-- Notification -->
<audio src="" id="audio-notification"></audio>
<audio src="" id="secondary-audio-notification"></audio>

<!-- Busy audio -->
<audio src="" id="audio-busy"></audio>

<!-- Calling audio -->
<audio src="" id="audio-calling"></audio>
