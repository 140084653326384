<div class="container">
  <div>
    <div>
      <span class="type">
        {{ notification.type }}
      </span>
    </div>
    <div>
      <span class="message">
        {{ notification.message }}
      </span>
    </div>
  </div>

  <div class="actions">
    <button (click)="close()">{{ notification.actionText }}</button>
  </div>
</div>
