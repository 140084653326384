<div class="setting-dialog-title" *ifViewportSize="['medium', 'large']">
  <button id="close-button" mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-tab-group selectedIndex="1" (selectedTabChange)="selectTab($event)" animationDuration="0ms"
  *ifViewportSize="['medium', 'large']">
  <mat-tab [label]="'settings' | translate" disabled></mat-tab>
  <mat-tab label="profile">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">account_circle</mat-icon>
      <span>{{ "profile" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('profile')" [ngTemplateOutlet]="profile"></ng-container>
  </mat-tab>
  <mat-tab label="appearance">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">contrast</mat-icon>
      <span>{{ "appearance" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('appearance')" [ngTemplateOutlet]="appearance"></ng-container>
  </mat-tab>
  <mat-tab *ngIf="isElectron" label="initialization">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">tune</mat-icon>
      <span>{{ "initialization" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('initialization')" [ngTemplateOutlet]="initialization"></ng-container>
  </mat-tab>
  <mat-tab label="callSettings">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">local_phone</mat-icon>
      <span>{{ "callSettings" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('callSettings')" [ngTemplateOutlet]="callSettings"></ng-container>
  </mat-tab>
  <mat-tab label="devices">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">headset_mic</mat-icon>
      <span>{{ "devices" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('devices')" [ngTemplateOutlet]="devicesSettings"></ng-container>
  </mat-tab>
  <mat-tab label="password">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">lock</mat-icon>
      <span>{{ "password" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('password')" [ngTemplateOutlet]="password"></ng-container>
  </mat-tab>
  <mat-tab label="about">
    <ng-template mat-tab-label>
      <mat-icon class="m-r-xs">info</mat-icon>
      <span>{{ "about" | translate }}</span>
    </ng-template>
    <ng-container *ngIf="isCurrentTab('about')" [ngTemplateOutlet]="about"></ng-container>
  </mat-tab>
</mat-tab-group>

<div class="mobile-view" *ifViewportSize="['small']">
  <div class="mobile-title">
    <button id="back-button" mat-icon-button (click)="backButton()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="m-h-auto">{{ selectedOption.title }}</div>
  </div>

  <div class="mobile-menu" *ngIf="selectedOption.option === ''">
    <div class="mobile-option" (click)="showContent('profile')">
      <mat-icon class="m-r-xs">account_circle</mat-icon>
      <span>{{ "profile" | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="mobile-option" (click)="showContent('appearance')">
      <mat-icon class="m-r-xs">contrast</mat-icon>
      <span>{{ "appearance" | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="mobile-option" *ngIf="isElectron" (click)="showContent('initialization')">
      <mat-icon class="m-r-xs">tune</mat-icon>
      <span>{{ "initialization" | translate }}</span>
    </div>
    <mat-divider *ngIf="isElectron"></mat-divider>
    <div class="mobile-option" (click)="showContent('callSettings')">
      <mat-icon class="m-r-xs">local_phone</mat-icon>
      <span>{{ "callSettings" | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="mobile-option" (click)="showContent('devicesSettings')">
      <mat-icon class="m-r-xs">headset_mic</mat-icon>
      <span>{{ "devices" | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="mobile-option" (click)="showContent('password')">
      <mat-icon class="m-r-xs">lock</mat-icon>
      <span>{{ "password" | translate }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="mobile-option" (click)="showContent('about')">
      <mat-icon class="m-r-xs">info</mat-icon>
      <span>{{ "about" | translate }}</span>
    </div>
  </div>

  <div class="mobile-content" *ngIf="selectedOption.option !== ''">
    <ng-container *ngIf="selectedOption.option === 'profile'" [ngTemplateOutlet]="profile"></ng-container>
    <ng-container *ngIf="selectedOption.option === 'appearance'" [ngTemplateOutlet]="appearance"></ng-container>
    <ng-container *ngIf="selectedOption.option === 'initialization'" [ngTemplateOutlet]="initialization"></ng-container>
    <ng-container *ngIf="selectedOption.option === 'callSettings'" [ngTemplateOutlet]="callSettings"></ng-container>
    <ng-container *ngIf="selectedOption.option === 'devicesSettings'"
      [ngTemplateOutlet]="devicesSettings"></ng-container>
    <ng-container *ngIf="selectedOption.option === 'password'" [ngTemplateOutlet]="password"></ng-container>
    <ng-container *ngIf="selectedOption.option === 'about'" [ngTemplateOutlet]="about"></ng-container>
  </div>
</div>

<ng-template #appearance>
  <div class="theme">
    <div class="theme-title">{{ "themes" | translate }}</div>

    <div class="theme-radio-btn">
      <mat-radio-group (change)="onToggleTheme($event)">
        <mat-radio-button value="light" [checked]="!isDarkMode">
          {{ "light" | translate }}
        </mat-radio-button>
        <mat-radio-button value="dark" [checked]="isDarkMode">
          {{ "dark" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="theme-title">{{ "lang" | translate }}</div>
    <div class="lang-radio-btn">
      <mat-radio-group (change)="setLanguage($event)">
        <mat-radio-button value="pt" [checked]="currentLanguage() === 'pt'">
          {{ "language.portuguese" | translate }}
        </mat-radio-button>
        <mat-radio-button value="es" [checked]="currentLanguage() === 'es'">
          {{ "language.spanish" | translate }}
        </mat-radio-button>
        <mat-radio-button value="en" [checked]="currentLanguage() === 'en'">
          {{ "language.english" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</ng-template>

<ng-template #profile>
  <div class="profile-container">
    <form [formGroup]="profileForm" role="form">
      <app-img-picker [form]="profileForm" controlName="profilePicture" (removePicture)="removeProfilePicture()">
      </app-img-picker>

      <div class="profile-title">
        {{ "personalInfo" | translate }}
      </div>

      <div class="profile-displayname">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label> {{ "name" | translate }} </mat-label>
          <input matInput type="text" formControlName="name" (focus)="setFocus(true)" (blur)="setFocus(false)" />
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
          <mat-label> {{ "lastName" | translate }} </mat-label>
          <input matInput type="text" formControlName="lastName" (focus)="setFocus(true)" (blur)="setFocus(false)" />
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="changeProfileData()" style="text-transform: uppercase"
          [disabled]="profileForm.invalid">
          {{ "save" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #password>
  <div class="password-container">
    <div class="password-title">
      {{ "changePassword" | translate }}
    </div>

    <form role="form" [formGroup]="passwordForm">
      <div class="password-manager">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label> {{ "currentPassword" | translate }} </mat-label>
          <input matInput [type]="showPassword[0] ? 'text' : 'password'" autocomplete="off"
            formControlName="currentPassword" (focus)="setFocus(true)" (blur)="setFocus(false)" />
          <button mat-icon-button matSuffix (click)="showPassword[0] = !showPassword[0]">
            <mat-icon>
              {{ showPassword[0] ? "visibility" : "visibility_off" }}
            </mat-icon>
          </button>
          <mat-error *ngIf="passwordForm.get('currentPassword').hasError('required')">
            {{ "requiredField" | translate }}
          </mat-error>
          <mat-error *ngIf="
              passwordForm.get('currentPassword').hasError('incorrectPassword')
            ">
            {{ "wrongPassword" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>
            {{ "password" | translate }}
          </mat-label>
          <input matInput [type]="showPassword[1] ? 'text' : 'password'" autocomplete="off" formControlName="password"
            (focus)="setFocus(true)" (blur)="setFocus(false)" />
          <button mat-icon-button matSuffix (click)="showPassword[1] = !showPassword[1]">
            <mat-icon>
              {{ showPassword[1] ? "visibility" : "visibility_off" }}
            </mat-icon>
          </button>
          <mat-error *ngIf="passwordForm.get('password').hasError('required')">
            {{ "requiredField" | translate }}
          </mat-error>
          <mat-error *ngIf="
              passwordForm.get('password').hasError('equalCurrentPassword')
            ">
            A nova senha não pode ser igual à senha atual
          </mat-error>
          <mat-error *ngIf="passwordForm.get('password').hasError('minlength')">
            {{ "passwordMinLength" | translate: { length: 6 } }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width m-t-sm">
          <mat-label>
            {{ "confirmPassword" | translate }}
          </mat-label>
          <input matInput [type]="showPassword[2] ? 'text' : 'password'" autocomplete="off"
            formControlName="confirmPassword" (focus)="setFocus(true)" (blur)="setFocus(false)" />
          <button mat-icon-button matSuffix (click)="showPassword[2] = !showPassword[2]">
            <mat-icon>
              {{ showPassword[2] ? "visibility" : "visibility_off" }}
            </mat-icon>
          </button>
          <mat-error *ngIf="passwordForm.get('confirmPassword').hasError('required')">
            {{ "requiredField" | translate }}
          </mat-error>
          <mat-error *ngIf="
              passwordForm.get('confirmPassword').hasError('diffPasswords')
            ">
            {{ "passwordDoNotMatch" | translate }}
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="changePassword()" [disabled]="passwordForm.invalid"
          style="text-transform: uppercase">
          {{ "changePassword" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #callSettings>
  <div class="call-config">
    <!-- CÓDIGO DE ROTEAMENTO EXTERNO -->
    <div class="call-config-title">{{ "externalRoutingCode" | translate }}</div>
    <div class="call-config-container">
      <div class="m-h-auto" *ngIf="externalRouteExist(); else without_route_code">
        <div class="normal-text-bold" style="font-weight: 500 !important">
          {{ "autoRoutingCode" | translate }}
        </div>
        <p class="small-text-regular">
          <span> {{ "code" | translate }}</span> :
          {{ showExternalRouteValue() }}
        </p>
      </div>
      <ng-template #without_route_code>
        <div class="small-text-regular">
          {{ "manualRoutingCode" | translate }}
        </div>
      </ng-template>
    </div>

    <!-- CÓDIGO DE TRANSFERÊNCIAS -->
    <!-- <div class="call-config-title">CÓDIGO DE TRANSFERÊNCIAS</div>
    <div class="call-config-container">
      <div class="m-h-auto">
        <div class="small-text-regular">Transferência direta:
          <span> {{ blindTransferCode ? blindTransferCode : 'Nenhum código predefinido' }} </span>
        </div>
      </div>
      <div class="m-h-auto">
        <div class="small-text-regular">Transferência com consulta:
          <span> {{ attendedTransferCode ? attendedTransferCode :  'Nenhum código predefinido' }} </span>
        </div>
      </div>
    </div> -->
  </div>
</ng-template>

<ng-template #initialization>
  <div class="initialization">
    <div class="initialization-title">{{ "initialize" | translate }}</div>

    <mat-slide-toggle labelPosition="before" [checked]="autoLaunch" (change)="toggleAutoLaunch($event)">
      {{ "autoStartMobiPhoneWithSystem" | translate }}.
    </mat-slide-toggle>

    <mat-slide-toggle labelPosition="before" [checked]="startMinimized" [disabled]="!autoLaunch"
      (change)="toggleMinimization($event)">
      {{ "startMinimized" | translate }}.
    </mat-slide-toggle>
  </div>
</ng-template>

<ng-template #devicesSettings>
  <div class="device-settings-container" [ngClass]="{dark: theme.isDarkTheme()}">
    <div class="device-settings-title">
      {{ 'devicesSettings' | translate }}
    </div>
    <app-devices-settings></app-devices-settings>
  </div>
</ng-template>

<ng-template #about>
  <div class="about" [ngClass]="{dark: theme.isDarkTheme()}">
    <div class="about-title">
      {{ (isElectron ? "versions" : 'version') | translate }}
    </div>

    <div class="version-container">

      <!-- Desktop -->
      <div class="m-h-auto about-txt" *ngIf="isElectron && desktopVersion !== ''"
        style="margin-bottom: 10px !important;">

        {{ "desktopApplication" | translate }} <br>

        <span>
          {{ "version" | translate }}: {{ desktopVersion }} -

          <span *ngIf="isValidVersion(desktopVersion, 'Desktop');else newDesktopVersion">
            {{'updateOn' | translate }}: {{getDesktopReleaseDate() | timelineDateFormatPipe:'date':true }}
          </span>

        </span>

      </div>

      <!-- Web -->
      <div class="m-h-auto about-txt">
        {{ "mobiPhoneService" | translate }} <br>

        <span>
          {{ "version" | translate }}: {{ mobiPhoneVersion }} -

          <span *ngIf="isValidVersion(mobiPhoneVersion, 'Web');else newWebVersion">
            {{'updateOn' | translate }}: {{getWebReleaseDate() | timelineDateFormatPipe:'date':true }}
          </span>

        </span>
      </div>
    </div>

    <div class="about-title">{{ "TermsAndPolicy" | translate }}</div>

    <div class="lgpd-container">
      <div class="m-h-auto about-txt">
        <a (click)="openLGPDAgreement()">{{ "TermsOfUse" | translate }}</a>
      </div>
      <div class="m-h-auto about-txt">
        <a (click)="openPrivacyPolicy()"> {{ "PrivacyPolicy" | translate }} </a>
      </div>
    </div>
    <div class="desktop-download-container">
      <app-download></app-download>
    </div>
  </div>
</ng-template>

<ng-template #newDesktopVersion>
  <a class="new-version" (click)="updateElectron()">
    {{ "outOfDateMessage" | translate }}
  </a>
</ng-template>

<ng-template #newWebVersion>
  <a class="new-version" (click)="updateWeb()">
    {{ "outOfDateMessage" | translate }}
  </a>
</ng-template>

<div cdkFocusInitial></div>
