import { DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ExternalUser } from './external-user.model';
import { User } from './user.model';

export interface History {
  id;
  contact: DocumentReference<User>;
  type: string;
  date: any;
  user?: Observable<User | ExternalUser>;
  startedAt?: any;
  endedAt?: any;
}
