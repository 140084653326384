import { environment } from "@environments/environment";
import {
  MATRIX_LOCAL_STORAGE_ACCESS_TOKEN,
  MATRIX_LOCAL_STORAGE_BASE_URL,
  MATRIX_LOCAL_STORAGE_DEVICE_ID,
  MATRIX_LOCAL_STORAGE_EMOJI_HISTORY,
  MATRIX_LOCAL_STORAGE_LAST_ROOM,
  MATRIX_LOCAL_STORAGE_MEET_TAB_INDEX,
  MATRIX_LOCAL_STORAGE_TAB_INDEX,
  MATRIX_LOCAL_STORAGE_USER_ID
} from "@shared/constants";
import { Emoji, MatrixLoginCredentials } from "@shared/models";
import { MatrixEvent } from "matrix-js-sdk";

// Variables
const secretStorageKeys = new Map();

/**
 * Save Last Event Info
 * @param {string} roomId Room id
 * @param {MatrixEvent} event Matrix event
 */
export const saveLastEventInfo = (roomId: string, event: MatrixEvent) => {
  localStorage.setItem(roomId, JSON.stringify({
    msg: event.getContent().body,
    msgType: event.getContent().msgtype,
    time: event.getDate()?.getTime(),
    eventType: event.getType(),
  }))
}

/**
 * Get Last Event Info
 * @param {string} roomId Room id
 * @returns { msg: string, msgType: string, time: number, eventType: string } Last event on timeline
 */
export const getLastEventInfo = (roomId: string): { msg: string, msgType: string, time: number, eventType: string } => {
  return JSON.parse(localStorage.getItem(roomId))
}

/**
 * Get Emoji history
 * @returns {Emoji[]} Emojis
 */
export const getEmojiHistory = (): Emoji[] => {
  return JSON.parse(localStorage.getItem(MATRIX_LOCAL_STORAGE_EMOJI_HISTORY)) ?? []
}

/**
 * Save Emoji on history
 */
export const saveEmojiOnHistory = (emoji: Emoji) => {
  const emojis = getEmojiHistory();
  if (!!emojis.find(value => value.imgUrl == emoji.imgUrl)) return;
  else emojis.push(emoji);
  localStorage.setItem(MATRIX_LOCAL_STORAGE_EMOJI_HISTORY, JSON.stringify(emojis))
}

/**
 * Get the last visited room
 * @returns {string} Room id
 */
export const getTheLastVisitedRoom = (): string => {
  return localStorage.getItem(MATRIX_LOCAL_STORAGE_LAST_ROOM);
}

/**
 * Delete the last visited room
 */
export const deleteTheLastVisitedRoom = (): void => {
  localStorage.removeItem(MATRIX_LOCAL_STORAGE_LAST_ROOM);
}

/**
 * Save id of last visited room
 * @param {string} roomId Room id
 */
export const setCurrentRoom = (roomId: string): void => {
  localStorage.setItem(MATRIX_LOCAL_STORAGE_LAST_ROOM, roomId);
}

/**
 * Save tab index
 * @param {0 | 1} tabIndex Tab index
 */
export const setTabIndex = (tabIndex: 0 | 1): void => {
  localStorage.setItem(MATRIX_LOCAL_STORAGE_TAB_INDEX, tabIndex.toString());
}

/**
 * Get tab index
 */
export const getTabIndex = (): number => {
  return +getMatrixSecret(MATRIX_LOCAL_STORAGE_TAB_INDEX);
}

/**
 * Save tab index
 * @param {0 | 1} tabIndex Tab index
 */
export const setMeetTabIndex = (tabIndex: 0 | 1): void => {
  localStorage.setItem(MATRIX_LOCAL_STORAGE_MEET_TAB_INDEX, tabIndex.toString());
}

/**
 * Get tab index
 */
export const getMeetTabIndex = (): number => {
  return +getMatrixSecret(MATRIX_LOCAL_STORAGE_MEET_TAB_INDEX);
}

/**
 * Get Login credentials
 * @returns {MatrixLoginCredentials} Login credentials
 */
export const getMatrixCredentials = (): MatrixLoginCredentials => {
  return {
    access_token: getMatrixSecret(MATRIX_LOCAL_STORAGE_ACCESS_TOKEN),
    device_id: getMatrixSecret(MATRIX_LOCAL_STORAGE_DEVICE_ID),
    user_id: getMatrixSecret(MATRIX_LOCAL_STORAGE_USER_ID),
  }
}

/**
 * Get secret from local storage
 * @param {string} key Key
 * @returns {string} Local storage value
 */
export const getMatrixSecret = (key: string): string => {
  return localStorage.getItem(key);
}

/**
 * Check if the user is authenticated in Matrix
 * @returns {boolean} If user is authenticated
 */
export const isAuthenticatedInMatrix = (): boolean => {
  return getMatrixSecret(MATRIX_LOCAL_STORAGE_ACCESS_TOKEN) !== null;
}

/**
 * Update Matrix Local Store
 * @param {string} accessToken Access Token
 * @param {string} deviceId Device Id
 * @param {string} userId User id
 * @param {string} baseUrl URL
 */
export const updateMatrixLocalStore = (
  accessToken: string,
  deviceId: string,
  userId: string,
  baseUrl: string = environment.matrixUrl
): void => {
  localStorage.setItem(MATRIX_LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
  localStorage.setItem(MATRIX_LOCAL_STORAGE_DEVICE_ID, deviceId);
  localStorage.setItem(MATRIX_LOCAL_STORAGE_USER_ID, userId);
  localStorage.setItem(MATRIX_LOCAL_STORAGE_BASE_URL, baseUrl);
}

/**
 * Clear local matrix credentials
 */
export const clearMatrixLocalStore = (): void => {
  localStorage.removeItem(MATRIX_LOCAL_STORAGE_ACCESS_TOKEN);
  localStorage.removeItem(MATRIX_LOCAL_STORAGE_DEVICE_ID);
  localStorage.removeItem(MATRIX_LOCAL_STORAGE_USER_ID);
  localStorage.removeItem(MATRIX_LOCAL_STORAGE_BASE_URL);
}

export const storePrivateKey = (keyId: string, privateKey: any) => {
  if (privateKey instanceof Uint8Array === false) {
    throw new Error('Unable to store, privateKey is invalid.');
  }
  secretStorageKeys.set(keyId, privateKey);
}

export const hasPrivateKey = (keyId: string) => {
  return secretStorageKeys.get(keyId) instanceof Uint8Array;
}

export const getPrivateKey = (keyId: string) => {
  return secretStorageKeys.get(keyId);
}

export const deletePrivateKey = (keyId: string) => {
  // @ts-ignore
  delete secretStorageKeys.delete(keyId);
}

export const clearSecretStorageKeys = () => {
  secretStorageKeys.clear();
}

const getSecretStorageKey = async ({ keys }): Promise<[string, Uint8Array]> => {
  const keyIds = Object.keys(keys);
  const keyId = keyIds.find(hasPrivateKey);
  if (!keyId) return undefined;
  const privateKey = getPrivateKey(keyId);
  return [keyId, privateKey];
}

const cacheSecretStorageKey = (keyId: string, keyInfo: any, privateKey: any) => {
  secretStorageKeys.set(keyId, privateKey);
}

export const matrixCryptoCallbacks = {
  getSecretStorageKey,
  cacheSecretStorageKey,
};
