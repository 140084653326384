export enum ANALYTICS_EVENTS {
  emoji_picker = 'use_emoji_picker',

  gif_picker = 'use_gif_picker',

  call_by_room = 'call_by_room',
  call_by_contacts = 'call_by_contacts',
  call_by_history = 'call_by_history',
  call_by_keyboard = 'dialer_by_keyboard',

  auto_join_error = 'auto_join_error',
  auto_join_error_404 = 'auto_join_error_404',

  received_call = 'received_call',

  attended_transfer = 'attended_transfer',
  blind_transfer = 'blind_transfer',

  init_dm_by_search = 'init_dm_by_search',
  init_dm_by_contact_button = 'init_dm_by_contact_button',
  init_dm_by_chat_button = 'init_dm_by_chat_button',
  init_dm_by_forward_button = 'init_dm_by_forward_button',

  init_group_by_dm = 'init_group_by_dm',
  init_group_by_button = 'init_group_by_button',

  mic_error = "jitsi_mic_error",
  peer_connection_failure = "jitsi_peer_connection_failure",
  jitsi_error_occurred = "jitsi_error_occurred",
  jitsi_suspend = "jitsi_suspend",
}
