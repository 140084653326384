// External route
export const EXTERNAL_ROUTE_REGEX = /^[\d\#?\*?]{0,3}$/;

// DDD
export const DDD_REGEX = /(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])/;

// DDI
export const DDI_REGEX = /[1-9]{2}/;

// Text with accents and aA-zZ
export const TEXT_WITH_ACCENTS_REGEX = /^[A-Za-z\sáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+|[A-Za-z\sáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]*$/;

// EMOTICON
export const EMOTICON_SYMBOL_REGEX = /:\)|;?\)|:-\)|;-\)|:\(|:-\(|:o|:-o|:O|:0|:-O|:D|:-D|:P|:-P|:p|:-p|:\'\(|:s|:S|:\$|:\@|:>|:\*|:\^\)|:\'D|:3|:'\(|:X|:O\)|;\(|<3|<\/3|:\||;-?\)|;-?D|:-v|:-\||:-@|:'-?\(|:-\$|:-\*|:-3|<3|:-s|:o\)|:v/gi;
export const EMOTICON_NAMES_REGEX = /:(fire|thumbsup|thumbsdown|poop):/gi;
export const EMOTICON_REGEX = new RegExp(`${EMOTICON_SYMBOL_REGEX.source}|${EMOTICON_NAMES_REGEX.source}`);

// Email regex
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Email Domain Regex
export const EMAIL_DOMAIN_REGEX = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// --- A TAG ---
// Email To regex
export const EMAIL_TO_REGEX = /\b[\w-\.]+@([\w-]+\.)+[\w-]{2,4}\b/g

export const EMAIL_DOMAIN =
  /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// LINKS
export const LINKS_REGEX = /\b(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\b/gi

// Phone regex
export const PHONE_REGEX = /\b(\+[0-9]{1,3}\s?)?(\([0-9]{2,3}\)|[0-9]{2,3})?\s?([9][0-9]{4})[-\s]?([0-9]{4})\b/gi;

// <a> element regex (links, emails and phones)
export const A_TAG_REGEX = new RegExp(`(${LINKS_REGEX.source}|${EMAIL_TO_REGEX.source}|${PHONE_REGEX.source})`, 'gi');
// --- A TAG ---

// --- HTML with links ---
// Img src regex
export const IMG_SRC_REGEX = /<img[^>]*src=['"]([^'"]+)['"][^>]*>/g;

// A href regex
export const A_HREF_REGEX = /<a[^>]*href=['"]([^'"]+)['"][^>]*>(.*?)<\/a>/g;
// --- HTML with links ---

// Only numbers regex (used in phone number)
export const ONLY_NUMBERS_REGEX = /^[0-9]*$/

export const NUMBER_REGEX = /^\d+$/;

export const PROTOCOL_REGEX = /^(\d){1,20}\.?\d{1,10}$/;

export const DOMINE_REGEX =
  /^(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$|^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export const HTTPS_REGEX =
  /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const COMPANY_NAME_REGEX = /^(?:([a-z]|[A-Z]).*)$/;

export const PHONE_NUMBER = /^\d{10,14}$/;

export const CPF_CNPJ_REGEX = /(^[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$)|(^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2})$/;

export const CNPJ_REGEX = /(^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}\-[0-9]{2})$/;

export const TRANSFER_CODE_REGEX = /^[\d\#?\*?]{0,}$/;

export const DEVICE_EXT_PASSWORD_REGEX = /^[a-zA-Z0-9_\-\.!]{8,31}$/;

export const MAC_ADDRESS_OR_PORT_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-5][0-5][0-3][0-5])(\/([1-9]|[1-9][0-9]{1,3}|[1-3][0-9]{4}|40[0-8][0-9]|409[0-5]))?$/;
