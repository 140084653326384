import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { ThemeService, UtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
  // Prevent copy, paste and cut
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() minDate: Date;
  @Input() defaultDate: Date;
  @Input() showClear: boolean = true;
  @Input() hideTime: boolean = false;
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() disabled: boolean = false;
  @Input() maxDate: Date = this.setMaxDate();
  @Input() onlyMonth: boolean = false;

  public childControl: AbstractControl;
  public dateformat: string;

  constructor(
    private translate: TranslateService,
    private utilsService: UtilsService,
    private adapter: NgxMatDateAdapter<Date>,
    public themeService: ThemeService
  ) {
    this.adapter.setLocale(this.translate.currentLang);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultDate)
      this.childControl?.patchValue(changes.defaultDate.currentValue);
  }

  ngOnInit(): void {
    this.childControl = this.form.get(this.controlName);
    this.dateformat = this.utilsService.getDateFormatByLocale(this.onlyMonth);
    if (this.hideTime) this.dateformat = this.dateformat.replace(' HH:mm', '')
    if (this.defaultDate && !this.childControl?.value) // Set default date
      this.childControl.patchValue(this.defaultDate);
  }

  /**
   * Clear input
   * @param {Event} event Event
   */
  public clearDate(event: Event): void {
    event.stopPropagation();
    // Reset date
    if (!this.disabled) {
      this.childControl.patchValue(this.defaultDate ?? null);
    }
  }

  /**
   * Prevent users from entering any data
   * @returns {boolean} False value
   */
  public eventHandler(): boolean {
    return false;
  }

  /**
   * Close date picker
   * @param dp
   */
  closeDatePicker(eventData: any, dp?: any) {
    // get month and year from eventData and close datepicker, thus not allowing user to select date
    if (this.onlyMonth) {
      this.childControl.patchValue(eventData);
      dp.close();
    }
  }

  /**
   * Define max datepicker date
   * @returns Max date
   */
  private setMaxDate(): Date {
    const date = new Date();
    date.setHours(23)
    date.setMinutes(59)
    return date;
  }
}
