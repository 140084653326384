import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JitsiMobileGuard, JitsiTokenGuard } from '@core/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'action',
    loadChildren: () =>
      import('./features/email-actions/email-actions.module').then(
        (m) => m.EmailActionsModule
      ),
  },
  {
    path: 'meeting-guest/:id',
    canActivate: [JitsiTokenGuard],
    loadChildren: () => import('./features/guest-meeting/guest-meeting.module').then(m => m.GuestMeetingModule)
  },
  {
    path: 'external-meeting/:token',
    canActivate: [JitsiMobileGuard],
    loadChildren: () => import('./features/external-meeting/external-meeting.module').then(m => m.ExternalMeetingModule)
  },
  {
    path: 'lcs-admin-panel/:token',
    loadChildren: () => import('./features/lcs-admin-panel/lcs-admin-panel.module').then(m => m.LCSAdminPanelModule)
  },
  {
    path: '404',
    loadChildren: () =>
      import('./core/not-found-page/not-found-page.module').then(
        (m) => m.NotFoundPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
