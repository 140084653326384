import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlatformService } from '@core/services';
import { RequestPermissionComponent, RequestPermissionDeniedComponent } from '@shared/components';
import { OverlayService } from '@shared/components/overlay/overlay.service';
import { MyOverlayRef } from '@shared/components/overlay/page/overlay.component';
import { ElectronService } from 'ngx-electron';

@Injectable({
  providedIn: 'root'
})
export class MediaDevicesService {

  constructor(
    private dialog: MatDialog,
    private overlay: OverlayService,
    private platform: PlatformService,
    private electron: ElectronService
  ) { }

  /**
   * Check if current platform is browser
   * @returns Boolean indicating if current platform is browser or not
   */
  private isBrowser(): boolean {
    return !this.platform.isMobile() && !this.electron.isElectronApp;
  }

  /**
   * Close current overlay
   * @param timeout Timeout to be reseted
   * @param overlayRef Current overlay to be closed
   */
  private closeOverlay(timeout: NodeJS.Timeout, overlayRef: MyOverlayRef): void {
    clearTimeout(timeout);
    if (overlayRef) {
      overlayRef.close();
    }
  }

  /**
   * Show permission denied component dialog
   */
  private showPermissionDeniedMessage(): void {
    this.dialog.open(RequestPermissionDeniedComponent);
  }

  /**
   * Provides access to hardware media devices like camera and microphones
   * @param constraints Constraints to request permission
   * @returns Streams with granted media device
   */
  async getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream> {
    let timeout: NodeJS.Timeout = null;
    let overlayRef: MyOverlayRef<RequestPermissionComponent, null> = null;

    if (this.isBrowser()) {
      timeout = setTimeout(() => {
        overlayRef = this.overlay.open(RequestPermissionComponent, null);
      }, 2000);
    }

    try {
      const streams = await navigator.mediaDevices.getUserMedia(constraints);
      this.closeOverlay(timeout, overlayRef);
      return streams;
    } catch (error) {
      this.closeOverlay(timeout, overlayRef);
      this.showPermissionDeniedMessage();
      return null;
    }
  }
}
