<div [ngClass]="{ dark: themeService.isDarkTheme() }">
  <h2 mat-dialog-title>
    LGPD - {{ "lgpd.generalDataProtectionLaw" | translate}}
    <span *ngIf="!data.readOnly">
      {{ "lgpd.subtitle" | translate }}.
    </span>
  </h2>
  <mat-dialog-content class="mat-typography">
    <h3><span> {{ "lgpd.knowledgeTerm" | translate}}</span> – (LGPD) {{ "lgpd.generalDataProtectionLaw" | translate}}</h3>

    <p [innerHTML]="'lgpd.paragraph1' | translate"></p>

    <p> {{ "lgpd.paragraph2" | translate}} </p>

    <p> {{ "lgpd.paragraph3" | translate}} </p>
      
    <p [innerHTML]="'lgpd.paragraph4' | translate"></p>

    <p [innerHTML]="'lgpd.paragraph5' | translate"></p>

    <p [innerHTML]="'lgpd.paragraph6' | translate"></p>

    <p [innerHTML]="'lgpd.paragraph7' | translate"></p>

    <p *ngIf="!data.readOnly">
      <mat-checkbox color="primary" [(ngModel)]="checked"></mat-checkbox>
      <strong>
        “{{ "lgpd.paragraph8" | translate }}”
      </strong>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      *ngIf="!data.readOnly"
      class="decline-btn"
      mat-button
      (click)="onDecline()"
    >
      {{ "back" | translate}}
    </button>
    <button
      *ngIf="!data.readOnly"
      class="accept-btn"
      mat-button
      cdkFocusInitial
      (click)="onAccept()"
      [disabled]="!checked"
    >
    {{ "accept" | translate}}
    </button>
    <button *ngIf="data.readOnly" mat-button mat-dialog-close>{{ "close" | translate}}</button>
  </mat-dialog-actions>
</div>
