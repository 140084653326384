<form [formGroup]="form" class="profile-container">
  <div class="profile-title title-button" *ngIf="form.get(controlName).value === null"
    (click)="pictureUploader.click()">
    {{ "addImage" | translate }}
  </div>
  <div class="profile-title image-row" *ngIf="form.get(controlName).value !== null">
    <div class="title-button m-r-xs" (click)="pictureUploader.click()">
      {{ "changeImage" | translate }}
    </div>
    •
    <div class="title-button m-l-xs" (click)="remove()">
      {{ "remove" | translate }}
    </div>
  </div>

  <div class="profile-picture">
    <div class="picture-content">
      <img loading="lazy" [src]="form.get(controlName).value ?? defaultImg" alt="Profile Picture"
        (error)="onImgError($event.target)" />
      <input type="file" hidden #pictureUploader accept=".png, .jpg, .jpeg"
        (change)="changeProfilePicture($event.target)" />
    </div>
  </div>
</form>
