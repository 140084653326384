export interface PbxSector {
  id?: string;
  pbxId: string;
  name: string;
  extension: string;
}

export interface CompanySector {
  id?: string;
  name: string;
  users: string[];
  abbreviation?: string;
}
