<!-- Footer - Actions buttons -->
<footer class="props-footer-actions" [ngClass]="{ dark: themeService.isDarkTheme() }">
  <!-- Cancel -->
  <button *ngIf="canShowCancel" mat-button (click)="goBack()">
    {{ cancelText | translate }}
  </button>

  <!-- Submit -->
  <button class="submit-button" [disabled]="isSubmitDisabled" color="primary" mat-raised-button type="submit"
    (click)="confirm.emit()">
    {{ submitText | translate }}
  </button>
</footer>
