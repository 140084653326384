import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { orderBy } from '@angular/fire/firestore';
import { FirestoreService, LcsEventsService } from '@core/services';
import { LcsEventDescriptions, LcsEventPABXTypes } from '@shared/enums';
import { Equipment } from '@shared/models';
import { getCreateEquipExtensionsPath, getDevicePath } from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor(
    private http: HttpClient,
    private firestore: FirestoreService,
    private lcsEventService: LcsEventsService,
  ) { }

  /**
   * Get all equipment data
   * @returns {Observable<Equipment[]>} Equipment data
   */
  public fetchAll(): Observable<Equipment[]> {
    return this.firestore
      .colWithIds$<Equipment>(getDevicePath(), [orderBy('name')]);
  }

  /**
   * Fetch equipment data by id
   * @param {string} equipmentId Equipment ID
   * @returns {Observable<Equipment>} Equipment data by ID
   */
  public fetchById(equipmentId: string): Promise<Equipment> {
    return lastValueFrom(
      this.firestore
        .docWithId$<Equipment>(getDevicePath(equipmentId))
        .pipe(first())
    );
  }

  /**
   * Create equipment extensions
   * @param {Equipment} equipment Equipment data
   */
  public async createEquipExtensions(equipment: Equipment) {
    try {
      // Create extensions
      await lastValueFrom(this.http.post<void>(getCreateEquipExtensionsPath(), {
        sn: equipment.sn, // Serial number
        equipType: equipment.equip_type, // Equipment type
        extQuantity: equipment.lic.max_ext // Maximum extensions
      }).pipe(first()))

      // Handle success
      this.handleSuccess(LcsEventPABXTypes.EXTENSION_CREATION, {
        createdExtensions: {
          sn: equipment.sn,
          equipType: equipment.equip_type,
          extQuantity: equipment.lic.max_ext
        }
      });
    } catch (error) {
      // Handle error
      this.handleError(error, LcsEventPABXTypes.EXTENSION_CREATION);
    }
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventPABXTypes} type The event type
   */
  public handleError(error: any, type: LcsEventPABXTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, true);

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsEventPABXTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventPABXTypes, value: any): void {
    this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, true);
  }
}
