import { GIF_HISTORY, INITIAL_GIF, MAX_GIF_HISTORY } from "@shared/constants";
import { Gif } from "@shared/models";

/**
 * Save Initial gif
 * @param {any} results Results
 */
export const saveInitialGif = (results: any) => {
  localStorage.setItem(INITIAL_GIF, JSON.stringify(results))
}

/**
 * Get Initial gif
 * @returns {any} Results
 */
export const getInitialGif = (): any => {
  return JSON.parse(localStorage.getItem(INITIAL_GIF))
}

/**
 * Get gif history
 * @returns {Emoji[]} Emojis
 */
export const getGifHistory = (): Gif[] => {
  return JSON.parse(localStorage.getItem(GIF_HISTORY)) ?? []
}

/**
 * Save gif on history
 */
export const saveGifOnHistory = (emoji: Gif) => {
  let emojis = getGifHistory();
  if (!!emojis.find(value => JSON.stringify(value) == JSON.stringify(emoji))) return;
  else emojis.push(emoji);
  if (emojis.length > MAX_GIF_HISTORY) emojis = emojis.slice(1);
  localStorage.setItem(GIF_HISTORY, JSON.stringify(emojis))
}

