import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PABX, Tenant, User } from '@shared/models';

type Data = {
  pbx: PABX[];
  company: Tenant;
  users: User[];
};

@Component({
  selector: 'app-select-pbx-dialog',
  templateUrl: './select-pbx-dialog.component.html',
  styleUrls: ['./select-pbx-dialog.component.scss']
})

export class SelectPbxDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<SelectPbxDialogComponent>
  ) { }

  ngOnInit(): void {
    // Init variables
    let pbxId = '';

    // Check if has only one PBX
    if (this.data.pbx.length === 1) pbxId = this.data.pbx[0].id;

    // Init form
    this.form = this.fb.group({
      pbxId: [pbxId],
    });
  }

  /**
   * Submit form
   */
  public onSubmit() {
    if (!this.form.valid) return;
    this.dialogRef.close(this.form.value);
  }
}
