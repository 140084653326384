import { Injectable } from '@angular/core';
import {
  addDoc,
  and,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  limit,
  orderBy,
  query,
  QueryCompositeFilterConstraint,
  runTransaction,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { AuthService, NotifierService, UtilsService } from '@core/services';
import { EXTERNAL_CONTACTS_IMPORT_LIMIT } from '@shared/constants';
import { Contact } from '@shared/models';
import { getContactsPath, normalizeTerm } from '@shared/utils';
import { BehaviorSubject, first, forkJoin, lastValueFrom, Observable } from 'rxjs';

const FIELDS = [
  {name: 'normalizedName', type: 'string'},
  {name: 'normalizedCompany', type: 'string'},
  {name: 'normalizedNumbers', type: 'array'},
]
@Injectable({
  providedIn: 'root',
})
export class ExternalContactsService {
  private companyId: string = '';
  public externalContacts$ = new BehaviorSubject<Contact[]>([]);
  public dynamicExternalContacts$ = new BehaviorSubject<Contact[]>([]);
  public contactsToFetch$ = new BehaviorSubject<number>(0);
  public searchTerm$ = new BehaviorSubject<string>('');
  public isCompanyList$ = new BehaviorSubject<boolean>(false);

  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private notifier: NotifierService,
    private utilService: UtilsService
  ) {
    this.companyId =
      this.authService.user.companyId ??
      localStorage.getItem('mobi_phone:tenantId');
  }

  /**
   * Fetch external contacts based on user scroll. This will also listen
   * to firestore changes, and update dinamically our contacts list
   * @param {number} contactsToFetch how many contacts
   * @param {boolean} isCompanyList whether if it's company list or user private contacts list
   * @param {string} searchTerm the search term (if present)
   */
  public async dynamicFetchContacts(contactsToFetch : number, isCompanyList : boolean, searchTerm?:string) {
    
    this.isCompanyList$.next(isCompanyList)
    // If a param is present, update the Observable
    if (contactsToFetch)
      this.contactsToFetch$.next(contactsToFetch)
    else
      contactsToFetch = this.contactsToFetch$.value
    // If no param present, use the Observable as param

    // If param is present, save it to Observable
    if (searchTerm || searchTerm === '')
      this.searchTerm$.next(searchTerm)
    else if (searchTerm !== '') {
      // If param is undefined AND observable has something, it is data to be preserved
      if (this.searchTerm$.value || this.searchTerm$.value === '') {
        searchTerm = this.searchTerm$.value
      }
    }

    // Get collection reference
    const appRef = collection(this.firestore, getContactsPath(this.companyId));

    // Input needs normalization because it's normalized on firestore
    const normalizedSearchInput = normalizeTerm(this.searchTerm$.value)

    // If has search term, fetch contacts with search term
    if(this.searchTerm$.value)
      return this.fetchContactsWithSearchTerm(appRef);
    return this.fetchContacts(appRef)
  }

  /**
   * This will fetch contacts with the presence of search term. For every
   * field to be searched (FIELDS) we have to make a query and update
   * the external contacts observable
   * 
   * In order to search for one more field, update FIELDS array
   * @param appRef The collection reference
   */
  private async fetchContactsWithSearchTerm(appRef: CollectionReference<DocumentData>){
    // One query for every field
    const queries: Observable<any>[] = [];

    // Mapping through fields
    FIELDS.map((field) => {

      // Query constraints
      const queryConstraints = [];

      // Create the query
      queryConstraints.push(
        this.createCompleteSearchQueryConstraint(field.name, field.type, normalizeTerm(this.searchTerm$.value))
      );

      // Query order
      queryConstraints.push(orderBy(field.name));

      // Query limit
      queryConstraints.push(limit(this.contactsToFetch$.value))
  
      // Form query
      const appQuery = query(appRef, ...queryConstraints);

      queries.push(collectionData(appQuery, { idField: 'id' }).pipe(first())) 
    })

    // Matrix with the result of all queries
    const allQueriesResult = await lastValueFrom(forkJoin(queries))
    
    // Array to remove duplicates
    const contactsWithouDuplicate = []

    // Compound all queries result into one array
    allQueriesResult.map((contactsArr) => {
      contactsArr.map((contact) => {

        // Remove duplicates
        const alreadyExist = contactsWithouDuplicate.some(localContact => contact.id == localContact.id)
        if(!alreadyExist)
          contactsWithouDuplicate.push(contact)
      })
      
    })

    // Update observable
    this.dynamicExternalContacts$.next(contactsWithouDuplicate)
    return;
  }

  /**
   * Fetch contacts without search term
   * @param appRef The collection reference
   */
  private async fetchContacts(appRef: CollectionReference<DocumentData>){

    // Query constraints (rules)
    const queryConstraints = [];

    // Create constraint, whether it's company's list or user's list
    queryConstraints.push(
      this.createQueryConstraint(
        this.isCompanyList$.value
      )
    );
    
    // Query order based on the first field
    queryConstraints.push(orderBy(FIELDS[0].name));

    // Query limit
    queryConstraints.push(limit(this.contactsToFetch$.value))

    // Form query
    const appQuery = query(appRef, ...queryConstraints); 

    // Observable
    const obs$ = collectionData(appQuery, { idField: 'id' }).pipe(first());

    // Await for contacts
    const cts = await lastValueFrom(obs$) as any[]
    
    // Remove duplicate based on id
    const notDuplicatedArr = this.utilService.getArrWithoutDuplicatedFieds(cts, 'id')

    // Updatd observable
    this.dynamicExternalContacts$.next(notDuplicatedArr as any[])
    return;
  }

  /**
   * Create a general query constraint, for company contact or personal contact
   * @param isCompanyList Whether if it's company list or user private contact list
   * @returns {QueryCompositeFilterConstraint} the query constraint
   */
  private createQueryConstraint(isCompanyList: boolean){
    if (isCompanyList) 
      return where('isCompanyContact', '==', true)
    return and(where('createdBy', '==', this.authService.userId), where('isCompanyContact', '==', false))
  }

  /**
   * Create a query constraint to handle the type of field to be searched
   * @param fieldToBeSearched Field to be searched
   * @param typeOfFieldToBeSearched Type of field (eg string, array, number)
   * @param searchTerm The search term
   * @returns The query constraint
   */
  private createSearchQueryConstraint(fieldToBeSearched: string, typeOfFieldToBeSearched: string, searchTerm: string){
    
    // Based on field type, do different queries
    switch(typeOfFieldToBeSearched){
      case 'string':
        return and(
          where(fieldToBeSearched, '>=', searchTerm),
          where(fieldToBeSearched, '<=', searchTerm + '\uf8ff')
        )
      case 'array':
        return where(fieldToBeSearched, 'array-contains', searchTerm)
    }
  }

  /**
   * Combines the General Query Constraint with the Search Query Constraint
   * @param fieldToBeSearched Field to be searched
   * @param typeOfFieldToBeSearched Type of field (eg string, array, number)
   * @param searchTerm The search term
   * @returns The query constraint
   */
  public createCompleteSearchQueryConstraint(fieldToBeSearched: string, typeOfFieldToBeSearched: string, searchTerm: string){
    return and(
      this.createQueryConstraint(this.isCompanyList$.value),
      this.createSearchQueryConstraint(fieldToBeSearched, typeOfFieldToBeSearched, searchTerm)
    )
  }

  /**
   * Check if the contact exist in the company contacts or my contacts
   * @param {boolean} toCompany Boolean to check if the contact will be added to company contacts or my contacts
   * @returns {boolean} true if the contact already exist, false if not
   */
  public contactAlreadyExist(
    toCompany: boolean,
    currentContact: Contact
  ): Contact[] {
    // Get my contacts
    const contacts = this.externalContacts$.value.filter(
      (contact) =>
        toCompany
          ? contact.isCompanyContact // If is company contact
          : !contact.isCompanyContact // If is my contact
    );

    // Get current phone numbers
    const currentPhoneNumbers = currentContact.phoneNumbers.map(
      (phoneNumber) => phoneNumber.number
    );

    // Get contacts with conflicts
    const contactsWithConflicts = contacts.filter((contact) =>
      this.hasConflict(contact, currentPhoneNumbers)
    );

    // Check if the current contact has the same phone number as the contact in contacts
    return contactsWithConflicts;
  }

  /**
   * Check if the contact has conflict with the current phone numbers
   * @param {Contact} contact { name: string, phoneNumbers: PhoneNumber[]
   * @param {string[]} currentPhoneNumbers Array of phone numbers
   * @returns {boolean} true if the contact has conflict
   */
  private hasConflict(
    contact: Contact,
    currentPhoneNumbers: string[]
  ): boolean {
    // Get contact phone numbers
    const contactPhoneNumbers = contact.phoneNumbers.map(
      (phoneNumber) => phoneNumber.number
    );

    // Check if the current contact has the same phone number as the contact in contacts
    return contactPhoneNumbers.some((phoneNumber) =>
      currentPhoneNumbers.includes(phoneNumber)
    );
  }

  /**
   * Create a new external contact
   * @param {Contact} contact Contact data
   */
  public async createExternalContact(
    contact: Contact,
    companyId: string = this.companyId
  ): Promise<string> {
    // Get collection reference
    const colRef = collection(this.firestore, getContactsPath(companyId));

    // Add contact to firestore
    const newDoc = await addDoc(colRef, contact);

    // Show notification
    this.notifier.showNotification({
      type: 'success',
      message: 'contactCreatedSuccessfully',
      actionText: 'OK',
      panelClass: 'success',
    });

    // For every create, wait 2 seconds and then fetch again
    setTimeout(async () => {
      await this.dynamicFetchContacts(
        20,
        this.isCompanyList$.value,
        this.searchTerm$.value
      )
    }, 2000)
    // Return contact id
    return newDoc.id;
  }

  public generateRandomId(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  /**
   * Create a new external contact
   * @param {Contact} contact Contact data
   */
  public async createExternalContacts(
    contacts: Contact[],
    companyId: string = this.companyId
  ) {
    try {
      // Create a transaction queue
      await runTransaction(this.firestore, async (trans) => {
        // For every contact, we specify which operation we wanna do (set)
        contacts.map((contact) => {
          if (contacts.indexOf(contact) < EXTERNAL_CONTACTS_IMPORT_LIMIT) {
            // We generate a unique ID in client side before sending
            const path = getContactsPath(companyId).concat(
              '/',
              this.generateRandomId(28)
            );

            // Now we have a specific document reference for our new contact
            const ref = doc(this.firestore, path);

            // Here we queue this transaction that will be completed at the end of runTransaction
            trans.set(ref, contact);
          }
        });
      }).catch((err) => console.log(err));

      // For every import, wait 2 seconds and then fetch again
      setTimeout(async () => {
        await this.dynamicFetchContacts(
          20,
          this.isCompanyList$.value,
          this.searchTerm$.value
        )
      }, 3000)

      let notifierMessage;
      if (contacts.length <= 1) notifierMessage = 'contactCreatedSuccessfully';
      else notifierMessage = 'contactsCreatedSuccessfully';

      this.notifier.showNotification({
        type: 'success',
        message: notifierMessage,
        actionText: 'OK',
        panelClass: 'success',
      });
    } catch (err) {
      console.log(err);
    }
  }

  public async getById(id: string) {
    // Get doc reference
    const docRef = doc(this.firestore, getContactsPath(this.companyId, id));

    // Get doc
    const document = await getDoc(docRef);
    // Check if doc exists
    if (!document.exists()) return null;

    // Get contact data
    const contact = document.data() as Contact;

    // Add id to contact
    contact.id = id;

    // Return contact data
    return contact;
  }

  public async update(newContact: Contact, contactId: string) {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getContactsPath(this.companyId, contactId)
    );

    // Update contact
    await updateDoc(docRef, { ...newContact });

    // Show notification
    this.notifier.showNotification({
      type: 'success',
      message: 'contactUpdatedSuccessfully',
      actionText: 'OK',
      panelClass: 'success',
    });
  }

  /**
   * Delete a contact
   * @param {Contact} contact Contact data
   */
  public async remove(contact: Contact): Promise<void> {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getContactsPath(this.companyId, contact.id)
    );

    // For every delete, wait 2 seconds and then fetch again
    setTimeout(async () => {
      await this.dynamicFetchContacts(
        20,
        this.isCompanyList$.value,
        this.searchTerm$.value
      )
    }, 2000)
    // Delete contact from firestore
    await deleteDoc(docRef);

    // Handle notification
    this.notifier.showNotification({
      type: 'success',
      message: 'deletedSuccessfully',
      actionText: 'OK',
      panelClass: 'success',
    });
  }

  // AsyncContacts
  async getExternalContactNameByPhoneNumber(
    destinationPhone: string,
    isExternal?: boolean,
    initialDestinationName?: string
  ): Promise<string> {

    return new Promise((resolve, reject) => {
      this.getContactsByNumber(destinationPhone).then((res) => {
        if (res.length >= 1) {
          const contact = (res as Contact[])[0]
          const name = contact.name
          resolve(name)
        }
        resolve('')
      }).catch((err) => {
        reject(err)
      })
    })

  }

  getEightPhoneDigits(number: string) {
    // Reversing the string (for example: (35)91234-5678 becomes 87654321953)
    let num: string = number.split('').reverse().join('');

    if (num.length >= 8) {
      // Getting first 8 digits (87654321953 becomes 87654321)
      num = num.slice(0, 8);

      // Reverse again (87654321 becomes 1234-5678)
      num = num.split('').reverse().join('');
    }

    return num;
  }

  takeLastNDigits(value: string, numberOfDigits: number) {
    let key: string = this.reverseString(value);
    key = key.slice(0, numberOfDigits);
    key = this.reverseString(key);

    return key;
  }

  reverseString(value: string) {
    let reversedString: string = value.split('').reverse().join('');
    return reversedString;
  }

  /**
 * Get meetings
 * @param {string} id Meet id
 * @returns Meetings in that company
 */
  public getContactsByNumber(number: any): Promise<Contact[]> {
    if (number) {

      // Get collection reference
      const appRef = collection(this.firestore, getContactsPath(this.authService.user.companyId));
      // Form query
      const appQuery = query(appRef, where('normalizedNumbers', 'array-contains', number));

      // Return collection data
      return lastValueFrom(collectionData(appQuery, { idField: 'id' })
        .pipe(first()
        )) as Promise<Contact[]>
    }
    return new Promise((res, rej) => {
      res([])
    });
  }

  filterContacts(fetchedContacts: any[], destinationPhone: any, destinationName: any, isExternal: boolean) {

    let destinationPhoneNumbers = [];

    // Search inside externalContacts stored locally
    fetchedContacts.map((contact) => {
      contact.phoneNumbers.map((phoneNumber) => {
        const number = phoneNumber.number;

        const epd = this.getEightPhoneDigits(number);
        // If there's a contact similar to remote number
        if (destinationPhone.includes(epd) && epd.length == 8) {
          destinationPhoneNumbers.push({ number: number, name: contact.name });
        } else if (destinationPhone.length <= 5) {
          if (destinationPhone == number) {
            if (phoneNumber.description == 'serviceNumber') {
              destinationName = contact.name;
              isExternal = true;
            }
          }
        }
      });
    });

    let isNumberWithDDD = false;
    let isNumberWithCountryCode = false;

    if (destinationPhoneNumbers.length > 1) {
      destinationPhoneNumbers.map((phoneNumber) => {
        const number = phoneNumber.number;
        const numberWithDDD = this.takeLastNDigits(number, 11);
        const numberWithoutDDD = this.takeLastNDigits(number, 9);
        const numberWithCountryCode = this.takeLastNDigits(number, 13);

        if (
          numberWithCountryCode.length == 13 &&
          destinationPhone.includes(numberWithCountryCode)
        ) {
          isNumberWithCountryCode = true;
          destinationName = phoneNumber.name;
        } else if (
          numberWithDDD.length == 11 &&
          destinationPhone.includes(numberWithDDD) &&
          numberWithCountryCode.length != 13
        ) {
          isNumberWithDDD = true;
          if (!isNumberWithCountryCode) {
            destinationName = phoneNumber.name;
          }
        } else if (
          destinationPhone.includes(numberWithoutDDD) &&
          number.length == 9
        ) {
          if (!isNumberWithDDD && !isNumberWithCountryCode) {
            destinationName = phoneNumber.name;
          }
        }
      });
    } else {
      if (!destinationName) destinationName = destinationPhoneNumbers[0]?.name;
    }
    return destinationName;
  }
}

