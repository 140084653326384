import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-permission-denied',
  templateUrl: './request-permission-denied.component.html',
  styleUrls: ['./request-permission-denied.component.scss'],
})
export class RequestPermissionDeniedComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RequestPermissionDeniedComponent>
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
