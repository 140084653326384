import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type Data = {
  title: string;
  desc: string;
  btnText?: string;
  hideCancel?: boolean;
  buttonColor?: string;
}

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent implements OnInit {
  buttonColor = 'primary';

  constructor(
    public dialogRef: MatDialogRef<SimpleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    if (this.data.buttonColor) this.buttonColor = this.data.buttonColor;
  }

  onSubmit() {
    this.dialogRef.close(true)
  }

}
