import { ENTRY_ROUTE_DESTINATIONS_ENUM } from "@shared/enums/entryRoute.enum";

export interface EntryRoute {
  id?: string;
  name: string,
  source: string,
  destination: Destination,
}

export interface Destination {
  type: ENTRY_ROUTE_DESTINATIONS_ENUM;
  value: string;
}

export interface TimeCondition {
  id?: string;
  timedate: TimeDate;
  dst_in_hour: DstHour;
  dst_out_hour: DstHour;
}

export interface DstHour {
  type: string;
  value: string;
}

export interface TimeDate {
  all_days: boolean;
  days: string[];
  init_hour: string;
  end_hour: string;
}
