import { Injectable } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { FirestoreService } from '@core/services';
import { PbxSector } from '@shared/models';
import { getPABXSectorPath } from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PbxSectorService {
  constructor(private firestoreService: FirestoreService) { }

  /**
   * Get all PBX sector data from current company
   * @returns {Observable<PbxSector[]>} PBX sector data
   */
  public fetchAll(): Observable<PbxSector[]> {
    return this.firestoreService
      .colWithIds$<PbxSector>(getPABXSectorPath(), [orderBy('name')]);
  }

  /**
   * Fetch PBX sector data by PBX ID
   * @param {string} pbxId PBX ID
   * @returns {Observable<PbxSector[]>} PBX sector data by PBX ID
   */
  public fetchByPxId(pbxId: string): Observable<PbxSector[]> {
    return this.firestoreService
      .colWithIds$<PbxSector>(getPABXSectorPath(), [where('pbxId', '==', pbxId)]);
  }

  /**
   * Create a new PBX sector
   * @param {PbxSector} pbxSector PBX sector data
   */
  public async create(pbxSector: PbxSector): Promise<void> {
    await this.firestoreService.addDoc<PbxSector>(getPABXSectorPath(), pbxSector);
  }

  /**
   * Delete a PBX sector
   */
  public async delete(pbxSectorId: string): Promise<void> {
    await this.firestoreService.deleteDoc(getPABXSectorPath(pbxSectorId));
  }

  /**
   * Delete all PBX sectors by PBX ID
   * @param {string} pbxId PBX ID
   */
  public async deleteByPbxId(pbxId: string): Promise<void> {
    // Fetch all PBX sectors by PBX ID
    const pbxSectors = await lastValueFrom(this.fetchByPxId(pbxId).pipe(first()));

    // Delete all PBX sectors
    await this.deleteSectors(pbxSectors);
  }

  /**
   * Get the sectors of PBX
   * @param {Sector[]} sectors All sectors
   * @param {string} pbxId PBX id
   * @returns {Sector[]} All PBX sectors
   */
  public findPBXSectors(sectors: PbxSector[], pbxId: string): PbxSector[] {
    return sectors.filter(sector => pbxId === sector.pbxId);
  }

  /**
   * Create a list of sectors
   * @param {PbxSector[]} sectors Obj that contains the sector data
   */
  public async createSectors(sectors: PbxSector[], pbxId: string): Promise<void> {
    for (const sector of sectors) {
      sector.pbxId = pbxId;
      await this.create(sector);
    }
  }

  /**
   * Delete a list of sectors
   * @param {PbxSector[]} sectors The sectors that will be removed
   */
  public async deleteSectors(sectors: PbxSector[]): Promise<void> {
    for (const pbxSector of sectors) {
      await this.delete(pbxSector.id);
    }
  }
}
