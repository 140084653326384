import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CsvService, UtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { TICKETS_STATUS, TICKETS_TYPE } from '@shared/constants';
import { CallsSummary, Cdr, CdrFilterParams } from '@shared/models';
import { formParams, getCDRPath } from '@shared/utils';
import { Observable, forkJoin, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CdrService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private utilsService: UtilsService,
    private csvService: CsvService,
    private datePipe: DatePipe

  ) { }

  /**
   * Export tickets in a CSV file
   * @param {Cdr[]} tickets All tickets that will be exported
   * @param {string} companyId Company ID | Tenant ID
   */
  public exportCSV(tickets: Cdr[], companyId: string) {
    try {
      // Define file name
      const fileName = `${companyId}.csv`;

      // Define the columns
      const columns: string[] = [
        `${this.translate.instant('date')}/${this.translate.instant('hour')}`,
        this.translate.instant('protocol'),
        this.translate.instant('source'),
        this.translate.instant('destiny'),
        this.translate.instant('type'),
        this.translate.instant('status'),
        this.translate.instant('duration'),
      ];

      // Define the attributes that will be saved in the CSV
      const keys: string[] = [
        'start',
        'uniqueid',
        'src',
        'dst',
        'dcontext',
        'disposition',
        'billsec',
      ];

      // Parse the tickets data
      const data: Cdr[] = tickets.map(ticket => {
        return {
          ...ticket,
          start: this.dateTransform(ticket.start),
          dcontext: this.translate.instant(this.utilsService.parseAttr(ticket.dcontext, TICKETS_TYPE)),
          disposition: this.translate.instant(this.utilsService.parseAttr(ticket.disposition, TICKETS_STATUS)),
          billsec:this.timeTransform(+ticket.billsec),
        }
      })

      // Generate CSV
      this.csvService.exportCSV(columns, keys, data, fileName);

    } catch (error) {
      throw new Error('Fail on export csv file');
    }
  }

  timeTransform(value: number): string {
    if (value <= 0) {
      return '--:--';
    }

    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value - hours * 3600) / 60);
    const seconds: number = value - minutes * 60 - hours * 3600;
    let stgBuilder = '';

    if (hours > 0 && hours < 10) {
      stgBuilder = '0' + hours + ':';
    } else if (hours > 0) {
      stgBuilder += hours + ':';
    }

    if (minutes < 10) {
      stgBuilder += '0' + minutes + ':';
    } else {
      stgBuilder += minutes + ':';
    }

    if (seconds < 10) {
      stgBuilder += '0' + seconds;
    } else {
      stgBuilder += seconds;
    }

    return stgBuilder;
  }

  // TODO julio: send this to the pipe (pipe not working well, had to put this here)
  dateTransform(value: string): string {
    return value ? this.formatDate(value) : '';
  }

  /**
   * Format the date by lang
   * @param {string} value Date
   * @returns Formatted date
   */
  private formatDate(value: string): string {
    const date = new Date(value);
    let localeLang = this.utilsService.getDateFormatByLocale(true);
    let resultDate = this.datePipe.transform(date, localeLang);
    return resultDate;
  }

  public getReceivedCalls(filter: CdrFilterParams): Observable<any> {

    // Get path
    const path = getCDRPath() + '/calls/count/received';

    // Return the tickets
    return this.http.get<CallsSummary>(path, {
      params: formParams(filter)
    });
  }


  public getInboundCalls(filter: CdrFilterParams): Observable<any> {

    // Get path
    const path = getCDRPath() + '/calls/count/inbound';

    // Return the tickets
    return this.http.get<CallsSummary>(path, {
      params: formParams(filter)
    });
  }

  public getOutboundCalls(filter: CdrFilterParams): Observable<any> {

    // Get path
    const path = getCDRPath() + '/calls/count/outbound';

    // Return the tickets
    return this.http.get<CallsSummary>(path, {
      params: formParams(filter)
    });
  }

  public getNestedCalls(filter: CdrFilterParams): Observable<any> {

    let path = getCDRPath();


   return forkJoin([
    this.http.get(`${path}/calls/count/received`, {
      params: formParams(filter)
    }).pipe(tap()),
      this.http.get(`${path}/calls/count/outbound`, {
        params: formParams(filter)
      }).pipe(tap()),
      this.http.get(`${path}/calls/count/inbound`, {
        params: formParams(filter)
      }).pipe(tap()),
    ])



  }

    /**
   * Get all tickets
   * @returns {Cdr[]} All tickets
   */
    public getCallSummary(filter: CdrFilterParams): Observable<CallsSummary> {

      // Get path
      const path = getCDRPath() + '/calls/summary';

      // Return the tickets
      return this.http.get<CallsSummary>(path, {
        params: formParams(filter)
      });
    }

    public getCallCount(filter: any){
      // Get path
      const path = getCDRPath() + '/count/calls';

      // Return the tickets
      return this.http.get<CallsSummary>(path, {
        params: formParams(filter)
      });
    }/*
    public getDetailedCallSummary(filter: CdrFilterParams): Observable<CallsSummary> {

      // Get path
      const path = getCDRPath() + '/calls/detailed-summary';

      // Return the tickets
      return this.http.get<CallsSummary>(path, {
        params: formParams(filter)
      });
    }
    */
}
