import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  TENOR_BASE_URL,
  TENOR_CLIENT_KEY, TENOR_POPULAR,
  TENOR_SEARCH_LIMIT
} from '@shared/constants';
import { SearchResult } from '@shared/models';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenorService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  /**
   * Get locate
   * @returns {string} Locate
   */
  private getLocate(): string {
    switch (this.translate.currentLang) {
      case 'pt':
        return 'pt_BR';
      case 'es':
        return 'es_ES';
      default:
        return 'en_US';
    }
  }

  /**
   * Get Form Default Path
   * @param {string} url Base URL
   * @returns {string} Default path
   */
  private getFormDefaultPath(url: string): string {
    return `${url}?key=${environment.gifKey}`
      + `&client_key=${TENOR_CLIENT_KEY}`
      + `&limit=${TENOR_SEARCH_LIMIT}`
      + '&ar_range=standard'
      + '&media_filter=gif,tinygif'
      + `&locale=${this.getLocate()}`
  }

  /**
   * Form tenor path
   * @param {string} term Term that will be search
   * @returns {string} Path
   */
  private formPathToSearch(term: string): string {
    return this.getFormDefaultPath(TENOR_BASE_URL) + `&q=${term}`
  }

  /**
   * Form init tenor path
   * @returns {string} Path
   */
  private formInitPath(): string {
    return this.getFormDefaultPath(TENOR_POPULAR);
  }

  /**
   * Get initial gif
   */
  public async getInitialValue(): Promise<SearchResult> {
    const path = this.formInitPath()
    return lastValueFrom(this.http.get(path).pipe(first())) as Promise<SearchResult>;
  }

  /**
   * Search by term
   * @param {string} term Term
   * @returns {Observable} Observable with the search
   */
  public search(term: string): Promise<SearchResult> {
    const path = this.formPathToSearch(term);
    return lastValueFrom(this.http.get(path).pipe(first())) as Promise<SearchResult>;
  }
}
