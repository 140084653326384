import { Component, OnInit } from '@angular/core';
import { PlatformService } from '@core/services';
import { ElectronService } from 'ngx-electron';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  linuxDownloadUrl = `${location.protocol}//arquivos.leucotron.com.br/update/mobiphone/release/mobi-phone-desktop.deb`;
  windowsDownloadUrl = `${location.protocol}//arquivos.leucotron.com.br/update/mobiphone/release/mobi-phone-desktop.exe`;
  googlePlayStoreUrl = `https://play.google.com/store/apps/details?id=com.leucotron.android.mobiphone`;
  appStoreUrl = `https://apps.apple.com/br/app/mobi-phone/id1622070557`;

  winMouseOver = false;
  linuxMouseOver = false;
  androidMouseOver = false;
  iosMouseOver = false;

  constructor(private electron: ElectronService, private platform: PlatformService) { }

  ngOnInit(): void {
  }

  /**
   * Verify current platform to show component only on web platform.
   *
   * @returns True if current platform equal to web
   */
  public checkPlatform(): boolean {
    return !this.electron.isElectronApp;
  }
}
