import { EventEmitter, Injectable, Output, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;
  private theme: string;
  @Output() themeChanged: EventEmitter<any> = new EventEmitter();

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initTheme(): void {
    this.getTheme();
    this.renderer.addClass(document.body, this.theme);
  }

  updateTheme(theme: 'light-theme' | 'dark-theme'): void {
    this.themeChanged.emit(theme);
    const previousTheme = this.theme;

    this.setTheme(theme);
    this.renderer.removeClass(document.body, previousTheme);
    this.renderer.addClass(document.body, theme);
  }

  isDarkTheme(): boolean {
    return this.theme === 'dark-theme';
  }

  private getTheme() {
    const theme = localStorage.getItem('preferred-theme');

    if (theme) {
      this.theme = theme;
    } else {
      this.theme = 'light-theme';
    }
  }

  private setTheme(theme: 'light-theme' | 'dark-theme'): void {
    this.theme = theme;
    localStorage.setItem('preferred-theme', theme);
  }
}
