import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { LcsEventDescriptions, LcsEventsTimeConditionTypes } from '@shared/enums';
import { TimeCondition } from '@shared/models/timeCondition.model';
import { getDiffBetweenObj, getTimeConditionsPath } from '@shared/utils';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { LcsEventsService } from '../lcs-events/lcs-events.service';
import { NotifierService } from '../notifier/notifier.service';

@Injectable({
  providedIn: 'root',
})
export class TimeConditionService {
  private companyId: string = '';

  constructor(
    private lcsEventsService: LcsEventsService,
    private notifier: NotifierService,
    private firestore: Firestore,
    private authService: AuthService
  ) {
    this.companyId =
      this.authService.user.companyId ??
      localStorage.getItem('mobi_phone:tenantId');
  }

  /**
   * Create a new external timeCondition
   * @param {TimeCondition} timeCondition TimeCondition data
   */
  public async create(
    timeCondition: TimeCondition,
    companyId: string = this.companyId
  ): Promise<string> {
    // Get collection reference
    const colRef = collection(this.firestore, getTimeConditionsPath(companyId));

    try {
      // Add timeCondition to firestore
      const newDoc = await addDoc(colRef, timeCondition);

      await this.handleSuccess(LcsEventsTimeConditionTypes.TIME_CONDITION_CREATION, {
        created: { ...timeCondition, id: newDoc.id },
      });

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'timeConditionCreatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });

      return newDoc.id;
    } catch (err: any) {
      this.handleError(err, LcsEventsTimeConditionTypes.TIME_CONDITION_CREATION);
    }
  }

  /**
   * Update an existing timeCondition
   * @param {any} newTimeCondition Updated timeCondition data
   * @param {string} timeConditionId TimeCondition ID
   */
  public async update(newTimeCondition: any, timeConditionId: string, originalTimeCondition: any) {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getTimeConditionsPath(this.companyId, timeConditionId)
    );

    try {
      // Update timeCondition
      await updateDoc(docRef, { ...newTimeCondition });

      this.handleSuccess(LcsEventsTimeConditionTypes.TIME_CONDITION_UPDATE, {
        id: newTimeCondition.id,
        name: newTimeCondition.name,
        diff: getDiffBetweenObj(newTimeCondition, originalTimeCondition, ['id']),
      });

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'timeConditionUpdatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });
    } catch (err) {
      this.handleError(err, LcsEventsTimeConditionTypes.TIME_CONDITION_UPDATE);
    }
  }

  /**
   * Get a timeCondition by ID
   * @param {string} id TimeCondition ID
   */
  public async getById(id: string) {
    // Get doc reference
    const docRef = doc(this.firestore, getTimeConditionsPath(this.companyId, id));

    // Get doc
    const document = await getDoc(docRef);
    // Check if doc exists
    if (!document.exists()) return null;

    // Get timeCondition data
    const timeCondition = document.data() as any;

    // Add id to timeCondition
    timeCondition.id = id;

    // Return timeCondition data
    return timeCondition;
  }

  /**
   * Fetch all timeConditions
   * @param {string} companyId Company ID
   */
  public fetchAll(companyId: string): Observable<any[]> {
    const col = <CollectionReference<any>>(
      collection(this.firestore, getTimeConditionsPath(companyId))
    );

    return collectionData<any>(col, { idField: 'id' });
  }

  /**
   * Delete a timeCondition
   * @param {TimeCondition} timeCondition TimeCondition data
   */
  public async delete(timeCondition: TimeCondition): Promise<void> {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getTimeConditionsPath(this.companyId, timeCondition.id)
    );

    try {
      // Delete timeCondition from firestore
      await deleteDoc(docRef);

      this.handleSuccess(LcsEventsTimeConditionTypes.TIME_CONDITION_DELETION, {
        deleted: {
          id: timeCondition.id,
        },
      });
    } catch (err) {
      this.handleError(err, LcsEventsTimeConditionTypes.TIME_CONDITION_DELETION);
    }
  }

  /**
   * Handle success
   * @param {LcsEventsTimeConditionTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventsTimeConditionTypes, value: any): Promise<void> {
    return this.lcsEventsService.create(
      { type, description: LcsEventDescriptions.SUCCESS, value },
      true
    );
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventsTimeConditionTypes} type The event type
   */
  public handleError(error: any, type: LcsEventsTimeConditionTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventsService.create(
      { type, description: LcsEventDescriptions.ERROR, value: { error } },
      true
    );

    // Throw the error
    throw error;
  }
}