import { Injectable } from '@angular/core';
import { MissingCallsService } from '@core/services';
import { ElectronService } from 'ngx-electron';

@Injectable({
  providedIn: 'root'
})
export class DesktopService {

  constructor(
    private electron: ElectronService,
    private missingCallsService: MissingCallsService,
  ) { }

  /**
   * Update badge
   * @param {number} notificationCount Notification Count
   */
  public updateBadge(notificationCount: number): void {
    // Topic
    const topic = 'update-badge';

    // Get missing calls count
    let missingCalls = this.missingCallsService.getHasMissingCalls() ? 1 : 0;

    // Not allow less than zero
    notificationCount = notificationCount < 0 ? 0 : notificationCount;

    // Get count
    const count = notificationCount + missingCalls;

    // Send event to update badge
    this.electron.ipcRenderer?.send(topic, count <= 0 ? null : count);
  }
}
