import { Injectable } from '@angular/core';
import {
  Firestore,
  addDoc,
  and,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { AuthService, NotifierService } from '@core/services';
import { EXTERNAL_CONTACTS_IMPORT_LIMIT } from '@shared/constants';
import { Contact } from '@shared/models';
import { getContactsPath, normalizeTerm } from '@shared/utils';
import { BehaviorSubject, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExternalContactsService {
  private companyId: string = '';
  public externalContacts$ = new BehaviorSubject<Contact[]>([]);
  public dynamicExternalContacts$ = new BehaviorSubject<Contact[]>([]);
  public contactsToFetch$ = new BehaviorSubject<number>(0);
  public searchTerm$ = new BehaviorSubject<string>('');
  public isCompanyList$ = new BehaviorSubject<boolean>(false);

  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private notifier: NotifierService,
  ) {
    this.companyId =
      this.authService.user.companyId ??
      localStorage.getItem('mobi_phone:tenantId');
  }

  /**
  * Fetch external contacts based on user scroll. This will also listen
  * to firestore changes, and update dinamically our contacts list
  * @param {number} contactsToFetch How many contacts to fetch each time
  */

  public async dynamicFetchContacts(contactsToFetch, isCompanyList, param?) {
    this.isCompanyList$.next(isCompanyList)
    // If a param is present, update the Observable
    if (contactsToFetch)
      this.contactsToFetch$.next(contactsToFetch)
    else
      contactsToFetch = this.contactsToFetch$.value
    // If no param present, use the Observable as param

    // If param is present, save it to Observable
    if (param || param === '')
      this.searchTerm$.next(param)
    else if (param !== '') {
      // If param is undefined AND observable has something, it is data to be preserved
      if (this.searchTerm$.value || this.searchTerm$.value === '') {
        param = this.searchTerm$.value
      }
    }

    // Get collection reference
    const appRef = collection(this.firestore, getContactsPath(this.companyId));

    // Input needs normalization because it's normalized on firestore
    const normalizedSearchInput = normalizeTerm(this.searchTerm$.value)

    // Query constraints to query two parameters in an OR conditional
    const queryConstraints = [];
    queryConstraints.push(
      this.dynamicConstraints(isCompanyList, 'normalizedName', normalizedSearchInput as string)
    );
    queryConstraints.push(orderBy('normalizedName'));
    queryConstraints.push(limit(contactsToFetch))

    // Form query
    const appQuery = query(appRef, ...queryConstraints);


    const queryConstraints2 = [];
    queryConstraints2.push(
      this.dynamicConstraints(isCompanyList, 'normalizedCompany', normalizedSearchInput as string)
    );
    queryConstraints2.push(orderBy('normalizedCompany'));
    queryConstraints2.push(limit(contactsToFetch))

    // We need 2 appquerys because of thi where(field, '>=', param). We need 2 querys for 2 fields
    // Form query
    const appQuery2 = query(appRef, ...queryConstraints2);

    // This is the general contacts array (searching for normalized company AND normalized name)
    let contacts = []

    const obs$ = collectionData(appQuery, { idField: 'id' }).pipe(first());
    const cts = await lastValueFrom(obs$)

    // Remove duplicated
    cts.map((contato) => {
      const isDuplicate = contacts.some((existingContact) => existingContact.id === contato.id);
      // Only push the contact if it's not a duplicate
      if (!isDuplicate) {
        contacts.push(contato);
      }
    })

    this.dynamicExternalContacts$.next(contacts as Contact[])

    if (this.searchTerm$.value) {
      const obs$ = collectionData(appQuery2, { idField: 'id' }).pipe(first());
      const cts = await lastValueFrom(obs$)
      cts.map((contato) => {
        const isDuplicate = contacts.some((existingContact) => existingContact.id === contato.id);
        // Only push the contact if it's not a duplicate
        if (!isDuplicate) {
          contacts.push(contato);
        }
      })
      this.dynamicExternalContacts$.next(contacts as Contact[])
    }

  }

  public dynamicConstraints(isCompanyList, field, param?: string) {
    let query
    if (isCompanyList) {
      query = where('isCompanyContact', '==', true)
    } else {
      query = and(where('createdBy', '==', this.authService.userId), where('isCompanyContact', '==', false))
    }
    if (param) {
      return and(
        query,
        where(field, '>=', param),
        where(field, '<=', param + '\uf8ff')
      )

    } else {
      return query
    }
  }

  /**
   * Check if the contact exist in the company contacts or my contacts
   * @param {boolean} toCompany Boolean to check if the contact will be added to company contacts or my contacts
   * @returns {boolean} true if the contact already exist, false if not
   */
  public contactAlreadyExist(
    toCompany: boolean,
    currentContact: Contact
  ): Contact[] {
    // Get my contacts
    const contacts = this.externalContacts$.value.filter(
      (contact) =>
        toCompany
          ? contact.isCompanyContact // If is company contact
          : !contact.isCompanyContact // If is my contact
    );

    // Get current phone numbers
    const currentPhoneNumbers = currentContact.phoneNumbers.map(
      (phoneNumber) => phoneNumber.number
    );

    // Get contacts with conflicts
    const contactsWithConflicts = contacts.filter((contact) =>
      this.hasConflict(contact, currentPhoneNumbers)
    );

    // Check if the current contact has the same phone number as the contact in contacts
    return contactsWithConflicts;
  }

  /**
   * Check if the contact has conflict with the current phone numbers
   * @param {Contact} contact { name: string, phoneNumbers: PhoneNumber[]
   * @param {string[]} currentPhoneNumbers Array of phone numbers
   * @returns {boolean} true if the contact has conflict
   */
  private hasConflict(
    contact: Contact,
    currentPhoneNumbers: string[]
  ): boolean {
    // Get contact phone numbers
    const contactPhoneNumbers = contact.phoneNumbers.map(
      (phoneNumber) => phoneNumber.number
    );

    // Check if the current contact has the same phone number as the contact in contacts
    return contactPhoneNumbers.some((phoneNumber) =>
      currentPhoneNumbers.includes(phoneNumber)
    );
  }

  /**
   * Create a new external contact
   * @param {Contact} contact Contact data
   */
  public async createExternalContact(
    contact: Contact,
    companyId: string = this.companyId
  ): Promise<string> {
    // Get collection reference
    const colRef = collection(this.firestore, getContactsPath(companyId));

    // Add contact to firestore
    const newDoc = await addDoc(colRef, contact);

    // Show notification
    this.notifier.showNotification({
      type: 'success',
      message: 'contactCreatedSuccessfully',
      actionText: 'OK',
      panelClass: 'success',
    });

    // For every create, wait 2 seconds and then fetch again
    setTimeout(async () => {
      await this.dynamicFetchContacts(
        20,
        this.isCompanyList$.value,
        this.searchTerm$.value
      )
    }, 2000)
    // Return contact id
    return newDoc.id;
  }

  public generateRandomId(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  /**
   * Create a new external contact
   * @param {Contact} contact Contact data
   */
  public async createExternalContacts(
    contacts: Contact[],
    companyId: string = this.companyId
  ) {
    try {
      // Create a transaction queue
      await runTransaction(this.firestore, async (trans) => {
        // For every contact, we specify which operation we wanna do (set)
        contacts.map((contact) => {
          if (contacts.indexOf(contact) < EXTERNAL_CONTACTS_IMPORT_LIMIT) {
            // We generate a unique ID in client side before sending
            const path = getContactsPath(companyId).concat(
              '/',
              this.generateRandomId(28)
            );

            // Now we have a specific document reference for our new contact
            const ref = doc(this.firestore, path);

            // Here we queue this transaction that will be completed at the end of runTransaction
            trans.set(ref, contact);
          }
        });
      }).catch((err) => console.log(err));

      // For every import, wait 2 seconds and then fetch again
      setTimeout(async () => {
        await this.dynamicFetchContacts(
          20,
          this.isCompanyList$.value,
          this.searchTerm$.value
        )
      }, 3000)

      let notifierMessage;
      if (contacts.length <= 1) notifierMessage = 'contactCreatedSuccessfully';
      else notifierMessage = 'contactsCreatedSuccessfully';

      this.notifier.showNotification({
        type: 'success',
        message: notifierMessage,
        actionText: 'OK',
        panelClass: 'success',
      });
    } catch (err) {
      console.log(err);
    }
  }

  public async getById(id: string) {
    // Get doc reference
    const docRef = doc(this.firestore, getContactsPath(this.companyId, id));

    // Get doc
    const document = await getDoc(docRef);
    // Check if doc exists
    if (!document.exists()) return null;

    // Get contact data
    const contact = document.data() as Contact;

    // Add id to contact
    contact.id = id;

    // Return contact data
    return contact;
  }

  public async update(newContact: Contact, contactId: string) {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getContactsPath(this.companyId, contactId)
    );

    // Update contact
    await updateDoc(docRef, { ...newContact });

    // Show notification
    this.notifier.showNotification({
      type: 'success',
      message: 'contactUpdatedSuccessfully',
      actionText: 'OK',
      panelClass: 'success',
    });
  }

  /**
   * Delete a contact
   * @param {Contact} contact Contact data
   */
  public async remove(contact: Contact): Promise<void> {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getContactsPath(this.companyId, contact.id)
    );

    // For every delete, wait 2 seconds and then fetch again
    setTimeout(async () => {
      await this.dynamicFetchContacts(
        20,
        this.isCompanyList$.value,
        this.searchTerm$.value
      )
    }, 2000)
    // Delete contact from firestore
    await deleteDoc(docRef);
  }

  // AsyncContacts
  async getExternalContactNameByPhoneNumber(
    destinationPhone: string,
    isExternal?: boolean,
    initialDestinationName?: string
  ): Promise<string> {

    return new Promise((resolve, reject) => {
      this.getContactsByNumber(destinationPhone).then((res) => {
        if (res.length >= 1) {
          const contact = (res as Contact[])[0]
          const name = contact.name
          resolve(name)
        }
        resolve('')
      }).catch((err) => {
        reject(err)
      })
    })

  }

  getEightPhoneDigits(number: string) {
    // Reversing the string (for example: (35)91234-5678 becomes 87654321953)
    let num: string = number.split('').reverse().join('');

    if (num.length >= 8) {
      // Getting first 8 digits (87654321953 becomes 87654321)
      num = num.slice(0, 8);

      // Reverse again (87654321 becomes 1234-5678)
      num = num.split('').reverse().join('');
    }

    return num;
  }

  takeLastNDigits(value: string, numberOfDigits: number) {
    let key: string = this.reverseString(value);
    key = key.slice(0, numberOfDigits);
    key = this.reverseString(key);

    return key;
  }

  reverseString(value: string) {
    let reversedString: string = value.split('').reverse().join('');
    return reversedString;
  }

  /**
 * Get meetings
 * @param {string} id Meet id
 * @returns Meetings in that company
 */
  public getContactsByNumber(number: any): Promise<Contact[]> {
    if (number) {

      // Get collection reference
      const appRef = collection(this.firestore, getContactsPath(this.authService.user.companyId));
      // Form query
      const appQuery = query(appRef, where('normalizedNumbers', 'array-contains', number));

      // Return collection data
      return lastValueFrom(collectionData(appQuery, { idField: 'id' })
        .pipe(first()
        )) as Promise<Contact[]>
    }
    return new Promise((res, rej) => {
      res([])
    });
  }

  filterContacts(fetchedContacts: any[], destinationPhone: any, destinationName: any, isExternal: boolean) {

    let destinationPhoneNumbers = [];

    // Search inside externalContacts stored locally
    fetchedContacts.map((contact) => {
      contact.phoneNumbers.map((phoneNumber) => {
        const number = phoneNumber.number;

        const epd = this.getEightPhoneDigits(number);
        // If there's a contact similar to remote number
        if (destinationPhone.includes(epd) && epd.length == 8) {
          destinationPhoneNumbers.push({ number: number, name: contact.name });
        } else if (destinationPhone.length <= 5) {
          if (destinationPhone == number) {
            if (phoneNumber.description == 'serviceNumber') {
              destinationName = contact.name;
              isExternal = true;
            }
          }
        }
      });
    });

    let isNumberWithDDD = false;
    let isNumberWithCountryCode = false;

    if (destinationPhoneNumbers.length > 1) {
      destinationPhoneNumbers.map((phoneNumber) => {
        const number = phoneNumber.number;
        const numberWithDDD = this.takeLastNDigits(number, 11);
        const numberWithoutDDD = this.takeLastNDigits(number, 9);
        const numberWithCountryCode = this.takeLastNDigits(number, 13);

        if (
          numberWithCountryCode.length == 13 &&
          destinationPhone.includes(numberWithCountryCode)
        ) {
          isNumberWithCountryCode = true;
          destinationName = phoneNumber.name;
        } else if (
          numberWithDDD.length == 11 &&
          destinationPhone.includes(numberWithDDD) &&
          numberWithCountryCode.length != 13
        ) {
          isNumberWithDDD = true;
          if (!isNumberWithCountryCode) {
            destinationName = phoneNumber.name;
          }
        } else if (
          destinationPhone.includes(numberWithoutDDD) &&
          number.length == 9
        ) {
          if (!isNumberWithDDD && !isNumberWithCountryCode) {
            destinationName = phoneNumber.name;
          }
        }
      });
    } else {
      if (!destinationName) destinationName = destinationPhoneNumbers[0]?.name;
    }
    return destinationName;
  }
}

