import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from '@core/services';
import { environment } from '@environments/environment';
import { MeetingRoom } from '@shared/models';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private emailUrl: string = `${environment.baseURL}/email`;

  constructor(
    private tenantService: TenantService,
    private http: HttpClient,
  ) { }

  async sendForgotPasswordEmail(
    email: string,
    tenantId: string,
    lang: string
  ): Promise<any> {
    // Form http request body
    const body = {
      email,
      tenantId,
      lang,
      mobiphoneUrl: window.location.origin
    }

    // Form path
    const path = this.emailUrl + '/forgot-password';

    // Get reset password response
    const response = await lastValueFrom(this.http.post<{ mailId: string }>(path, body).pipe(first()));

    // Get mail id
    return response.mailId
  }

  /**
   * Send Meet Email Template
   * @param {string[]} emails recipient emails
   * @param {string} link Meet link
   * @param {string} meet Meet
   * @param {string} organizer Current user name
   */
  public async sendMeetEmail(
    emails: string[],
    link: string,
    meet: MeetingRoom,
    organizer: string,
    organizerEmail?: string
  ): Promise<any> {
    // Get company
    const company = this.tenantService.tenant;

    // Form template options
    const body = {
      emails,
      link,
      meet,
      organizer,
      company,
      organizerEmail,
    };

    // Form path
    const path = this.emailUrl + '/meet-invite';

    // Save on firestore
    return await lastValueFrom(this.http.post(path, body).pipe(first()));
  }


}
