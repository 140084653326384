import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-play-audio-button',
  templateUrl: './play-audio-button.component.html',
  styleUrls: ['./play-audio-button.component.scss'],
})
export class PlayAudioButtonComponent implements OnInit {
  mouseOver: boolean = false;
  @Output() openPlayerEvent = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.openPlayerEvent.emit();
  }
}
