import { environment } from '@environments/environment';

const storageBucket: string = environment.firebaseConfig.storageBucket;

interface BodyTemplate {
  header: string;
  info: string;
  buttonText?: string;
  footer: string;
  qrcode?: string;
}


export const newPartnerHtmlTemplate = (
  mode: string,
  code: string,
  apiKey: string,
  lang?: string
): string => {
  let link: string = `${window.location.origin}/action?mode=${mode}&oobCode=${code}&apiKey=${apiKey}`;
  if (lang) {
    link += `&lang=${lang}`;
  }

  const languagesBodyTemplate: { [key: string]: BodyTemplate } = {
    pt: {
      header: `
      <p><span style="color: #000000;">
        Boas vindas ao LCS, parceiro Leucotron!
      </span></p>
      `,
      info: `
      <p><span style="color: #000000;">
        Antes de acessar a sua nova plataforma de gestão de serviços digitais
        Leucotron, precisamos concluir a configuração da sua conta.
      </span></p>
      `,
      buttonText: 'CRIAR SENHA',
      footer: `
      <p><em><span style="color: #000000;">
        Se você não solicitou este e-mail ou desconhece o Leucotron Cloud
        Service, ignore este e-mail.
      </span></em></p>
      `,
    },
    en: {
      header: `
      <p><span style="color: #000000;">
        Welcome to LCS, Leucotron partner!
      </span></p>
      `,
      info: `
      <p><span style="color: #000000;">
        Before accessing your new Leucotron digital service management platform,
        we need to complete yout account setup.
      </span></p>
      `,
      buttonText: 'CREATE PASSWORD',
      footer: `
      <p><em><span style="color: #000000;">
        If you did not request this email or are unfamiliar with Leucotron Cloud Service,
        please ignore this email.
      </span></em></p>
      `,
    },
    es: {
      header: `
      <p><span style="color: #000000;">
        ¡Bienvenido a LCS, socio de Leucotron!
      </span></p>
      `,
      info: `
      <p><span style="color: #000000;">
        Antes de acceder a su nueva plataforma de administración de servicios digitales
        de Leucotron, necesitamos completar la configuración de su cuenta.
      </span></p>
      `,
      buttonText: 'CREAR CONTRASEÑA',
      footer: `
      <p><em><span style="color: #000000;">
        Si no solicitó este correo electrónico o no está familiarizado con el Leucotron Cloud Service,
        ignore este correo electrónico.
      </span></em></p>
      `,
    },
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
    <header>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap"
        rel="preload"
      />
    </header>
    <body
      style="
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
      "
    >
      <div
        style="
          text-align: center;
          min-width: 600px;
          display: contents;
          padding: 0 calc((100% - 600px) / 2);
        "
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-header.png?alt=media"
          loading="lazy"
          alt="Leucotron Header"
        />
        <div style="text-align: left; padding: 0 15px; max-width: 600px; gap: 10px; margin-top: 10px; margin-bottom: 10px;">
          ${languagesBodyTemplate[lang].header}
          ${languagesBodyTemplate[lang].info}
          <div style="width: 100%; text-align: center">
            <a href="${link}" target="_blank">
              <button
                style="
                  cursor: pointer;
                  background-color: #002B77;
                  color: white;
                  font-weight: bold;
                  width: 300px;
                  height: 40px;
                  border: none;
                  border-radius: 25px;
                "
              >
                ${languagesBodyTemplate[lang].buttonText}
              </button>
            </a>
          </div>
          ${languagesBodyTemplate[lang].footer}
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-footer.png?alt=media"
          loading="lazy"
          alt="Leucotron Footer"
        />
      </div>
    </body>
  </html>
  `;
};

export const resetPasswordHtmlTemplate = (
  mode: string,
  code: string,
  apiKey: string,
  lang?: string
): string => {
  let link: string = `${window.location.origin}/action?mode=${mode}&oobCode=${code}&apiKey=${apiKey}`;
  if (lang) {
    link += `&lang=${lang}`;
  }

  const languagesBodyTemplate: { [key: string]: BodyTemplate } = {
    pt: {
      header: `
      <p>Olá, usuário do Leucotron Cloud Service</p>
      `,
      info: `
      <p>
        Recebemos uma solicitação para redefinição de senha do seu usuário.
        Basta clicar no botão abaixo para redefinir sua senha.
      </p>
      `,
      buttonText: 'REDEFINIR SENHA',
      footer: `
      <p>
        Se você não solicitou a redefinição de senha ou desconhece o Leucotron
        Cloud Service, ignore este e-mail.
      </p>
      `,
    },
    en: {
      header: `
      <p>Hello, Leucotron Cloud Service user</p>
      `,
      info: `
      <p>
        We received a request to reset your user's password. Click the button
        below to reset your password.
      </p>
      `,
      buttonText: 'RESET PASSWORD',
      footer: `
      <p>
        If you did not request a password reset or are unfamiliar with Leucotron
        Cloud Service, please ignore this email.
      </p>
      `,
    },
    es: {
      header: `
      <p>
        Hola, usuario del Leucotron Cloud Service
      </p>
      `,
      info: `
      <p>
        Hemos recibido una solicitud para restablecer la contraseña de su usuario.
        Haga clic en el botón de abajo para restablecer su contraseña.
      </p>
      `,
      buttonText: 'REINICIAR CONTRASEÑA',
      footer: `
      <p>
        Si no solicitó un restablecimiento de contraseña o no está familiarizado
        con el Leucotron Cloud Service, ignore este correo electrónico.
      </p>
      `,
    },
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
    <header>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap"
        rel="preload"
      />
    </header>
    <body
      style="
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
      "
    >
      <div
        style="
          text-align: center;
          min-width: 600px;
          padding: 0 calc((100% - 600px) / 2);
        "
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-header.png?alt=media"
          loading="lazy"
          alt="Leucotron Header"
        />
        <div style="text-align: left; padding: 0 15px">
          ${languagesBodyTemplate[lang].header}
          ${languagesBodyTemplate[lang].info}
          <div style="width: 100%; text-align: center">
            <a href="${link}" target="_blank">
              <button
                style="
                  cursor: pointer;
                  background-color: #002B77;
                  color: white;
                  font-weight: bold;
                  width: 300px;
                  height: 40px;
                  border: none;
                  border-radius: 25px;
                "
              >
                ${languagesBodyTemplate[lang].buttonText}
              </button>
            </a>
          </div>
          ${languagesBodyTemplate[lang].footer}
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-footer.png?alt=media"
          loading="lazy"
          alt="Leucotron Footer"
        />
      </div>
    </body>
  </html>
  `;
};


export const salesNewPartnerHtmlTemplate = (
  info: string,
  userEmail: string,
  lang?: string,
): string => {
  const languagesBodyTemplate: { [key: string]: BodyTemplate } = {
    pt: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Concessionária Cadastrada</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <br/><p><span style="color: #000000;"><strong>Cadastrado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    en: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Registered Concessionaire</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <br/><p><span style="color: #000000;"><strong>Registered by: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    es: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Concesionario registrado</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <br/><p><span style="color: #000000;"><strong>Registrado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
    <header>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap"
        rel="preload"
      />
    </header>
    <body
      style="
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
      "
    >
      <div
        style="
          text-align: center;
          min-width: 600px;
          display: contents;
          padding: 0 calc((100% - 600px) / 2);
        "
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-header.png?alt=media"
          loading="lazy"
          alt="Leucotron Header"
        />
        <div style="text-align: left; padding: 0 15px; max-width: 600px; gap: 10px; margin-top: 10px; margin-bottom: 10px;">
          ${languagesBodyTemplate[lang].header}
          ${languagesBodyTemplate[lang].info}
          ${languagesBodyTemplate[lang].footer}
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-footer.png?alt=media"
          loading="lazy"
          alt="Leucotron Footer"
        />
      </div>
    </body>
  </html>
  `;
};

export const salesNewCompanyHtmlTemplate = (
  info: string,
  userEmail: string,
  lang?: string,
): string => {
  const languagesBodyTemplate: { [key: string]: BodyTemplate } = {
    pt: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Empresa Cadastrada</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <br/><p><span style="color: #000000;"><strong>Cadastrado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    en: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Registered Concessionaire</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <br/><p><span style="color: #000000;"><strong>Registered by: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    es: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Concesionario registrado</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <br/><p><span style="color: #000000;"><strong>Registrado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
    <header>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap"
        rel="preload"
      />
    </header>
    <body
      style="
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
      "
    >
      <div
        style="
          text-align: center;
          min-width: 600px;
          display: contents;
          padding: 0 calc((100% - 600px) / 2);
        "
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-header.png?alt=media"
          loading="lazy"
          alt="Leucotron Header"
        />
        <div style="text-align: left; padding: 0 15px; max-width: 600px; gap: 10px; margin-top: 10px; margin-bottom: 10px;">
          ${languagesBodyTemplate[lang].header}
          ${languagesBodyTemplate[lang].info}
          ${languagesBodyTemplate[lang].footer}
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-footer.png?alt=media"
          loading="lazy"
          alt="Leucotron Footer"
        />
      </div>
    </body>
  </html>
  `;
};

export const salesUpdateHtmlTemplate = (
  info: string,
  userEmail: string,
  lang?: string,
): string => {

  const languagesBodyTemplate: { [key: string]: BodyTemplate } = {
    pt: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Atualização</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <p><span style="color: #000000;"><strong>Modificado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    en: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Update</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <p><span style="color: #000000;"><strong>Modified by: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    es: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Actualizar</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <p><span style="color: #000000;"><strong>Modificado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
    <header>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap"
        rel="preload"
      />
    </header>
    <body
      style="
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
      "
    >
      <div
        style="
          text-align: center;
          min-width: 600px;
          padding: 0 calc((100% - 600px) / 2);
          display: contents;
        "
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-header.png?alt=media"
          loading="lazy"
          alt="Leucotron Header"
        />
        <div style="text-align: left; padding: 0 15px; max-width: 600px; gap: 10px; margin-top: 10px; margin-bottom: 10px;">
          ${languagesBodyTemplate[lang].header}
          ${languagesBodyTemplate[lang].info}
          ${languagesBodyTemplate[lang].footer}
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-footer.png?alt=media"
          loading="lazy"
          alt="Leucotron Footer"
        />
      </div>
    </body>
  </html>
  `;
};

/**
 * Sales delete html template
 * @param {string} info - Info about the deleted item
 * @param {string} userEmail - User email
 * @param {string} lang - Language
 * @returns {string} - HTML template
 */
export const salesDeleteHtmlTemplate = (
  info: string,
  userEmail: string,
  lang?: string,
): string => {
  const languagesBodyTemplate: { [key: string]: BodyTemplate } = {
    pt: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Exclusão</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <p><span style="color: #000000;"><strong>Excluído por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    en: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Deletion</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <p><span style="color: #000000;"><strong>Deleted by: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
    es: {
      header: `
      <p style="text-align: center;"><span style="color: #000000;"><strong><span style="color: #000000; font-size: 20px;">Eliminación</span></strong></span></p>
      <hr />
      `,
      info,
      footer: `
      <p><span style="color: #000000;"><strong>Eliminado por: </strong><a href="mailto:${userEmail}">${userEmail}<a/><br/></span></p>
      `,
    },
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
    <header>
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap"
        rel="preload"
      />
    </header>
    <body
      style="
        overflow: hidden;
        margin: 0 !important;
        padding: 0;
        font-family: Roboto, 'Helvetica Neue', sans-serif;
      "
    >
      <div
        style="
          text-align: center;
          min-width: 600px;
          display: contents;
          padding: 0 calc((100% - 600px) / 2);
        "
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-header.png?alt=media"
          loading="lazy"
          alt="Leucotron Header"
        />
        <div style="text-align: left; padding: 0 15px; max-width: 600px; gap: 10px; margin-top: 10px; margin-bottom: 10px;">
          ${languagesBodyTemplate[lang].header}
          ${languagesBodyTemplate[lang].info}
          ${languagesBodyTemplate[lang].footer}
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/templateEmail%2Flcs-footer.png?alt=media"
          loading="lazy"
          alt="Leucotron Footer"
        />
      </div>
    </body>
  </html>
  `;
};
