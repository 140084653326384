import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output } from '@angular/core';

type ChipInputFieldComponentValue = {
  key: string;
  separator: string;
  value: string;
};

@Component({
  selector: 'app-chip-input-field',
  templateUrl: './chip-input-field.component.html',
  styleUrls: ['./chip-input-field.component.scss']
})
export class ChipInputFieldComponent {
  // Input field
  @Input() placeholder = '';
  @Input() label = '';
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() tooltipContent = '';
  @Input() tooltipIcon = 'question_mark_circle';

  // Chip
  @Input() addOnBlur = true;
  @Input() chips: string[] = [];
  @Input() separatorKeysCodes = [ENTER];
  @Input() private content: ChipInputFieldComponentValue;

  // Events
  @Output() addChip = new EventEmitter();
  @Output() removeChip = new EventEmitter();

  constructor() { }

  /**
   * Get the value of the input field
   */
  public getInputValue(chip: any): string {
    return this.content
      ? `${chip[this.content.key]}${this.content.separator}${chip[this.content.value]}`
      : chip;
  }
}
