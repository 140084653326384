import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoomService, ThemeService } from '@core/services';
import { User } from '@shared/models';
import { Room } from 'matrix-js-sdk';
import { RoomFullInfoDialogComponent } from '../room-full-info-dialog/room-full-info-dialog.component';

@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss']
})
export class GroupInfoComponent {
  @Input() contacts: User[] = [];
  @Input() group: Room;

  @Input() isRoomHeader: boolean = false;
  @Input() isSmall: boolean = false;
  @Input() showMembers: boolean = true;
  @Input() membersNamesLimit: number = 4;
  @Input() clickable: boolean = false;

  constructor(
    public dialog: MatDialog,
    public themeService: ThemeService,
    private roomService: RoomService,
  ) { }

  /**
   * Get group avatar
   * @returns {string} Avatar url
   */
  public getAvatar(): string {
    return this.roomService.getGroupAvatar(this.group.roomId);
  }

  /**
   * Check if there is someone typing in this room
   * @returns {boolean} true if there is someone typing in this room
   */
  public isTyping(): boolean {
    return this.isRoomHeader && this.roomService.isTyping(this.group.roomId) && this.group.roomId === this.roomService.roomId?.value;
  }


  /**
   * Get members name
   * @returns {string} Members name
   */
  public getMembersName(): string {
    const members = this.roomService.getUsersFromMembers(this.group.roomId, this.contacts)
    return members ? this.formGroupMembers(members) : '';
  }

  /**
   * Form group name by members names
   * @param {User[]} contacts Groups members
   * @returns {string} Group name
   */
  public formGroupMembers(members: User[]): string {
    // Sort by alphabetically order and get only user first name
    const membersNames = members
      .map(value => value?.displayName?.trim())
      // .map((value) => value?.split(' ')[0])
      .filter(value => !!value)
      .sort((a, b) => a.localeCompare(b)) ?? [];

    // Get limit
    const limit = this.getLimitOfMemberNames();

    // Return group name
    switch (true) {
      case membersNames.length <= limit:
        return membersNames.join(', ');

      case membersNames.length > limit:
        const names = [...membersNames].splice(0, limit);
        return `${names.join(', ')}, +${Math.abs(membersNames.length - limit)}`;
    }
  }

  /**
   * Get Limit Of Member Names
   * @returns {number} Limit
   */
  private getLimitOfMemberNames(): number {
    switch (true) {
      case window.innerWidth <= 1550 && window.innerWidth > 960:
        return this.membersNamesLimit - 1;
      case window.innerWidth <= 960 && window.innerWidth > 500:
        return this.membersNamesLimit - 2;
      case window.innerWidth <= 500:
        return this.membersNamesLimit - 3;
      default:
        return this.membersNamesLimit;
    }
  }

  /**
   * Get total number of members of the meeting
   * @returns {number} Total Members
   */
  public getGroupTotalMembers(): number {
    const members = this.roomService.getValidMembers(this.group.roomId)
    return Math.abs(members.length)
  }

  /**
   * Show Group info
   */
  public showGroupInfo(): void {
    // Check if the group is clickable
    if (!this.clickable) return;

    // Open dialog with group info
    this.dialog.open(RoomFullInfoDialogComponent, {
      panelClass: "room-full-info-dialog",
      data: {
        type: 'group',
        room: this.group,
        contacts: this.contacts
      }
    });
  }
}
