import { Timestamp } from "firebase/firestore";

export interface NavigationToNewMeet {
  roomId?: string;
  date?: Date;
}

export interface MeetTimeRange {
  startTime: string;
  endTime: string;
}

export interface WeekInfo {
  initialDate: Date;
  finalDate: Date;
}

export enum MeetingTypes {
  Recurrent = 'recurrent',
  Scheduled = 'scheduled'
};

export interface MeetingRoom {
  id?: string;
  title: string;
  desc?: string;
  roomId?: string;
  avatar?: string;
  meetDate?: Timestamp;
  startHour?: string;
  finalHour?: string;
  creationDate?: number;
  lastMeetingDate?: number;
  externalUsers?: string[];
  internalUsers?: string[];
  recurrentDays?: string[];
  type: MeetingTypes;
  roomType: ('dm' | 'group');
}
