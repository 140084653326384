import { EmojiGroups } from "@shared/enums"

export interface Emoji18n {
  search?: string,
  emojilist?: string,
  notfound?: string,
  clear?: string,
  categories?: {
    search?: string,
    recent?: string,
    people?: string,
    nature?: string,
    foods?: string,
    activity?: string,
    places?: string,
    objects?: string,
    symbols?: string,
    flags?: string,
    custom?: string
  },
}

export interface Emoji {
  codes: string,
  char: string,
  name: string,
  category: string,
  group: EmojiGroups,
  subgroup: string,
  keywords: string[],
  keywordsStr: string
  imgUrl: string
}
