<h2 mat-dialog-title class="dialog-title">
  <div class="m-h-auto">
    {{ "enterExternalRoutingCode" | translate }}
  </div>
</h2>

<div class="small-text-regular">
  {{ "infoNoExternalRoutingCode" | translate}}
</div><br>

<form role="form" [formGroup]="form" [ngClass]="{ dark: themeService.isDarkTheme() }">

  <mat-dialog-content class="mat-typography">
    <mat-form-field class="full-width">
      <mat-label>{{ "externalRoutingCode" | translate }}</mat-label>
      <input type="text" aria-label="Number" matInput
        formControlName="externalRoute" [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ "cancel" | translate }}</button>
    <button type="submit" mat-button class="call-btn" (click)="save()" [disabled]="!form.valid">
      {{ "call" | translate }}
    </button>
  </mat-dialog-actions>

</form>
