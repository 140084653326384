import { DEFAULT_TOOLBAR_BUTTONS } from "@shared/constants";
import { DEVICES_TYPES } from "@shared/enums";
import { DeviceConfig, ExternalAPIOptions, InterfaceConfig } from "@shared/models";
import { base32 } from "rfc4648";

/**
 * Get Default API Options
 * @returns {Partial<ExternalAPIOptions>}
 */
export async function getDefaultAPIOptions(
  isMobiPhoneUser: boolean,
  isDark: boolean,
  system: string,
  loadDevices: boolean = true
): Promise<Partial<ExternalAPIOptions>> {
  // For more info - https://bit.ly/3CHl1sj
  const configOverwrite: Partial<ExternalAPIOptions> = {
    configOverwrite: {
      doNotStoreRoom: true,
      enableClosePage: false,
      enableWelcomePage: false,
      disableDeepLinking: true,
      disableInviteFunctions: true,
      disableProfile: isMobiPhoneUser,
      disableModeratorIndicator: true,
      enableEmailInStats: true,
      requireDisplayName: !isMobiPhoneUser,
      hideEmailInSettings: true,
      apiLogLevels: ['error'],
      useHostPageLocalStorage: true,
      hideLobbyButton: true,
      testing: {
        disableE2EE: true,
      },
      disabledSounds: [
        'RECORDING_OFF_SOUND',
        'RECORDING_ON_SOUND',
      ],
      localRecording: {
        disable: false,
        notifyAllParticipants: true,
        disableSelfRecording: true,
      },
      recordingService: {
        enabled: false,
        sharingEnabled: true,
        hideStorageWarning: false,
      },
      analytics: {
        disabled: true,
      },
      gravatar: {
        disabled: true,
      },
      prejoinConfig: {
        hideDisplayName: isMobiPhoneUser,
      },
      breakoutRooms: {
        hideAddRoomButton: true,
      },
      remoteVideoMenu: {
        disableKick: true,
      },
      logging: {
        defaultLogLevel: 'error',
        disableLogCollector: true,
      },
    },
    interfaceConfigOverwrite: {
      DEFAULT_BACKGROUND: isDark ? '#3F3F3F' : '#F2F2F2',
      TOOLBAR_BUTTONS: getToolbarButtons(system)
    }
  }

  // Get devices
  if (loadDevices) configOverwrite.devices = await getDevices()

  // Return
  return configOverwrite;
}

/**
 * Get Devices
 * @returns {DeviceConfig} Devices
 */
async function getDevices(): Promise<DeviceConfig> {
  // Get devices
  const audioInput = await getDeviceLabelFromId(localStorage.getItem(DEVICES_TYPES.INPUT));
  const audioOutput = await getDeviceLabelFromId(localStorage.getItem(DEVICES_TYPES.OUTPUT));
  const videoInput = await getDeviceLabelFromId(localStorage.getItem(DEVICES_TYPES.VIDEO));

  // Form devices
  const devices: DeviceConfig = {};

  // Set devices
  if (audioInput) devices.audioInput = audioInput;
  if (audioOutput) devices.audioOutput = audioOutput;
  if (videoInput) devices.videoInput = videoInput;

  // Return devices
  return devices;
}

/**
 * Get Device from device id
 */
async function getDeviceLabelFromId(deviceId: string): Promise<string> {
  try {
    // Get devices
    let devices = [];

    // If is Firefox, get devices from user media
    if (/Firefox/.test(navigator.userAgent)) {
      // Get stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

      // Get input devices from user media
      devices = await navigator.mediaDevices.enumerateDevices();

      // Stop stream
      stream.getTracks().forEach((track) => track.stop());
    } else {
      // Get input devices from user media
      devices = await navigator.mediaDevices.enumerateDevices();
    }

    // Return device
    return devices.find(device => device.deviceId === deviceId)?.label || null;
  } catch (error) {
    return '';
  }
}


/**
 * Create Jitsi Room Name
 * @param {roomId} roomId Room Id
 * @returns {string} Room id in base 32
 */
export function createJitsiRoomName(roomId: string): string {
  // Form buffer
  const Buffer = require('buffer/').Buffer;
  const buffer = Buffer.from(roomId);

  // Room id in base 32
  return base32.stringify(buffer, { pad: false })
}

/**
 * Get matrix room id from jitsi room name
 * @param {string} encodedString Encoded string
 * @returns {string} Matrix Room id
 */
export function getRoomNameFromJitsiName(encodedString: string): string {
  // String -> Uint8Array
  const aux = base32.parse(encodedString);

  // Form buffer
  const Buffer = require('buffer/').Buffer;
  const buffer = Buffer.from(aux, 'base32');

  // Converte o buffer para uma string
  return buffer.toString('utf8');
}

/**
 * get Toolbar Buttons
 * @returns {string} Toolbar Buttons
 */
function getToolbarButtons(system: string): InterfaceConfig['TOOLBAR_BUTTONS'] {
  let invalidButtons: InterfaceConfig['TOOLBAR_BUTTONS'] = [];

  switch (system) {
    // Firefox
    case 'Firefox':
      invalidButtons = ['recording'];
      break;

    // Safari
    case 'Safari':
      invalidButtons = ['recording'];
      break;

    // Others
    default:
      break;
  }

  // Filtered Toolbar Buttons
  return DEFAULT_TOOLBAR_BUTTONS
    .filter(opt => !invalidButtons.includes(opt));
}

/**
 * Get lang
 * @returns {string} Lang
 */
export function getJitsiLang(lang: string): string {
  switch (lang) {
    // English
    case 'en':
      return 'en';

    // Spanish
    case 'es':
      return 'es';

    // // Portuguese
    // case 'pt':
    //   return 'pt';

    // Brazilian portuguese
    default:
      return 'ptBR';
  }
}
