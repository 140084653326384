import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from '@core/services';
import { EXTERNAL_ROUTE_REGEX } from '@shared/constants';
import { Observable, map, startWith } from 'rxjs';

type Data = {
  externalRoute: number | string[]
}

@Component({
  selector: 'app-select-external-route',
  templateUrl: './select-external-route.component.html',
  styleUrls: ['./select-external-route.component.scss']
})
export class SelectExternalRouteComponent implements OnInit {
  filteredOptions: Observable<string[]>;

  form: UntypedFormGroup = this.fb.group({
    externalRoute: [[], [Validators.pattern(EXTERNAL_ROUTE_REGEX)]],
  });
  constructor(private fb: UntypedFormBuilder,
    public themeService: ThemeService,
    public dialogRef: MatDialogRef<SelectExternalRouteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
  ) { }

  ngOnInit(): void {
    this.filteredOptions = this.form.get('externalRoute').valueChanges.pipe(
      startWith(''),
      map(value => !!this.data.externalRoute ? this._filter(value) : []),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (Array.isArray(this.data.externalRoute)) {
      return [...this.data.externalRoute].filter(option => option.toLowerCase().includes(filterValue));
    } else return `${this.data.externalRoute}`.includes(filterValue) ? [`${this.data.externalRoute}`] : []
  }

  /**
   * Send and close the dialog result
   */
  public save() {
    this.dialogRef.close({ ...this.form.value });
  }
}
