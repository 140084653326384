import { PbxModelEnum, User } from "@shared/models";

/**
  * Form Users Endpoint Body
  * @param {User} user User Data
  * @param {Partial<PBX>} pbx PBX Data
  * @returns Endpoint body
  */
export const formUsersEndpointBody = (user: any, pbx: Partial<any>) => {
  return {
    extension: user.extension,
    passwordExten: user.passwordExten,
    uuid: user.id,
    email: user.email,
    authuser: user.authuser ?? user.multiTalkAuthorizationUser,
    displayName: user.displayName,
    callbypbx: user.pbxRecEnabled ?? false,
    domainPbx: getDomainPbx(user, pbx),
    portSipPbx: pbx?.portSipPbx ?? '',
    dtmfMode: pbx?.dtmfPbx ?? 'rfc4733',
  }
}

/**
 * Get Domain PBX
 * @param {User} user User Data
 * @param {Partial<PBX>} pbx PBX Data
 * @returns Domain PBX
 */
export const getDomainPbx = (user: any, pbx: Partial<any>): string => {
  // If user has no pbx, return empty string
  if (!user.pbxId) return '';

  // Get pbx type
  switch (pbx?.modelPbx) {
    case PbxModelEnum.SKYVOICE:
      const skyvoicePbx = pbx as any;
      return skyvoicePbx?.vpnIp ? skyvoicePbx?.vpnIp : skyvoicePbx?.domainPbx;
    default:
      return pbx?.domainPbx;
  }
}

/**
 * Get user name + company sector (if exists)
 * @param {User} user User Data
 * @returns {string} User name + company sector (if exists)
 */
export const getUserNameWithSector = (user: User): string => {
  return !!user?.displayName ? user.displayName : '';
  // switch (true) {
  //   // Return empty string if user has no display name
  //   case !user?.displayName:
  //     return '';

  //   // Return user name if has no company sector
  //   case !user.companySector:
  //     return user.displayName;

  //   // Return user name if user name includes company sector
  //   case user.displayName.includes(user.companySector):
  //     return user.displayName;

  //   // Return user name (company sector)
  //   default:
  //     return `${user.displayName} (${user.companySector})`;
  // }
}

/**
 * Get user name without company sector
 * @param {User} user User Data
 * @returns {string} User name without company sector
 */
export const getUserNameWithoutSector = (user: User): string => {
  switch (true) {
    // Return empty string if user has no display name
    case !user?.displayName:
      return '';

    // Return user name if has no company sector
    case !user.companySector:
      return user.displayName;

    // Return user name without company sector
    default:
      return user.displayName.replace(` (${user.companySector})`, '');
  }
}

export function ImportUserException(msg: string, content?: string[]) {
  this.message = msg;
  this.content = content ? Array.from(new Set(content)) : null;
};
