import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-size-exceeded',
  templateUrl: './file-size-exceeded.component.html',
  styleUrls: ['./file-size-exceeded.component.scss'],
})
export class FileSizeExceededComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<FileSizeExceededComponent>
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
