import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpTimingMonitorService } from '@core/services';

@Component({
  selector: 'app-dev-mode-modal',
  templateUrl: './dev-mode-modal.component.html',
  styleUrls: ['./dev-mode-modal.component.scss']
})
export class DevModeModalComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  filteredRoute = this.httpTimingMonitorService.filteredRoute$.value

  constructor(
    public dialogRef: MatDialogRef<DevModeModalComponent>,
    public httpTimingMonitorService: HttpTimingMonitorService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  leaveFiltering(filter: boolean) {
    this.dialogRef.close();
    if (filter && this.searchInput.nativeElement?.value)
      this.httpTimingMonitorService.filteredRoute$.next(this.searchInput.nativeElement.value)
    else
      this.httpTimingMonitorService.filteredRoute$.next(null)
  }

}
