import { Component, Input, OnInit } from '@angular/core';
import { RoomService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@shared/models';

@Component({
  selector: 'app-typing',
  templateUrl: './typing.component.html',
  styleUrls: ['./typing.component.scss']
})
export class TypingComponent implements OnInit {
  @Input() contacts: User[];
  @Input() roomId: string;
  @Input() isDM: boolean = false;

  public membersTyping: string[] = [];

  constructor(
    private roomService: RoomService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void { }

  /**
 * Get names of the members
 * @returns {string} Members name
 */
  public getTypingDesc(): string {
    // Get only the name of the members in this room
    const membersOfThisRoom = this.roomService.getMembersNameOfThisRoom([...this.roomService.typingMembers], this.roomId);

    // Get the contact names
    this.membersTyping = this.roomService
      .getMembersName(this.contacts, membersOfThisRoom);

    // Get fist name
    this.membersTyping = this.membersTyping.map(member => member.split(' ')[0]);

    // Get the typing text
    return this.membersTyping.length > 2
      ? `${this.membersTyping.slice(0, 2).join(', ')} ${this.translateService.instant('andOthers')}`
      : this.membersTyping.join(', ');
  }

}
