export interface AppIntegration {
  id: string;
  icon: AppIntegrationIcon;
  name: string;
  domain: string;
  enabled: boolean;
  groups: string[];
  favorited: boolean;
  type: AppIntegrationType;
  openInWindow: boolean;
}

export interface AppIntegrationIcon {
  name: string;
  isSvg: boolean;
}

export type AppIntegrationType = 'conecta' | 'agente' | 'supervisor' | 'phonebook' | 'userManager' | 'lcdr' | 'lrec';
