import { Injectable } from '@angular/core';
import {
  Database,
  onValue,
  ref,
  remove,
  set
} from '@angular/fire/database';
import {
  Firestore,
  Unsubscribe,
  collectionChanges,
  where
} from '@angular/fire/firestore';
import { FirestoreService, TenantService } from '@core/services';
import { collection } from '@firebase/firestore';
import { User } from '@shared/models';
import {
  getCallingWithPath,
  getUserNameWithSector,
  getUserNameWithoutSector,
  getUsersCollectionPath
} from '@shared/utils';
import { isEqual } from 'lodash';
import { DocumentChange, DocumentData } from 'rxfire/firestore/interfaces';
import { BehaviorSubject, Observable, first, lastValueFrom, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  // Calling With flags
  public callingWith$ = new BehaviorSubject<string>(null);
  private callingWithUnsubscribe: Unsubscribe = null;

  // Contacts Database flags
  public contacts$ = new BehaviorSubject<User[]>([]);
  public hasLoaded = false;

  constructor(
    private firestore: Firestore,
    private tenant: TenantService,
    private firestoreService: FirestoreService,
    private database: Database,
  ) { }

  /**
   * Init contacts
   */
  public init(): void {
    // Get users from firestore and update local users
    this.getFromFirestore()
  }

  /**
   * Listening calling with
   * @param {string} id User id
   */
  public listeningCallingWith(id: string): void {
    // Check if has already a listener
    if (this.callingWithUnsubscribe) return;

    // Database users presence path
    const path = getCallingWithPath(this.tenant.tenant.id, id);

    // Database users presence ref
    const usersCallingWithRef = ref(this.database, path);

    // Get users presence ref
    this.callingWithUnsubscribe = onValue(usersCallingWithRef, (snapshot) => {
      this.callingWith$.next(snapshot?.val());
    });
  }

  /**
   * Get users from firestore and update local users
   */
  public getFromFirestore() {
    // Form ref
    const usersRef = collection(this.firestore, getUsersCollectionPath(this.tenant.tenant.id));

    console.log("Listening collaborators list...");

    // Get users
    collectionChanges(usersRef, { events: ['added', 'modified', 'removed'] })
      .subscribe(events => {
        // Get events by type
        const addEvents = this.filterEvents(events, 'added');
        const modifiedEvents = this.filterEvents(events, 'modified');
        const removedEvents = this.filterEvents(events, 'removed');

        // Add users
        this.addUserContact(addEvents);

        // Update users
        modifiedEvents.forEach(user => this.updateUserContact(user));

        // Remove users
        this.removeUserContact(removedEvents);
      });
  }

  /**
   * Filter events
   * @param {DocumentChange<DocumentData>[]} events Document data
   * @param {string} type Event type
   * @returns {User[]} Array of users
   */
  private filterEvents(events: DocumentChange<DocumentData>[], type: string): User[] {
    return events.filter(event => event.type === type)
      .map(event => { return { id: event.doc.id, ...event.doc.data() } as User })
      .sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1)
      .map(user => {
        // Remove unused fields
        delete user.pass;
        delete user.presence;

        // Return user
        return user;
      });
  }


  /**
   * Remove contact
   * @param {User} users Users
   */
  private removeUserContact(users: User[]) {
    // Check if has users
    if (!users.length) return;

    // Get users ids
    const usersIds = users.map(user => user.id);

    // Remove users from contacts list
    const contacts = this.contacts$.value
      .filter(contact => !usersIds.includes(contact.id))
      .sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1);

    // Update contacts list
    this.contacts$.next(contacts);
  }

  /**
   * Add User contact
   * @param {User} users Users
   */
  private addUserContact(users: User[]) {
    // Check if has users
    if (!users.length) return;

    // Set has loaded
    this.hasLoaded = true;

    // Add users to contacts list
    const newContacts = this.contacts$.value
      .concat(users)
      .sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1);

    // Check if has users
    this.contacts$.next(newContacts);
  }

  /**
   * Update User contact
   * @param {User} user User
   */
  private updateUserContact(user: User) {
    // Check if has user
    if (!user) return;

    // Get contacts
    const userContacts = this.contacts$.value;

    // Get user index
    const index = userContacts.findIndex(contact => contact.id === user.id);

    // Check if has user
    if (index === -1) return this.addUserContact([user]);

    // Get old user
    const oldUser = userContacts[index];

    // Check if has changes
    if (isEqual(user, oldUser)) return;

    // Update user
    userContacts[index] = user;

    // Sort users
    userContacts
      .sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1);

    // Update contacts
    this.contacts$.next(userContacts);
  }

  /**
   * Get contacts and execute callback
   * @param {Function} callback Function to execute after getting contacts
   * @param {boolean} pure If true, return user without company sector in name (default: true)
   * @returns {void}
   */
  public getContactsAndExecute(callback: (contacts: User[]) => void, pure: boolean = true): void {
    // Execute callback Check if has loaded
    if (this.hasLoaded) {
      return callback(this.getContacts(pure, [...this.contacts$.getValue()]));
    }
    // Get contacts
    this.contacts$.pipe(take(3)).subscribe((users) => {
      // Execute callback if has contacts
      if (users.length > 0) {
        callback(this.getContacts(pure, [...users]));
      }
    });
  }

  /**
   * Get user by email
   * @param {string} email User email
   * @param {string} tenantId Company id
   * @returns {Observable<User>} User
   */
  public getByEmail(
    email: string,
    tenantId: string = this.tenant.tenant?.id
  ): Observable<User> {
    // Database users path
    const path = getUsersCollectionPath(tenantId);

    // Get user
    return this.firestoreService
      .colOneWithId$<User>(path, [where('email', '==', email)])
      .pipe(first());
  }

  /**
   * Get user by id in firestore
   * @param {string} id User id
   * @param {string} tenantId Company id
   */
  public getByIdInFirestore(
    id: string,
    tenantId: string = this.tenant.tenant?.id
  ): Promise<User> {
    // Database users path
    const path = getUsersCollectionPath(tenantId, id);

    // Get user
    return lastValueFrom(
      this.firestoreService
        .docWithId$<User>(path)
        .pipe(first())
    );
  }

  /**
   * Get user by id
   * @param {string} id User id
   * @param {boolean} pure If true, return user without company sector
   * @returns {Promise<User>} User
   */
  public async getById(id: string, pure: boolean = false): Promise<User> {
    // Get local user
    let user = [...this.contacts$.getValue()].find((user) => user.id === id);

    // Check if has user
    if (!user) user = await this.getByIdInFirestore(id);

    // Check if has user
    if (!user) return null;

    // Replace display names to displayName + company sector
    user.displayName = pure
      ? getUserNameWithoutSector(user)
      : getUserNameWithSector(user);

    // Get user by id
    return user;
  }

  /**
   * Get user by extension
   * @param {string} extension User extension
   * @param {boolean} pure If true, return user without company sector
   * @returns {User} User
   */
  public getByExtension(extension: string, pure: boolean = false): User | undefined {
    return this.getContacts(pure)
      .find((user) => user.extension === extension);
  }

  /**
   * Get all contacts
   * @param {boolean} pure If true, return user without company sector
   * @returns {User[]} Users
   */
  private getContacts(pure: boolean, users = this.contacts$.getValue()): User[] {
    return users;
    // return users.map(user => {
    //   user.displayName = pure
    //     ? getUserNameWithoutSector(user)
    //     : getUserNameWithSector(user);
    //   return user;
    // });
  }

  /**
   * Update Calling With
   * @param {string} id User id
   * @param {string} callingWith Calling With
   */
  public updateCallingWith(id: string, callingWith: string): void {
    // Database users presence ref
    const path = getCallingWithPath(this.tenant.tenant.id, id);

    // Get users presence ref
    const usersCallingWithRef = ref(this.database, path);

    // Set users presence
    set(usersCallingWithRef, callingWith);
  }

  /**
   * Delete Calling With
   * @param {string} id User id
   */
  public deleteCallingWith(id: string): void {
    // Database users presence ref
    const path = getCallingWithPath(this.tenant.tenant.id, id);

    // Get users presence ref
    const usersCallingWithRef = ref(this.database, path);

    // Set users presence
    remove(usersCallingWithRef);
  }

  /**
   * Remove calling with listener
   */
  public removeCallingWithListener(): void {
    // Remove calling with listener
    if (this.callingWithUnsubscribe) {
      this.callingWithUnsubscribe();
      this.callingWithUnsubscribe = null;
    }
  }
}
