<div style="height: 100%">
    <div class="advanced-filter-title">
        <h2 mat-dialog-title class="dialog-title"> Filtrar rota </h2>
    </div>

    <mat-dialog-content class="mat-typography">
        <mat-form-field class="full-width">
            <mat-label> Rota: </mat-label>
            <input matInput #searchInput [value]="filteredRoute" />
        </mat-form-field>
    </mat-dialog-content>
</div>

<mat-dialog-actions class="dialog-actions" align="end">
    <button color="primary" mat-raised-button (click)="leaveFiltering(true)" type="submit" class="dialog-save-btn">
        Filtrar </button>
    <button mat-button class="dialog-cancel-btn" (click)="leaveFiltering(false)"> Limpar </button>
</mat-dialog-actions>