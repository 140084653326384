<div class="edit-bar">
  <div
    class="edit-item"
    (click)="transform.scale < 2 ? zoomIn() : null"
    [class.item-disabled]="transform.scale >= 2"
  >
    <mat-icon> zoom_in </mat-icon>
  </div>
  <div
    class="edit-item"
    (click)="transform.scale > 0 ? zoomOut() : null"
    [class.item-disabled]="transform.scale <= 0"
  >
    <mat-icon> zoom_out </mat-icon>
  </div>
  <div
    class="edit-item"
    (click)="flipH()"
    [class.item-active]="transform.flipH"
  >
    <mat-icon> swap_horiz </mat-icon>
  </div>
  <div
    class="edit-item"
    (click)="flipV()"
    [class.item-active]="transform.flipV"
  >
    <mat-icon> swap_vert </mat-icon>
  </div>
  <div class="edit-item" (click)="rotateLeft()">
    <mat-icon> rotate_left </mat-icon>
  </div>
  <div class="edit-item" (click)="rotateRight()">
    <mat-icon> rotate_right </mat-icon>
  </div>
  <div class="edit-item" (click)="preview()">
    <mat-icon> preview </mat-icon>
  </div>
</div>
