import { externalContactsTranslations, nameof } from "@shared/constants/csv.constant";

/**
 * Yes Or No to Boolean
 * @param yesOrNo a string containing yes or no in en, es or pt
 * @returns isYes Boolean value representing the equivalent for yes or no
 */
export const yesOrNoToBoolean = (yesOrNo: string): boolean => {
    let isYes = false;
    if (yesOrNo.toLowerCase() === 'sim')
        isYes = true;
    else if (yesOrNo.toLowerCase() === 'si')
        isYes = true;
    else if (yesOrNo.toLowerCase() === 'sí')
        isYes = true;
    else if (yesOrNo.toLowerCase() === 'yes')
        isYes = true;

    return isYes;
}

/**
 * Convert phone description type
 * @param description string with the description in en, pt or es
 * @returns contactType type of the contact based on standardalized contact types
 */
export const convertPhoneDescription = (description: string) => {
    let contactType = '';
    for (const type in externalContactsTranslations) {
        if (externalContactsTranslations[type].includes(description?.toLowerCase())) {
            contactType = nameof(externalContactsTranslations, x => x[type])
        }
    }
    return contactType;
}
