<button
  mat-fab
  class="float"
  *ifViewportSize="['small']"
  (click)="toggleDialpad()"
>
  <mat-icon>dialpad</mat-icon>
</button>
<div class="dialpad mat-app-background" #dialpad *ifViewportSize="['small']">
  <div class="dialpad--header" (click)="toggleDialpad()">
    <mat-icon>expand_more</mat-icon>
    <span> {{ "keyboard" | translate }}</span>
  </div>
  <div class="dialpad--content">
    <app-dialer [floaterExpanded]="showDialpad"></app-dialer>
  </div>
</div>
