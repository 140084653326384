export enum UserPresence {
  AVAILABLE = 'Available',
  BUSY = 'Busy',
  DONOTDISTURB = 'DoNotDisturb',
  IN_MEETING = 'InMeeting',
  IN_CALL = 'InCall',
  IN_PRESENTATION = 'InPresentation',
  AWAY = 'Away',
  BERIGHTBACK = 'BeRightBack',
  OFFLINE = 'Offline',
}

export enum UserPresenceDevice {
  MOBILE = 'Mobile',
  DESKTOP = 'Desktop',
  WEB = 'Web'
}
