import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';

interface Notification {
  type: string;
  message: string;
  actionText: string;
}

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public notification: Notification,
    public snackBarRef: MatSnackBarRef<NotifierComponent>
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.snackBarRef.dismiss();
  }
}
