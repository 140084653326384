<!-- Title -->
<h1 mat-dialog-title *ngIf="data.title">{{data.title | translate }}</h1>

<div [ngClass]="{ dark: themeService.isDarkTheme() }">
  <!-- Warn -->
  <div *ngIf="data.warn" class="warn">{{data.warn | translate }}</div>

  <!-- Desc -->
  <p *ngIf="data.desc ">{{data.desc | translate }}</p>

  <!-- Copy link -->
  <app-copy-element [isExternalButton]="true" [textToCopy]="link">
  </app-copy-element>
</div>

<!-- Actions -->
<mat-dialog-actions align="end">
  <button mat-button style="text-transform: uppercase" mat-dialog-close>{{ "cancel" | translate }}</button>
  <button *ngIf="data.canInitCall" (click)="enterConference()" color="primary" mat-raised-button type="submit"
    style="text-transform: uppercase" class="apply-btn"> {{ "start" | translate }} </button>
</mat-dialog-actions>
