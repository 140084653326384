export type CategoryType = 'featured' | 'emoji' | 'trending';

export type ContentFilter = 'off' | 'low' | 'medium' | 'high';

export type GifFormat =
  | 'gif'
  | 'gifpreview'
  | 'mediumgif'
  | 'tinygif'
  | 'nanogif'
  | 'mp4'
  | 'loopedmp4'
  | 'tinymp4'
  | 'nanomp4'
  | 'webm'
  | 'tinywebm'
  | 'nanowebm';

export interface SearchResult {
  next: string;
  results: Gif[];
}

export type TrendingResult = SearchResult;

export interface CategoryResult {
  tags: Tag[];
}

export interface Media {
  preview: string;
  url: string;
  dims: number[];
  size: number;
}

export interface Gif {
  created: number;
  hasaudio: boolean;
  id: string;
  media_formats: { [format in GifFormat]: Media };
  tags: string[];
  title: string;
  itemurl: string;
  hascaption: boolean;
  content_description: string;
  url: string;
}

export interface Tag {
  searchterm: string;
  path: string;
  image: string;
  name: string;
}

export interface SearchOptions {
  locale?: string;
  media_filter?: string;
  ar_range?: string;
  contentfilter?: ContentFilter;
  limit?: number;
  pos?: string;
  anon_id?: string;
}

export type TrendingOptions = SearchOptions;

export interface CategoryOptions {
  locale?: string;
  type?: CategoryType;
  contentfilter?: ContentFilter;
  anon_id?: string;
}

export interface SearchSuggestionsOptions {
  locale?: string;
  limit?: number;
  anon_id?: string;
}

export interface SearchSuggestionsResult {
  results: string[];
}

export type AutoCompleteOptions = SearchSuggestionsOptions;
export type AutoCompleteResult = SearchSuggestionsResult;
export type TrendingSearchTermsOptions = SearchSuggestionsOptions;
export type TrendingSearchTermsResult = SearchSuggestionsResult;

export interface RegisterShareOptions {
  locale?: string;
  q?: string;
  anon_id?: string;
}

export interface RegisterShareResult {
  status: string;
}

export interface GifsOptions {
  media_filter?: string;
  limit?: number;
  pos?: string;
  anon_id?: string;
}

export type GifsResult = SearchResult;

export interface AnonymousIDResult {
  anon_id: string;
}
