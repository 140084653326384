export type LcsEventTypes = LcsEventUserTypes
  | LcsEventSectorTypes
  | LcsEventIntegrationTypes
  | LcsEventPABXTypes
  | LcsEventRingGroupTypes
  | LcsEventsTrunkTypes
  | LcsEventsEntryRouteTypes
  | LcsEventsTimeConditionTypes;

export enum LcsEventUserTypes {
  USER_CREATION = 'user_creation',
  USER_DELETION = 'user_deletion',
  USER_UPDATE = 'user_update',
  USER_IMPORT = 'user_import',
  USER_LOGIN = 'user_login',
  USER_LOGOUT = 'user_logout',
}

export enum LcsEventSectorTypes {
  COMPANY_SECTOR_CREATION = 'company_sector_creation',
  COMPANY_SECTOR_UPDATE = 'company_sector_update',
}

export enum LcsEventIntegrationTypes {
  INTEGRATION_APP_CREATION = 'integration_app_creation',
  INTEGRATION_APP_UPDATE = 'integration_app_update',
  INTEGRATION_APP_DELETION = 'integration_app_deletion',
}

export enum LcsEventRingGroupTypes {
  RING_GROUP_CREATION = 'ring_group_creation',
  RING_GROUP_UPDATE = 'ring_group_update',
  RING_GROUP_DELETION = 'ring_group_deletion',
}


export enum LcsEventsTrunkTypes {
  TRUNK_CREATION = 'trunk_creation',
  TRUNK_UPDATE = 'trunk_update',
  TRUNK_DELETION = 'trunk_deletion',
}

export enum LcsEventsEntryRouteTypes {
  ENTRY_ROUTE_CREATION = 'entry_route_creation',
  ENTRY_ROUTE_UPDATE = 'entry_route_update',
  ENTRY_ROUTE_DELETION = 'entry_route_deletion',
}

export enum LcsEventsTimeConditionTypes {
  TIME_CONDITION_CREATION = 'time_condition_creation',
  TIME_CONDITION_UPDATE = 'time_condition_update',
  TIME_CONDITION_DELETION = 'time_condition_deletion',
}


export enum LcsEventPABXTypes {
  PABX_CREATION = 'pabx_creation',
  PABX_UPDATE = 'pabx_update',
  PABX_DELETION = 'pabx_deletion',

  EXTENSION_CREATION = 'equip_extension_creation',
  EXTENSION_UPDATE = 'equip_extension_update',
  EXTENSION_DELETION = 'equip_extension_deletion',

  PROVIDER_CREATION = 'equip_provider_creation',
  PROVIDER_UPDATE = 'equip_provider_update',
  PROVIDER_DELETION = 'equip_provider_deletion',
}

export enum LcsEventDescriptions {
  SUCCESS = 'success',
  ERROR = 'error',
  NORMAL_LOGIN = 'normal_login',
  MICROSOFT_LOGIN = 'microsoft_login',
}
