<div class="notification-container">
  <div *ngIf="notifications.length === 0" class="no-notification">
    {{ "noNotifications" | translate }}
  </div>
  <div *ngIf="notifications.length > 0" class="all-read-btn-container" (click)="$event.stopPropagation()">
    <button mat-stroked-button (click)="markAllAsRead()">
      {{ "markAllAsRead" | translate }}
    </button>
  </div>
  <ng-container *ngFor="let notification of notifications">
    <div class="notification" [class.notification--unread]="!notification.read">
      <h4 class="notification--title" (click)="$event.stopPropagation()">
        <span>{{ notification.title | translate }}</span>
        <div class="notification--mark-read-status" [class.read]="notification.read" [class.unread]="!notification.read"
          (click)="toggleRead(notification)" [matTooltip]="getTooltip(notification.read)" matTooltipPosition="before">
        </div>
      </h4>
      <span class="notification--body">
        {{ notification.text | translate }} {{ notification.version}}
      </span>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
</div>