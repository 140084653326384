import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ThemeService } from '@core/services';
import { convertHour, populateHoursArray, validateTime } from '@shared/utils';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() label?: string;
  @Input() width: string = '200px';
  @Input() is24hFormat: boolean = true;
  @Input() isEdition: boolean = true;
  @Input() increment = 5; // Increment in minutes
  @Input() defaultHourValue: string;


  public options: string[] = [];
  private sub: Subscription;


  constructor(
    public themeService: ThemeService,
  ) { }


  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }


  ngOnInit(): void {
    // Create array with time options
    this.options = populateHoursArray(this.increment)
      .map(hour => convertHour(hour, this.is24hFormat ? 24 : 12));


    // Listening control changes
    this.sub = this.control
      .valueChanges
      .subscribe(value => {
        // Data validation
        if (!validateTime(value, this.is24hFormat))
          this.control.setErrors({ invalid: true })



        /*Lines above will update the filtered options
        * Everytime the user types, it will slice the options
        * and show only the matching ones
        * Example:
        *
        * User typed "12:"
        * Actions: Only shows 12:00, 12:05 and so on
        * This feature was deactivated due to usability factors
        */


        const filtered = !!value?.trim()
          ? this.options.filter(option => option.includes(value))
          : [...this.options];



        // Scroll to first option
        if (filtered.length > 0) document.getElementById(filtered[0])?.scrollIntoView();


        // Set default value
        this.defaultHourValue = this.options.filter(option => option.includes(value))[0]
      })


    if (!this.isEdition) this.control.setValue(this.defaultHourValue);
    else this.defaultHourValue = this.control.value;


  }


  /**
   * Update time option
   * @param {MatSelectChange} option Mat Select Change
   */
  public updateOption(option: MatSelectChange): void {
    this.control.setValue(option.value);
  }
}
