import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '@core/services';

@Component({
  selector: 'app-copy-element',
  templateUrl: './copy-element.component.html',
  styleUrls: ['./copy-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyElementComponent implements OnInit {
  @Input() textToCopy: string = '';
  @Input() isExternalButton = false;

  constructor(
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
  }

  /**
 * Copy link
 */
  public copy(): void {
    this.utilsService.copy(this.textToCopy);
  }

}
