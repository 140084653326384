import { Injectable } from '@angular/core';
import { FirestoreService, LcsEventsService } from '@core/services';
import { LcsEventDescriptions, LcsEventPABXTypes } from '@shared/enums';
import { EquipmentProvider } from '@shared/models';
import { getDeviceProviderPath, getDiffBetweenObj } from '@shared/utils';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EquipmentProviderService {
  constructor(
    private firestore: FirestoreService,
    private lcsEventService: LcsEventsService,
  ) { }

  /**
 * Get equipment providers
 * @param {string} equipmentId Equipment ID
 * @returns {Promise<EquipmentProvider[]>} Equipment providers
 */
  public getEquipProviders(equipmentId: string): Promise<EquipmentProvider[]> {
    if (!equipmentId) return Promise.resolve([]);
    const path = getDeviceProviderPath(equipmentId);
    return lastValueFrom(this.firestore.colWithIds$<EquipmentProvider>(path).pipe(first()));
  }

  /**
   * Get equipment provider
   * @param {string} equipmentId Equipment ID
   * @param {string} providerId provider ID
   * @returns {Promise<EquipmentProvider>} Equipment provider
   */
  public getEquipProvider(equipmentId: string, providerId: string): Promise<EquipmentProvider> {
    const path = getDeviceProviderPath(equipmentId, providerId);
    return lastValueFrom(this.firestore.docWithId$<EquipmentProvider>(path).pipe(first()));
  }

  /**
   * Create equipment provider
   * @param {string} equipmentId Equipment ID
   * @param {EquipmentProvider} data Equipment provider data
   */
  public async createEquipProvider(equipmentId: string, data: EquipmentProvider): Promise<void> {
    try {
      // Get provider path
      const path = getDeviceProviderPath(equipmentId);

      // Add provider
      await this.firestore.addDoc<EquipmentProvider>(path, data);

      // Handle success
      this.handleSuccess(LcsEventPABXTypes.PROVIDER_CREATION, {
        sn: equipmentId,
        created: {
          ...data,
          passw: '*****',
        }
      });
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.PROVIDER_CREATION);
    }
  }

  /**
   * Update equipment provider
   * @param {string} equipmentId Equipment ID
   * @param {string} providerId provider ID
   * @param {Partial<EquipmentProvider>} data Equipment provider data
   * @param {Partial<EquipmentProvider>} original Original provider data
   */
  public async updateEquipProvider(
    equipmentId: string,
    data: Partial<EquipmentProvider>,
    original: Partial<EquipmentProvider>
  ): Promise<void> {
    try {
      // Get provider id
      const providerId = original.id;

      // Get provider path
      const path = getDeviceProviderPath(equipmentId, providerId);

      // Update provider
      await this.firestore.updateDoc(path, data);

      // Handle success
      this.handleSuccess(LcsEventPABXTypes.PROVIDER_UPDATE, {
        id: providerId,
        sn: equipmentId,
        diff: getDiffBetweenObj(data, original, ['passw']),
      });
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.PROVIDER_UPDATE);
    }
  }

  /**
   * Remove provider
   * @param {string} equipmentId Equipment ID
   * @param {EquipmentProvider} provider provider ID
   */
  public async removeEquipProvider(equipmentId: string, provider: EquipmentProvider): Promise<void> {
    try {
      // Get provider path
      const path = getDeviceProviderPath(equipmentId, provider.id);

      // Delete provider
      await this.firestore.deleteDoc(path);

      // Handle success
      this.handleSuccess(LcsEventPABXTypes.PROVIDER_DELETION, {
        sn: equipmentId,
        deleted: {
          ...provider,
          passw: '*****',
        }
      });
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.PROVIDER_DELETION);
    }
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventPABXTypes} type The event type
   */
  public handleError(error: any, type: LcsEventPABXTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, true);

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsEventPABXTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventPABXTypes, value: any): void {
    this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, true);
  }
}
