export enum TICKETS_STATUS_ENUM {
  ANSWERED = 'ANSWERED',
  NO_ANSWER = 'NO ANSWER',
  FAILED = 'FAILED',
  BUSY = 'BUSY',
  UNKNOWN = 'UNKNOWN'
}

export enum TICKETS_TYPE_ENUM {
  inbound = 'inbound',
  outbound = 'outbound',
  received = 'received'
}
