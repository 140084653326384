import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HTMLInputTypeAttribute } from '@shared/enums';

@Component({
  selector: 'dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputComponent),
      multi: true,
    },
  ],
})
export class DropdownInputComponent implements OnInit, ControlValueAccessor {
  @Input() appearance: string;
  @Input() inputLabel: string;
  @Input() type: HTMLInputTypeAttribute;
  @Input() optionLabelKey: string;
  @Input() optionValueKey: string;
  @Input() allOptions: any[];
  @Input() options: any[];
  @Input() filterComparison?: (opt: any) => boolean;
  @Input('dropDownDisabled') disabled: boolean;

  input: string;

  constructor(private fb: UntypedFormBuilder) {}

  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(input: string) {
    this.input = input;
  }

  ngOnInit(): void {}

  selected(evt: MatAutocompleteSelectedEvent): void {
    this.input = evt.option.value;
  }

  applySearchFilter(searchFilter: string): void {
    const normalizedFilter = searchFilter.toString().trim().toLowerCase();

    if (normalizedFilter && normalizedFilter !== '') {
      this.options = this.allOptions
        .filter((option) => {
          const name = option.name ?? option.displayName;
          return (
            (name?.toLowerCase().includes(normalizedFilter) ||
              option.extension
                ?.toString()
                .toLowerCase()
                .includes(normalizedFilter)) &&
            this.filterComparison(option)
          );
        })
        .map((option) => {
          const name = option.name ?? option.displayName;
          const optionName = `${name} (${option.extension})`;
          return { ...option, optionName };
        });
    } else {
      this.options = this.allOptions
        .filter((option) => this.filterComparison(option))
        .map((option) => {
          const name = option.name ?? option.displayName;
          const optionName = `${name} (${option.extension})`;
          return { ...option, optionName };
        });
    }

    this.onChange(searchFilter);
  }
}
