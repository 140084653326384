import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DataSnapshot,
  Database,
  DatabaseReference,
  OnDisconnect,
  ThenableReference,
  Unsubscribe,
  onDisconnect,
  onValue,
  push,
  ref,
  remove,
  serverTimestamp,
  set
} from '@angular/fire/database';
import { AuthService, LoadingSpinnerService } from '@core/services';
import { UserPresence, UserPresenceDevice } from '@shared/enums';
import { Presence } from '@shared/models';
import {
  getConnectionsFunctionsPath,
  getLSStatusConnectionKey,
  getLSTenantId,
  setLSStatusConnectionKey
} from '@shared/utils';
import 'firebase/auth';
import { ElectronService } from 'ngx-electron';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserPresenceService {
  private hasInitialized = false;
  private canCreateConnection = true;
  private newConnectionRef: ThenableReference = null;
  private connectionRef: DatabaseReference = null;
  private onDisconnectRef: OnDisconnect = null;
  private connectionUnsubscribe: Unsubscribe = null;
  private usersPresences = new Map<string, Presence>();

  constructor(
    private spinner: LoadingSpinnerService,
    private database: Database,
    private authService: AuthService,
    private http: HttpClient,
    private electronService: ElectronService
  ) {
    // if (this.electronService.isElectronApp) {
    //   this.electronService.ipcRenderer.on('updateOnIdle', () => {
    //     this.setPresence(UserPresence.BERIGHTBACK);
    //   });

    //   this.electronService.ipcRenderer.on('updateOnConnect', () => {
    //     this.setPresence(UserPresence.AVAILABLE);
    //   });
    // }
  }

  /**
   * Initialize user presence service
   */
  public initialize(): void {
    // If has already initilized, return
    if (this.hasInitialized) return;

    // Get tenant id
    const tenantId = this.authService.user.companyId ?? getLSTenantId();

    // Subscribe to users presence
    this.subscribePresence(tenantId)
  }

  /**
   * Subscribe to users presence
   * @param {string} companyId Company id
   */
  private subscribePresence(companyId: string): void {
    // Database users presence ref
    const usersPresenceRef = ref(this.database, `companies/${companyId}/status`);

    // Get users presence once
    onValue(usersPresenceRef, (snap) => {
      // Init users presence map
      snap.forEach((child) => this.addPresence(child));

      // Subscribe to connection status
      this.subscribeConnection(companyId);
    });
  }

  /**
   * Add user presence to map
   * @param {DataSnapshot} snap
   */
  private addPresence(snap: DataSnapshot): void {
    // Check if has changed
    if (this.usersPresences.get(snap.key)?.status === snap.val().status)
      return;

    // Add user presence to map
    this.usersPresences.set(snap.key, snap.val());
  }

  /**
   * Subscribe to connection status
   * @param {string} tenantId Tenant id | Company id
   */
  private subscribeConnection(tenantId: string): void {
    // Return if has already initialized
    if (this.hasInitialized) return;

    // Log
    console.log('Listening for connection status...');

    // Get connection key from local storage
    const connectionKey = getLSStatusConnectionKey();

    // Set hasInitialized as true to prevent multiple subscriptions
    this.hasInitialized = true;

    // User id
    const uid = this.authService.user.id;

    // Database connections ref
    this.connectionRef = connectionKey
      ? ref(this.database, `companies/${tenantId}/users/${uid}/connections/${connectionKey}`)
      : ref(this.database, `companies/${tenantId}/users/${uid}/connections`);

    if (connectionKey) {
      // Listen for changes in connection status
      this.connectionUnsubscribe = onValue(this.connectionRef, (snap) => {
        if (snap.val() === null && this.canCreateConnection) {
          // We're disconnected! Do anything here that should happen only if online (or on reconnect)
          // Add this device to my connections list
          const currentPresence = this.getPresence();

          // Set the current user's presence status for this device
          if (currentPresence && currentPresence.force)
            this.setPresence(currentPresence.status as UserPresence, currentPresence.force);
          else
            this.setPresence(UserPresence.AVAILABLE);
        }
      });
    }
    else {
      // Database connected ref
      const connectedRef = ref(this.database, '.info/connected');

      // Listen for changes in connection status
      this.connectionUnsubscribe = onValue(connectedRef, (snap) => {
        if (snap.val() === true && this.canCreateConnection) {
          // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
          this.newConnectionRef = push(this.connectionRef);

          // Save connection key to local storage
          setLSStatusConnectionKey(this.newConnectionRef.key);

          // When I disconnect, remove this device
          this.onDisconnectRef = onDisconnect(this.newConnectionRef);
          this.onDisconnectRef.remove();

          // Add this device to my connections list
          const currentPresence = this.getPresence();

          // Set the current user's presence status for this device
          if (currentPresence && currentPresence.force)
            this.setPresence(currentPresence.status as UserPresence, currentPresence.force);
          else
            this.setPresence(UserPresence.AVAILABLE);
        }
      });
    }
  }

  /**
   * Get user presence status
   * @param {string} uid User id
   * @returns {Presence} User presence status
   */
  public getPresence(uid: string = this.authService.userId): Presence {
    return this.usersPresences.get(uid) ?? { status: UserPresence.OFFLINE, force: false };
  }

  /**
   * Set all connections status
   * @param {UserPresence} status User presence status
   * @param {boolean} force Force status regardless of priority
   */
  public setAllConnections(status: UserPresence, force: boolean = false) {
    // Get tenant id
    const companyId = this.authService.user.companyId;

    // Get user id
    const userId = this.authService.userId;

    // Get path
    const url = getConnectionsFunctionsPath();

    // Prepare body
    const body = {
      status,
      force,
      timestamp: serverTimestamp(),
      companyId,
      userId
    };

    // Show spinner
    this.spinner.show();

    // Make request
    lastValueFrom(this.http.put<void>(url, body))
      .finally(() => this.spinner.hide()) // Hide spinner
  }

  /**
   * Set user presence status
   *
   * @param status New status
   * @param force Force status regardless of priority
   * @returns
   */
  async setPresence(status: UserPresence, force: boolean = false): Promise<void> {
    const currentPresence = this.getPresence();
    const currentStatus = currentPresence?.status;
    const currentStatusIsForced = currentPresence?.force;

    // Only set presence if new status is forced or current status is available
    if (currentStatus !== UserPresence.AVAILABLE && currentStatusIsForced && !force) return;

    // Set presence
    set(this.newConnectionRef ?? this.connectionRef, {
      status,
      force,
      timestamp: serverTimestamp(),
      device: this.getPresenceDevice(),
    });
  }

  /**
   * Get user presence device
   * @returns {UserPresenceDevice} User presence device
   */
  public getPresenceDevice(): UserPresenceDevice {
    return this.electronService.isElectronApp ? UserPresenceDevice.DESKTOP : UserPresenceDevice.WEB;
  }

  /**
   * Delete status
   */
  public deletePresence(): Promise<void> {
    // Get user id
    const id = this.authService.user.id;

    // Get tenant id
    const companyId = this.authService.user.companyId ?? localStorage.getItem('mobi_phone:tenantId');

    // Database connections ref
    const myConnectionsRef = ref(this.database, `companies/${companyId}/users/${id}/connections`);

    // Remove user info from Database
    return remove(myConnectionsRef);
  }

  /**
   * Check if user is in presentation
   */
  public isUserInPresentation(id: string = this.authService.userId): boolean {
    return this.getPresence(id).status == UserPresence.IN_PRESENTATION;
  }

  /**
   * Check if user is not disturb
   */
  public isUserNotDisturb(id: string = this.authService.userId): boolean {
    return this.getPresence(id).status == UserPresence.DONOTDISTURB;
  }

  /**
   * Remove current connection
   */
  async removeConnection(): Promise<void> {
    try {
      // Unsubscribe from .info/connected events
      this.connectionUnsubscribe();

      // Set hasInitialized as false to initiate .info/connected event again
      this.hasInitialized = false;

      // Cancel onDisconnect trigger
      this.onDisconnectRef?.cancel();

      // Remove current connection
      await remove(this.newConnectionRef ?? this.connectionRef);
    } catch (error) {
      console.log(error.message);
    }
  }

  /**
   * Remove connection by desktop
   */
  public async removeConnectionByDesktop(): Promise<void> {
    // Set can create connection as false
    this.canCreateConnection = false;

    // Remove connection
    await this.removeConnection();
  }
}
