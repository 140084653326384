import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AuthService,
  ClientService,
  ClusterService,
  HistoryService,
  JitsiService,
  JsSIPService,
  NotifierService,
  PlatformService,
  TenantService,
  ThemeService,
  UserPresenceService
} from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { SettingsDialogComponent } from '@shared/components';
import { UserPresence } from '@shared/enums';
import { JitsiStatus, PREFIX_STORAGE, User } from '@shared/models';
import { getUserNameWithSector } from '@shared/utils';
import { Subject, first, lastValueFrom, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Output() hasRegistrationError = new EventEmitter<boolean>(false);

  registrationError = false;
  enableForceRegister = false;
  hasNotification = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public auth: AuthService,
    public themeService: ThemeService,
    private router: Router,
    private jssip: JsSIPService,
    private jitsi: JitsiService,
    private matDialog: MatDialog,
    private userPresence: UserPresenceService,
    public platform: PlatformService,
    public translate: TranslateService,
    private historyService: HistoryService,
    private cdr: ChangeDetectorRef,
    private clientService: ClientService,
    private tenantService: TenantService,
    private clusterService: ClusterService,
    private notifier: NotifierService,
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {

    this.clientService
      .makeLogoutByAPI
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value) this.onLogout();
      });

    this.jssip
      .registerEvents
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        switch (data) {
          case 'unregistered':
          case 'error_connect':
          case 'registration_failed':
            const isSettingsEmpty: boolean =
              Object.keys(this.jssip.uaSettings).length === 0;
            this.registrationError = true;
            this.hasRegistrationError.emit(true);
            if (isSettingsEmpty) {
              setTimeout(() => {
                this.enableForceRegister = true;
              }, 4000);
            } else {
              this.enableForceRegister = true;
            }
            break;
          default:
            this.registrationError = false;
            this.hasRegistrationError.emit(false);
            break;
        }
      });

    this.auth
      .user$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (!user) {
          this.onLogout();
        }
      });
  }

  /**
   * Get user display name
   * @returns {string} User display name
   */
  public getDisplayName(): string {
    return getUserNameWithSector(this.auth.user);
  }

  async forceRegister(): Promise<void> {
    // Fetch company data
    const companyId = localStorage.getItem(`${PREFIX_STORAGE}:tenantId`);
    const company = await lastValueFrom(this.tenantService.getById(companyId).pipe(first()));

    // verify if cluster has changed
    const currentCluster = this.clusterService.cluster;
    const settings = this.jssip.uaSettings;

    if (company.clusterId !== currentCluster?.id) {
      // Update cluster data
      await this.clusterService.setClusterId(company.clusterId);

      // Update jssip register data
      const port = this.clusterService.cluster.port;
      const domain = this.clusterService.cluster.domain;
      const domainWithPort = `${domain}:${port}`;

      settings.webSocketUrl = `wss://${domainWithPort}/ws`;
      settings.uri = settings.uri.split('@')[0] + '@' + domain;
    }

    const isSettingsEmpty: boolean = Object.keys(settings).length === 0;
    if (!isSettingsEmpty) {
      this.enableForceRegister = false;
      this.registrationError = false;
      this.hasRegistrationError.emit(false);
      this.jssip.forceRegister(settings);
    }
  }

  /**
   * Get user status
   * @returns {string} User status
   */
  public getUserStatus(): string {
    return this.userPresence.getPresence(this.auth?.user?.id)?.status;
  }

  setNotifications(hasNotifications: boolean) {
    this.hasNotification = hasNotifications;
    this.cdr.detectChanges();
  }

  onOpenSettingsDialog(): void {
    // Verify if user is in a conference
    switch (this.jitsi.status.value) {
      case JitsiStatus.start:
      case JitsiStatus.progress:
        this.notifier.showNotification({
          type: 'ongoingConference',
          message: 'settingsUnavailableByConference',
          actionText: '',
          panelClass: '',
        });
        break;

      // Open settings
      default:
        this.matDialog.open(SettingsDialogComponent, {
          width: '750px',
          height: '450px',
          disableClose: true,
          closeOnNavigation: true,
          panelClass: 'settings-dialog',
        });
        break;
    }
  }

  /**
   * Verify if the user has PABX integration
   * @param {User} user User data
   * @returns True if the user has PABX integration
   */
  public hasPABXIntegration(user: User): boolean {
    return !!user.pbxId;
  }

  public changePresence(event: string) {
    this.userPresence.setAllConnections(event as UserPresence, true);
  }

  /**
   * Make logout
   */
  public async onLogout(): Promise<void> {
    // Clear history
    this.historyService.clear();

    // Unregister jssip
    this.jssip.unregister();

    // Make logout on matrix client
    if (this.tenantService.canShowChat())
      await this.clientService.logout();

    // Remove user presence
    await this.userPresence.removeConnection();

    // Clear service worker cache
    await this.clearServiceWorkerCache();

    // Clear indexedDB
    await this.clearIndexedDB();

    // Logout
    this.auth.signOut().then(() => {
      // Navigate to login
      this.router.navigate(['/login']);

      // Clear local storage
      window.localStorage.clear();

      // Clear session storage
      window.sessionStorage.clear();

      // Reload page
      window.location.reload();
    });
  }

  /**
   * Clear indexedDB
   */
  private async clearIndexedDB(): Promise<void> {
    try {
      // Get all databases
      const databases = await window.indexedDB.databases();

      // Delete all databases
      await Promise.allSettled(databases.map((database) => window.indexedDB.deleteDatabase(database.name)));

    } catch (error) {
      console.error('Error on clear indexedDB', error);
    }
  }

  /**
   * Clear service worker cache
   */
  private async clearServiceWorkerCache(): Promise<void> {
    try {
      // Get all cache names
      const cacheNames = await caches.keys();

      // Filter cache names - only cache with our prefix
      const filteredCacheNames = cacheNames.filter((name) => name.startsWith('ngsw:'));

      // Delete all cache
      await Promise.allSettled(filteredCacheNames.map((cacheName) => caches.delete(cacheName)));

      // Cancel service workers
      const registration = await navigator.serviceWorker.getRegistration();

      // Unregister service worker
      await registration?.unregister();
    } catch (error) {
      console.error('Error on clear cache', error);
    }
  }
}
