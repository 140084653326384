import { User } from "./user.model";

export interface Cdr {
  id: number;
  uuid: string;
  uuidsrc: string;
  uuiddst: string;
  clusterId: string;
  tenantid: string;
  src: string;
  dst: string;
  start: any;
  answer: any;
  end: any;
  linkedid: string;
  duration: string;
  dcontext: string;
  billsec: string;
  recording: string[];
  size: string;
  channelstats: any;
  type: string;
  disposition: string;
  protocol: string;
  userfield: string;
  user?: string | User;
  xProtocol?: string;
  uniqueid?:string;
}

export interface CdrFilterParams {
  page?: number;
  limit?: number;
  tenantId?: string | string[];
  initialDate?: string;
  finalDate?: string;
  extension?: string;
  protocol?: string;
  type?: string;
  status?: string | string[];
}

export interface CallsSummary {
  count?: CallsCountSummary;
  sum?: CallsSumSummary;
  context: any;
}

export interface CallsCountSummary {
  received?: number,
  outgoing?: number,
  missed?: number
}

export interface CallsSumSummary {
  storage?: string;
  totalDuration?: string;
}