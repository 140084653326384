import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  ContactService,
  SocketService,
  TenantService,
  UtilsService
} from '@core/services';
import { environment } from '@environments/environment';
import { Disposition, DispositionPriority, SOCKET_TOPIC } from '@shared/enums';
import { Cdr } from '@shared/models';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private maxCdrListSize = 50;
  private dataSubject = new ReplaySubject<Cdr[]>(1);
  data$: Observable<Cdr[]> = this.dataSubject.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private contactSevice: ContactService,
    private tenant: TenantService,
    private socket: SocketService,
    private utilsService: UtilsService
  ) {
    this.subscribeToCdrWebsocketEvent();

    const cdrData = this.getSessionStorageCdrData();

    if (cdrData) {
      this.emitCdrDataToObservables(cdrData);
      return;
    }

    this.fetch();
  }

  async fetch(): Promise<void> {
    const idToken = await lastValueFrom(
      this.authService.idToken().pipe(take(1))
    );

    const headerOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + idToken,
      }),
    };

    const response = await lastValueFrom(
      this.http.get<Cdr[]>(
        `${environment.baseURL}/cdr?page=1&limit=${this.maxCdrListSize}`,
        headerOptions
      ).pipe(take(1))
    );
    this.setTickets(response);
  }

  /**
   * Set current Time Zone to tickets start date
   * @param tickets List with tickets to format
   * @returns Ticket list with current Time Zone start date
   */
  private generateTicketsWithTimezone(tickets: Cdr[]): Cdr[] {
    const resultArray = new Array();

    tickets.forEach(ticket => {
      resultArray.push({
        ...ticket,
        start: this.utilsService.getDateInTimeZone(ticket.start)
      });
    });

    return resultArray;
  }
  /**
   * Set local tickets with the new data
   * @param {Cdr[]} tickets All tickets that the user own
   */
  private setTickets(tickets: Cdr[]): void {
    this.emitCdrDataToObservables(tickets);
    this.setSessionStorageCdrData(tickets);
  }

  /**
   * Group tickets by protocol property
   * @param tickets A List of tickets data
   * @returns Object where key equal protocol and value equal to list of tickets
   */
  private groupTicketsByDst(tickets: Cdr[]): object {
    return tickets.reduce((r, a) => {
      r[a.dst] = [...r[a.dst] || [], a];
      return r;
    }, {});
  }

  /**
   * Reduce tickets by disposition priority
   * @param keys Protocol keys
   * @param groups Object with protocol keys and tickets values
   * @returns List of tickets filtered by disposition priority
   */
  private reduceByPriority(keys: string[], groups: object): Cdr[] {
    const filteredTickets: Cdr[] = [];

    for (const key of keys) {
      const result = groups[key].reduce((c, n) => {
        return DispositionPriority[c.disposition] < DispositionPriority[n.disposition] ? c : n;
      });
      filteredTickets.push(result);
    }

    return filteredTickets;
  }

  /**
   * Subscribes to cdr websocket topic to receive new cdrs data.
   */
  subscribeToCdrWebsocketEvent(): void {
    this.socket.listenToTopic(SOCKET_TOPIC.cdr, (tickets: Cdr[]) => {
      const groups = this.groupTicketsByDst(tickets);
      const keys = Object.keys(groups);
      tickets = this.reduceByPriority(keys, groups);

      tickets.forEach(cdr => {
        if (this.isOwner(cdr)) {
          const cdrData = this.getSessionStorageCdrData();
          // Cdrs empty
          if (!cdrData) {
            this.setTickets(tickets);
            return;
          }
          // Remove last cdr data to keep always 50 cdr
          if (cdrData.length === this.maxCdrListSize) { cdrData.pop(); }
          // Add new cdr to first position
          cdrData.unshift(cdr);
          this.setTickets(cdrData);
        }
      });
    });
  }

  /**
   * Get cdr data from session storage.
   *
   * @returns Session storaged cdr data.
   */
  getSessionStorageCdrData(): Cdr[] {
    const sessionStorageCdrData = sessionStorage.getItem('cdrs');
    if (!sessionStorageCdrData) { return null; }
    return JSON.parse(sessionStorageCdrData);
  }

  /**
   * Stores cdr data to session storage.
   *
   * @param cdr The cdr list to store.
   */
  setSessionStorageCdrData(cdr: Cdr[]): void {
    sessionStorage.setItem('cdrs', JSON.stringify(cdr));
  }

  /**
   * Sends cdr data to observables.
   *
   * @param cdr The cdr list do send.
   */
  private emitCdrDataToObservables(cdr: Cdr[]): void {
    this.dataSubject.next(this.normalizeCDR(this.generateTicketsWithTimezone(cdr)));
  }

  /**
   * Check if current user is owner of the new incoming cdr data.
   *
   * @param cdr The new cdr.
   * @returns Indicates if current user is cdr owner.
   */
  private isOwner(cdr: Cdr): boolean {
    return this.tenant.tenant.id === cdr.tenantid &&
      (this.authService.userId === cdr.uuidsrc || this.authService.userId === cdr.uuiddst);
  }

  /**
   * Check if current user received the call.
   * @param {Cdr} cdr The cdr.
   * @returns {boolean} Indicates if is a received call
   */
  public isAReceivedCall(cdr: Cdr): boolean {
    return this.tenant.tenant.id === cdr.tenantid && this.authService.userId === cdr.uuiddst;
  }

  normalizeCDR(cdrs: Cdr[]): Cdr[] {
    return cdrs.map((cdr) => {
      if (this.authService.user.id === cdr.uuidsrc) {
        if (cdr.disposition === Disposition.ANSWERED) {
          cdr.type = 'outgoing';
        } else {
          cdr.type = 'outgoing_lost';
        }
      } else if (this.authService.user.id === cdr.uuiddst) {
        if (cdr.disposition === Disposition.ANSWERED) {
          cdr.type = 'incoming';
        } else {
          cdr.type = 'incoming_lost';
        }
      }

      // External call, do not have firestore contact
      if (cdr.dcontext === 'outbound') {
        return cdr;
      }

      // Internal without Mobi Phone, do not have firestore contact
      if (!cdr.uuiddst || !cdr.uuidsrc) {
        cdr.dcontext = 'outbound';
        return cdr;
      }


      return {
        user: cdr.uuidsrc === this.authService.userId ? cdr.uuiddst : cdr.uuidsrc,
        ...cdr,
      };
    });
  }

  /**
   * Remove all cdr data from session storage
   */
  clear(): void {
    this.dataSubject.next([]);
    sessionStorage.removeItem('cdrs');
  }
}
