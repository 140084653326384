<button *ngIf="showFullButton" class="full-button" (mouseover)="mouseOver = true" (mouseleave)="mouseOver = false"
  (click)="handleCall($event)">
  <mat-icon [svgIcon]="mouseOver ? 'call_white' : 'call_hover'"></mat-icon>
  <span class="button-text"> {{'call' | translate }} </span>
</button>

<button *ngIf="!showFullButton" mat-icon-button (mouseover)="mouseOver = true" (mouseleave)="mouseOver = false"
  (click)="handleCall($event)">
  <mat-icon class="call-icon" [svgIcon]="getSvgIcon()"></mat-icon>
</button>
