import {
  ACCEPTED_CARRIER_CODE,
  ACCEPTED_CELL_PHONE_INITIALS,
  ACCEPTED_LANDLINE_INITIALS,
  ACCEPTED_TELEPHONE_SERVICES,
  CARRIER_CODE_LENGTH,
  CELL_PHONE_LENGTH,
  DDD_REGEX,
  DDI_REGEX,
  LANDLINE_LENGTH,
  TELEPHONE_SERVICES
} from "@shared/constants";
import { removeEmptyValues } from "./remove-empty-field";

type Data = {
  value: string;
  formatValue: string;
  startPosition: number;
  endPosition: number;
  stringLength: number;
}

type PhoneContainer = {
  carrierCode?: Data
  ddi?: Data
  ddd?: Data
  cellPhone?: Data
  landline?: Data
  telephoneServices?: Data
}

/**
 * Create the obj with the necessary data
 * @param {string} value Phone number
 * @param {number} startPosition
 * @param {number} endPosition
 * @param {string} formatValue
 * @returns {Data} Obj that contains the necessary information about the number
 */
const formNumberInfo = (
  value: string,
  startPosition: number,
  endPosition: number,
  formatValue: string = ''
): Data => {
  return {
    value,
    formatValue,
    startPosition,
    endPosition,
    stringLength: endPosition - startPosition,
  }
}

/**
 * Get the cellphone data
 * @param {string} number Dialed number
 * @returns {Data} Obj that contains the info about the cell phone data in the dialed number
 */
const getPhoneNumber = (number: string): Data => {
  const digitInitialsAccepted = ACCEPTED_CELL_PHONE_INITIALS; // It is known that cell phone numbers initiate with 6, 7, 8 or 9
  const cellPhoneLength = CELL_PHONE_LENGTH; // It is known that cell phone numbers have 9 digits.

  // Get the position of a cell phone in the dialed number
  const startPosition = number.length - cellPhoneLength;
  const endPosition = number.length;

  // Get the phone number
  const value = number.substring(startPosition, endPosition);

  // Format value
  const formatValue = `${value.substring(0, 5)}-${value.substring(5, value.length)}`

  // Return obj if phone number is a valid phone number
  return digitInitialsAccepted.includes(value.substring(0, 2)) && value.length === cellPhoneLength
    ? formNumberInfo(
      value,
      startPosition,
      endPosition,
      formatValue
    ) : null;
}

/**
 * Get the landline data
 * @param {string} number Dialed number
 * @returns {Data} Obj that contains the info about the landline data in the dialed number
 */
const getLandlineNumber = (number: string): Data => {
  const digitInitialsAccepted = ACCEPTED_LANDLINE_INITIALS; // It is known that landline numbers initiate with 2, 3, 4 or 5
  const landlineLength = LANDLINE_LENGTH;// It is known that landline numbers have 8 digits.

  // Get the position of a landline in the dialed number
  const startPosition = number.length - landlineLength;
  const endPosition = number.length;

  // Get the landline
  const value = number.substring(startPosition, endPosition);

  // Format value
  const formatValue = `${value.substring(0, 4)}-${value.substring(4, value.length)}`

  // Return obj if landline is a valid landline
  return digitInitialsAccepted.includes(value.charAt(0)) && value.length === landlineLength
    ? formNumberInfo(
      value,
      startPosition,
      endPosition,
      formatValue
    ) : null;
}

/**
 * Get the DDD data
 * @param {string} number Dialed number
 * @returns {Data} Obj that contains the info about the DDD data in the dialed number
 */
const getDDD = (number: string): Data => {
  // Regex to validate DDD
  const pattern = DDD_REGEX;

  // Verify if is a phone number or a landline number
  const phoneLength = getPhoneNumber(number,) ? CELL_PHONE_LENGTH : LANDLINE_LENGTH;

  // Get the position of a DDD in the dialed number
  let startPosition = number.length - phoneLength - 3;
  let endPosition = number.length - phoneLength;

  // Get the DDD number
  let value = number.substring(startPosition, endPosition);

  // Format value
  let formatValue = `(${value.replace('0', '')}) `

  // Verify if DDD started with 0
  if (value.charAt(0) == '0') {

    // Return if string is a valid DDD
    return pattern.test(value.replace('0', ''))
      ? formNumberInfo(value, startPosition, endPosition, formatValue)
      : null;
  }

  // It's possible that DDD exist without 0, so the process will be restarted without 0
  // Get the position of a DDD in the dialed number
  startPosition = number.length - phoneLength - 2;
  endPosition = number.length - phoneLength;

  // Get the DDD number
  value = number.substring(startPosition, endPosition);

  // Format value
  formatValue = `(${value}) `

  // Return if string is a valid DDD
  return pattern.test(value)
    ? formNumberInfo(`0${value}`, startPosition, endPosition, `(${value}) `)
    : null;
}

/**
 * Get the DDI data
 * @param {string} number Dialed number
 * @returns {Data} Obj that contains the info about the DDI data in the dialed number
 */
const getDDI = (number: string): Data => {
  // Regex to validate DDI
  const pattern = DDI_REGEX;

  // Get DDD Info
  const dddInfo = getDDD(number,)
  if (!dddInfo) return null

  // Get DDD Info
  const carrierCodeInfo = getCarrierCode(number,)
  if (carrierCodeInfo) return null

  // Verify if is a phone number or a landline number
  const phoneLength = getPhoneNumber(number,) ? CELL_PHONE_LENGTH : LANDLINE_LENGTH;

  // Get the position of a DDI in the dialed number
  const startPosition = number.length - dddInfo.stringLength - phoneLength - 2;
  const endPosition = number.length - dddInfo.stringLength - phoneLength;

  // Get the DDI number
  const value = number.substring(startPosition, endPosition);

  // Format value
  const formatValue = `+${value} `

  // Return if string is a valid DDI
  return pattern.test(value)
    ? formNumberInfo(value, startPosition, endPosition, formatValue)
    : null;

}

/**
 * Get the DDI data
 * @param {string} number Dialed number
 * @returns {Data} Obj that contains the info about the DDI data in the dialed number
 */
const getCarrierCode = (number: string): Data => {
  let carrierCodeAccepted = ACCEPTED_CARRIER_CODE; // Carrier code is a known value
  let carrierCodeLength = CARRIER_CODE_LENGTH; // Carrier code numbers are known to have 3 digits.

  // Get DDD Info
  const dddInfo = getDDD(number,)

  // Verify if is a phone number or a landline number
  const phoneLength = getPhoneNumber(number,) ? CELL_PHONE_LENGTH : LANDLINE_LENGTH;

  if (!dddInfo) return null

  // Get the position of a Carrier code in the dialed number
  const startPosition = number.length - dddInfo.stringLength - phoneLength - carrierCodeLength;
  const endPosition = number.length - dddInfo.stringLength - phoneLength;

  // Get the Carrier code number
  const carrierCode = number.substring(startPosition, endPosition);

  // Return if string is a valid DDI
  return carrierCodeAccepted.includes(carrierCode)
    ? formNumberInfo(carrierCode, startPosition, endPosition)
    : null;

}

/**
 * Get the Telephone Services data
 * @param {string} number Dialed number
 * @returns {Data} Obj that contains the info about the Telephone Services data in the dialed number
 */
const getTelephoneServices = (number: string,): Data => {
  const digitInitialsAccepted = ACCEPTED_TELEPHONE_SERVICES; // Accepted telephone services
  const phoneLength = TELEPHONE_SERVICES; // Telephone services length

  // Get the position of a telephone services in the dialed number
  const startPosition = number.length - phoneLength;
  const endPosition = number.length;

  // Get the telephone services number
  const value = number.substring(startPosition, endPosition);

  // Get initiate of the telephone services number
  const initiateTelephoneServices = value.substring(0, 4);

  // Format value
  const formatValue = `${value.substring(0, 4)} ${value.substring(4, 7)} ${value.substring(7, value.length)}`;

  // Return if string is a valid telephone services
  return digitInitialsAccepted.includes(initiateTelephoneServices)
    ? formNumberInfo(
      value,
      startPosition,
      endPosition,
      formatValue)
    : null;
}

/**
 * Form a valid external target value
 * @param {string} target Dialed number
 * @returns {string} The phone number
 */
export const normalizeTarget = (target: string, routes: string[] = []): string => {
  // Declare variables
  let formatNumber = '';
  let original = target;
  let targetWithoutSpecialCharacter = target;

  // Available character
  const character1 = target.indexOf('*');
  const character2 = target.indexOf('#');

  // Remove external route with special character
  if (character1 > -1 || character2 > -1) {
    target = target.includes('*')
      ? target.substring(character1 + 1, target.length)
      : target.substring(character2 + 1, target.length)

    targetWithoutSpecialCharacter = target
  }

  // Remove known external routes
  if (original == target) {
    for (let i = 0; i < routes.length; i++) {
      if (original.substring(0, routes[i].length).includes(routes[i])) {
        target = target.substring(routes[i].length)
      }
    }
  }

  // Is a internacional call
  if (target.substring(0, 2) === '00') {
    // Verify if exist carrier code after 00
    const existCarrierCode = !!ACCEPTED_CARRIER_CODE
      .map(value => value.replace('0', ''))
      .filter(value => target
        .substring(2, 5)
        .includes(value)
      ).length;

    // If a carrier code exists, this is an international call
    if (existCarrierCode) return target;
  }

  // Is a internal call
  if (target && target.length <= 5) return target;

  // Split data from the number
  const carrierCode = getCarrierCode(target);
  const ddi = getDDI(target);
  const ddd = getDDD(target);
  const cellPhone = getPhoneNumber(target);
  const landline = !cellPhone && getLandlineNumber(target);
  const telephoneServices = getTelephoneServices(target);

  // Form the phone obj without empty values
  const phone: PhoneContainer = removeEmptyValues<PhoneContainer>({
    carrierCode,
    ddi,
    ddd,
    cellPhone,
    landline,
    telephoneServices
  })

  // Verify if some value was formatted
  if (!(phone.cellPhone || phone.landline || phone.telephoneServices)) return targetWithoutSpecialCharacter

  // Form format number
  for (const iterator in phone)
    formatNumber += phone[iterator].formatValue

  // Return format target
  return formatNumber;
}

