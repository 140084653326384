export const CSV_PHONE_SEPARATOR = "/";

export const CSV_USERS_HEADER = [
  'Nome',
  'Email',
  'Tags',
  'Ramal',
  'SenhaRamal',
]

export const CSV_USERS_BODY = [
  {
    Nome: 'Exemplo',
    Email: 'exemplo@leucotron.com.br',
    Tags: 'Comercial|Suporte|Financeiro',
    Ramal: '500',
    SenhaRamal: 'FLUX2010201030',
  },
];

export const CSV_CONTACTS_HEADER = {
  en: [
    'Name',
    'Company',
    'Company Contact',
    'Numbers',
    'Descriptions'
  ],
  es: [
    'Nombre',
    'Empresa',
    'Contacto de la empresa',
    'Numeros',
    'Descripciones'
  ],
  pt: [
    'Nome',
    'Empresa',
    'Contato da empresa',
    'Numeros',
    'Descricoes'
  ],
  standard: [
    'name',
    'company',
    'isCompanyContact',
    'numbers',
    'descriptions'
  ]
}

export const CSV_CONTACTS_BODY = {
  en: [{
    Name: 'Example',
    Company: 'Contact company',
    'Company Contact': 'Yes',
    Numbers: [123, 456],
    Descriptions: ['Extension', 'Personal']
  }],
  es: [{
    Nombre: 'Ejemplo',
    Empresa: 'Empresa de lo contacto',
    'Contacto de la empresa': 'Sí',
    Numeros: [123, 456],
    Descripciones: ['Extensión', 'Personal']
  }],
  pt: [{
    Nome: 'Exemplo',
    Empresa: 'Empresa do contato',
    'Contato da empresa': 'Sim',
    Numeros: [123, 456],
    Descricoes: ['Ramal', 'Pessoal']
  }],
}

export const yesOrNoTranslations = {
  yes: [
    'sim',
    'si',
    'sí',
    'yes'
  ],
  no: [
    'não',
    'nao',
    'no'
  ]
}

export const externalContactsTranslations = {
  cellPhone: ['cellphone', 'telefono movil', 'teléfono movil', 'telefono móvil', 'teléfono móvil', 'celular'],
  work: ['work', 'trabalho', 'trabajo'],
  extension: ['extension', 'extensión', 'ramal'],
  personal: ['personal', 'pessoal'],
  other: ['other', 'otro', 'otros', 'outro', 'outros', 'others'],
  serviceNumber: [
    'service number',
    'número de servicio',
    'número de serviço',
    'numero de servicio',
    'numero de serviço',
    'serviço',
    'servicio',
    'service'
  ]
}

export function nameof<T>(obj: T, expression: (x: { [Property in keyof T]: () => string }) => () => string): string {
  const res: { [Property in keyof T]: () => string } = {} as { [Property in keyof T]: () => string };

  Object.keys(obj).map(k => res[k as keyof T] = () => k);

  return expression(res)();
}
