import { PABX_INTEGRATION_OPTIONS } from "./company-pabx-integration.enum";

export enum AVAILABLE_EQUIPMENT_TYPES {
  Skyvoice = 'skyvoice',
}

export enum AVAILABLE_EQUIPMENT_BEHAVIORS {
  IGNORE = 'ignore',
  PROVIS = 'provis',
  DELAY = 'delay',
  BLOCK = 'block',
  UPDATE = 'update',
}

export enum AVAILABLE_VPN_BEHAVIORS {
  START = 'start',
  STOP = 'stop',
  RELOAD = 'reload',
}

// Equipments types
export const EQUIPMENT_TYPES: PABX_INTEGRATION_OPTIONS[] = [
  PABX_INTEGRATION_OPTIONS.skyvoice,
];
