import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotifierComponent } from './notifier-dialog/notifier.component';

interface SnackBarOptions {
  type: string;
  message: string;
  actionText: string;
  duration?: number;
  verticalPosition?: MatSnackBarVerticalPosition;
  horizontalPosition?: MatSnackBarHorizontalPosition;
  panelClass?: string | string[];
}

@Injectable()
export class NotifierService {
  constructor(private snackbar: MatSnackBar, private translate: TranslateService) {}

  showNotification(options: SnackBarOptions): void {
    this.snackbar.openFromComponent(NotifierComponent, {
      data: {
        type: options.type ? this.translate.instant(options.type) : '',
        message: options.message ? this.translate.instant(options.message) : '',
        actionText: options.actionText ? this.translate.instant(options.actionText) : 'OK',
      },
      duration: options.duration ? options.duration : 5000,
      verticalPosition: options.verticalPosition
        ? options.verticalPosition
        : 'top',
      horizontalPosition: options.horizontalPosition
        ? options.horizontalPosition
        : 'right',
      panelClass: options.panelClass,
    });
  }
}
