import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group, User } from '@shared/models';

type Data = {
  tags: Group[];
  users: User[];
  isAdd: boolean;
};

@Component({
  selector: 'app-select-tags',
  templateUrl: './select-tags.component.html',
  styleUrls: ['./select-tags.component.scss']
})
export class SelectTagsComponent implements OnInit {
  public form = this.fb.group({
    tags: [[]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SelectTagsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void { }

  onSubmit(): void {
    this.dialogRef.close(this.form.get('tags').value);
  }

  /**
   * Add the new group on the form
   * @param {string} newGroup Name of the new group
   */
  public addGroupOnForm(newGroup: string): void {
    const currentValue = this.form.get('tags').value;
    currentValue.push(newGroup);
    this.form.get('tags').setValue(currentValue);
  }

  /**
   * Remove the group
   * @param {string} group Name of the group that will be removed
   */
  public removeGroupOnForm(group: string): void {
    const currentValue = this.form.get('tags').value;
    const newValue = currentValue.filter((value: string) => value !== group);
    this.form.get('tags').setValue(newValue);
  }
}
