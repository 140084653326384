import { DAYS_OF_WEEK } from "@shared/enums";

export const MEET_TOLERANCE_TIME = 30; // Minutes

export const TIME_PICKER_DARK_THEME = {
  container: {
    bodyBackgroundColor: '#424242',
    buttonColor: '#3d6db9'
  },
  dial: {
    dialBackgroundColor: '#a7a7a7',
    dialEditableActiveColor: '#A1C2FF',
    dialEditableBackgroundColor: '#464646',
    dialInactiveColor: '#616161',
    dialActiveColor: '#3d6db9'
  },
  clockFace: {
    clockFaceBackgroundColor: '#555',
    clockHandColor: '#A1C2FF',
    clockFaceTimeInactiveColor: '#fff'
  }
};

export const TIME_PICKER_LIGHT_THEME = {
  dial: {
    dialEditableActiveColor: '#A1C2FF',
    dialBackgroundColor: '#3d6db9',
  },
  clockFace: {
    clockHandColor: '#A1C2FF',
  },
  container: {
    buttonColor: '#3d6db9'
  },
};

export const CALENDAR_RESOLUTION = {
  sm: {
    rowSpan: 2,
    rowHeight: '45px',
    limitOfMeeting: 1,
  },
  md: {
    rowSpan: 3,
    rowHeight: '45px',
    limitOfMeeting: 3,
  },
  lg: {
    rowSpan: 4,
    rowHeight: '60px',
    limitOfMeeting: 6,
  },
}

export const DAYS_NAMES: DAYS_OF_WEEK[] = [
  DAYS_OF_WEEK.sunday,
  DAYS_OF_WEEK.monday,
  DAYS_OF_WEEK.tuesday,
  DAYS_OF_WEEK.wednesday,
  DAYS_OF_WEEK.thursday,
  DAYS_OF_WEEK.friday,
  DAYS_OF_WEEK.saturday,
];

export const DAYS_NAMES_SHORT: string[] = DAYS_NAMES.map(day => day.slice(0, 3));
