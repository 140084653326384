// Type definitions for non-npm package jitsi-meet 2.0
// Project: https://github.com/jitsi/jitsi-meet
// Definitions by: Tom Price <https://github.com/tomtom5152>
//                 Philipp Katz <https://github.com/qqilihq>
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped
// Minimum TypeScript Version: 3.1

export interface RoomsInMeeting {
  id?: string;
  isActive: boolean;
  roomId: string;
}

export interface GuestJWTPayloadData {
  roomName: string,
  subject: string,
}

export interface ExternalJWTPayloadData {
  userId: string;
  lang: string;
  roomId: string;
  photoUrl: string;
  groupName: string;
  displayName: string;
  isDarkTheme: boolean;
  isVideoMuted?: boolean;
  isAudioMuted?: boolean;
  startEventId?: string;
  isMobile: boolean;
  isGuestMeeting: boolean;
  hasSubject?: boolean;
  token?: string; // Mobile identifier token
  matrixJWT?: string; // If has jwt, has not matrixJWT
  matrixServerName?: string; // If has jwt, has not matrixServerName
  jwt?: string; // If has matrixJWT and matrixServerName, has not jwt
  tempConferenceId?: string; // TEMP - unique id for current conference
}

export interface MeetingContainerInfo {
  height?: string;
  width?: string;
  left?: string;
  top?: string;
}

export interface JitsiSocketData {
  roomId?: string;
  userId?: string;
  participantsNumber?: number;
  eventId?: string;
  displayName?: string;
  recordingStatus?: boolean;
  platform: string;
  jitsiRoomId?: string;
}

export interface JitsiSocketMeetingEventId {
  roomId: string;
  eventId: string;
}

export interface GravatarConfig {
  baseUrl?: string;
  disabled?: boolean;
}

export interface ExternalJWTPayload<T> {
  iat?: number,
  nbf?: number,
  exp?: number,
  data?: T,
}

export interface ConfigHosts {
  domain?: string | undefined;
  anonymousdomain?: string | undefined;
  authdomain?: string | undefined;
  call_control?: string | undefined;
  focus?: string | undefined;
  muc?: string | undefined;
}

export interface ConfigTesting {
  disableE2EE?: boolean | undefined;
  p2pTestMode?: boolean | undefined;
  testMode?: boolean | undefined;
  noAutoPlayVideo?: boolean | undefined;
  capScreenshareBitrate?: 0 | 1 | undefined;
  callStatsThreshold?: number | undefined;
}

export interface QualityConstraints {
  ideal?: number | undefined;
  min?: number | undefined;
  max?: number | undefined;
}

export interface DimensionConstraints {
  height?: QualityConstraints | undefined;
  width?: QualityConstraints | undefined;
}

export interface Constraints {
  video: DimensionConstraints;
}

export interface ConfigDropbox {
  appKey: string;
  redirectURI: string;
}

export interface VideoBitrates {
  low?: number | undefined;
  standard?: number | undefined;
  high?: number | undefined;
}

export interface ConfigVideoQuality {
  disabledCodec?: string | undefined;
  preferredCodec?: string | undefined;
  maxBitratesVideo?: VideoBitrates | undefined;
  minHeightForQualityLvl?: Record<number, string> | undefined;
  resizeDesktopForPresenter?: boolean | undefined;
}

export interface ConfigRecordingLimit {
  limit?: number | undefined;
  appName?: string | undefined;
  appURL?: string | undefined;
}

export interface STUNServer {
  urls: string;
}

export interface ConfigP2P {
  enabled?: boolean | undefined;
  stunServers?: STUNServer[] | undefined;
  iceTransportPolicy?: string | undefined;
  preferH264?: boolean | undefined;
  preferredCodec?: string | undefined;
  disableH264?: boolean | undefined;
  disabledCodec?: string | undefined;
  backToP2PDelay?: number | undefined;
}

export interface ConfigAnalytics {
  // True if the analytics should be disabled
  disabled: boolean,

  // The Google Analytics Tracking ID:
  googleAnalyticsTrackingId?: string,

  // Matomo configuration:
  matomoEndpoint?: string,
  matomoSiteID?: string,

  // The Amplitude APP Key:
  amplitudeAPPKey?: string,

  // Obfuscates room name sent to analytics (amplitude, rtcstats)
  // Default value is false.
  obfuscateRoomName?: boolean,

  // Configuration for the rtcstats server:
  // By enabling rtcstats server every time a conference is joined the rtcstats
  // module connects to the provided rtcstatsEndpoint and sends statistics regarding
  // PeerConnection states along with getStats metrics polled at the specified
  // interval.
  rtcstatsEnabled?: boolean,
  rtcstatsStoreLogs?: boolean,

  // In order to enable rtcstats one needs to provide a endpoint url.
  rtcstatsEndpoint?: string,

  // The interval at which rtcstats will poll getStats, defaults to 10000ms.
  // If the value is set to 0 getStats won't be polled and the rtcstats client
  // will only send data related to RTCPeerConnection events.
  rtcstatsPollInterval?: number,

  // This determines if rtcstats sends the SDP to the rtcstats server or replaces
  // all SDPs with an empty string instead.
  rtcstatsSendSdp?: boolean,

  // Array of script URLs to load as lib-jitsi-meet "analytics handlers".
  scriptURLs?: string[]
}

export interface ConfigDeploymentInfo {
  shard?: string | undefined;
  region?: string | undefined;
  userRegion?: string | undefined;
}

export interface ChromeExtensionInfo {
  id?: string | undefined;
  path?: string | undefined;
}

export interface ChromeExtensionBanner {
  url?: string | undefined;
  chromeExtensionsInfo?: ChromeExtensionInfo[] | undefined;
}

export interface ConfigLocalRecording {
  // Whether to disable local recording or not.
  disable: boolean,

  // Whether to notify all participants when a participant is recording locally.
  notifyAllParticipants: boolean,

  // Whether to disable the self recording feature (only local participant streams).
  disableSelfRecording: boolean,
}

export interface ConfigE2EPing {
  pingInterval?: number | undefined;
  analyticsInterval?: number | undefined;
}

export interface ConfigDeploymentURLs {
  userDocumentationURL?: string | undefined;
  downloadAppsUrl?: string | undefined;
}

export interface ConfigRemoteVideoMenu {
  disableKick?: boolean | undefined;
}

// Options related to the breakout rooms feature.
export interface BreakoutRoomsConfig {
  // Hides the add breakout room button. This replaces `hideAddRoomButton`.
  hideAddRoomButton?: boolean,
  // Hides the auto assign participants button.
  hideAutoAssignButton?: boolean,
  // Hides the join breakout room button.
  hideJoinRoomButton?: boolean,
}

// Options related to the breakout rooms feature.
export interface LogConfig {
  // Default log level for the app and lib-jitsi-meet.
  defaultLogLevel?: string,
  // Option to disable LogCollector (which stores the logs on CallStats).
  disableLogCollector?: boolean,
  // Individual loggers are customizable.
  loggers?: any,
}

// Configs for prejoin page.
export interface PreJoinConfig {
  // When 'true', it shows an intermediate page before joining, where the user can configure their devices.
  // This replaces `prejoinPageEnabled`.
  enabled?: boolean,
  // Hides the participant name editing field in the prejoin screen.
  // If requireDisplayName is also set as true, a name should still be provided through
  // either the jwt or the userInfo from the iframe api init object in order for this to have an effect.
  hideDisplayName?: boolean,
  // List of buttons to hide from the extra join options dropdown ['no-audio', 'by-phone'],.
  hideExtraJoinButtons?: any,
}

// Configs for Whiteboard
export interface WhiteboardConfig {
  // Whether the feature is enabled or not.
  enabled: boolean,
  // The server used to support whiteboard collaboration.
  // https://github.com/jitsi/excalidraw-backend
  collabServerBaseUrl: string,
}

// Configs for participants pane
export interface ParticipantsPaneConfig {
  // Hides the moderator settings tab.
  hideModeratorSettingsTab?: boolean,
  // Hides the more actions button.
  hideMoreActionsButton?: boolean,
  // Hides the mute all button.
  hideMuteAllButton?: boolean,
}

// Recording service config
export interface RecordingServiceConfig {
  // When integrations like dropbox are enabled only that will be shown,
  // by enabling fileRecordingsServiceEnabled, we show both the integrations
  // and the generic recording service (its configuration and storage type
  // depends on jibri configuration)
  enabled?: boolean,

  // Whether to show the possibility to share file recording with other people
  // (e.g. meeting participants), based on the actual implementation
  // on the backend.
  sharingEnabled?: boolean,

  // Hide the warning that says we only store the recording for 24 hours.
  hideStorageWarning?: boolean,
}

export interface SecurityUiConfig {
  // Hides the lobby button. Replaces `hideLobbyButton`.
  hideLobbyButton?: boolean,
  // Hides the possibility to set and enter a lobby password.
  disableLobbyPassword?: boolean,
}

// For more info - https://github.com/jitsi/jitsi-meet/blob/e8de9b4d66ea243273627c157eac97e4456b376e/config.js#L716
export interface Config {
  conferenceInfo?: ConferenceInfoConfig;
  securityUi?: SecurityUiConfig;
  hosts?: ConfigHosts | undefined;
  bosh?: string | undefined;
  websocket?: string | undefined;
  clientNode?: string | undefined;
  focusUserJid?: string | undefined;

  recordingService?: RecordingServiceConfig;
  autoKnockLobby?: boolean | undefined;
  enableLobbyChat?: boolean | undefined;

  disableSelfView?: boolean | undefined;
  disableSelfViewSettings?: boolean | undefined;

  disabledSounds?: DisabledSoundsOptions;

  testing?: ConfigTesting | undefined;
  webrtcIceUdpDisable?: boolean | undefined;

  webrtcIceTcpDisable?: boolean | undefined;

  disableAudioLevels?: boolean | undefined;
  audioLevelsInterval?: number | undefined;
  enableNoAudioDetection?: boolean | undefined;
  enableSaveLogs?: boolean | undefined;
  enableNoisyMicDetection?: boolean | undefined;
  startAudioOnly?: boolean | undefined;
  startAudioMuted?: number | undefined;
  startWithAudioMuted?: boolean | undefined;
  startSilent?: boolean | undefined;

  opusMaxAverageBitrate?: number | undefined;
  enableOpusRed?: boolean | undefined;
  resolution?: number | undefined;
  maxFullResolutionParticipants?: number | undefined;
  constraints?: Constraints | undefined;
  disableSimulcast?: boolean | undefined;
  enableLayerSuspension?: boolean | undefined;
  startVideoMuted?: number | undefined;
  startWithVideoMuted?: boolean | undefined;
  preferH264?: boolean | undefined;
  disableH264?: boolean | undefined;
  desktopSharingFrameRate?: QualityConstraints | undefined;
  startScreenSharing?: boolean | undefined;

  fileRecordingsEnabled?: boolean | undefined;
  dropbox?: ConfigDropbox | undefined;
  fileRecordingsServiceEnabled?: boolean | undefined;
  fileRecordingsServiceSharingEnabled?: boolean | undefined;
  liveStreamingEnabled?: boolean | undefined;
  transcribingEnabled?: boolean | undefined;
  autoCaptionOnRecord?: boolean | undefined;

  channelLastN?: number | undefined;
  lastNLimits?: Record<number, number> | undefined;

  breakoutRooms?: BreakoutRoomsConfig;

  prejoinConfig?: PreJoinConfig;

  localSubject?: string;

  logging?: LogConfig;

  gravatar?: GravatarConfig;
  disableInitialGUM?: boolean;
  disableBeforeUnloadHandlers?: boolean;
  disableModeratorIndicator?: boolean;
  callStatsConfigParams?: any;

  whiteboard?: WhiteboardConfig;
  hideEmailInSettings?: boolean;
  participantsPane?: ParticipantsPaneConfig;

  videoQuality?: ConfigVideoQuality | undefined;
  recordingLimit?: ConfigRecordingLimit | undefined;
  disableRtx?: boolean | undefined;
  enableTcc?: boolean | undefined;
  enableRemb?: boolean | undefined;
  enableIceRestart?: boolean | undefined;
  minParticipants?: number | undefined;
  useTurnUdp?: false | undefined;
  openBridgeChannel?: boolean | string | undefined;

  hideLobbyButton?: boolean | undefined;
  requireDisplayName?: boolean | undefined;
  enableWelcomePage?: boolean | undefined;
  enableClosePage?: boolean | undefined;
  disable1On1Mode?: boolean | undefined;
  defaultLanguage?: string | undefined;
  disableProfile?: boolean | undefined;
  enableUserRolesBasedOnToken?: boolean | undefined;
  enableFeaturesBasedOnToken?: boolean | undefined;
  lockRoomGuestEnabled?: boolean | undefined;
  roomPasswordNumberOfDigits?: false | number | undefined;
  noticeMessage?: string | undefined;
  enableCalendarIntegration?: boolean | undefined;
  prejoinPageEnabled?: boolean | undefined;
  enableInsecureRoomNameWarning?: boolean | undefined;
  enableAutomaticUrlCopy?: boolean | undefined;
  gravatarBaseURL?: string | undefined;

  gatherStats?: boolean | undefined;
  pcStatsInterval?: number | undefined;
  callStatsID?: string | undefined;
  callStatsSecret?: string | undefined;

  enableDisplayNameInStats?: boolean | undefined;
  enableEmailInStats?: boolean | undefined;

  disableThirdPartyRequests?: boolean | undefined;

  p2p?: ConfigP2P | undefined;

  analytics?: ConfigAnalytics | undefined;
  apiLogLevels?: string[] | undefined;

  deploymentInfo?: ConfigDeploymentInfo | undefined;
  disableRecordAudioNotification?: boolean | undefined;
  chromeExtensionBanner?: ChromeExtensionBanner | undefined;

  localRecording?: ConfigLocalRecording | undefined;
  e2eping?: ConfigE2EPing | undefined;
  _desktopSharingSourceDevice?: string | undefined;
  disableDeepLinking?: boolean | undefined;
  disableLocalVideoFlip?: boolean | undefined;

  disableInviteFunctions?: boolean | undefined;
  doNotStoreRoom?: boolean | undefined;
  deploymentUrls?: ConfigDeploymentURLs | undefined;
  remoteVideoMenu?: ConfigRemoteVideoMenu | undefined;
  disableRemoteMute?: boolean | undefined;
  brandingDataUrl?: string | undefined;
  moderatedRoomServiceUrl?: string | undefined;
  hideConferenceTimer?: boolean | undefined;
  subject?: string | undefined;
  hideConferenceSubject?: boolean | undefined;

  // undocumented from jitsi-meet, type assumed from name;
  _immediateReloadThreshold?: any;
  debug?: boolean | undefined;
  debugAudioLevels?: any;
  dialInConfCodeUrl?: string | undefined;
  dialInNumbersUrl?: string | undefined;
  dialOutAuthUrl?: string | undefined;
  dialOutCodesUrl?: string | undefined;
  disableRemoteControl?: boolean | undefined;
  displayJids?: boolean | undefined;
  etherpad_base?: string | undefined;
  externalConnectUrl?: string | null | undefined;
  firefox_fake_device?: any;
  googleApiApplicationClientID?: any;
  iAmRecorder?: boolean | undefined;
  iAmSipGateway?: boolean | undefined;
  microsoftApiApplicationClientID?: boolean | undefined;
  peopleSearchQueryTypes?: any;
  peopleSearchUrl?: string | undefined;
  tokenAuthUrl?: string | undefined;

  brandingRoomAlias?: any;
  useHostPageLocalStorage?: boolean;
  transcription?: TranscriptionConfig;

  // undocumented from lib-jitsi-meet, type assumed from name;
  _peerConnStatusOutOfLastNTimeout?: any;
  _peerConnStatusRtcMuteTimeout?: any;
  abTesting?: any;
  avgRtpStatsN?: any;
  callStatsConfIDNamespace?: any;
  callStatsCustomScriptUrl?: any;
  desktopSharingSources?: any;
  disableAEC?: boolean | undefined;
  disableAGC?: boolean | undefined;
  disableAP?: boolean | undefined;
  disableHPF?: boolean | undefined;
  disableNS?: boolean | undefined;
  enableLipSync?: boolean | undefined;
  enableTalkWhileMuted?: boolean | undefined;
  forceJVB121Ratio?: boolean | undefined;
  hiddenDomain?: any;
  ignoreStartMuted?: boolean | undefined;
  nick?: any;
  startBitrate?: any;
}

export interface ConferenceInfoConfig {
  alwaysVisible?: ('recording' | 'raised-hands-count')[];
  autoHide?: string[];
}

export interface InterfaceMobileDynamicLink {
  APN?: string | undefined;
  APP_CODE?: string | undefined;
  CUSTOM_DOMAIN?: any;
  IBI?: string | undefined;
  ISI?: string | undefined;
}

export interface TranscriptionConfig {
  enabled?: boolean;
  useAppLanguage?: boolean;
  preferredLanguage?: string;
  disableStartForAll?: boolean;
  autoCaptionOnRecord?: boolean;
}

export interface InterfaceConfig {
  APP_NAME?: string | undefined;
  AUDIO_LEVEL_PRIMARY_COLOR?: string | undefined;
  AUDIO_LEVEL_SECONDARY_COLOR?: string | undefined;
  AUTO_PIN_LATEST_SCREEN_SHARE?: string | undefined;
  BRAND_WATERMARK_LINK?: string | undefined;

  CLOSE_PAGE_GUEST_HINT?: boolean | undefined;
  CONNECTION_INDICATOR_AUTO_HIDE_ENABLED?: boolean | undefined;
  CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT?: number | undefined;
  CONNECTION_INDICATOR_DISABLED?: boolean | undefined;

  DEFAULT_BACKGROUND?: string | undefined;
  DEFAULT_LOCAL_DISPLAY_NAME?: string | undefined;
  DEFAULT_LOGO_URL?: string | undefined;
  DEFAULT_REMOTE_DISPLAY_NAME?: string | undefined;
  DEFAULT_WELCOME_PAGE_LOGO_URL?: string | undefined;

  DISABLE_DOMINANT_SPEAKER_INDICATOR?: boolean | undefined;
  DISABLE_FOCUS_INDICATOR?: boolean | undefined;
  DISABLE_JOIN_LEAVE_NOTIFICATIONS?: boolean | undefined;
  DISABLE_PRESENCE_STATUS?: boolean | undefined;
  DISABLE_RINGING?: boolean | undefined;
  DISABLE_TRANSCRIPTION_SUBTITLES?: boolean | undefined;
  DISABLE_VIDEO_BACKGROUND?: boolean | undefined;

  DISPLAY_WELCOME_FOOTER?: boolean | undefined;
  DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD?: boolean | undefined;
  DISPLAY_WELCOME_PAGE_CONTENT?: boolean | undefined;
  DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT?: boolean | undefined;
  ENABLE_DIAL_OUT?: boolean | undefined;
  ENABLE_FEEDBACK_ANIMATION?: boolean | undefined;
  FILM_STRIP_MAX_HEIGHT?: number | undefined;
  filmStripOnly?: boolean | undefined;
  GENERATE_ROOMNAMES_ON_WELCOME_PAGE?: boolean | undefined;
  HIDE_DEEP_LINKING_LOGO?: boolean | undefined;
  HIDE_INVITE_MORE_HEADER?: boolean | undefined;
  INITIAL_TOOLBAR_TIMEOUT?: number | undefined;

  JITSI_WATERMARK_LINK?: string | undefined;
  LANG_DETECTION?: boolean | undefined;
  LIVE_STREAMING_HELP_LINK?: string | undefined;
  LOCAL_THUMBNAIL_RATIO?: number | undefined;
  MAXIMUM_ZOOMING_COEFFICIENT?: number | undefined;
  MOBILE_APP_PROMO?: boolean | undefined;
  NATIVE_APP_NAME?: string | undefined;
  OPTIMAL_BROWSERS?: string[] | undefined;
  POLICY_LOGO?: any;
  PROVIDER_NAME?: string | undefined;
  RECENT_LIST_ENABLED?: boolean | undefined;
  REMOTE_THUMBNAIL_RATIO?: number | undefined;
  SETTINGS_SECTIONS?: Array<'devices' | 'language' | 'moderator' | 'profile' | 'calendar' | 'sounds' | 'more'> | undefined;
  SHOW_BRAND_WATERMARK?: boolean | undefined;
  SHOW_CHROME_EXTENSION_BANNER?: boolean | undefined;

  SHOW_DEEP_LINKING_IMAGE?: boolean | undefined;
  SHOW_JITSI_WATERMARK?: boolean | undefined;
  SHOW_POWERED_BY?: boolean | undefined;
  SHOW_PROMOTIONAL_CLOSE_PAGE?: boolean | undefined;
  SHOW_WATERMARK_FOR_GUESTS?: boolean | undefined;

  SUPPORT_URL?: string | undefined;

  TOOLBAR_ALWAYS_VISIBLE?: boolean | undefined;
  TOOLBAR_BUTTONS?: Array<'microphone' | 'camera' | 'closedcaptions' | 'desktop' | 'embedmeeting' | 'fullscreen' |
    'fodeviceselection' | 'hangup' | 'profile' | 'chat' | 'recording' | 'participants-pane' | 'whiteboard' |
    'livestreaming' | 'etherpad' | 'sharedvideo' | 'settings' | 'raisehand' | 'noisesuppression' |
    'videoquality' | 'filmstrip' | 'invite' | 'feedback' | 'stats' | 'shortcuts' | 'select-background' |
    'tileview' | 'videobackgroundblur' | 'download' | 'help' | 'mute-everyone' | 'security'> | undefined;

  TOOLBAR_TIMEOUT?: number | undefined;

  UNSUPPORTED_BROWSERS?: string[] | undefined;
  VERTICAL_FILMSTRIP?: boolean | undefined;
  VIDEO_LAYOUT_FIT?: string | undefined;
  VIDEO_QUALITY_LABEL_DISABLED?: boolean | undefined;
  HIDE_KICK_BUTTON_FOR_GUESTS?: boolean | undefined;
  TILE_VIEW_MAX_COLUMNS?: number | undefined;

  MOBILE_DOWNLOAD_LINK_ANDROID?: string | undefined;
  MOBILE_DOWNLOAD_LINK_F_DROID?: string | undefined;
  MOBILE_DOWNLOAD_LINK_IOS?: string | undefined;
  MOBILE_DYNAMIC_LINK?: InterfaceMobileDynamicLink | undefined;
  APP_SCHEME?: string | undefined;
  ANDROID_APP_PACKAGE?: string | undefined;

  ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT?: number | undefined;
  INDICATOR_FONT_SIZES?: any;
  PHONE_NUMBER_REGEX?: any;
}

export type Invitee = any;

export interface UserInfo {
  email?: string | undefined;
  displayName?: string | undefined;
}

export interface ExternalAPIOptions {
  roomName?: string | undefined;
  width?: string | number | undefined;
  height?: string | number | undefined;
  parentNode?: Element | undefined;
  configOverwrite?: Config | undefined;
  interfaceConfigOverwrite?: InterfaceConfig | undefined;
  noSSL?: boolean | undefined;
  jwt?: string | undefined;
  onload?: (() => any) | undefined;
  invitees?: Invitee[] | undefined;
  devices?: DeviceConfig;
  userInfo?: UserInfo | undefined;
  lang?: string;
}

export interface DeviceConfig {
  audioInput?: string, // Label
  audioOutput?: string, // Label
  videoInput?: string // Label
}

export interface Device {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}

export interface DeviceMap {
  audioInput?: Device[] | undefined;
  audioOutput?: Device[] | undefined;
  videoInput?: Device[] | undefined;
}

export interface RoomInfo {
  isMainRoom: boolean;
  id: string;
  jid: string;
  participants: ParticipantRoomInfo[];
}

export interface ParticipantRoomInfo {
  id: string;
  jid: string;
  role: string;
  displayName: string;
}

export interface RoomsInfos {
  rooms: RoomInfo[];
}

export type DisabledSoundsOptions =
  Array<
    | 'ASKED_TO_UNMUTE_SOUND'
    | 'E2EE_OFF_SOUND'
    | 'E2EE_ON_SOUND'
    | 'INCOMING_MSG_SOUND'
    | 'KNOCKING_PARTICIPANT_SOUND'
    | 'LIVE_STREAMING_OFF_SOUND'
    | 'LIVE_STREAMING_ON_SOUND'
    | 'NO_AUDIO_SIGNAL_SOUND'
    | 'NOISY_AUDIO_INPUT_SOUND'
    | 'OUTGOING_CALL_EXPIRED_SOUND'
    | 'OUTGOING_CALL_REJECTED_SOUND'
    | 'OUTGOING_CALL_RINGING_SOUND'
    | 'OUTGOING_CALL_START_SOUND'
    | 'PARTICIPANT_JOINED_SOUND'
    | 'PARTICIPANT_LEFT_SOUND'
    | 'RAISE_HAND_SOUND'
    | 'REACTION_SOUND'
    | 'RECORDING_OFF_SOUND'
    | 'RECORDING_ON_SOUND'
    | 'TALK_WHILE_MUTED_SOUND'>
  | undefined;

export type ExternalAPICommands =
  'displayName' | 'password' | 'toggleLobby' | 'sendTones' | 'subject' | 'toggleAudio' | 'toggleVideo' | 'endConference' |
  'toggleFilmStrip' | 'toggleChat' | 'toggleShareScreen' | 'toggleTileView' | 'hangup' | 'email' | 'avatarUrl' | 'grantModerator' |
  'sendEndpointTextMessage' | 'setLargeVideoParticipant' | 'setVideoQuality' | 'muteEveryone' | 'startRecording' |
  'stopRecording' | 'joinBreakoutRoom' | 'closeBreakoutRoom' | 'autoAssignToBreakoutRooms' | 'removeBreakoutRoom';

export type RecordingMode = 'file' | 'stream';

export interface RecordingOptions {
  mode: RecordingMode;
  dropboxToken?: string | undefined;
  shouldShare?: boolean | undefined;
  rtmpStreamKey?: string | undefined;
  rtmpBroadcastID?: string | undefined;
  youtubeStreamKey?: string | undefined;
  youtubeBroadcastID?: string | undefined;
}

/**
* Events that can be emitted by ExternalAPI
*/

export interface CameraErrorEvent {
  type: string;
  message: string;
}

export interface AvatarChangedEvent {
  id: string;
  avatarURL: string;
}

export interface AudioAvailabilityChangedEvent {
  available: boolean;
}

export interface AudioMuteStatusChangedEvent {
  muted: boolean;
}

export interface EndpointTextMessageReceivedEvent {
  senderInfo: {
    jid: string;
    id: string;
  };
  eventData: {
    name: string;
    text: string;
  };
}

export interface LargeVideoChangedEvent {
  id: string;
}

export type LogLevels = 'info' | 'error' | 'debug' | 'warn';

export interface LogEvent {
  logLevel: LogLevels;
  args: string;
}

export interface MicErrorEvent {
  type: string;
  message: string;
}

export type ScreenSharingSourceTypes = 'window' | 'screen' | 'proxy' | 'device';

export interface ScreenSharingStatusChangedEvent {
  on: boolean;
  details: {
    sourceType?: ScreenSharingSourceTypes | undefined;
  };
}

export interface DominantSpeakerChangedEvent {
  id: string;
}

export interface TileViewChangedEvent {
  enabled: boolean;
}

export interface IncomingMessageEvent {
  from: string, // The id of the user that sent the message
  nick: string, // the nickname of the user that sent the message
  privateMessage: boolean, // whether this is a private or group message
  message: string // the text of the message
}

export interface OutgoingMessageEvent {
  message: string;
}

export interface DisplayNameChangeEvent {
  id: string;
  displayName: string;
}

export interface DeviceListChangedEvent {
  devices: DeviceMap;
}

export interface EmailChangeEvent {
  id: string;
  email: string;
}

export interface RecordingChangeEvent {
  on: boolean // new recording status - boolean,
  mode: string // recording mode, `local`, `stream` or `file`,
  error: string | undefined // error type if recording fails, undefined otherwise
}

export interface RecordingLinkAvailableEvent {
  link: string, // the recording link
  ttl: number // the time to live of the recording link
}

export interface FeedbackSubmittedEvent {
  error?: string | undefined;
}

export interface FilmstripDisplayChangedEvent {
  visible: boolean;
}

export interface ParticipantJoinedEvent {
  id: string;
  displayName: string;
}

export interface ParticipantKickedOutEvent {
  kicked: {
    id: string;
    local: boolean;
  };
  kicker: {
    id: string;
  };
}

export interface ParticipantLeftEvent {
  id: string;
}

export interface ParticipantRoleChangedEvent {
  id: string;
  role: string;
}

export interface ParticipantsInfo {
  avatarURL: string;
  displayName: string;
  formattedDisplayName: string;
  participantId: string;
}

export interface VideoConferenceJoinedEvent {
  roomName: string;
  id: string;
  displayName: string;
  avatarURL: string;
}

export interface VideoConferenceLeftEvent {
  roomName: string;
}

export interface VideoAvailabilityChangedEvent {
  available: boolean;
}

export interface VideoMuteStatusChangedEvent {
  muted: boolean;
}

export interface VideoQualityChangedEvent {
  videoQuality: number; // height resolution of new quality
}

export interface SubjectChangeEvent {
  subject: string;
}

export enum JitsiStatus {
  standby = 'standby',
  start = 'start',
  progress = 'progress',
  stop = 'stop',
  error = 'error',
}

export interface ErrorOccurredEvent {
  details: any, // additional error details
  message: string, // the error message
  name: string, // the coded name of the error
  type: string, // error type/source, one of : 'CONFIG', 'CONNECTION', 'CONFERENCE'
  isFatal: boolean // whether this is a fatal error which triggered a reconnect overlay or not
}

export interface PeerConnectionFailureEvent {
  // Type of PC, Peer2Peer or JVB connection.
  isP2P: boolean,

  // Was this connection previously connected. If it was it could mean
  // that connectivity was disrupted, if not it most likely means that the app could not reach
  // the JVB server, or the other peer in case of P2P.
  wasConnected: boolean
}

// Export keys of ExternalAPIEventCallbacks as a type
export type ExternalAPIEventTypes = keyof ExternalAPIEventCallbacks;

export interface ExternalAPIEventCallbacks {
  cameraError: (e: CameraErrorEvent) => void;
  avatarChanged: (e: AvatarChangedEvent) => void;
  audioAvailabilityChanged: (e: AudioAvailabilityChangedEvent) => void;
  audioMuteStatusChanged: (e: AudioMuteStatusChangedEvent) => void;
  endpointTextMessageReceived: (e: EndpointTextMessageReceivedEvent) => void;
  largeVideoChanged: (e: LargeVideoChangedEvent) => void;
  log: (e: LogEvent) => void;
  micError: (e: MicErrorEvent) => void;
  screenSharingStatusChanged: (e: ScreenSharingStatusChangedEvent) => void;
  dominantSpeakerChanged: (e: DominantSpeakerChangedEvent) => void;
  tileViewChanged: (e: TileViewChangedEvent) => void;
  incomingMessage: (e: IncomingMessageEvent) => void;
  outgoingMessage: (e: OutgoingMessageEvent) => void;
  displayNameChange: (e: DisplayNameChangeEvent) => void;
  deviceListChanged: (e: DeviceListChangedEvent) => void;
  emailChange: (e: EmailChangeEvent) => void;
  recordingStatusChanged: (e: RecordingChangeEvent) => void;
  recordingLinkAvailable: (e: RecordingLinkAvailableEvent) => void;
  feedbackSubmitted: (e: FeedbackSubmittedEvent) => void;
  errorOccurred: (e: ErrorOccurredEvent) => void;
  peerConnectionFailure: (e: PeerConnectionFailureEvent) => void;
  raiseHandUpdated: (e: any) => void;
  filmstripDisplayChanged: (e: FilmstripDisplayChangedEvent) => void;
  faceLandmarkDetected: (e: any) => void;
  participantJoined: (e: ParticipantJoinedEvent) => void;
  participantKickedOut: (e: ParticipantKickedOutEvent) => void;
  participantLeft: (e: ParticipantLeftEvent) => void;
  participantRoleChanged: (e: ParticipantRoleChangedEvent) => void;
  passwordRequired: () => void;
  videoConferenceJoined: (e: VideoConferenceJoinedEvent) => void;
  videoConferenceLeft: (e: VideoConferenceLeftEvent) => void;
  videoAvailabilityChanged: (e: VideoAvailabilityChangedEvent) => void;
  videoMuteStatusChanged: (e: VideoMuteStatusChangedEvent) => void;
  videoQualityChanged: (e: VideoQualityChangedEvent) => void;
  readyToClose: () => void;
  knockingParticipant: (e: any) => void;
  subjectChange: (e: SubjectChangeEvent) => void;
  suspendDetected: () => void;
}
export interface TypedEventEmitter<Events, K extends keyof Events> {
  addListener(event: K, listener: Events[K]): this;

  on(event: K, listener: Events[K]): this;

  once(event: K, listener: Events[K]): this;

  removeListener(event: K, listener: Events[K]): this;

  removeAllListeners(event?: K): this;

  setMaxListeners(n: number): this;

  getMaxListeners(): number;

  listeners(event: K): Array<(e: Event) => void>;

  emit(event: K, ...args: any[]): boolean;

  listenerCount(type: K): number;

  // Added in Node 6...
  prependListener(event: K, listener: Events[K]): this;

  prependOnceListener(event: K, listener: Events[K]): this;

  eventNames(): K[];
}

/**
* @see https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
*/
export interface JitsiMeetExternalAPI extends TypedEventEmitter<ExternalAPIEventCallbacks, keyof ExternalAPIEventCallbacks> {
  // tslint gets unhappy about a constructor on an interface, but this is what is passed to it
  // constructor(domain: string, options?: ExternalAPIOptions): this;

  captureLargeVideoScreenshot(): Promise<string>;

  getAvailableDevices(): Promise<DeviceMap>;

  getRoomsInfo(): Promise<RoomsInfos>;

  getCurrentDevices(): Promise<DeviceMap>;

  getParticipantsInfo(): any;

  getVideoQuality(): any;

  isDeviceListAvailable(): Promise<boolean>;

  isMultipleAudioInputSupported(): Promise<boolean>;

  pinParticipant(participantId: string): void;

  resizeLargeVideo(width: number, height: number): void;

  setAudioInputDevice(deviceLabel: string, deviceId: string): void;

  setAudioOutputDevice(deviceLabel: string, deviceId: string): void;

  setLargeVideoParticipant(participantId: string): void;

  setVideoInputDevice(deviceLabel: string, deviceId: string): void;

  startRecording(options: RecordingOptions): void;

  stopRecording(mode: RecordingMode): void;

  executeCommand(command: ExternalAPICommands, ...props: any): void;

  executeCommands(commands: Array<Record<ExternalAPICommands, any[]>>): void;

  getNumberOfParticipants(): number;

  getAvatarURL(participantId: string): string;

  getDisplayName(participantId: string): string;

  getEmail(participantId: string): string;

  getIFrame(): HTMLIFrameElement;

  isAudioMuted(): Promise<boolean>;

  isVideoMuted(): Promise<boolean>;

  isAudioAvailable(): Promise<boolean>;

  isVideoAvailable(): Promise<boolean>;

  invite(invitees: any[]): Promise<null>;

  /**
   * Removes the embedded Jitsi Meet conference
   */
  dispose(): void;
}

export interface JitsiMeetExternalAPIConstructor {
  new(domain: string, options?: ExternalAPIOptions): JitsiMeetExternalAPI;
}
