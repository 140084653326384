import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HandleVersionService } from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PREFIX_STORAGE } from '@shared/models';

interface MobiPhoneNotification {
  id: string;
  title: string;
  text: string;
  read: boolean;
  version?: string;
}

@Component({
  selector: 'notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit {
  @Output() hasNotifications: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  notifications: MobiPhoneNotification[] = [];

  constructor(
    private handleVersion: HandleVersionService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const storageNotifications: MobiPhoneNotification[] = JSON.parse(
      localStorage.getItem('notifications') ?? '[]'
    );
    this.notifications.push(...storageNotifications);
    this.getNotifications();
  }

  private async getNotifications() {
    const mobiPhoneVersion: string = await this.handleVersion.getVersion();
    if (mobiPhoneVersion === null) {
      return;
    }
    let localVersion = localStorage.getItem(`${PREFIX_STORAGE}:version`);
    const curVersion = environment.appVersion;

    if (localVersion === null) {
      localStorage.setItem(`${PREFIX_STORAGE}:version`, curVersion);
      localVersion = environment.appVersion;
    }

    if (curVersion > mobiPhoneVersion) {
      await this.handleVersion.setVersion(curVersion);
    } else if (curVersion < mobiPhoneVersion) {
      localStorage.setItem(`${PREFIX_STORAGE}:version`, mobiPhoneVersion);
      const id = `nAtt:${curVersion}_${mobiPhoneVersion}`;
      const hasNotification: MobiPhoneNotification = this.notifications.find(
        (notification) => notification.id === id
      );
      if (!hasNotification) {
        const title = 'updateAvailable';
        const message = 'updateAvailableMessage';

        this.notifications.unshift({
          id,
          title,
          text: `${message}`,
          read: false,
          version: mobiPhoneVersion,
        });
      }
    } else {
      const id = `att:${mobiPhoneVersion}`;
      const hasNotification: MobiPhoneNotification = this.notifications.find(
        (notification) => notification.id === id
      );
      if (!hasNotification) {
        const title = 'upToDate';
        const text = 'upToDateMessage';

        this.notifications.unshift({
          id,
          title,
          text,
          read: false,
        });
      }
    }

    this.checkNotificationsStatus();
  }

  markAllAsRead(): void {
    this.notifications = this.notifications.map((notification) => {
      return {
        ...notification,
        read: true,
      };
    });
    localStorage.setItem('notifications', JSON.stringify(this.notifications));
    this.hasNotifications.emit(false);
  }

  toggleRead(notification: MobiPhoneNotification): void {
    notification.read = !notification.read;
    this.checkNotificationsStatus();
  }

  getTooltip(read: boolean): string {
    return `${this.translate.instant('markAs')} ${
      read ? this.translate.instant('unread') : this.translate.instant('read')
    }`;
  }

  private checkNotificationsStatus() {
    const allRead: boolean =
      this.notifications.filter((notification) => notification.read === true)
        .length === this.notifications.length;

    localStorage.setItem('notifications', JSON.stringify(this.notifications));

    this.hasNotifications.emit(!allRead);
  }
}
