import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from '@core/services';

@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.scss'],
})
export class DialpadComponent implements OnInit {
  @Input() showHangup = false;
  @Output() dial: EventEmitter<{ target: HTMLElement; key: string }> =
    new EventEmitter<{ target: HTMLElement; key: string }>();

  dialpadKeys = [
    { value: '1', text: '' },
    { value: '2', text: 'ABC' },
    { value: '3', text: 'DEF' },
    { value: '4', text: 'GHI' },
    { value: '5', text: 'JKL' },
    { value: '6', text: 'MNO' },
    { value: '7', text: 'PQRS' },
    { value: '8', text: 'TUV' },
    { value: '9', text: 'WXYZ' },
    { value: '*', text: '' },
    { value: '0', text: '' },
    { value: '#', text: '' },
  ];

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {}
}
