import { PbxSector } from "./sector.model";

export interface PABX {
  id?: string,
  domainPbx: string,
  dtmfPbx: string,
  namePbx: string,
  portSipPbx: string,
  externalRoute?: number | string[],
  attendedTransferCode?: string,
  blindTransferCode?: string,
  modelPbx?: PbxModelEnum,
  pprefix?: string,
  bloq?: string,
  extRanges?: ExtensionRange[],
  extRangePrefix?: string,
  sectors?: PbxSector[],
}

export enum PbxModelEnum {
  ISION_IP = 'ision',
  FLUX_IP = 'flux',
  OTHER = 'other',
  SKYVOICE = 'skyvoice',
}

export interface PbxModel {
  name: PbxModelEnum;
  displayName: string;
}

export interface PBXExtension {
  id?: string;
  extension: string;
  pass: string;
  userId?: string;
  pbxId: string;
}

export interface EquipmentExtension {
  id: string;
  pasw: string;
  name?: string;
  association?: string;
  hw_id?: string;
  userId?: string;
};


export interface ExtraImportParams {
  pbxId: string,
  areaCode: string,
  dialplanId: string
}

export interface ExtensionRange {
  start: number;
  end: number;
}
