import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services';
import { environment } from '@environments/environment';
import { Observable, from, lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  private validURLs = [
    environment.baseURL,
  ];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Only add the token for valid URLs
    return this.validURLs.some((url) => req.url.includes(url))
      ? from(this.handle(req, next))
      : next.handle(req)
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await lastValueFrom(this.authService.idToken().pipe(take(1)));

    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      },
    });

    return lastValueFrom(next.handle(authReq));
  }
}
