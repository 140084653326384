import { Injectable } from '@angular/core';
import {
  ChatgptService,
  ClientService,
  RoomService,
  TimelineService
} from '@core/services';
import { environment } from '@environments/environment';
import { MatrixSyncStatus } from '@shared/enums';
import { clearMatrixLocalStore } from '@shared/utils';
import {
  ClientEvent,
  EventType,
  FileType,
  HttpApiEvent,
  MatrixClient,
  MatrixEvent,
  Room,
  RoomEvent,
  RoomMember,
  RoomMemberEvent,
  UploadOpts,
  UploadResponse
} from 'matrix-js-sdk';
import { logger } from 'matrix-js-sdk/lib/logger';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
  private matrixClient: MatrixClient;
  public statusEvents = new BehaviorSubject<MatrixSyncStatus>(MatrixSyncStatus.NULL);
  public allEvents = new Subject<MatrixEvent>();
  public initiate = false;

  constructor(
    private chatGPTService: ChatgptService,
    private clientService: ClientService,
    private roomService: RoomService,
    private timelineService: TimelineService,
  ) { }

  /**
   * Initiate Matrix client
   */
  public async initClient(): Promise<MatrixClient> {
    try {
      // Return if already init
      if (this.initiate) return;

      // Set logger prefix
      logger.prefix = 'Mobi Matrix SDK'

      // // Disable logs
      if (!environment.enableMatrixLogs) logger.disableAll();

      // Start matrix client
      this.matrixClient = await this.clientService.createMClient();

      // Auto join
      this.matrixClient.on(RoomMemberEvent.Membership, (event: MatrixEvent, member: RoomMember) => {
        // Ignore if not self
        if (member.userId !== this.matrixClient.getUserId()) return;

        // Store invite events
        if (!this.initiate) this.roomService.storeInviteEvents(member);
        else this.roomService.autoJoin(member);
      });

      // Start client
      await this.clientService.startMClient();

      // Setup Sync
      this.setupSync();

      // Return matrix client
      return this.matrixClient;
    } catch (error) {
      clearMatrixLocalStore(); // Clear local storage
      console.error('Fail on init client', error)
    }
  }

  /**
   * Setup Sync
   */
  public setupSync(): void {

    // Define events
    const sync = {
      NULL: () => { },
      SYNCING: (prevState: string) => { },
      PREPARED: async (prevState: string) => {
        // Check if is not already initiated
        if (!this.initiate) {
          // Log
          console.log('PREPARED state');

          // Set initiate
          this.initiate = true;

          // Listening events
          this.listenEvents();

          // For in invite events
          await this.joinOfflineRooms();

          // Populate rooms
          this.roomService.populateRooms();

          // Create self chat
          this.roomService.createSelfChat();

          // Create chatgpt room
          // this.chatGPTService.createChatGPTRoom();

          // Set status
          this.statusEvents.next(MatrixSyncStatus.PREPARED);
        }
      },
      RECONNECTING: () => { },
      CATCHUP: () => { },
      ERROR: () => {
        console.log('ERROR state');
      },
      STOPPED: () => { },
    };

    // Listening Sync event
    this.matrixClient.on(ClientEvent.Sync, (state: SyncState, prevState) => sync[state](prevState));
  }

  /**
   * Join offline rooms
   */
  public async joinOfflineRooms() {
    try {
      let invites = [...this.roomService.invites.values()];
      let promises = [];

      // Remove joined rooms
      invites = invites.filter(member => member.membership !== 'join');

      // Remove left rooms
      invites = invites.filter(member => member.membership !== 'leave');

      // Save promises
      for (const member of invites) {
        promises.push(this.roomService.autoJoin(member));
      }

      // Join rooms
      const resp = await Promise.allSettled(promises);

      // Remove invites
      resp.forEach((result, index) => {
        result.status === 'fulfilled'
          ? this.roomService.invites.delete(invites[index].roomId)
          : null;
      });
    } catch (error) {
      console.error('Fail on join offline rooms', error);
    }
  }

  /**
   * Check if initial sync has completed
   * @returns
   */
  public isInitialSyncComplete(): boolean {
    return this.matrixClient?.isInitialSyncComplete();
  }

  /**
   * Check if is syncing
   */
  public isSyncing(): boolean {
    return this.matrixClient.getSyncState() === SyncState.Syncing;
  }

  /**
   * Listen matrix events
   */
  private listenEvents(): void {
    // Update roomList when m.direct changes
    this.matrixClient.on(ClientEvent.AccountData, (event: MatrixEvent) => {
      this.roomService.updateRoomList(event)
    });

    // Update group list when member status changes
    this.matrixClient.on(RoomEvent.MyMembership, (room: Room, membership, prevMembership) => {
      this.roomService.updateGroupList(room, membership)
    });

    // Listening timeline events
    this.matrixClient.on(RoomEvent.Timeline, (event, room, toStartOfTimeline, removed, data) => {
      this.timelineService.listenRoomTimeline(event, room, toStartOfTimeline, removed, data);
    });

    // Listening client events
    this.matrixClient.on(ClientEvent.Event, (mEvent: MatrixEvent) => {
      switch (mEvent.getType()) {
        // Invalid types
        case EventType.Typing:
        case EventType.Receipt:
          break;

        default:
          // New value of event
          this.allEvents.next(mEvent);
          break;
      }
    });

    // Typing
    this.matrixClient.on(RoomMemberEvent.Typing, (event, member) => {
      this.roomService.updateTypingList(event, member);
    });

    // Redaction
    this.matrixClient.on(RoomEvent.Redaction, (mEvent, room) => {
      this.timelineService.listenRedaction(mEvent, room)
    });

    // Receipt
    this.matrixClient.on(RoomEvent.Receipt, (event, room) => {
      this.timelineService.listenReceiptEvent(event, room);
    });

    // Listening device disconnection
    this.matrixClient.on(HttpApiEvent.SessionLoggedOut, (error) => {
      this.clientService.makeLogoutByOtherDevice();
    });
  }

  /**
   * Upload content to matrix
   * @param {FileType} file File
   * @param {UploadOpts} opts Upload options
   * @returns {Promise<UploadResponse>} Upload Response
   */
  public uploadContent(file: FileType, opts?: UploadOpts): Promise<UploadResponse> {
    return this.matrixClient.uploadContent(file, opts);
  }

  /**
   * Convert mxc URL to Http one
   * @param mxcUrl
   * @returns
   */
  public mxcUrlToHttp(mxcUrl: string): string {
    return this.matrixClient.mxcUrlToHttp(mxcUrl)
  }


}
