import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'HoursMinutesSeconds',
})
export class HoursMinutesSecondsPipe implements PipeTransform {
  transform(value: string): string {
    const durantion = parseInt(value, 10);

    const hours = Math.floor(durantion / 3600);
    const minutes = Math.floor((durantion % 3600) / 60);
    const seconds = durantion % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds.toFixed(0)}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${seconds.toFixed(0)}s`;
    } else {
      return `${seconds.toFixed(0)}s`;
    }
  }
}
