import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
})
export class NameInitialsPipe implements PipeTransform {
  transform(value: string): unknown {
    // If value is empty, return
    if (!value) return;

    // If value is a number, return it
    if (/^\d+$/.test(value)) return value;

    // Get names | Ex: John Doe Smith => ['John', 'Doe', 'Smith']
    let names = value.split(' ');

    // Remove '(ANY_CHARACTER)' | Ex: John (Doe) Smith => ['John', 'Smith']
    names.forEach((name, index) => {
      if (name.includes('(')) names[index] = name.substring(0, name.indexOf('('));
    });

    // Remove empty names | Ex: ['John', '', 'Smith'] => ['John', 'Smith']
    names = names.filter((name) => !!name);

    // Get first letter of first name | Ex: John => J
    let initials = names[0].substring(0, 1).toUpperCase();

    // If has more than one name, get first letter of next name | Ex: John Doe Smith => JD
    if (names.length > 1) initials += names[names.length - 1].substring(0, 1).toUpperCase();

    // Return initials
    return initials;
  }
}
