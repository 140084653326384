import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';
@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  private renderer = new marked.Renderer();
  private defaultOptions: marked.MarkedOptions = {
    breaks: true,
    gfm: true,
  }

  // Para voltar a parte do markdown nas mensagem
  // 1 - Remover "white-space: pre-wrap;" do css da mensagem
  // 2 - Remover a trativa de link do pipe de 'replaceMsgContent'
  // 3 - Adicionar o pipe do markdown antes do pipe do 'replaceMsgContent'
  constructor() {
    // Update renderer
    this.updateRenderer()

    // Set markdown options
    marked.setOptions({
      ...this.defaultOptions,
      renderer: this.renderer
    })
  }

  /**
   * Update Renderer
   */
  private updateRenderer(): void {
    // Link
    this.renderer.link = (href, title, text) => {
      return '<a target="_blank" style="color:inherit;" href="' + href + '" title="' + title + '">' + text + '</a>';
    }

    // Paragraph
    this.renderer.paragraph = (text) => {
      return '<p style="margin: 0 0 8px;">' + text + '</p>';
    }

    // Image
    this.renderer.image = (href, title, text) => {
      return `<img loading="lazy" src="${href}" alt="${text}" style="max-width: 100%; border-radius: 2px; cursor: pointer;" height="200px" />`;
    }

    // List
    this.renderer.list = (body, ordered, start) => {
      if (ordered) {
        return '<ol style="margin: 0px;">' + body + '</ol>';
      } else {
        return '<ul style="margin: 0px;">' + body + '</ul>';
      }
    }
  }

  transform(value: any, args?: any[]): any {
    return value && value.length > 0 ? marked(value) : value;
  }
}
