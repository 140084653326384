import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ExternalContactsService, ThemeService } from '@core/services';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements AfterViewInit, OnChanges {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() applySearchFilter = new EventEmitter<string>();
  @Output() loading = new EventEmitter<boolean>();
  @Input() canShowClearButton = true;
  @Input() shouldDoDynamicFetch: any = false;
  @Input() placeholder: string = 'search';
  @Input() tabIndex: any;

  public isUserTyping = false;
  private debouceTimeMS = 1500;
  public defaultContactsQuantity = 20;
  public isCompanyList = false;

  constructor(
    private router: Router,
    public themeService: ThemeService,
    private externalContactsService: ExternalContactsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tabIndex)
      this.isCompanyList = changes.tabIndex.currentValue == 2
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.addEventListener('input', () => {
      this.onChange();
    });
  }

  onChange(): void {
    // If it's not on external contacts, do simple search
    if (!this.shouldDoDynamicFetch)
      this.applySearchFilter.emit(this.searchInput.nativeElement.value);
    else {
      // On Key Down, user is typing (true)
      if (this.searchInput.nativeElement.value == '') {
        this.externalContactsService.dynamicFetchContacts(this.defaultContactsQuantity, this.isCompanyList, '')
      }
      this.isUserTyping = true;
      this.loading.emit(true)
    }
  }

  onCancel(): void {
    this.searchInput.nativeElement.value = '';
    this.applySearchFilter.emit(this.searchInput.nativeElement.value);
  }

  public createNewContact() {
    this.router.navigate(['telephony/contacts/new'])
  }

  public showHistory() {
    this.router.navigate(['telephony'])
  }

  /*
  * Start a counter whenever the user triggers keyup
  * If there's no typing in the next {debouceTimeMS} seconds, perform action
  */
  public debounce() {
    // If it's not on external contacts, do simple search
    if (!this.shouldDoDynamicFetch) return;

    // Debounce is only called on keyup
    this.isUserTyping = false;

    // Start debounce timer
    setTimeout(() => {

      // Timeout is async, so we gotta check one more time
      if (!this.isUserTyping) {
        this.loading.emit(false)
        this.querySearchValue()
      }
    }, this.debouceTimeMS)

  }

  /*
  *  Perform a query based on the search value
  */
  public querySearchValue() {
    const searchedValue = this.searchInput.nativeElement.value
    this.externalContactsService.dynamicFetchContacts(this.defaultContactsQuantity, this.isCompanyList, searchedValue)
  }

}
