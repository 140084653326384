import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AVAILABLE_LANGUAGES } from '@shared/enums';
import { convertHour } from '@shared/utils';

@Pipe({
  name: 'hourFormatPipe',
  pure: false
})
export class HourFormatPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) { }

  transform(value: string): string {
    return value ? this.formatHour(value) : '';
  }

  /**
   * Format the hour by lang
   * @param {string} value Hour
   * @returns Formatted hour
   */
  private formatHour(value: string): string {
    switch (this.translate.currentLang) {
      case AVAILABLE_LANGUAGES.EN:
        return convertHour(value, 12);
      default:
        return convertHour(value, 24);
    }
  }
}
