import { PbxModel, PbxModelEnum } from "@shared/models";

export const PBX_MODELS: PbxModel[] = [
  {
    name: PbxModelEnum.ISION_IP,
    displayName: 'ISION IP',
  },
  {
    name: PbxModelEnum.FLUX_IP,
    displayName: 'FLUX IP',
  },
  {
    name: PbxModelEnum.OTHER,
    displayName: 'Outros',
  },
  {
    name: PbxModelEnum.SKYVOICE,
    displayName: 'SKYVOICE',
  },
];

export const EQUIPMENTS_MODELS = [
  PbxModelEnum.SKYVOICE
]

export const SKYVOICE_DTMF_LIST = [
  { label: 'SIP INFO', value: 'info' },
  { label: 'RFC2833', value: 'rfc2833' },
];

export const DTMF_LIST = [
  { label: 'SIP INFO', value: 'info' },
  { label: 'RFC4733', value: 'rfc4733' },
  { label: 'RFC2833', value: 'rfc2833' },
  { label: 'INBAND', value: 'inband' },
];

export const MIN_PABX_EXT_PASSWORD_LENGTH = 8;

export const MAX_PABX_EXT_PASSWORD_LENGTH = 31;
