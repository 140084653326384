import { Component, Input, OnInit } from '@angular/core';
import { ThemeService, UserPresenceService } from '@core/services';
import { User } from '@shared/models';

@Component({
  selector: 'app-avatar-with-status',
  templateUrl: './avatar-with-status.component.html',
  styleUrls: ['./avatar-with-status.component.scss'],
})
export class AvatarWithStatusComponent implements OnInit {

  @Input() contact: User;

  public isLoaded = false;

  constructor(
    public themeService: ThemeService,
    private userPresenceService: UserPresenceService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Check if can show avatar
   * @param {boolean} isLoaded true if can show img
   */
  public loaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  /**
   * Get user status
   * @returns {string} Status
   */
  public getUserStatus(): string {
    return this.userPresenceService.getPresence(this.contact?.id).status;
  }

}
