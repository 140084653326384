import { Injectable } from '@angular/core';
import { FirestoreService } from '@core/services';
import { Cluster, PREFIX_STORAGE } from '@shared/models';
import { Observable, lastValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  cluster: Cluster;

  constructor(private firestore: FirestoreService) { }

  private async setCluster(clusterId: string): Promise<void> {
    this.cluster = await lastValueFrom(
      this.firestore
        .docWithId$<Cluster>(`voice_servers/${clusterId}`)
        .pipe(first())
    );
  }

  async setClusterId(clusterId: string): Promise<void> {
    await this.setCluster(clusterId);
    localStorage.setItem(`${PREFIX_STORAGE}:clusterId`, clusterId);
  }

  get(): Observable<Cluster[]> {
    return this.firestore.colWithIds$<Cluster>('voice_servers');
  }

  getById(clusterId: string): Promise<Cluster> {
    return lastValueFrom(
      this.firestore
        .docWithId$<Cluster>(`voice_servers/${clusterId}`)
        .pipe(first())
    );
  }
}
