import { PABX_INTEGRATION_OPTIONS } from "@shared/enums";
import { PREFIX_STORAGE } from "@shared/models";

/**
 * Set local storage - Tenant id
 * @param {string} tenantId Tenant id
 */
export const setLSTenantId = (tenantId: string): void => {
  localStorage.setItem(`${PREFIX_STORAGE}:tenantId`, tenantId);
}

/**
 * Get local storage - Tenant id
 * @returns {string} Tenant id
 */
export const getLSTenantId = (): string => {
  return localStorage.getItem(`${PREFIX_STORAGE}:tenantId`);
}

/**
 * Remove local storage - Tenant id
 */
export const removeLSTenantId = (): void => {
  localStorage.removeItem(`${PREFIX_STORAGE}:tenantId`);
}

/**
 * Set local storage - User id
 * @param {string} userId User id
 */
export const setLSUserId = (userId: string): void => {
  localStorage.setItem(`${PREFIX_STORAGE}:userId`, userId);
}

/**
 * Get local storage - User id
 * @returns {string} User id
 */
export const getLSUserId = (): string => {
  return localStorage.getItem(`${PREFIX_STORAGE}:userId`);
}

/**
 * Remove local storage - User id
 */
export const removeLSUserId = (): void => {
  localStorage.removeItem(`${PREFIX_STORAGE}:userId`);
}

/**
 * Set local storage - User email
 * @param {string} userEmail User email
 */
export const setLSUserEmail = (userEmail: string): void => {
  localStorage.setItem(`${PREFIX_STORAGE}:userEmail`, userEmail);
}

/**
 * Get local storage - User email
 * @returns {string} User email
 */
export const getLSUserEmail = (): string => {
  return localStorage.getItem(`${PREFIX_STORAGE}:userEmail`);
}

/**
 * Remove local storage - User email
 */
export const removeLSUserEmail = (): void => {
  localStorage.removeItem(`${PREFIX_STORAGE}:userEmail`);
}

/**
 * Set local storage - Desktop version
 */
export const setDesktopVersion = (version: string): void => {
  localStorage.setItem(`${PREFIX_STORAGE}:desktopVersion`, version);
}

/**
 * Get local storage - Desktop version
 * @returns {string} Desktop version
 */
export const getDesktopVersion = (): string => {
  return localStorage.getItem(`${PREFIX_STORAGE}:desktopVersion`);
}

/**
 * Remove local storage - Desktop version
 */
export const removeDesktopVersion = (): void => {
  localStorage.removeItem(`${PREFIX_STORAGE}:desktopVersion`);
}

/**
 * Set local storage - Status connection key
 * @param {string} statusConnectionKey Status connection key
 */
export const setLSStatusConnectionKey = (statusConnectionKey: string): void => {
  localStorage.setItem('mobi_phone:connection', statusConnectionKey);
}

/**
 * Get local storage - Status connection key
 * @returns {string} Status connection key
 */
export const getLSStatusConnectionKey = (): string => {
  return localStorage.getItem('mobi_phone:connection');
}

/**
 * Remove local storage - Status connection key
 */
export const removeLSStatusConnectionKey = (): void => {
  localStorage.removeItem('mobi_phone:connection');
}


/**
 * Set local storage - Company PABX Integration
 * @param {PABX_INTEGRATION_OPTIONS} pabxIntegration Company PABX Integration
 */
export const setLSCompanyPABXIntegration = (pabxIntegration: PABX_INTEGRATION_OPTIONS): void => {
  localStorage.setItem(`${PREFIX_STORAGE}:pabxIntegration`, pabxIntegration);
}

/**
 * Get local storage - Company PABX Integration
 * @returns {PABX_INTEGRATION_OPTIONS} Company PABX Integration
 */
export const getLSCompanyPABXIntegration = (): PABX_INTEGRATION_OPTIONS => {
  return localStorage.getItem(`${PREFIX_STORAGE}:pabxIntegration`) as PABX_INTEGRATION_OPTIONS;
}

/**
 * Remove local storage - Company PABX Integration
 */
export const removeLSCompanyPABXIntegration = (): void => {
  localStorage.removeItem(`${PREFIX_STORAGE}:pabxIntegration`);
}

/**
 * Set local storage - Page and Category for Administration Panel
 * @param {string} category Category
 * @param {string} page Page
 */
export const setLSAdminCategoryAndPage = (category: string, page: string): void => {
  localStorage.setItem(`${PREFIX_STORAGE}:adminTab`, JSON.stringify({ category, page }));
}

/**
 * Get local storage - Page and Category for Administration Panel
 * @returns {{ category: string, page: string }} Page and Category
 */
export const getLSAdminCategoryAndPage = (): { category: string, page: string } => {
  return JSON.parse(localStorage.getItem(`${PREFIX_STORAGE}:adminTab`));
}

/**
 * Remove local storage - Page and Category for Administration Panel
 */
export const removeLSAdminCategoryAndPage = (): void => {
  localStorage.removeItem(`${PREFIX_STORAGE}:adminTab`);
}
