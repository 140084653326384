<!-- Main -->
<main class="props-main" [ngClass]="{ dark: themeService.isDarkTheme() }">

  <!-- Info -->
  <div class="info-box" *ngIf="canShowInfo">
    <!-- Title -->
    <strong> {{ infoTitle | translate }}: </strong>

    <!-- Break line -->
    <br>

    <!-- Info text -->
    {{ infoText | translate }}
  </div>

  <!-- Form -->
  <form class="props-form" role="form" [formGroup]="formGroup">

    <!-- Receive content from the parent component -->
    <ng-content></ng-content>

  </form>

</main>
