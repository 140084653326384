import { Injectable } from '@angular/core';
import { orderBy } from '@angular/fire/firestore';
import {
  FirestoreService,
  LcsEventsService,
  TenantService
} from '@core/services';
import { LcsEventDescriptions, LcsEventSectorTypes } from '@shared/enums';
import { CompanySector } from '@shared/models';
import { getCompanySectorPath, getDiffBetweenObj } from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanySectorService {
  constructor(
    private tenantService: TenantService,
    private fireService: FirestoreService,
    private lcsEventService: LcsEventsService,
  ) { }

  /**
   * Fetches the company sectors
   * @returns {Observable<CompanySector[]>} The company sectors
   */
  public fetch(): Observable<CompanySector[]> {
    // Format the path to the company sectors collection
    const path = getCompanySectorPath(this.tenantService.tenant?.id);

    // Fetch the company sectors
    return this.fireService.colWithIds$<CompanySector>(path, [orderBy('name')]);
  }

  /**
   * Fetches a company sector by ID
   * @param {string} id The company sector ID
   * @returns {Promise<CompanySector>} The company sector
   */
  public fetchById(id: string): Promise<CompanySector> {
    // Format the path to the company sector document
    const path = getCompanySectorPath(this.tenantService.tenant?.id, id);

    // Fetch the company sector
    return lastValueFrom(this.fireService.docWithId$<CompanySector>(path).pipe(first()))
  }

  /**
   * Creates a company sector
   * @param {CompanySector} sector The company sector to create
   */
  public async create(sector: CompanySector): Promise<void> {
    // Format the path to the company sectors collection
    const path = getCompanySectorPath(this.tenantService.tenant?.id);

    // Create the company sector
    return this.fireService
      .addDoc(path, sector)
      .then(() => this.handleSuccess(LcsEventSectorTypes.COMPANY_SECTOR_CREATION, { created: sector }))
      .catch((error) => this.handleError(error, LcsEventSectorTypes.COMPANY_SECTOR_CREATION));
  }

  /**
   * Updates a company sector
   * @param {CompanySector} sector The company sector to update
   * @param {CompanySector} original The original company sector
   */
  public async update(sector: CompanySector, original: CompanySector): Promise<void> {
    // Format the path to the company sector document
    const path = getCompanySectorPath(this.tenantService.tenant?.id, sector.id);

    // Update the company sector
    return this.fireService
      .updateDoc(path, sector)
      .then(() => this.handleSuccess(LcsEventSectorTypes.COMPANY_SECTOR_UPDATE, {
        id: sector.id,
        name: sector.name,
        diff: getDiffBetweenObj(sector, original)
      }))
      .catch((error) => this.handleError(error, LcsEventSectorTypes.COMPANY_SECTOR_UPDATE));
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventSectorTypes} type The event type
   */
  public handleError(error: any, type: LcsEventSectorTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, true);

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsEventSectorTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventSectorTypes, value: any): void {
    this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, true);
  }
}
