import { Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { ANALYTICS_EVENTS } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsSharedService {
  constructor(private analytics: Analytics) { }

  /**
   * Log event
   * @param {ANALYTICS_EVENTS} eventName Event name
   * @param {any} eventParams Params
   */
  public logEvent(eventName: ANALYTICS_EVENTS, eventParams?: { [key: string]: any; }): void {
    logEvent(this.analytics, eventName, eventParams);
  }
}
