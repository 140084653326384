export enum CallState {
  CONNECTING = 'call_connecting',
  PROGRESS = 'call_progress',
  CONFIRMED = 'call_confirmed',
  ENDED = 'call_ended',
  FAILED = 'call_failed',
  INCOMING_PROGRESS = 'call_incoming_progress',
  INCOMING_ACCEPTED = 'call_incoming_accepted',
  INCOMING_CONFIRMED = 'call_incoming_confirmed',
  INCOMING_ENDED = 'call_incoming_ended',
  INCOMING_FAILED = 'call_incoming_failed',
  STREAM = 'call_stream',
}
