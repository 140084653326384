import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, CryptoService, NotifierService, SocketService } from '@core/services';
import { environment } from '@environments/environment';
import { AiResponse, TranscriptionResponse } from '@shared/models';
import { BehaviorSubject, Subject, lastValueFrom, take } from 'rxjs';
import { parse, v5 } from 'uuid';

interface TextTranscription {
  fileName: string;
  transcription: string;
}
@Injectable({
  providedIn: 'root',
})
export class AiService {
  public inputText = new BehaviorSubject<TranscriptionResponse>(null);
  public listEmail: string[] = [];
  public loadingMap = new Map<string, number>();
  public observableTranscription: Subject<TextTranscription> = new Subject();
  public observableText: Subject<string> = new Subject();
  private fileNameInput: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public cript: CryptoService,
    private notifier: NotifierService,
    private socketService: SocketService
  ) {
    this.connectionSocket();
  }

  private async connectionSocket(): Promise<void> {
    this.socketService.listenToTopic(
      `aiService`,
      async (aiService: AiResponse) => {
        // console.log('connecting receve: ', aiService);

        if (this.listEmail.includes(aiService.userEmail)) {
          if (aiService.loadingValue === -1) {
            const index = this.listEmail.indexOf(aiService.userEmail);
            this.listEmail.splice(index, 1);

            if (aiService.fileName === this.fileNameInput) { // Text Edit
              this.observableText.next(null);
              this.fileNameInput = '';
            } else { // Transcription
              this.loadingMap.delete(aiService.fileName);
              this.observableTranscription.next({ fileName: aiService.fileName, transcription: null });

              this.notifier.showNotification({
                type: 'error',
                message: 'internalErrorWhenTranscribing',
                actionText: '',
                panelClass: 'error',
              });
            }

          } else if (aiService.loadingValue === 100) {
            const index = this.listEmail.indexOf(aiService.userEmail);
            this.listEmail.splice(index, 1);

            const text = await this.getAiText(aiService.userEmail, aiService.fileName);
            if (aiService.fileName === this.fileNameInput) {// Text Edit
              this.observableText.next(text);
              this.fileNameInput = '';
            } else { // Transcription
              this.observableTranscription.next({
                fileName: aiService.fileName,
                transcription: text,
              });
              this.loadingMap.delete(aiService.fileName);
            }

          } else {
            if (aiService.fileName !== this.fileNameInput) {
              this.loadingMap.set(aiService.fileName, aiService.loadingValue);
            }
          }
        }
      }
    );
  }

  /**
   * Get audio transcriptions
   * @returns {transcription} Text transcription
   */
  public async getAiText(userEmail: string, fileName: string): Promise<string> {
    // Get path
    const path = environment.baseURL + '/aiService/getFile';

    // const token = await lastValueFrom(this.authService.idToken().pipe(take(1)));

    const filter = {
      userEmail: userEmail,
      fileName: fileName,
      leucotronService: 'mobiphone',
    };

    const resp = await lastValueFrom(this.http.get<any>(path, {
      observe: 'response',
      params: filter,
    })
    );

    if (resp.status !== 200) {
      throw new Error();
    }
    if (resp.body['data'] === null) {
      return null;
    }
    if (resp.body['data']['transcriptionSummary'] === null) {
      return 'loading';
    }

    const key = await this.getKey(userEmail);

    const text = this.cript.decryptText(
      resp.body['data']['transcriptionSummary'],
      key
    );

    let textTranscription = text.split('transcribedText:');
    textTranscription = textTranscription[1].split(', textSummary:');
    const transcription = textTranscription[0];
    const newText = textTranscription[1];

    if (newText.length > 2) {
      return newText;
    }

    return transcription;
  }

  public async transcribeAudio(formData: FormData, email: string, fileName: string) {
    try {

      this.listEmail.push(email);

      fileName = this.changeAudioNameOggToMp3(fileName);

      if (await this.insertTranscriptionOnDB(email, fileName)) {

        const path = environment.aiApiURL + '/myAIhelper/withFile';

        const token = await lastValueFrom(this.authService.idToken().pipe(take(1)));
        formData.append('token', token);

        const companyUser = this.authService.getCompany();
        formData.append('companyUser', companyUser);
        formData.append('fileOwnerEmail', email);

        const resp = await lastValueFrom(
          this.http.post<TranscriptionResponse>(path, formData)
        );

        // console.log("response: " + resp.processStatus)
        if (resp.processStatus !== 'done') {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      this.loadingMap.delete(fileName);
      const index = this.listEmail.indexOf(email);
      this.listEmail.splice(index, 1);
      this.notifier.showNotification({
        type: 'error',
        message: 'errorRequestTranscription',
        actionText: '',
        panelClass: 'error',
      });

      this.deleteAudio(email, fileName);

      throw new Error();
    }
  }

  public async deleteAudio(userEmail: string, fileName: string) {
    // Get path
    const path = environment.baseURL + '/aiService/delete';

    fileName = this.changeAudioNameOggToMp3(fileName);
    const filter = { userEmail: userEmail, fileName: fileName };

    await lastValueFrom(
      this.http.post<any>(path, filter, {
        observe: 'response',
      })
    ).catch((error) => {
      console.log('erro ao deletar', error);
    });
  }

  private async insertTranscriptionOnDB(userEmail: string, fileName: string): Promise<Boolean> {
    let success: Boolean = true;
    // Get path
    const path = environment.baseURL + '/aiService/create';

    fileName = this.changeAudioNameOggToMp3(fileName);

    const uuid = this.createUuid(fileName);

    const data = {
      uuid: uuid,
      userEmail: userEmail,
      processStatus: 'loading',
      fileName: fileName,
      transcriptionSummary: null,
      leucotronService: 'mobiphone',
    };
    await lastValueFrom(
      this.http.post<any>(path, data, {
        observe: 'response',
      })
    ).catch((error) => {
      // console.log("couldn't create new line", error);
      success = false;
    });

    return success;
  }

  public changeAudioNameOggToMp3(fileName: string): string {
    const extension = fileName.split('.').pop();

    if (extension === 'ogg') {
      const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
      fileName = nameWithoutExtension + '.mp3';
    }
    return fileName;
  }

  private createUuid(fileName: string) {
    const namespace = '6ba7b812-9dad-11d1-80b4-00c04fd430c8';
    const id = v5(fileName, parse(namespace));
    return id;
  }

  private async getKey(email: string) {
    // Get path
    const path = environment.baseURL + '/aiKey/getKey?email=' + email;

    const resp = await lastValueFrom(
      this.http.get<any>(path, {
        observe: 'response',
      })
    );
    const data = resp.body['data'].key;
    const key = this.cript.decryptText(data, environment.criptokey);

    return key;
  }

  public async sendTextToAi(formData: FormData, email: string, fileName: string) {
    try {
      this.listEmail.push(email);
      this.fileNameInput = fileName;
      if (await this.insertTranscriptionOnDB(email, fileName)) {
        const path = environment.aiApiURL + '/myAIhelper/withoutFile';
        const token = await lastValueFrom(this.authService.idToken().pipe(take(1)));

        formData.append('token', token);
        const companyUser = this.authService.getCompany();
        formData.append('leucotronService', 'mobiphone');
        formData.append('companyUser', companyUser);
        const resp = await lastValueFrom(
          this.http.post<TranscriptionResponse>(path, formData)
        );
        if (resp.processStatus === 'error') {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      // this.loadingMap.delete(fileName);
      const index = this.listEmail.indexOf(email);
      this.listEmail.splice(index, 1);
      this.observableText.next(null);
      console.log("ocorreu um erro!")

      this.deleteAudio(email, fileName);
      throw error;
    }
  }
}
