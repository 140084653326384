export interface PhoneNumber {
  number: string;
  description: string;
  isValid?: boolean;
}

export interface Contact {
  id?: string;
  name: string;
  phoneNumbers?: PhoneNumber[];
  createdBy?: string;
  company?: string;
  isCompanyContact?: boolean;
}
