/**
 * Get company postfix
 * Example: Teste-ntt4y => ntt4y
 * @param {string} companyId Company id
 * @returns {string} Company postfix
 */
export const getCompanyPostfix = (companyId: string): string => {
    const idSplitted = companyId.split('-');
    return idSplitted[idSplitted.length - 1];
}
