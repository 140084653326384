import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';

type Size = 'small' | 'medium' | 'large';

const config = {
  small: [Breakpoints.Small, Breakpoints.XSmall],
  medium: [Breakpoints.Medium],
  large: [Breakpoints.Large, Breakpoints.XLarge],
};

@Directive({
  selector: '[ifViewportSize]',
})
export class IfViewportSizeDirective implements OnDestroy {
  private subscription = new Subscription();

  @Input('ifViewportSize') set size(values: Size[]) {
    const queries = values.map((value) => config[value]);

    this.subscription.unsubscribe();
    this.subscription = this.observer
      .observe(queries.reduce((acc, val) => acc.concat(val), []))
      .subscribe(this.updateView);
  }

  constructor(
    private observer: BreakpointObserver,
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  updateView = ({ matches }: BreakpointState) => {
    if (matches && !this.vcRef.length) {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else if (!matches && this.vcRef.length) {
      this.vcRef.clear();
    }
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
