import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService,
  ContactService,
  JsSIPService,
  LoadingSpinnerService,
  SocketService,
  ThemeService
} from '@core/services';
import { SOCKET_TOPIC } from '@shared/enums';
import { Subject, finalize, take, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'app-switch-device-notification',
  templateUrl: './switch-device-notification.component.html',
  styleUrls: ['./switch-device-notification.component.scss']
})
export class SwitchDeviceNotificationComponent implements OnInit, OnDestroy {
  public showSwitchDeviceNotification = false;
  private authServiceUnsubscribe$ = new Subject<void>();
  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    public contactsService: ContactService,
    public themeService: ThemeService,
    private authService: AuthService,
    private jssipService: JsSIPService,
    private socketService: SocketService,
    private spinner: LoadingSpinnerService,
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe$?.next();
    this.ngUnsubscribe$?.complete();
    this.authServiceUnsubscribe$?.next();
    this.authServiceUnsubscribe$?.complete();
  }

  ngOnInit(): void {
    this.authService
      .user$
      .pipe(takeUntil(this.authServiceUnsubscribe$))
      .subscribe((user) => {
        if (user) {
          // New implementation
          this.contactsService.listeningCallingWith(user.id);
          this.authServiceUnsubscribe$?.next();
          this.authServiceUnsubscribe$?.complete();
        }
      });

    // New implementation
    this.contactsService.callingWith$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((callingWith) => {
        this.handleCallState(callingWith);
      });
  }


  /**
   * Handle current user state change.
   * @param user Current user
   */
  async handleCallState(callingWith: string): Promise<void> {
    // Hide container if current user is not calling
    this.showSwitchDeviceNotification = !(!callingWith || this.jssipService.isSessionEnabled);
  }

  /**
   * Hide ongoing call container information
   */
  closeNotification(): void {
    this.showSwitchDeviceNotification = !this.showSwitchDeviceNotification;
  }

  /**
   * Sends a websocket event to ongoing call refer to current device.
   */
  switchToDevice(): void {
    try {
      this.spinner.show();
      this.contactsService.deleteCallingWith(this.authService.userId);
      this.jssipService.shouldAutoAnswer = true;
      this.socketService.sendMessage([SOCKET_TOPIC.transfer_device, this.authService.user]);
      this.jssipService
        .callingEvents
        .pipe(
          take(2),
          takeUntil(timer(15000)),
          finalize(() => { this.spinner.hide(); }))
        .subscribe(e => {
          if (e) { this.spinner.hide(); };
        });
    } catch (error) {
      this.spinner.hide();
    }
  }
}
