import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type Data = {
  title: string;
  desc: string;
  info: string;
  btnText: string;
  cancelBtnText?: string;
}

@Component({
  selector: 'app-confirm-deletion',
  templateUrl: './confirm-deletion.component.html',
  styleUrls: ['./confirm-deletion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDeletionComponent implements OnInit {
  public cancelBtnText: string = this.data.cancelBtnText || 'cancel';

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeletionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) { }

  ngOnInit(): void {
  }

  public onSubmit(): void {
    this.dialogRef.close(true);
  }

}
