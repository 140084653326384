<div id="switch-device-notification" [ngClass]="{ dark: themeService.isDarkTheme() }"
  *ngIf="showSwitchDeviceNotification">

  <div class="details">
    <div class="message-wrapper">
      <p class="message">
        {{ "header.callOngoing" | translate}} {{ "header.changeDevice" | translate}}
      </p>
      <button mat-raised-button class="switch-device-button" (click)="switchToDevice()" color="primary">
        {{ "enter" | translate}}
      </button>
    </div>
  </div>

  <button mat-icon-button class="close-notification-button" (click)="closeNotification()">
    <mat-icon>close</mat-icon>
  </button>
</div>