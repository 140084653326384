import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AnalyticsSharedService,
  AuthService,
  ClusterService,
  ContactService,
  ExternalContactsService,
  JitsiService,
  MediaDevicesService,
  PlatformService,
  SocketService,
  TenantService,
  UserMediaService,
  UserPresenceService,
  UtilsService
} from '@core/services';
import { environment } from '@environments/environment';
import { SimpleDialogComponent } from '@shared/components';
import { ANALYTICS_EVENTS, CallState, SOCKET_TOPIC } from '@shared/enums';
import {
  HoldData,
  MultiLineCallData,
  UaSettings,
  User
} from '@shared/models';
import { getUserNameWithSector } from '@shared/utils';
import * as JsSIP from 'jssip';
import {
  ConnectingEvent,
  EndEvent,
  IncomingAckEvent,
  IncomingEvent,
  OutgoingAckEvent,
  OutgoingEvent,
  RTCSession,
  RTCSessionEventMap,
} from 'jssip/lib/RTCSession';
import { IncomingRequest } from 'jssip/lib/SIPMessage';
import {
  CallOptions,
  RTCSessionEvent,
  UA,
  UAConfiguration,
} from 'jssip/lib/UA';
import { DisconnectEvent } from 'jssip/lib/WebSocketInterface';
import { BehaviorSubject, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JsSIPService {
  private ua: UA;
  private errors = 0;
  private currentCallSession: RTCSession;
  private sessions: RTCSession[] = [];
  public video = false;
  private captureStreams: MediaStream = null;
  private localStreams: MediaStream = null;
  private user: User;

  public uaSettings: UaSettings = null;
  shouldAutoAnswer = false;
  ignoreIncomingCall = false;

  registerEvents = new BehaviorSubject<string>('disconnected');
  callingEvents = new BehaviorSubject<any>(null);
  sideNavMenuEvent = new BehaviorSubject<boolean>(false);

  status = '';
  destinationPhone = '';
  destinationName = '';
  localDisplayname = ''; // session.local_identity.display_name
  bluetoothDevice: MediaDeviceInfo | null = null;

  callHandlers: Partial<RTCSessionEventMap> = {
    connecting: (e: ConnectingEvent) => {
      // console.log('Call connecting', e);
      this.callingEvents.next({
        destinationPhone: this.destinationPhone,
        destinationName: this.destinationName,
        local_display_name: this.localDisplayname,
        state: CallState.CONNECTING,
        type: 'outgoing',
      });
    },
    progress: (e: IncomingEvent | OutgoingEvent) => {
      // console.log('Call progress', e);
      this.userMediaService.callingSound();
      this.callingEvents.next({
        destinationPhone: this.destinationPhone,
        destinationName: this.destinationName,
        local_display_name: this.localDisplayname,
        state: CallState.PROGRESS,
        type: 'outgoing',
      });
    },
    failed: (e: EndEvent) => {
      // console.log('Call failed', e);
      this.userMediaService.pauseAllSounds();
      this.callingEvents.next({
        destinationPhone: this.destinationPhone,
        destinationName: this.destinationName,
        local_display_name: this.localDisplayname,
        state: CallState.FAILED,
        type: 'outgoing',
        data: e,
      });

      this.removeSessionFromList();
      this.shouldAutoAnswer = false;
      this.stopTracksAfterEnd();
    },
    ended: async (e: EndEvent) => {
      // console.log('Call ended', e);
      let sessionId = e.cause;
      if (e.message !== null) {
        const incomingReq = e.message as IncomingRequest;
        const toTag = incomingReq.to.getParam('tag');
        const parsedCallId = incomingReq.parseHeader('Call-ID');
        sessionId = `${parsedCallId}${toTag}`;
      }
      const session =
        this.sessions.find((sess) => sess.id === sessionId) ??
        this.currentCallSession;
      if (session === this.currentCallSession) {
        if (this.sessions.length > 1) {

          await this.changeToNewSession({
            isOutgoing: true,
            session,
            state: CallState.ENDED,
          });
        } else {
          this.userMediaService.pauseCalling();
          this.callingEvents.next({
            destinationPhone: this.destinationPhone,
            destinationName: this.destinationName,
            local_display_name: this.localDisplayname,
            state: CallState.ENDED,
            type: 'outgoing',
          });
        }
      }

      this.removeSessionFromList(session);
      this.shouldAutoAnswer = false;
      this.stopTracksAfterEnd();
    },
    confirmed: (e: IncomingAckEvent | OutgoingAckEvent) => {
      // console.log('Call confirmed', e);
      this.userMediaService.pauseCalling();
      this.callingEvents.next({
        destinationPhone: this.destinationPhone,
        destinationName: this.destinationName,
        local_display_name: this.localDisplayname,
        state: CallState.CONFIRMED,
        type: 'outgoing',
        voiceonly: true,
      });
    },
    peerconnection: (data: { peerconnection: RTCPeerConnection }) => {
      data.peerconnection.ontrack = (event: RTCTrackEvent) => {
        this.callingEvents.next({
          event,
          state: CallState.STREAM,
        });
      };
    },
  };

  constructor(
    public dialog: MatDialog,
    private userMediaService: UserMediaService,
    private platform: PlatformService,
    private clusterService: ClusterService,
    private socket: SocketService,
    private mediaDevices: MediaDevicesService,
    private jitsi: JitsiService,
    private analytics: AnalyticsSharedService,
    private userPresenceService: UserPresenceService,
    private tenant: TenantService,
    private utilsService: UtilsService,
    private contacts: ContactService,
    private externalContacts: ExternalContactsService,
    private auth: AuthService
  ) {
    // Enable logs
    environment.enableJsSipLogs
      ? JsSIP.debug.enable('JsSIP:*')
      : JsSIP.debug.disable();

    // Get user
    this.auth.user$.subscribe((user) => (this.user = user));
  }

  start(uaSettings: UaSettings): void {
    if (this.ua?.isConnected()) {
      return;
    }

    this.uaSettings = uaSettings;

    const configuration: UAConfiguration = {
      sockets: new JsSIP.WebSocketInterface(uaSettings.webSocketUrl),
      uri: uaSettings.uri,
      password: uaSettings.password,
      display_name: uaSettings.displayName,
      user_agent: uaSettings.userAgent ?? 'mobi-phone',
      register: uaSettings.register,
      register_expires: uaSettings.register_expires,
      contact_uri: uaSettings.uri,
      connection_recovery_max_interval:
        uaSettings.connection_recovery_max_interval,
      connection_recovery_min_interval:
        uaSettings.connection_recovery_min_interval,
    };

    try {
      this.ua = new JsSIP.UA(configuration);

      this.ua.on('connected', () => {
        this.errors = 0;
      });

      this.ua.on('disconnected', (event: DisconnectEvent) => {
        if (event.error) {
          this.errors++;
          if (this.errors > 5) {
            this.unregister();
            this.registerEvents.next('error_connect');
          }
        } else {
          this.errors = 0;
          this.registerEvents.next('disconnected');
        }
      });

      this.ua.on('registered', () => {
        this.status = 'connected';
        this.registerEvents.next('connected');
      });

      this.ua.on('unregistered', () => {
        this.status = 'disconnected';
        this.registerEvents.next('unregistered');
      });

      this.ua.on('registrationExpiring', () => {
        this.ua.register();
      });

      this.ua.on('registrationFailed', () => {
        this.status = 'registration_failed';
        this.registerEvents.next('registration_failed');
      });

      this.ua.on('newRTCSession', async (event: RTCSessionEvent) => {
        if (
          !this.currentCallSession?.isInProgress() &&
          !this.currentCallSession?.isEstablished()
        ) {
          this.currentCallSession = event.session;
          this.sessions.push(event.session);
          if (this.ignoreIncomingCall) {
            event.session.terminate();
            this.ignoreIncomingCall = false;
            return;
          }
          if (event.session.direction === 'incoming') {
            this.analytics.logEvent(ANALYTICS_EVENTS.received_call);
            this.listenIncomingSession(event.session);
            if (this.shouldAutoAnswer) {
              this.answer();
            } else if (!this.canBlockReceiveCalls()) {
              this.userMediaService.ringingSound();
            }
          }
        } else {
          if (!(this.user.enableMultiCall ?? false)) {
            event.session.terminate();
          } else {
            this.sessions.push(event.session);
            if (event.session.direction === 'incoming') {
              this.analytics.logEvent(ANALYTICS_EVENTS.received_call);
              this.listenIncomingSession(event.session);
            }
          }
        }
      });

      this.ua.start();
    } catch (error) {
      console.error(error);
    }
  }

  register(): void {
    if (this.ua) {
      this.ua.register();
    }
  }

  unregister(): void {
    if (this.ua) {
      this.ua.terminateSessions();
      this.ua.stop();
      this.ua.unregister();
      this.registerEvents.next('disconnected');
    }
  }

  forceRegister(settings: UaSettings) {
    this.ua = undefined;
    this.start(settings);
  }

  listenIncomingSession(session: RTCSession): void {
    session.on('progress', async () => {
      // Rejected call
      if (this.canBlockReceiveCalls()) {
        this.rejectIncomingCall();
        return;
      }

      if (session.id === this.currentCallSession.id) {
        if (session.remote_identity.uri.user === 'vvx-e1-srs') {
          this.callingEvents.next({
            destinationPhone: session.remote_identity.display_name,
            destinationName: 'Externo',
            local_display_name: session.local_identity.display_name,
            state: CallState.INCOMING_PROGRESS,
            type: 'incoming',
          });
        } else {
          this.callingEvents.next({
            destinationPhone: session.remote_identity.uri.user,
            destinationName: session.remote_identity.display_name
              ? session.remote_identity.display_name
              : session.remote_identity.uri.user,
            local_display_name: session.local_identity.display_name,
            state: CallState.INCOMING_PROGRESS,
            type: 'incoming',
          });
        }
      }
    });
    session.on('accepted', () => {
      this.userMediaService.pauseRing();
      if (session.id === this.currentCallSession.id) {
        this.callingEvents.next({
          destinationPhone: session.remote_identity.uri.user,
          destinationName: session.remote_identity.display_name
            ? session.remote_identity.display_name
            : session.remote_identity.uri.user,
          local_display_name: session.local_identity.display_name,
          state: CallState.INCOMING_ACCEPTED,
          type: 'incoming',
        });
      }
    });
    session.on('confirmed', () => {
      if (session.id === this.currentCallSession.id) {
        this.callingEvents.next({
          destinationPhone: session.remote_identity.uri.user,
          destinationName: session.remote_identity.display_name
            ? session.remote_identity.display_name
            : session.remote_identity.uri.user,
          local_display_name: session.local_identity.display_name,
          state: CallState.INCOMING_CONFIRMED,
          type: 'incoming',
          voiceonly: true,
        });
      }

      if (!this.platform.isIOS()) {
        this.setSpeakerphoneAudio(false);
      }
    });
    session.on('ended', async (e: EndEvent) => {
      if (session.id === this.currentCallSession.id) {
        if (this.sessions.length > 1) {
          await this.changeToNewSession();
        } else {
          this.callingEvents.next({
            destinationPhone: '',
            destinationName: '',
            local_display_name: '',
            state: CallState.INCOMING_ENDED,
            type: 'incoming',
            isMultiLine: false,
          });
        }
      }

      this.removeSessionFromList(session);
      this.shouldAutoAnswer = false;

      this.stopTracksAfterEnd();
    });
    session.on('failed', async (e: EndEvent) => {
      if (session.id === this.currentCallSession.id) {
        if (this.sessions.length > 1) {
          await this.changeToNewSession();
        } else {
          this.callingEvents.next({
            destinationPhone: session.remote_identity.uri.user,
            destinationName: session.remote_identity.display_name
              ? session.remote_identity.display_name
              : session.remote_identity.uri.user,
            local_display_name: session.local_identity.display_name,
            state: CallState.INCOMING_FAILED,
            type: 'incoming',
            isMultiLine: false,
          });
        }
      }

      this.userMediaService.pauseAllSounds();
      this.removeSessionFromList(session);
      this.shouldAutoAnswer = false;
      this.stopTracksAfterEnd();
    });
    session.on(
      'peerconnection',
      (data: { peerconnection: RTCPeerConnection }) => {
        data.peerconnection.ontrack = (event: RTCTrackEvent) => {
          this.callingEvents.next({
            event,
            state: CallState.STREAM,
          });
        };
      }
    );
  }

  // AsyncContacts
  private async changeToNewSession(options?: {
    session?: RTCSession;
    isOutgoing?: boolean;
    state?: CallState;
  }) {
    const isOutgoing = options?.isOutgoing ?? false;
    const session: RTCSession = options?.session ?? this.currentCallSession;
    const isMuted: boolean = session.isMuted().audio;
    const currentUserExt: string = this.utilsService.normalizePhone(
      session.remote_identity.uri.user
    );
    let currentUser: User = this.contacts.getByExtension(currentUserExt);
    if (!currentUser) {
      currentUser = {
        displayName:
          await this.externalContacts.getExternalContactNameByPhoneNumber(
            currentUserExt
          ),
        email: '',
        extension: currentUserExt,
        id: '',
        passwordExten: '',
        phoneNumber: currentUserExt,
        photoUrl: '',
        terms: null,
      };
    }

    // Get self contact
    let selfContact = await lastValueFrom(this.auth.user$.pipe(first()));

    // Update contact name
    selfContact.displayName = getUserNameWithSector(selfContact);

    this.currentCallSession = this.sessions.find(
      (sess) => sess.id !== session.id
    );
    const remoteContact = await this.getRemoteContact(
      this.currentCallSession.remote_identity.uri.user,
      this.currentCallSession.remote_identity.display_name ??
      this.currentCallSession.remote_identity.uri.user
    );


    const multiLineData: MultiLineCallData = {
      currentSessionUser: currentUser,
      selfData: selfContact,
      newSessionUser: remoteContact,
      userFrom: currentUser,
      tenantId: this.tenant.tenant?.id,
      isExternal:
        (!remoteContact?.displayName ?? false) ||
        remoteContact?.displayName === 'Externo',
    };

    const holdData: HoldData = {
      selfData: selfContact,
      remoteData: remoteContact,
      userInHold: remoteContact,
      tenantId: this.tenant.tenant?.id,
    };

    this.isSessionMuted(this.currentCallSession, isMuted);
    const type: string = isOutgoing ? 'outgoing' : 'incoming';
    const isEstablished = this.currentCallSession.isEstablished();

    if (!isOutgoing) {
      if (isEstablished) {
        this.socket.sendMessage([SOCKET_TOPIC.multiLine_call, multiLineData]);
        this.toggleHold(holdData);
      } else {
        await this.multiLineCall(
          this.currentCallSession,
          holdData,
          multiLineData,
          null,
          isMuted
        );
      }

      // this.callingEvents.next({
      //   destinationPhone: '',
      //   destinationName: '',
      //   local_display_name: '',
      //   state,
      //   type,
      //   isMultiLine: false,
      // });
    } else {
      this.multiLineOutgoingCall(
        this.currentCallSession,
        holdData,
        multiLineData,
        isEstablished,
        isMuted
      );

      // this.callingEvents.next({
      //   isEstablished,
      //   state: options.state,
      //   type,
      //   isMultiLine: true,
      // });
    }
  }

  // AsyncContacts
  private async getRemoteContact(
    destinationPhone: string,
    destinationName: string
  ): Promise<User> {
    try {
      let user: User;
      const normalizedPhone = this.utilsService.normalizePhone(destinationPhone);

      user = this.contacts.getByExtension(normalizedPhone);

      if (user) {
        user.displayName = getUserNameWithSector(user);
        return user;
      }

      destinationName =
        await this.externalContacts.getExternalContactNameByPhoneNumber(
          normalizedPhone
        );
      return {
        displayName: destinationName ?? 'Externo',
        email: '',
        extension: destinationPhone,
        id: '',
        passwordExten: '',
        phoneNumber: destinationPhone,
        photoUrl: '',
        terms: null,
      };
    } catch (err) {
      console.log(err)
    }
  }

  async stopTracksAfterEnd() {
    if (this.sessions.length === 0) {
      // await this.userMediaService.fetchDevices();
      this.stopTracks();
    }
  }

  /**
   * Reject incoming call
   */
  private rejectIncomingCall(): void {
    // Stop ring
    console.log('Call rejected');
    // Reject call
    this.hangup(this.currentCallSession);
  }

  /**
   * Check if user can receive calls
   */
  private canBlockReceiveCalls(): boolean {
    const isDnd = this.userPresenceService.isUserNotDisturb();
    const hasActiveConference = this.jitsi.hasActiveConference();
    return hasActiveConference || isDnd;
  }

  async call(target: string, displayName?: string): Promise<void> {
    if (this.jitsi.hasActiveConference()) {
      // Open dialog
      const dialogRef = this.dialog.open(SimpleDialogComponent, {
        panelClass: 'simple-dialog',
        data: {
          title: 'ongoingConference',
          desc: 'initNewConference',
          btnText: 'yes',
        },
      });

      // Get dialog resp
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((resp) => {
          if (resp) {
            // Close current conference
            this.jitsi.hangUp();

            // Init Mobi Phone Call
            setTimeout(() => {
              this.initCall(target, displayName);
            }, 1000);
          }
        });
    } else this.initCall(target, displayName);
  }

  private async initCall(target: string, displayName?: string) {
    const domain: string = this.clusterService.cluster.domain;
    this.destinationName = displayName;
    this.destinationPhone = target;

    // Form constraints for call with device id from local storage
    const constraints: MediaStreamConstraints = {
      audio: {
        deviceId: {
          exact: this.userMediaService.getDefaultDeviceId('microphone'),
        },
      },
      video: false,
    };

    // Get audio tracks
    const audio = (
      await this.mediaDevices.getUserMedia(constraints)
    ).getAudioTracks();

    // Get video tracks (silent)
    const video = this.silentVideo({});

    // Create media stream
    const mediaStream = new MediaStream([audio[0], video]);

    // Set local streams
    this.localStreams = mediaStream;

    const options: CallOptions = {
      eventHandlers: this.callHandlers,
      mediaStream,
    };

    if (!this.isSessionEnabled) {
      this.ua.call(`sip:${target}@${domain}`, options);
    }
  }

  silentVideo({ width = 640, height = 480 } = {}): MediaStreamTrack {
    const canvas = Object.assign(document.createElement('canvas'), {
      width,
      height,
    });
    canvas.getContext('2d').fillRect(0, 0, width, height);
    const stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], { enabled: false });
  }

  async answer(): Promise<void> {
    if (this.jitsi.hasActiveConference()) {
      // Open dialog
      const dialogRef = this.dialog.open(SimpleDialogComponent, {
        panelClass: 'simple-dialog',
        data: {
          title: 'ongoingConference',
          desc: 'initNewConference',
          btnText: 'yes',
        },
      });

      // Get dialog resp
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((resp) => {
          if (resp) {
            // Close current call
            this.jitsi.hangUp();

            // Init new call
            setTimeout(() => {
              this.answerTheCall(this.currentCallSession);
            }, 1000);
          }
        });
    } else this.answerTheCall(this.currentCallSession);
  }

  async answerTheCall(session: RTCSession, muted?: boolean) {
    const audio = (
      await this.mediaDevices.getUserMedia({ audio: true, video: false })
    ).getAudioTracks();
    const video = this.silentVideo({});
    const mediaStream = new MediaStream([audio[0], video]);
    this.localStreams = mediaStream;
    const options: CallOptions = {
      mediaStream,
    };
    session.answer(options);
    this.isSessionMuted(session, muted);
  }

  // AsyncContacts
  async returnToCall(session: RTCSession, holdData: HoldData, muted?: boolean) {
    const remoteContact = await this.getRemoteContact(
      session.remote_identity.uri.user,
      session.remote_identity.display_name ?? session.remote_identity.uri.user
    );

    holdData.remoteData = remoteContact;
    holdData.userInHold = remoteContact;

    this.toggleHold(holdData, session);

    this.isSessionMuted(session, muted);
  }

  isSessionMuted(session: RTCSession, muted?: boolean): void {
    setTimeout(() => {
      if (muted) {
        session.mute();
      } else if (muted === false) {
        session.unmute();
      }
    }, 500);
  }

  hangup(session?: RTCSession, holdData?: HoldData): void {
    session ??= this.currentCallSession;
    if (session?.isInProgress() || session?.isEstablished()) {
      session.terminate({
        cause: session.id,
      });
    }
  }

  hangupMultiLineCall(session: RTCSession) {
    session.terminate({
      cause: session.id,
    });
  }

  // AsyncContacts
  async multiLineOutgoingCall(
    session: RTCSession,
    holdData: HoldData,
    multilineData: MultiLineCallData,
    established: boolean = false,
    isMuted?: boolean
  ) {
    this.socket.sendMessage([SOCKET_TOPIC.multiLine_call, multilineData]);
    isMuted ??= this.isAudioMuted();
    if (established) {
      await this.returnToCall(session, holdData, isMuted);
    } else {
      this.answerTheCall(session, isMuted);
    }
  }

  // AsyncContacts
  async multiLineCall(
    session: RTCSession,
    holdData: HoldData,
    multilineData: MultiLineCallData,
    established?: boolean,
    isMuted?: boolean
  ) {
    const isHolding = this.checkHolding(this.currentCallSession);
    this.socket.sendMessage([
      SOCKET_TOPIC.multiLine_call,
      { ...multilineData, isHolding },
    ]);
    if (!isHolding) {
      this.hold(holdData);
    }
    isMuted ??= this.isAudioMuted();
    this.currentCallSession = session;
    established ??= session.isEstablished();
    if (!established) {
      this.answerTheCall(session, isMuted);
    } else {
      await this.returnToCall(session, holdData, isMuted);
    }
  }

  // answerAndHold(session: RTCSession) {
  //   session.answer({ extraHeaders: ['AnsAndHold'] });
  //   session.hold();
  // }

  private checkHolding(session: RTCSession): boolean {
    return session.isOnHold().local || session.isOnHold().remote;
  }

  hold(data: HoldData, session?: RTCSession): void {
    session ??= this.currentCallSession;

    this.socket.sendMessage([SOCKET_TOPIC.hold, data]);
    session.hold();
  }

  unhold(data: HoldData, session?: RTCSession): void {
    session ??= this.currentCallSession;

    this.socket.sendMessage([SOCKET_TOPIC.unhold, data]);
    session.unhold();
  }

  toggleHold(data: HoldData, session?: RTCSession): void {
    session ??= this.currentCallSession;

    if (session.isOnHold().local) {
      this.socket.sendMessage([SOCKET_TOPIC.unhold, data]);
      session.unhold();
    } else {
      this.socket.sendMessage([SOCKET_TOPIC.hold, data]);
      session.hold();
    }
  }

  refer(target: string): void {
    const domain = this.clusterService.cluster.domain;
    this.currentCallSession.refer(`sip:${target}@${domain}`);
    this.hangup(this.currentCallSession);
  }

  toggleMute(): void {
    if (this.currentCallSession.isMuted().audio) {
      this.currentCallSession.unmute();
    } else {
      this.currentCallSession.mute();
    }
  }

  isAudioMuted(session?: RTCSession): boolean {
    session ??= this.currentCallSession;
    return session.isMuted().audio;
  }

  async toggleVideo(): Promise<MediaStream> {
    if (!this.video) {
      const streams = await this.enableCamera();
      if (streams) {
        this.video = true;
      }
      return streams;
    } else {
      this.video = false;
      return this.disableCamera();
    }
  }

  /**
   * Starts sending video to remote peer
   */
  async enableCamera(): Promise<MediaStream> {
    const constraints = this.getCameraConstraints();
    const streams = await this.mediaDevices.getUserMedia(constraints);
    // if streams empty, permission has been denied
    if (!streams) {
      return null;
    }
    const senders = this.currentCallSession.connection.getSenders();
    const videoSender = senders.find((sender) => sender.track.kind === 'video');
    videoSender.replaceTrack(streams.getVideoTracks()[0]);
    return streams;
  }

  /**
   * Stops sending video to remote peer
   */
  async disableCamera(): Promise<MediaStream> {
    const senders = this.currentCallSession.connection.getSenders();
    const videoSender = senders.find((sender) => sender.track.kind === 'video');
    videoSender.track.stop();
    await videoSender.replaceTrack(this.silentVideo());
    return null;
  }

  /**
   * Starts sending screen video to remote peer
   * @returns Screen sharing streams
   */
  async enableScreenShare(): Promise<MediaStream> {
    const senders = this.currentCallSession.connection.getSenders();
    const constraints = this.getScreenShareConstraints();
    const streams = await navigator.mediaDevices.getDisplayMedia(constraints);
    const videoSender = senders.find((sender) => sender.track.kind === 'video');
    videoSender.replaceTrack(streams.getVideoTracks()[0]);
    this.captureStreams = streams;
    return streams;
  }

  /**
   * Stops sending screen video to remote peer
   * @param captureStreams Screen sharing stream to stop sending
   */
  async disableScreenShare(captureStreams: MediaStream): Promise<void> {
    const senders = this.currentCallSession.connection.getSenders();
    const videoSender = senders.find((sender) => sender.track.kind === 'video');
    const screenTracks = captureStreams ? captureStreams.getTracks() : [];
    screenTracks.forEach((track) => track.stop());
    captureStreams = null;
    await videoSender.replaceTrack(this.silentVideo());
  }

  /**
   * Get screen share contraints
   * @returns Screen share constraints
   */
  private getScreenShareConstraints(): MediaStreamConstraints {
    return {
      video: {
        width: {
          max: 1920,
        },
        height: {
          max: 1080,
        },
        frameRate: 5,
      },
    };
  }

  /**
   * Get sessions list
   * @returns Sessions list
   */
  getSessionsList(): RTCSession[] {
    return this.sessions;
  }

  /**
   * Get current call session
   * @returns Current Call Session
   */
  getCurrentCallSession(): RTCSession {
    return this.currentCallSession;
  }

  /**
   * Check if dialer is open
   */
  public isDialerOpen(value: any): boolean {
    const callingOptions = [
      CallState.CONNECTING,
      CallState.PROGRESS,
      CallState.CONFIRMED,
      CallState.INCOMING_PROGRESS,
      CallState.INCOMING_ACCEPTED,
      CallState.INCOMING_CONFIRMED,
      CallState.STREAM,
    ];

    return !!callingOptions.includes(value?.state);
  }

  /**
   * Get camera contraints
   * @returns Camera constraints
   */
  private getCameraConstraints(): MediaStreamConstraints {
    return {
      video: {
        advanced: [
          {
            frameRate: {
              min: 30,
            },
          },
          {
            height: {
              min: 720,
            },
          },
          {
            width: {
              min: 1280,
            },
          },
          {
            frameRate: {
              max: 60,
            },
          },
          {
            width: {
              max: 1280,
            },
          },
          {
            height: {
              max: 720,
            },
          },
          {
            aspectRatio: {
              exact: 1.77778,
            },
          },
        ],
      },
    };
  }

  electronScreenShare(stream: MediaStream) {
    if (this.currentCallSession.connection.getTransceivers().length > 1) {
      this.currentCallSession.connection.getTransceivers()[1].direction =
        'sendrecv';
      this.currentCallSession.connection
        .getTransceivers()[1]
        .sender.replaceTrack(stream.getTracks()[0]);
    } else {
      this.currentCallSession.connection.addTrack(
        stream.getTracks()[0],
        stream
      );
    }

    stream.getTracks()[0].onended = () => {
      this.disableScreenShare(stream);
    };

    this.currentCallSession.renegotiate();
  }

  sendDTMF(digit: string): void {
    this.currentCallSession.sendDTMF(digit);
  }

  setSpeakerphoneAudio(speakerphone: boolean): void {
    navigator.mediaDevices.enumerateDevices().then((res) => {
      let sinkId = '';
      const outputDevices = res.filter((d) => d.kind === 'audioinput');
      if (speakerphone) {
        sinkId =
          outputDevices.length > 1
            ? outputDevices[1].deviceId
            : outputDevices[0].deviceId;
      } else {
        sinkId =
          outputDevices.length > 2
            ? outputDevices[2].deviceId
            : outputDevices[0].deviceId;
      }
      const constraints = {
        audio: { deviceId: sinkId ? { exact: sinkId } : undefined },
        video: false,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream: MediaStream) => {
          stream.getTracks().forEach((track) => track.stop());
        });
    });
  }

  getSenderAudio() {
    return this.currentCallSession
      ? this.currentCallSession.connection.getSenders()[0]
      : null;
  }

  getSenderVideo() {
    return this.currentCallSession
      ? this.currentCallSession.connection.getSenders()[1]
      : null;
  }

  stopRing(): void {
    this.userMediaService.pauseRing();
  }

  stopTracks(): void {
    const selfVideo: HTMLMediaElement = document.getElementById(
      'selfVideo'
    ) as HTMLMediaElement;
    const selfTracks = selfVideo?.srcObject
      ? (selfVideo.srcObject as MediaStream).getTracks()
      : [];

    const remoteVideo: HTMLMediaElement = document.getElementById(
      'remoteVideo'
    ) as HTMLMediaElement;
    const remoteTracks = remoteVideo?.srcObject
      ? (remoteVideo.srcObject as MediaStream).getTracks()
      : [];

    const remoteAudio: HTMLMediaElement = document.getElementById(
      'audio-remote'
    ) as HTMLAudioElement;

    const localTracks = this.localStreams ? this.localStreams.getTracks() : [];

    const remoteAudioTracks = remoteAudio?.srcObject
      ? (remoteAudio.srcObject as MediaStream).getTracks()
      : [];

    const screenTracks = this.captureStreams
      ? this.captureStreams.getTracks()
      : [];

    screenTracks.forEach((track) => {
      track.stop();
    });

    selfTracks.forEach((track) => {
      track.stop();
    });

    remoteTracks.forEach((track) => {
      track.stop();
    });

    remoteAudioTracks.forEach((track) => {
      track.stop();
    });

    localTracks.forEach((track) => {
      track.stop();
    });

    if (selfVideo !== null) {
      selfVideo.srcObject = null;
    }
    if (remoteVideo !== null) {
      remoteVideo.srcObject = null;
    }
    if (remoteAudio !== null) {
      remoteAudio.srcObject = null;
    }
  }

  get isSessionEnabled(): boolean {
    return (
      this.currentCallSession?.isInProgress() ||
      this.currentCallSession?.isEstablished()
    );
  }

  getUa(): UA {
    return this.ua;
  }

  private removeSessionFromList(session?: RTCSession) {
    if (this.sessions.length === 0) return;

    session ??= this.currentCallSession;
    const id = this.sessions.findIndex((sess) => sess.id === session?.id);
    if (id !== -1) {
      this.sessions.splice(id, 1);
    }
    if (this.sessions.length === 0) {
      this.currentCallSession = undefined;
    }
  }
}
