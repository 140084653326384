import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  JsSIPService,
  NotifierService,
  PlatformService,
  UserMediaService
} from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { DEVICES_TYPES } from '@shared/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-devices-settings',
  templateUrl: './devices-settings.component.html',
  styleUrls: ['./devices-settings.component.scss']
})
export class DevicesSettingsComponent implements OnInit, OnDestroy {
  // Calling
  public isCalling: boolean = false;

  // Devices
  public inputDevices: MediaDeviceInfo[] = [];
  public outputDevices: MediaDeviceInfo[] = [];
  public videoDevices: MediaDeviceInfo[] = [];

  // Form
  public devicesSettingsForm = this.fb.group({
    video: [''],
    microphone: ['', [Validators.required]],
    speakerphone: ['', [Validators.required]],
    ringtone: [''],
  });
  public canRingOnAdditionalDevice: boolean = false;

  // Subscriptions
  private subscriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private jssip: JsSIPService,
    public platform: PlatformService,
    private translate: TranslateService,
    private notifier: NotifierService,
    private userMediaService: UserMediaService,
    public dialogRef: MatDialogRef<DevicesSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public videoMuted: boolean
  ) { }

  ngOnInit(): void {
    // Set can ring on additional device
    this.initCanRingOnAdditionalDevice();

    // Init devices
    this.userMediaService
      .fetchDevices()
      .then(() => this.initDevices()); // Init devices

    // Listening calling changes
    this.listeningCallingChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Toggle ring on additional device
   */
  public toggleRingOnAdditionalDevice(): void {
    // Toggle can ring on additional device
    this.canRingOnAdditionalDevice = !this.canRingOnAdditionalDevice;

    // Set can ring on additional device in local storage
    localStorage.setItem('canRingOnAdditionalDevice', this.canRingOnAdditionalDevice.toString());

    // If can ring on additional device enabled, set default ringtone device
    this.canRingOnAdditionalDevice
      ? this.devicesSettingsForm.patchValue({ ringtone: this.userMediaService.getDefaultDeviceId('ringtone') })
      : this.handleSecondaryRing(); // Disable secondary ring
  }

  /**
   * Set input devices
   */
  public initDevices(): void {
    // Split devices
    this.inputDevices = [...this.userMediaService.inputDevices];
    this.outputDevices = [...this.userMediaService.outputDevices];
    this.videoDevices = [...this.userMediaService.videoDevices];

    // Set default devices
    this.devicesSettingsForm.patchValue({ video: this.userMediaService.getDefaultDeviceId('video') });
    this.devicesSettingsForm.patchValue({ microphone: this.userMediaService.getDefaultDeviceId('microphone') });
    this.devicesSettingsForm.patchValue({ speakerphone: this.userMediaService.getDefaultDeviceId('speakerphone') });
    this.devicesSettingsForm.patchValue({ ringtone: this.userMediaService.getDefaultDeviceId('ringtone') });
  }

  /**
   * Init can ring on additional device
   */
  private initCanRingOnAdditionalDevice(): void {
    // Set can ring on additional device
    this.canRingOnAdditionalDevice = localStorage.getItem('canRingOnAdditionalDevice') === 'true';
  }

  /**
   * Handle video
   * @param {string} deviceId Device id
   */
  private handleVideo(deviceId: string) {
    // If device id is empty, return
    if (!deviceId) return;

    // Set video device
    localStorage.setItem(DEVICES_TYPES.VIDEO, deviceId);
  }

  /**
   * Handle microphone
   * @param {string} deviceId Device id
   */
  private handleMicrophone(deviceId: string) {
    // If device id is empty, return
    if (!deviceId) return;

    // Set microphone device
    localStorage.setItem(DEVICES_TYPES.INPUT, deviceId);
  }


  /**
   * Handle speakerphone
   * @param {string} deviceId Device id
   */
  private async handleSpeakerphone(deviceId: string) {
    // Set speakerphone device
    localStorage.setItem(DEVICES_TYPES.OUTPUT, deviceId);

    // If device id is empty, return
    if (!deviceId) return;

    // Get speakerphone audio
    const audio = document.getElementById('audio-remote') as any;
    const ringAudio = document.getElementById('audio-ring') as any;

    // Set speakerphone audio
    try {
      if (audio.sinkId !== undefined && ringAudio.sinkId !== undefined) {
        await audio.setSinkId(deviceId);
        await ringAudio.setSinkId(deviceId);
      } else {
        console.warn('Navegador não suporta seleção de dispositivos de saída!');
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Handle secondary ring
   * @param {string} deviceId Device id
   */
  private handleSecondaryRing(deviceId: string = "") {
    // Set ringtone device
    if (deviceId) localStorage.setItem(DEVICES_TYPES.SECONDARY_RING, deviceId);

    // Get secondary ring audio
    const secondaryRingAudio = document.getElementById('secondary-audio-ring') as any;

    // Get secondary notification audio
    const secondaryNotificationAudio = document.getElementById('secondary-audio-notification') as any;

    // Set secondary ring audio
    secondaryRingAudio.sinkId !== undefined
      ? secondaryRingAudio.setSinkId(deviceId).catch((err: any) => console.error(err))
      : console.warn('Navegador não suporta seleção de dispositivos de saída!');;

    // Set secondary notification audio
    secondaryNotificationAudio.sinkId !== undefined
      ? secondaryNotificationAudio.setSinkId(deviceId).catch((err: any) => console.error(err))  // Set secondary notification audio
      : console.warn('Navegador não suporta seleção de dispositivos de saída!');
  }

  /**
   * Listening calling changes
   */
  private listeningCallingChanges(): void {
    const callingEventsSub = this.jssip
      .callingEvents
      .subscribe(value => this.isCalling = this.jssip.isDialerOpen(value))

    this.subscriptions.add(callingEventsSub);
  }

  /**
   * Apply devices settings
   */
  public apply() {
    // Get microphone device id
    const microphoneId = !!this.devicesSettingsForm.get('microphone')?.value
      ? this.devicesSettingsForm.get('microphone').value
      : this.userMediaService.getDefaultDeviceId('microphone');

    // Get speakerphone device id
    const speakerphoneId = !!this.devicesSettingsForm.get('speakerphone')?.value
      ? this.devicesSettingsForm.get('speakerphone').value
      : this.userMediaService.getDefaultDeviceId('speakerphone');

    // Get video device id
    const videoId = !!this.devicesSettingsForm.get('video')?.value
      ? this.devicesSettingsForm.get('video').value
      : this.userMediaService.getDefaultDeviceId('video');

    // Get ringtone device id
    const ringtoneId = !!this.devicesSettingsForm.get('ringtone')?.value
      ? this.devicesSettingsForm.get('ringtone').value
      : this.userMediaService.getDefaultDeviceId('ringtone');

    // Set devices
    this.handleMicrophone(microphoneId);
    this.handleSpeakerphone(speakerphoneId);
    this.handleVideo(videoId);
    this.handleSecondaryRing(ringtoneId);

    // If not calling, return
    if (!this.isCalling) {
      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'devicesUpdatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });

      // Return
      return;
    };

    // Set video muted
    this.videoMuted = this.videoMuted !== null
      ? this.videoMuted
      : !this.jssip.video;

    // Get constraints
    const constraints = this.userMediaService.getConstraints(this.videoMuted);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream: MediaStream) => {
        if (
          this.platform.isMobile() ||
          (!this.platform.isMobile && this.platform.isFirefox())
        ) {
          stream.getAudioTracks().forEach((track) => track.stop());
        } else {
          stream.getAudioTracks().forEach((audioTrack: MediaStreamTrack) => {
            this.jssip.getSenderAudio() !== null
              ? this.jssip.getSenderAudio().replaceTrack(audioTrack)
              : audioTrack.stop();
            if (
              this.jssip.isAudioMuted() &&
              this.jssip.getSenderAudio() !== null
            ) {
              if (!audioTrack.muted) {
                audioTrack.enabled = false;
              }
            }
          });
        }

        const selfVideo: HTMLMediaElement = document.getElementById(
          'selfVideo'
        ) as HTMLMediaElement;
        stream.getAudioTracks().forEach((track) => {
          stream.removeTrack(track);
        });
        stream.getVideoTracks().forEach((videoTrack: MediaStreamTrack) => {
          this.jssip.getSenderVideo() !== null
            ? this.jssip.getSenderVideo().replaceTrack(videoTrack)
            : videoTrack.stop();
          if (this.videoMuted && this.jssip.getSenderVideo() !== null) {
            if (!videoTrack.muted) {
              videoTrack.enabled = false;
            }
          }
        });
        selfVideo.srcObject = stream;
      })
      .catch((e) => {
        console.error(e);
      });

    // Show notification
    this.notifier.showNotification({
      type: 'success',
      message: 'devicesUpdatedSuccessfully',
      actionText: 'OK',
      panelClass: 'success',
    });

    this.dialogRef?.close();
  }
}
