/**
 * Remove remove empty values from an obj
 * @param {T} obj Obj with undefined values in it
 * @returns {T} obj without undefined values in it
 */
export const removeEmptyValues = <T>(obj: T): T => {
  const aux = { ...obj }
  Object.keys(aux).forEach((key) => {
    if (!aux[key]) delete aux[key];
  });
  return aux;
};
