export interface LcsEvent {
  uuid?: string;
  date?: any;
  userId: string,
  userEmail?: string,
  companyId: string,
  device: string,
  description: string,
  type: string,
  value: any,
}

export interface LcsEventsFilter {
  page?: number;
  limit?: number;
  initialDate?: string;
  finalDate?: string;
  userEmail?: string,
  companyId?: string,
  device?: string,
  description?: string,
  type?: any,
}

export interface LcsEventsSearchResult {
  events: LcsEvent[];
  context: any;
}
