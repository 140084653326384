<div class="group-header" [ngClass]="{ dark: themeService.isDarkTheme(), clickable: clickable }"
  (click)="showGroupInfo()">
  <app-avatar type="group" class="contact-avatar" [class.img-small]="isSmall" [photoUrl]="getAvatar()">
  </app-avatar>

  <div class="list-text" *ifViewportSize="['medium', 'large']">
    <span class="list-text-header medium-text-regular" [class.small-text-regular]="isSmall">{{ group.name }}</span>
    <span *ngIf="!isTyping() && showMembers" class="list-text-desc normal-text-regular" [class.with-auto]="isSmall"
      [class.small-text-regular]="isSmall">
      {{ getGroupTotalMembers() }} {{'members' | translate }}
      <app-meeting-info [roomId]="group.roomId"></app-meeting-info>
    </span>
    <app-typing class="list-text-desc normal-text-regular" [class.small-text-regular]="isSmall" *ngIf="isTyping()"
      [roomId]="group.roomId" [contacts]="contacts">
    </app-typing>
  </div>

  <div class="list-text" *ifViewportSize="['small']">
    <span class="list-text-header small-text-regular">{{ group.name }}</span>
    <span *ngIf="!isTyping() && showMembers" class="list-text-desc small-text-regular" [class.with-auto]="isSmall">
      {{ getGroupTotalMembers() }} {{'members' | translate }}
      <span *ngIf="roomMeet">
        | {{ 'meeting' | translate }}: {{ getMeetingDate() | timelineDateFormatPipe:'date':true}} {{ '\u2022' }}
        {{getMeetingStartHour() | hourFormatPipe}} {{ 'hoursTo' | translate }} {{ getMeetingFinalHour() |
        hourFormatPipe}}
      </span>
    </span>
    <app-typing class="list-text-desc small-text-regular" *ngIf="isTyping()" [roomId]="group.roomId"
      [contacts]="contacts">
    </app-typing>
  </div>

</div>
