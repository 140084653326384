import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AnalyticsSharedService,
  AuthService,
  ContactService,
  ExternalContactsService,
  JsSIPService,
  NotifierService
} from '@core/services';
import { ANALYTICS_EVENTS } from '@shared/enums';
import { Contact } from '@shared/models';
import { isExternalRouteExist } from '@shared/utils';
import { SelectExternalRouteComponent } from '../select-external-route/select-external-route.component';

@Component({
  selector: 'app-dial-voice-call-button',
  templateUrl: './dial-voice-call-button.component.html',
  styleUrls: ['./dial-voice-call-button.component.scss'],
})
export class DialVoiceCallButtonComponent implements OnInit {
  @Input() target: string;
  @Input() displayName: string;

  clicked = false;
  externalRouteNumber: number | string[];
  constructor(
    private jssip: JsSIPService,
    private contacts: ContactService,
    private notifier: NotifierService,
    private authService: AuthService,
    public dialog: MatDialog,
    private analytics: AnalyticsSharedService,
    private externalContactsService: ExternalContactsService
  ) { }

  ngOnInit(): void {
    this.externalRouteNumber = this.authService.getExternalRoute();
  }

  async handleCall(ev: PointerEvent): Promise<void> {
    let serviceNumberFound: Contact | undefined;

    this.externalContactsService.externalContacts$.value.map((externalContact) => {
      externalContact.phoneNumbers.map((phoneNumber) => {
        const number = phoneNumber.number;

        if (this.target == number && phoneNumber.description == 'serviceNumber' && this.target.length <= 5) {
          serviceNumberFound = externalContact;
        }
      })
    })

    if (this.clicked) { return; }
    else { this.clicked = true; }

    if (this.target && this.target.match(/[0-9\*#\+]/g)) {
      if (this.displayName && !serviceNumberFound) {
        return this.jssip.call(this.target, this.displayName);
      }

      const contact = this.contacts.getByExtension(this.target)

      // Send event to analytics
      this.analytics.logEvent(ANALYTICS_EVENTS.call_by_keyboard)

      if (contact && !serviceNumberFound) {
        return this.jssip.call(this.target, contact.displayName);
      }

      if (this.target.length <= 5 && !serviceNumberFound) {
        return this.jssip.call(this.target, this.target);
      }

      // External call
      if (isExternalRouteExist(this.externalRouteNumber)) {
        const isOnlyOneRoute = Array.isArray(this.externalRouteNumber)
          ? this.externalRouteNumber.length == 1
          : true;
        const firstExternalRoute = Array.isArray(this.externalRouteNumber)
          ? this.externalRouteNumber[0]
          : this.externalRouteNumber;

        if (isOnlyOneRoute) {
          this.target = `${firstExternalRoute}${this.target}`;
          return this.jssip.call(this.target, serviceNumberFound?.name ? serviceNumberFound?.name : 'Externo');
        }
        else {
          // Open modal
          const dialogRef = this.dialog.open(SelectExternalRouteComponent, {
            width: '400px',
            data: { externalRoute: this.externalRouteNumber }
          });
          dialogRef.afterClosed()
            .subscribe(result => {
              if (result) {
                this.target = `${result.externalRoute}${this.target}`;
                return this.jssip.call(this.target, serviceNumberFound?.name ? serviceNumberFound?.name : 'Externo');
              } else {
                this.clicked = false;
              }
            });
        }
      } else {
        return this.jssip.call(this.target, serviceNumberFound?.name ? serviceNumberFound?.name : 'Externo');
      }
    } else {
      this.notifier.showNotification({
        type: 'Erro',
        message: 'Telefone inválido',
        actionText: 'OK',
        panelClass: 'error',
      });
      this.clicked = false;
    }
  }
}
