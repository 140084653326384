import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MeetingsService } from '@core/services';
import { MeetingRoom } from '@shared/models';

@Component({
  selector: 'app-meeting-info',
  templateUrl: './meeting-info.component.html',
  styleUrls: ['./meeting-info.component.scss']
})
export class MeetingInfoComponent implements OnInit {
  // Room ID
  @Input() roomId: string;

  // Meeting Room
  public roomMeet: MeetingRoom;

  constructor(private meetService: MeetingsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roomId) this.setRoomMeet();
  }

  ngOnInit(): void {
    this.setRoomMeet();
  }

  /**
   * Set Room Data
   */
  private setRoomMeet(): void {
    this.roomMeet = this.meetService.getMeetFromRoom(this.roomId)
  }

  /**
   * Get Room's Meeting Date
   * @returns {Date} The meeting date.
   */
  public getMeetingDate(): Date {
    const dateMillis = this.roomMeet?.meetDate.seconds * 1000;
    const date = new Date(dateMillis);
    return date;
  }

  /**
   * Get Room's Start hour
   * @returns {string} The start hour of the meeting.
   */
  public getMeetingStartHour(): string {
    const startHour = this.roomMeet?.startHour;
    return startHour;
  }

  /**
   * Get Room's Final hour
   * @returns {string} The final hour of the meeting.
   */
  public getMeetingFinalHour(): string {
    const finalHour = this.roomMeet?.finalHour;
    return finalHour;
  }

}
