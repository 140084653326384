import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { NotifierService } from '@core/services';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialog implements OnInit {
  croppedImage: string = '';
  canvasRotation: number = 0;

  constructor(
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public imageUrl: string,
    public dialogRef: MatDialogRef<ImageCropperDialog>
  ) { }

  ngOnInit(): void { }

  /**
   * Image cropped
   * @param {ImageCroppedEvent} event Image Cropped Event
   */
  public imageCropped(event: ImageCroppedEvent) {
    this.compressImage(event.base64, 512, 512)
      .then(resp => this.croppedImage = resp);
  }

  /**
   * Compress an img
   * @param {string} src img base64
   * @param {number} newWidth New Width
   * @param {number} newHeight New Height
   * @returns {Promise<string>} Img compressed
   */
  public compressImage(src: string, newWidth: number, newHeight: number): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL('image/jpeg', 0.8);
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  loadImageFailed() {
    // show message
    this.notifier.showNotification({
      type: 'Erro',
      actionText: '',
      message: 'Falha ao carregar imagem',
      panelClass: 'error',
    });
    this.dialogRef.close();
  }

  // transform(data: ImageTransform) {
  //   console.log('Ev:', data);
  //   this.transformData = data;
  //   this.cdr.detectChanges();
  // }

  save(): void {
    this.dialogRef.close(this.croppedImage);
  }

  // preview() {
  //   const previewDialogRef = this.dialog.open(PicturePreviewDialog, {
  //     width: '35%',
  //     disableClose: true,
  //     data: this.croppedImage,
  //   });

  //   previewDialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       this.dialogRef.close(this.croppedImage);
  //     }
  //   });
  // }
}
