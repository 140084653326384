import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  JitsiService,
  NotifierService,
  RoomService,
  ThemeService
} from '@core/services';

type Data = {
  canInitCall: boolean;
  roomId?: string;
  title?: string;
  warn?: string;
  desc?: string;
}

@Component({
  selector: 'app-conference-link',
  templateUrl: './conference-link.component.html',
  styleUrls: ['./conference-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConferenceLinkComponent implements OnInit {
  public link = '';

  constructor(
    public themeService: ThemeService,
    private jitsi: JitsiService,
    private notifierService: NotifierService,
    private roomService: RoomService,
    public dialogRef: MatDialogRef<ConferenceLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.generateLink();
  }

  /**
   * Generate Link
   */
  private generateLink(): void {
    this.link = !!this.data.roomId
      ? this.jitsi.getExternalLink(this.data.roomId)
      : this.jitsi.getExternalLink();
  }

  /**
   * Copy link
   */
  public copyLink(): void {
    try {
      // Copy the text
      navigator.clipboard.writeText(this.link);

      // Show notification
      this.notifierService.showNotification({
        type: 'success',
        message: 'copyToClipboard',
        actionText: 'OK',
        panelClass: 'success',
      });

    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Enter Conference
   */
  public enterConference(): void {
    this.jitsi.initCall(this.roomService.selfRoomId, 'external')
    this.dialogRef.close(true);
  }
}
