export enum EMOJI_SET_LIST {
  APPLE = 'apple',
  FIREFOX = 'twitter',
  GOOGLE = 'google'
};

export enum EmojiGroups {
  recent = "history",
  smileys_emotion = "smileysAndEmotion",
  people_body = "peopleAndBody",
  animals_nature = "animalsAndNature",
  food_drink = "foodAndDrink",
  travel_places = "travelAndPlaces",
  activities = "activities",
  objects = "objects",
  symbols = "symbols",
  flags = "flags",
}
