<h2 mat-dialog-title>{{ "autoStartMobiPhone" | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <div class="desk-setting-container">
      <mat-checkbox
        formControlName="autoLaunch"
        (change)="toggleAutoLaunch($event.checked)"
      >
        {{ "autoStartMobiPhoneWithSystem" | translate }}.
      </mat-checkbox>
      <mat-checkbox formControlName="startMinimized">
        {{ "startMinimized" | translate }}.
      </mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ "cancel" | translate }}</button>
  <button
    mat-button
    type="submit"
    class="save-btn"
    (click)="save()"
  >
    {{ "save" | translate }}
  </button>
</mat-dialog-actions>
