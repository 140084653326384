import { AppIntegrationIcon } from "@shared/models";

// The icon should be only an SVG file
export const INTEGRATION_TYPE_LIST = [
  {
    label: 'custom',
    value: ''
  },
  {
    label: 'Conecta',
    value: 'conecta',
    icon: 'conecta-icon',
    urlSuffix: '/user/agent',
  },
  {
    label: 'Agente',
    value: 'agente',
    icon: 'agente-icon',
    urlSuffix: '/agent/#/home',
  },
  {
    label: 'Supervisor',
    value: 'supervisor',
    icon: 'supervisor-icon',
    urlSuffix: '/supervisor/#/home',
  },
  {
    label: 'Phonebook',
    value: 'phonebook',
    icon: 'phonebook-icon',
    urlSuffix: '/phonebook/#/contacts',
  },
  {
    label: 'User Manager',
    value: 'userManager',
    icon: 'user-manager-icon',
    urlSuffix: '/user-manager/#/users/list',
  },
  {
    label: 'LCDR',
    value: 'lcdr',
    icon: 'lcdr-icon',
    urlSuffix: '/lcdr',
  },
  {
    label: 'LREC',
    value: 'lrec',
    icon: 'lrec-icon',
    urlSuffix: '/lrec',
  },
];

export const INTEGRATION_ICONS_LIST: AppIntegrationIcon[] = [
  { name: 'notifications_active', isSvg: false },
  { name: 'poll', isSvg: false },
  { name: 'share', isSvg: false },
  { name: 'call', isSvg: false },
  { name: 'clear_all', isSvg: false },
  { name: 'dialpad', isSvg: false },
  { name: 'email', isSvg: false },
  { name: 'ballot', isSvg: false },
  { name: 'filter_list', isSvg: false },
  { name: 'flag', isSvg: false },
  { name: 'send', isSvg: false },
  { name: 'waves', isSvg: false },
  { name: 'access_alarm', isSvg: false },
  { name: 'dvr', isSvg: false },
  { name: 'graphic_eq', isSvg: false },
  { name: 'widgets', isSvg: false },
  { name: 'border_all', isSvg: false },
  { name: 'bubble_chart', isSvg: false },
  { name: 'cloud', isSvg: false },
  { name: 'desktop_mac', isSvg: false },
  { name: 'headset', isSvg: false },
  { name: 'security', isSvg: false },
  { name: 'assistant_photo', isSvg: false },
  { name: 'flash_on', isSvg: false },
  { name: 'person', isSvg: false },
  { name: 'settings', isSvg: false },
  { name: 'business_center', isSvg: false },
  { name: 'attach_money', isSvg: false },
  { name: 'supervisor_account', isSvg: false },
  { name: 'chat', isSvg: false },
];
