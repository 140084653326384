<div class="player">
  <div>
    <button id="pause-btn" mat-icon-button *ngIf="isPlaying" (click)="onPause()">
      <mat-icon svgIcon="pause"></mat-icon>
    </button>
    <button id="play-btn" mat-icon-button *ngIf="!isPlaying" (click)="onPlay()">
      <mat-icon svgIcon="play"></mat-icon>
    </button>
  </div>

  <div class="audio-slider">
    <span>{{ currentTime$ | async }}</span>
    <mat-slider min="0" max="100" step="1" [ngClass]="{'mat-slider--white': whitePlayer}"
      [value]="currentProgress$ | async" (change)="onSliderChange($event)">
    </mat-slider>
    <span>{{ durationTime }}</span>
  </div>

  <div *ifViewportSize="['medium', 'large']">
    <ng-container *ngIf="!isChatPlayer">
      <mat-slider min="0" max="1" step="0.1" [ngClass]="{'mat-slider--white': whitePlayer}" [value]="volume"
        (change)="onVolumeSliderChange($event)">
      </mat-slider>
      <button mat-icon-button (click)="onToggleMute()">
        <mat-icon>{{ isMuted ? "volume_off" : "volume_up" }}</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<audio #player (timeupdate)="onTimeUpdate()" (pause)="onPause()" preload="metadata"></audio>