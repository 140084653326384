import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { IntegrationsService, PlatformService } from '@core/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntegrationGuard implements CanLoad {
  constructor(
    private integrationService: IntegrationsService,
    private router: Router,
    private platform: PlatformService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!segments[1]) {
      return this.router.parseUrl('/');
    }
    if (this.platform.isMobile()) {
      return this.router.parseUrl('/');
    }
    const id: string = segments[1]?.path;
    const validAppList: boolean = this.integrationService.getApps().length > 0;
    const validId: boolean =
      this.integrationService.getApps().find((app) => app.id === id) !==
      undefined;
    if (!validId) {
      return new Promise(async (resolve, reject) => {
        const app = await this.integrationService.findById(id);
        if (app) {
          this.integrationService.selectApp(app);
          this.integrationService.setApps([app]);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else if (validAppList && validId) {
      return true;
    } else {
      return this.router.parseUrl('/');
    }
  }
}
