<div class="dialog-description">
  <h1 class="title"> {{ data.title | translate }} </h1>
  <h3 class="subtitle"> {{ data.desc| translate }}. </h3>
</div>

<mat-dialog-content class="mat-typography" [ngClass]="{ dark: themeService.isDarkTheme() }">
  <p class="trick">
    <mat-icon svgIcon="new-document"></mat-icon>
    {{ "downloadFileModel" | translate }}
    <span (click)="downloadTemplate()"> &nbsp; {{ "clickingHere" | translate }} </span>.
  </p>

  <p class="trick">
    <mat-icon svgIcon="customer"></mat-icon>
    {{ "fillRequiredFieldToImport" | translate }}.
  </p>

  <p class="trick">
    <mat-icon svgIcon="cloud-computing"></mat-icon>
    {{ "uploadToUpdateContacts" | translate }}.
  </p>
  <p class="trick">
    <mat-icon>warning</mat-icon>
    {{ "importCSVLimit" | translate: {limit: data.limit} }}
  </p>
  <div class="dropzone" fileDrop (filesDropped)="handleDrop($event)">
    <input type="file" #fileDropRef id="fileDropRef" accept=".csv" (change)="fileBrowseHandler($event)" />
    <p class="large-text-regular"> {{ "dragAndDropHere" | translate }} </p>
    <label for="fileDropRef"> {{ "selectFileFromComputer" | translate }} </label>
  </div>

  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <div class="file-icon" style="width: 50px">
        <mat-icon svgIcon="new-document"></mat-icon>
      </div>
      <div class="info">
        <p class="trick single-file-name">
          {{ file?.name }}
        </p>
      </div>

      <div class="delete" (click)="deleteFile(i)">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
          <path fill="#B1B1B1" fill-rule="nonzero"
            d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
        </svg>
      </div>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end" [ngClass]="{ dark: themeService.isDarkTheme() }">
  <button mat-button mat-dialog-close> {{ "cancel" | translate }} </button>
  <button class="submit-button" color="primary" mat-raised-button type="submit" (click)="submit()">
    {{ "import" | translate }}
  </button>
</mat-dialog-actions>
