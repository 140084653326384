import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomEmojiService } from '@core/services';
import {
  A_HREF_REGEX,
  A_TAG_REGEX,
  EMAIL_TO_REGEX,
  IMG_SRC_REGEX,
  PHONE_REGEX
} from '@shared/constants';
import { Emoji } from '@shared/models';
import { getLinksFromHTML } from '@shared/utils';

@Pipe({
  name: 'replaceMsgContent'
})
export class ReplaceMsgContentPipe implements PipeTransform {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private customEmojiService: CustomEmojiService
  ) { }

  public transform(html: string, size: number): SafeHtml {
    return this.sanitizer
      .bypassSecurityTrustHtml(this.formatText(html, size));
  }

  /**
   * Format the HTML
   * @param html html formatted
   */
  public formatText(html: string, size: number = 19): string {
    // Get links on tags
    const linksOnTags = this.getLinkOnTags(html);

    // Ensure most html entities are parsed to unicode:
    const div = <Element>this.document.createElement('div');

    // Replace html entities
    div.innerHTML = html;

    // Get the html
    html = div.innerHTML;

    // Replace a tags
    html = html
      // Replace zero width joins with their unicode representations
      .replace(/&zwj;/g, '\u200d')
      // Replace emojis to imgs
      .replace(this.customEmojiService.emojiRegex, (unicodeEmoji) => this.replaceEmoji(unicodeEmoji, size))
      // Replace links
      .replace(A_TAG_REGEX, (link) => this.replaceLinks(link, linksOnTags));

    // Return HTML
    return html;
  }

  /**
   * Replace emojis on the html
   * @param {string} unicodeEmoji Unicode
   * @param {number} emojiSize emoji size
   * @returns {string} Html
   */
  private replaceEmoji(unicodeEmoji: string, emojiSize: number): string {
    // Get emoji hex code
    const hexCode = this.customEmojiService.getHexCode(unicodeEmoji)?.toLocaleLowerCase();

    // Get emoji
    const emoji = this.customEmojiService.findEmojiByHexCode(hexCode);

    // Not found
    if (!emoji) return unicodeEmoji;

    // Return
    return this.createEmojiElem(emoji, emojiSize).outerHTML;
  }

  /**
   * Replace links on the html
   * @param {string} link URL
   * @param {string[]} tags Tags that should not be replaced
   * @returns {string} Html
   */
  private replaceLinks(link: string, tags: string[]): string {
    // If has links on tags that should not be replaced
    if (tags.length > 0) {
      // Remove &amp; from the link to compare
      const linkToCompare = link.replace(/&amp;/g, '&');

      // If the link to compare includes any tag
      for (const tag of tags) {
        // Remove &amp; from the tag
        const tagToCompare = tag.replace(/&amp;/g, '&');

        // Check if the link to compare includes any tag
        const linkHasTag = linkToCompare.includes(tagToCompare);

        // Check if the last 5 characters of the link to compare ends with </a
        const linkEndsWithTag = linkToCompare.slice(-5).includes('</a');

        // Check if the tag includes any link
        const tagHasLink = tagToCompare.includes(linkToCompare);

        // If any of the above is true, return the link
        if (linkHasTag || linkEndsWithTag || tagHasLink) return link;
      }
    }

    // Return HTML in string
    return this.createAElem(link).outerHTML;
  }

  /**
   * Replace bold on the html
   * @param {string} bold Bold text
   * @returns {string} Html
   */
  private replaceBold(bold: string): string {
    // Remove the ** from the bold
    bold = bold.slice(3, -3);

    // Return
    return ` <b>${bold}</b> `;
  }

  /**
   * Replace italic on the html
   * @param {string} italic Italic text
   * @returns {string} Html
   */
  private replaceItalic(italic: string): string {
    // Remove the ** from the bold
    italic = italic.slice(2, -2);

    // Return
    return ` <i>${italic}</i> `;
  }

  /**
   * Replace strike on the html
   * @param {string} strike Strike text
   * @returns {string} Html
   */
  private replaceStrike(strike: string): string {
    // Remove the ** from the bold
    strike = strike.slice(3, -3);

    // Return
    return ` <strike>${strike}</strike> `;
  }

  /**
   * Create the img element for the emoji
   * @param {Emoji} emoji Emoji
   * @param {number} size Size of the emoji {default: 19}
   * @returns {HTMLImageElement} Img element
   */
  private createEmojiElem(emoji: Emoji, size: number): HTMLImageElement {
    // Create the img element
    const img: HTMLImageElement = document.createElement('img');

    // Set img data
    img.src = emoji.imgUrl;
    img.alt = emoji.char;
    img.width = size;
    img.height = size;
    img.style.marginLeft = '2px'
    img.style.marginBottom = '-4px';
    img.classList.add('emoji'); // Add emoji class

    // Set accessibility
    img.setAttribute('aria-label', emoji.name);
    img.setAttribute('aria-hidden', 'false');

    // Return
    return img;
  }

  /**
   * Create the a element
   * @param {string} link Link
   * @returns {HTMLAnchorElement} A element
   */
  private createAElem(link: string): HTMLAnchorElement {
    // Create the a element
    const a: HTMLAnchorElement = document.createElement('a');

    // Check if starts with http
    const linkStartsWithHttp = link.startsWith('http');

    // Clone regex to avoid errors on the switch - This happens because the regex is global
    const phoneRegex = new RegExp(PHONE_REGEX);
    const emailRegex = new RegExp(EMAIL_TO_REGEX);

    // Check if is a phone number | link | email
    switch (true) {
      // Set a data if is an phone number
      case !linkStartsWithHttp && phoneRegex.test(link):
        a.href = `tel:${link}`;
        a.style.color = "inherit";
        break;

      // Set a data if is an email
      case !linkStartsWithHttp && emailRegex.test(link):
        a.href = `mailto:${link}`;
        a.style.color = "inherit";
        break;

      // Set a data if is an normal link
      default:
        a.href = this.getFormattedLink(link);
        a.target = "_blank";
        a.style.color = '#7895c7';
        break;
    }

    // Default values
    a.innerText = `${link}`

    // Return the a element
    return a;
  }

  /**
   * Get links on tags that should not be replaced
   * @param {string} html HTML
   * @returns {string[]} Links on tags that should not be replaced
   */
  private getLinkOnTags(html: string): string[] {
    // Return
    return [
      ...getLinksFromHTML(html, IMG_SRC_REGEX), // Get img tags
      ...getLinksFromHTML(html, A_HREF_REGEX), // Get a tags
    ];
  }

  /**
   * Get formatted link
   * @param {string} link Pure link
   * @returns {string} Formatted link
   */
  private getFormattedLink(link: string): string {
    if (link.includes('https') || link.includes('http')) return link;
    else return `https://${link}`
  }
}
