import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AVAILABLE_LANGUAGES } from '@shared/enums';

type DateType = ('normal' | 'date' | 'time' | 'fulltime')

@Pipe({
  name: 'timelineDateFormatPipe',
  pure: false
})
export class TimelineDateFormatPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe,
  ) { }

  transform(value: string, type: DateType = 'normal', isFullDate: boolean = false): string {
    const date = new Date(value);
    const format = this.getDateFormatByLocale(date, type, isFullDate);
    return this.datePipe.transform(date, format);
  }

  /**
   * Check if msg is on the current date
   * @param {Date} date Msg date
   * @returns {boolean} True if the msg is on the current date
   */
  private isToday(date: Date): boolean {
    const today = new Date()
    return date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
  }

  /**
   * Check if msg was sent in the current year
   * @param {Date} date Msg date
   * @returns {boolean} True if msg was sent in the current year
   */
  private isCurrentYear(date: Date): boolean {
    return date.getFullYear() == new Date().getFullYear()
  }

  /**
   * Get date format by language
   * @returns {string} Date format
   */
  private getDateFormatByLocale(date: Date, type: DateType, isFullDate: boolean): string {
    // Init flags
    let format = '';
    const isToday = this.isToday(date);
    const isCurrentYear = this.isCurrentYear(date);

    switch (this.translate.currentLang) {
      // English
      case AVAILABLE_LANGUAGES.EN:
        format = isToday ? 'h:mm aa' : 'M/d/yyyy h:mm aa';
        if (type == 'date') format = 'M/d/yyyy';
        if (type == 'time') format = 'h:mm aa';
        if (type == 'fulltime') format = 'h:mm:ss aa';
        break;

      // Spanish
      case AVAILABLE_LANGUAGES.ES:
        format = isToday ? 'HH:mm' : 'd/M/yyyy HH:mm';
        if (type == 'date') format = 'd/M/yyyy';
        if (type == 'time') format = 'HH:mm';
        if (type == 'fulltime') format = 'HH:mm:ss';
        break;

      // Brazilian portuguese
      default:
        format = isToday ? 'HH:mm' : 'dd/MM/yyyy HH:mm';
        if (type == 'date') format = 'dd/MM/yyyy';
        if (type == 'time') format = 'HH:mm'
        if (type == 'fulltime') format = 'HH:mm:ss'
        break;
    }

    // Remove year information if is current year
    if (isCurrentYear && !isFullDate)
      format = format.replace('/yyyy', '');

    // Return result
    return format;
  }
}
