<!-- Field -->
<mat-form-field appearance="outline" class="full-width">
  <!-- Label -->
  <mat-label>{{ label | translate }}</mat-label>

  <!-- Chips List -->
  <mat-chip-list #chipList>

    <!-- Chips -->
    <mat-chip *ngFor="let chip of chips" (removed)="removeChip.emit(chip)">
      {{ getInputValue(chip) }}
      <mat-icon matChipRemove>close</mat-icon>
    </mat-chip>

    <!-- Readonly Input -->
    <input *ngIf="readonly; else normalInput" [placeholder]="placeholder | translate" [matChipInputFor]="chipList"
      (click)="addChip.emit()" [readOnly]="true" />

    <!-- Normal Input -->
    <ng-template #normalInput>
      <input [placeholder]="placeholder | translate" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="addChip.emit($event)" />
    </ng-template>

    <!-- Tooltip -->
    <mat-icon *ngIf="tooltipContent" matSuffix [matTooltip]="tooltipContent | translate"
      [svgIcon]="tooltipIcon"></mat-icon>
  </mat-chip-list>
</mat-form-field>
