import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoomService } from '@core/services';
import { MATRIX_LOCAL_STORAGE_BASE_URL } from '@shared/constants';
import { setCurrentRoom } from '@shared/utils';

@Component({
  selector: 'app-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatButtonComponent implements OnInit {
  @Input() userId: string;

  public mouseOver = false;

  constructor(
    private router: Router,
    private roomService: RoomService,
  ) { }

  ngOnInit(): void {
  }

  async createDM(button: HTMLButtonElement): Promise<void> {
    button.disabled = true;
    this.userId = `@${this.userId}:${localStorage.getItem(MATRIX_LOCAL_STORAGE_BASE_URL).split('//')[1]}`;
    const roomId = await this.roomService.createDM(this.userId, 'contact');
    button.disabled = false;
    this.redirect(roomId);
  }

  private redirect(roomId: string): void {
    setCurrentRoom(roomId);
    this.roomService.updateRoom(roomId, false)
    this.router.navigate(['/chat']);
  }
}
