export interface ChatGgtAPIResponse {
  content: string,
  dataComplet: {
    id: string,
    object: string,
    created: number,
    model: string,
    usage: {
      prompt_tokens: number,
      completion_tokens: number,
      total_tokens: number
    },
    choices: [
      {
        message: {
          role: string,
          content: string
        },
        finish_reason: string,
        index: number
      }
    ]
  }
}

export interface ChatGgtLoginResponse {
  auth: boolean;
  token: string;
}
