import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatgptService, RoomService, ThemeService, UserPresenceService } from '@core/services';
import { User } from '@shared/models';

type Info = 'email' | 'extension' | 'meet' | 'status' | 'sector';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit {
  // Contact to show info
  @Input() info: string;
  @Input() clickable: boolean = false;

  // Info to show in the contact
  @Input() infos: Info[] = [];
  @Input() showStatus: boolean = true;

  // Text size
  @Input() isSmall: boolean = true;
  @Input() isBold: boolean = false;

  // Room header
  @Input() isRoomHeader: boolean = false;

  // Typing
  @Input() contacts: User[] = [];
  @Input() roomId: string;

  constructor(
    private roomService: RoomService,
    public themeService: ThemeService,
    public dialog: MatDialog,
    private chatGPT: ChatgptService,
    private userPresenceService: UserPresenceService,
  ) { }

  ngOnInit(): void {}

  /** 
   * Check if can click
   * @returns {boolean} True if can click
   */
  public canClick(): boolean {
    return this.clickable;
  }
}
