import { CALL_EVENT } from "@shared/constants";
import { ReplyItem, ReplyItemExtraHeaders } from "@shared/models";
import { EventType, IContent, MatrixEvent, RelationType } from "matrix-js-sdk";

const divType = '<msgtype>';
const divSender = '<msgreplysender>';
const divReplyBody = '<msgreplybody>';
const divBody = '<msgbody>';
const divExtraHeaders = '<msgextraheaders>';

function replyItemFactory(item?: ReplyItem): ReplyItem {
  return {
    eventId: item?.eventId,
    msgType: item?.msgType,
    replyMsg: item?.replyMsg,
    userId: item?.userId,
    hasOnlyImages: item?.hasOnlyImages ?? false,
  }
}

/**
 * Get Text Between Characters
 * @param {string} original
 * @param {string} divider
 * @returns {string} Text
 */
function getTextBetweenCharacters(original: string, divider: string): string {
  return original.slice(original.indexOf(divider) + divider.length, original.lastIndexOf(divider)).trim();
}

/**
 * Check if is a reaction deleted
 * @param {MatrixEvent} event Matrix Event
 * @returns {boolean} True if is a reaction deleted
 */
export function isReactionDeleted(event: MatrixEvent): boolean {
  return isReactionEvent(event) && !!event?.event?.unsigned?.redacted_because;
}

/**
 * Check if is a redaction to reaction
 * @param {MatrixEvent} event Matrix Event
 * @returns {boolean} True if is a redaction to reaction
 */
export function isRedactionToReaction(event: MatrixEvent): boolean {
  return isRedactionEvent(event) && event?.getContent().reason?.includes('reaction');
}

/**
 * Verify if is a edit event
 * @param {MatrixEvent} mEvent Matrix Event
 * @returns {boolean} true if event is a edit event
 */
export function isEditedEvent(mEvent: MatrixEvent): boolean {
  return mEvent?.getRelation()?.rel_type === RelationType.Replace;
}

/**
 * Check if is a mxc file
 * @param {string} link Chat file link
 * @returns {boolean} true if is a mxc file
 */
export function isMxcFile(link: string): boolean {
  return link?.includes('mxc://');
}

/**
 * Check if is a call
 * @param {MatrixEvent} mEvent Matrix Event
 * @returns {boolean} true if is a call
 */
export function isCallEvent(mEvent: MatrixEvent): boolean {
  return mEvent.getType() == CALL_EVENT;
}

/**
 * Check if is redaction
 * @param {MatrixEvent} mEvent Matrix Event
 * @returns {boolean} true if is redaction
 */
export function isRedactionEvent(mEvent: MatrixEvent): boolean {
  return mEvent?.getType() == EventType.RoomRedaction;
}

/**
 * Verify if is a reaction event
 * @param {MatrixEvent} mEvent Matrix Event
 * @returns {boolean} true if event is a reaction event
 */
export function isReactionEvent(mEvent: MatrixEvent): boolean {
  return mEvent?.getType() === EventType.Reaction;
}

/**
 * Get id server
 * @param {string} userId Matrix user id
 * @returns {string} id server
 */
export function getIdServerFromUserID(userId: string): string {
  const idParts = userId.split(':');
  return idParts[1];
}

/**
 * Parse reply
 * @param {string} rawBody Body if the raw
 * @returns { replyData: ReplyItem, body: string } Reply data and clean body
 */
export function parseReply(rawBody: string, eventId: string = null): { replyData: ReplyItem, body: string } {
  if (rawBody?.indexOf(divType) !== 0) return { replyData: replyItemFactory(), body: rawBody, };

  const body = getTextBetweenCharacters(rawBody, divBody);
  const userId = getTextBetweenCharacters(rawBody, divSender);
  const msgType = getTextBetweenCharacters(rawBody, divType);
  const replyMsg = getTextBetweenCharacters(rawBody, divReplyBody);
  const replyExtraHeaders: ReplyItemExtraHeaders = rawBody?.indexOf(divExtraHeaders) !== -1
    ? JSON.parse(getTextBetweenCharacters(rawBody, divExtraHeaders))
    : null;

  const replyData = replyItemFactory({
    eventId,
    msgType,
    replyMsg,
    userId: userId,
    hasOnlyImages: replyExtraHeaders?.hasOnlyImages ?? false,
  })

  return { replyData, body };
}

export function bindReplyToEditContent(originalBody: string, newBody: string) {
  let body = originalBody.slice(0, originalBody.indexOf(divBody));
  body += `${divBody}${newBody}${divBody}`;
  return body;
}

export function bindReplyToContent(reply: MatrixEvent, content: IContent) {
  const newContent = { ...content };
  let replyBody = reply.getContent().body;

  // If Msg is reply msg
  if (reply.replyEventId) {
    const { body } = parseReply(replyBody)
    replyBody = body;
  }

  // Form extra headers
  const extraHeaders: ReplyItemExtraHeaders = {
    hasOnlyImages: reply?.getContent()?.hasOnlyImages ?? false,
  }

  // Form body
  newContent.body = `${divType}${reply.getContent().msgtype}${divType}\n`;
  newContent.body += `${divSender}${reply.getSender()}${divSender}\n`;
  newContent.body += `${divReplyBody}${replyBody}${divReplyBody}\n`;
  newContent.body += `${divBody}${content.body}${divBody}\n`;
  newContent.body += `${divExtraHeaders}${JSON.stringify(extraHeaders)}${divExtraHeaders}`;

  // Bind
  newContent['m.relates_to'] = content['m.relates_to'] || {};
  newContent['m.relates_to']['m.in_reply_to'] = { event_id: reply.getId() };

  // Return new content
  return newContent;
}
