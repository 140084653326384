import { EventType } from "matrix-js-sdk";

// If media will be saved on firebase storage or in matrix server
export const SAVE_MATRIX_CHAT_FILES_ON_STORAGE = true;

export const MATRIX_DB_STORAGE = 'web-store';
export const CALL_EVENT = EventType.CallInvite;
export const MATRIX_LOGIN_IDENTIFIER_TYPE = 'm.id.user';
export const MATRIX_LOGIN_TYPE = 'm.login.password';

export const MATRIX_LOCAL_STORAGE_ACCESS_TOKEN = 'mobi_phone_access_token';
export const MATRIX_LOCAL_STORAGE_DEVICE_ID = 'mobi_phone_device_id';
export const MATRIX_LOCAL_STORAGE_USER_ID = 'mobi_phone_user_id';
export const MATRIX_LOCAL_STORAGE_BASE_URL = 'mobi_phone_hs_base_url';
export const MATRIX_LOCAL_STORAGE_LAST_ROOM = 'mobi_phone_last_room';
export const MATRIX_LOCAL_STORAGE_TAB_INDEX = 'mobi_phone_tab_index';
export const MATRIX_LOCAL_STORAGE_MEET_TAB_INDEX = 'mobi_phone_meet_tab_index';
export const MATRIX_LOCAL_STORAGE_EMOJI_HISTORY = 'mobi_phone_recent_emojis';

export const MATRIX_DEVICE_NAME_PREFIX = 'Mobi';
export const MATRIX_PASS_CLIENT_SUFFIX = '@L3uc0.';

// Support
export const MARK_AS_READ_SUPPORT_EVENT = [
  EventType.RoomMessage,
  EventType.RoomCreate,
  EventType.RoomMember,
  EventType.Reaction,
  EventType.RoomAvatar,
  EventType.RoomName,
  EventType.RoomPowerLevels,
  CALL_EVENT,
];
export const MATRIX_TIMELINE_SUPPORT_EVENT = [
  EventType.RoomMessage,
  EventType.RoomCreate,
  EventType.RoomMember,
  EventType.RoomAvatar,
  EventType.RoomName,
  EventType.RoomPowerLevels,
  CALL_EVENT,
];
export const MATRIX_MEMBER_ACTIONS_SUPPORT = ['leave', 'invite', 'join'];
export const LAST_EVENTS_SUPPORT = [
  EventType.RoomMessage,
  EventType.RoomCreate,
  // EventType.RoomMember,
  // EventType.Reaction,
  // EventType.RoomAvatar,
  EventType.RoomRedaction,
  CALL_EVENT
];
export const LAST_EVENTS_READ_SUPPORTED = [
  EventType.RoomMessage,
  EventType.RoomCreate,
  CALL_EVENT
];
export const MEMBER_ACTIONS_EVENT = [
  EventType.RoomMember,
  EventType.RoomAvatar,
  EventType.RoomName,
  EventType.RoomPowerLevels,
  CALL_EVENT
];

export const NOT_MEMBER_STATUS = ['leave', 'kick', 'ban'];

export const TYPING_TIMEOUT = 10000;

// Timeline limits
export const MAX_INVITES = 5;
export const BASE_EVENTS_LOAD_LIMIT = 10; // Load 10 events per request
export const MAX_TIMELINE_EVENTS = window.innerHeight > 1080 ? 90 : 30; // 60 for 4k screens and 30 for others
export const INITIAL_EVENTS_NUMBER = window.innerHeight > 1080 ? 60 : 30; // 60 for 4k screens and 30 for others
export const MIN_TIMELINE_EVENTS = 10;
export const EVENTS_LOADED_ON_SEARCH = 200;

// Bytes
export const MAX_FILE_SIZE = 52428800;

// Reactions
export const REACTIONS_EMOJI_OPTIONS = [
  'Thumbs Up',
  'Red Heart',
  'Face With Tears Of Joy',
  'Face With Open Mouth',
  'Loudly Crying Face',
  'Pouting Face'
]
