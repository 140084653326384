import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsSharedService, AuthService, JitsiService, JsSIPService } from '@core/services';
import { ANALYTICS_EVENTS } from '@shared/enums';
import { User } from '@shared/models';
import { isExternalRouteExist } from '@shared/utils';
import { first } from 'rxjs';
import { SelectExternalRouteComponent } from '../select-external-route/select-external-route.component';

@Component({
  selector: 'app-voice-call-button',
  templateUrl: './voice-call-button.component.html',
  styleUrls: ['./voice-call-button.component.scss'],
})
export class VoiceCallButtonComponent implements OnInit {
  @Input() target: string;
  @Input() displayName: string;
  @Input() isGroup: boolean = false;
  @Input() groupId: string;
  @Input() isReceivedCall: boolean = false;
  @Input() showFullButton: boolean = false;
  @Input() showFillButton: boolean = false;
  @Input() forceUseExternalRoute: boolean = false;
  @Input() parentName: ('contacts' | 'history' | 'room');

  externalRoute: number | string[];
  mouseOver = false;
  clicked = false;
  public user: User = null;
  private debounceTimeout: any;
  public numberOfClicks: number = 0;

  constructor(
    private jssip: JsSIPService,
    private jitsi: JitsiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private analytics: AnalyticsSharedService
  ) { }

  ngOnInit(): void {
    this.externalRoute = this.authService.getExternalRoute();
  }

  /**
   * Send Event To Analytics
   */
  private sendEventToAnalytics(): void {
    // Send event to analytics
    switch (this.parentName) {
      case 'contacts':
        this.analytics.logEvent(ANALYTICS_EVENTS.call_by_contacts)
        break;
      case 'history':
        this.analytics.logEvent(ANALYTICS_EVENTS.call_by_history)
        break;
      case 'room':
        this.analytics.logEvent(ANALYTICS_EVENTS.call_by_room)
        break;
      default:
        break;
    }
  }

  /**
   * Get svg icon name
   * @returns {string} svg icon name
   */
  public getSvgIcon(): string {
    if (this.showFillButton) return 'call_hover';
    return this.mouseOver ? 'call_hover' : 'call'
  }

  /**
   * Handle call
   * @param {PointerEvent} event Event to avoid multiple clicks
   */
  public async handleCall(): Promise<void> {
    // Send event
    this.sendEventToAnalytics();

    // Init conference call
    if (this.isGroup) {
      // Activate on first click only to avoid hiding again on multiple clicks (Works on web)
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.numberOfClicks++
        if (this.numberOfClicks != 1) {
          this.numberOfClicks = 0;
          return;
        }
        return this.jitsi.initCall(this.groupId);
      }, 50)

      return;
    }

    if (this.target && this.displayName?.length > 0 && !this.forceUseExternalRoute) {
      return this.jssip.call(this.target, this.displayName);
    }

    if (this.target.length <= 5 && !this.forceUseExternalRoute) {
      return this.jssip.call(this.target, this.target);
    }

    // Init voice call with external user
    return this.handleExternalCall();
  }

  /**
   * Handle external call
   * @returns {Promise<void>} Init voice call with external user
   */
  private handleExternalCall(): Promise<void> {
    // Get display name
    const displayName = this.displayName?.length > 0
      ? this.displayName // Use display name if exist
      : 'Externo'; // Use default display name

    // Init call
    if (this.isReceivedCall || this.forceUseExternalRoute) {
      return this.selectExternalRouteAndInitCall(displayName);
    } else return this.jssip.call(this.target, displayName);
  }

   /**
   * Select external route and init call
   */
   private selectExternalRouteAndInitCall(displayName: string): Promise<void> {
    let target = this.target;
    let isExtension = target.length <= 5;

    // External call
    if (isExternalRouteExist(this.externalRoute)) {
      // Check if only one external route exist
      const isOnlyOneRoute = Array.isArray(this.externalRoute)
        ? this.externalRoute.length == 1
        : true;

      // Get first external route
      const firstExternalRoute = Array.isArray(this.externalRoute)
        ? this.externalRoute[0]
        : this.externalRoute;

      // If only one external route exist, use it
      if (isOnlyOneRoute) {
        // Add external route to target
        if (!isExtension) target = `${firstExternalRoute}${this.target}`;

        // log target
        // console.log('Only one route config - target', this.target);

        // Init call
        return this.jssip.call(target, displayName);
      } else {
        // Open modal
        const dialogRef = this.dialog.open(SelectExternalRouteComponent, {
          width: '400px',
          data: { externalRoute: this.externalRoute },
        });

        // Subscribe to dialog close
        dialogRef
          .afterClosed()
          .pipe(first())
          .subscribe((result) => {
            if (result) {
              // Add external route to target
              if (!isExtension)
                target = `${result.externalRoute}${this.target}`;

              // log target
              // console.log('Multi routes config - target', this.target);

              // Init call
              return this.jssip.call(target, displayName);
            }
          });
      }
    } else {
      // Open modal
      const dialogRef = this.dialog.open(SelectExternalRouteComponent, {
        width: '400px',
        data: { externalRoute: this.externalRoute },
      });

      // Subscribe to dialog close
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((result) => {
          if (result) {
            // Add external route to target
            target = `${result.externalRoute}${this.target}`;

            // log target
            // console.log('No external route config - target', this.target);

            // Init call
            return this.jssip.call(target, displayName);
          }
        });
    }
  }
}
