<form [formGroup]="devicesSettingsForm" role="form">
  <!-- Video -->
  <mat-form-field appearance="fill" *ngIf="videoDevices.length > 0">
    <mat-label>{{'video' | translate }}</mat-label>
    <mat-select formControlName="video">
      <mat-option *ngFor="let device of videoDevices" [value]="device.deviceId">
        {{device.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Microphone -->
  <mat-form-field appearance="fill">
    <mat-label>{{'microphone' | translate }}</mat-label>
    <mat-select formControlName="microphone">
      <mat-option *ngFor="let device of inputDevices" [value]="device.deviceId">
        {{device.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Speakerphone -->
  <mat-form-field *ngIf="!platform.isFirefox()" appearance="fill">
    <mat-label>{{'speakerphone' | translate }}</mat-label>
    <mat-select formControlName="speakerphone">
      <mat-option *ngFor="let device of outputDevices" [value]="device.deviceId">
        {{device.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Ring on additional device  -->
  <mat-slide-toggle *ngIf="!platform.isFirefox() && !isCalling" [checked]="canRingOnAdditionalDevice" [labelPosition]="'before'"
    (toggleChange)="toggleRingOnAdditionalDevice()" color="primary">
    {{'ringOnAdditionalDevice' | translate }}
  </mat-slide-toggle><br />

  <!-- Ringtone -->
  <mat-form-field appearance="fill" *ngIf="canRingOnAdditionalDevice && !platform.isFirefox() && !isCalling">
    <mat-label>{{'alsoRingOn' | translate }}</mat-label>
    <mat-select formControlName="ringtone">
      <mat-option *ngFor="let device of outputDevices" [value]="device.deviceId">
        {{device.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<mat-dialog-actions align="end">
  <button *ngIf="isCalling" style="text-transform: uppercase;" mat-button mat-dialog-close>{{"cancel" | translate}}</button>
  <button
    (click)="apply()"
    mat-button
    type="button"
    class="apply-btn"
  >
  {{"apply" | translate}}
  </button>
</mat-dialog-actions>
