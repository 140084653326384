import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PREFIX_STORAGE } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  changeLanguageEmitter = new EventEmitter<string>();

  constructor(private translate: TranslateService) {}

  setLanguage(language: string): void {
    this.translate.resetLang(this.translate.getDefaultLang());
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem(`${PREFIX_STORAGE}:language`, language);
    this.translate.reloadLang(language);
    this.changeLanguageEmitter.emit(language);
  }
}
