import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatrixService, UtilsService } from '@core/services';
import { isMxcFile } from '@shared/utils';
import { ElectronService } from 'ngx-electron';

type Data = {
  url: any;
  type: ('doc' | 'video' | 'normal');
  link?: string;
  fileName?: string;
  disableActions: boolean;
}

@Component({
  selector: 'app-fullscreen-view',
  templateUrl: './fullscreen-view.component.html',
  styleUrls: ['./fullscreen-view.component.scss']
})
export class FullscreenViewComponent implements OnInit {
  @ViewChild('imgContainer') imgContainer: ElementRef;
  @ViewChild('imgContent') imgContent: ElementRef;

  private pos = { top: 0, left: 0, x: 0, y: 0 };
  private zoomScaleOptions = ['100%', '150%', '200%', '250%', '300%', '350%', '400%'];
  private zoomScale = 0;
  public link: string = '';
  public isLoading = true;
  public extension = '';
  public viewer = 'google';
  public isZoomed = false;
  public isMoving = false;
  public isElectronApp = false;

  public officeSupport = ['ppt', 'pptx', 'doc,', 'docx', 'xls', 'xlsx'];

  public defaultWidth: string;
  public defaultHeight: string;

  constructor(
    public dialogRef: MatDialogRef<FullscreenViewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Data,
    private electronService: ElectronService,
    private matrixService: MatrixService,
    private utilsService: UtilsService,
  ) { }

  ngOnInit(): void {
    this.isElectronApp = this.electronService.isElectronApp;
    this.extension = this.data.fileName?.split('.').pop()?.toLowerCase();
    this.link = isMxcFile(this.data.link) ? this.matrixService.mxcUrlToHttp(this.data.link) : this.data.link;
    this.setViewer();
  }

  /**
   * Executed when content is loaded
   */
  public contentLoaded() {
    this.isLoading = false;

    if (this.imgContent) {
      this.defaultHeight = this.imgContent.nativeElement.offsetHeight + 'px';
      this.defaultWidth = this.imgContent.nativeElement.offsetWidth + 'px';
    }
  }

  /**
   * Download file
   */
  public download(): void {
    this.utilsService.downloadFile(this.data.url, this.data.fileName)
  }

  /**
   * Set doc viewer
   */
  public setViewer(): void {
    switch (true) {
      case this.officeSupport.includes(this.extension):
        this.viewer = 'office'
        break;

      default:
        this.viewer = 'google'
        break;
    }
  }

  /**
   * Check if can show zoom
   * @returns {boolean} True if can show zoom
   */
  public canShowZoom(): boolean {
    return !this.isLoading && this.data.type == 'normal' && !this.data.disableActions;
  }

  /**
   * Can show download button
   */
  public canShowDownload(): boolean {
    return !this.data.disableActions;
  }

  /**
   * Can show open button in new window
   */
  public canShowOpen(): boolean {
    return !this.isLoading && this.data.type !== 'video' && !this.data.disableActions;
  }

  /**
   * Open url in a new window
   */
  public async open() {
    switch (true) {
      case this.data.type == 'normal':
      case ['pdf', 'txt'].includes(this.extension):
        window.open(this.link);
        break;

      default:
        if (this.officeSupport.includes(this.extension))
          window.open('https://view.officeapps.live.com/op/view.aspx?src=' + this.link);
        else window.open('https://docs.google.com/gview?url=' + this.link);
        break;
    }
  }

  /**
   * Zoom in
   * @param {MouseEvent} e Mouse event
   */
  public zoomIn(e: MouseEvent): void {
    this.isZoomed = true;
    const zoomOptionsLength = this.zoomScaleOptions.length - 1;
    this.zoomScale = this.zoomScale == zoomOptionsLength ? zoomOptionsLength : this.zoomScale + 1;
    this.zoom(e);
  }

  /**
   * Zoom out
   * @param {MouseEvent} e Mouse event
   */
  public zoomOut(e: MouseEvent): void {
    this.zoomScale = this.zoomScale == 0 ? 0 : this.zoomScale - 1;
    this.isZoomed = this.zoomScale !== 0;
    this.zoom();
  }

  /**
   * Zoom
   * @param {MouseEvent} e Mouse event
   */
  private zoom(e?: MouseEvent): void {
    if (this.isZoomed) {
      this.imgContainer.nativeElement.style.overflow = 'hidden';
      this.imgContainer.nativeElement.style.maxWidth = this.defaultWidth;
      this.imgContainer.nativeElement.style.maxHeight = this.defaultHeight;
      this.imgContent.nativeElement.style.cursor = 'grab';
      this.imgContent.nativeElement.style.width = this.zoomScaleOptions[this.zoomScale];
      this.imgContent.nativeElement.style.maxHeight = this.zoomScaleOptions[this.zoomScale];
      this.imgContent.nativeElement.style.maxWidth = this.zoomScaleOptions[this.zoomScale];
      this.imgContent.nativeElement.style.left = `-${e ? e.clientX : this.pos.left}`;
      this.imgContent.nativeElement.style.top = `-${e ? e.clientY : this.pos.top}`;
    } else {
      this.imgContent.nativeElement.style.width = 'auto';
      this.imgContent.nativeElement.style.cursor = 'default';
      this.imgContent.nativeElement.style.maxHeight = '80vh';
      this.imgContent.nativeElement.style.maxWidth = '80vw';
    }
  }

  /**
   * Listening mouse down
   * @param {MouseEvent} e Mouse event
   */
  public onMouseDown(e: MouseEvent): void {
    if (!this.isZoomed) return;
    this.isMoving = true;
    this.imgContent.nativeElement.style.cursor = 'grabbing';
    this.pos = {
      // The current scroll
      left: this.imgContainer.nativeElement.scrollLeft,
      top: this.imgContainer.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  }

  /**
   * Listening mouse up
   * @param {MouseEvent} e Mouse event
   */
  public onMouseUp(e: MouseEvent): void {
    if (!this.isZoomed) return;
    this.imgContent.nativeElement.style.cursor = 'grab';
    this.isMoving = false;
  }

  /**
   * Listening mouse move
   * @param {MouseEvent} e Mouse event
   */
  public onMouseMove(e: MouseEvent): void {
    e.preventDefault();
    if (this.isMoving) {
      // How far the mouse has been moved
      const dx = (e.clientX - this.pos.x) * 2;
      const dy = (e.clientY - this.pos.y) * 3;

      // Scroll the element
      this.imgContainer.nativeElement.scrollTop = this.pos.top - dy;
      this.imgContainer.nativeElement.scrollLeft = this.pos.left - dx;
    }
  }

  /**
   *  Listening mouse leave
   */
  public onMouseLeave() {
    if (!this.isZoomed) return;
    this.imgContent.nativeElement.style.cursor = 'grab';
    this.isMoving = false;
  }
}
