import { InterfaceConfig } from "@shared/models";

export const DEFAULT_JITSI_ROOM_DATABASE = 'roomsInMeeting';

export const DEFAULT_TOOLBAR_BUTTONS: InterfaceConfig['TOOLBAR_BUTTONS'] = [
  'camera',
  'hangup',
  'desktop',
  'microphone',
  'fullscreen',
  'raisehand',
  'shortcuts',
  'recording',
  'tileview',
  'download',
  'stats',
  'chat',
  'settings',
  'closedcaptions',
  'participants-pane',
  'noisesuppression',
  'select-background'
];
