import { environment } from "@environments/environment";
import { MatrixRoomType } from "@shared/enums";
import { EventTimeline, EventType, MatrixEvent, MsgType, Room } from "matrix-js-sdk";

/**
 * Form matrix user id from contact
 * @param {string} userId Contact id
 * @returns {string} Matrix user id
 */
export const formMatrixFromUserId = (userId: string): string => {
  const matrixDomain = environment.matrixUrl.replace('https://', '');
  return `@${userId.toLocaleLowerCase()}:${matrixDomain}`
}

/**
 * Get topic to this room
 * @param {Room} room Matrix Room
 * @returns {string} Topic
 */
export function getTopicForARoom(room: Room): string {
  return room?.getLiveTimeline()?.getState(EventTimeline.FORWARDS)?.getStateEvents(EventType.RoomTopic)[0]?.getContent()?.topic;
}

/**
 * Get creation content to this room
 * @param {Room} room Matrix Room
 * @returns {string} Create content
 */
export function getCreationContentForARoom(room: Room): any {
  return room?.getLiveTimeline()?.getState(EventTimeline.FORWARDS)?.getStateEvents(EventType.RoomCreate)[0]?.getContent();
}

/**
 * Check if is a meet group
 * @param {Room} room Matrix Room
 * @returns {boolean} true if is a meet group
 */
export function isMeetGroup(room: Room): boolean {
  return getCreationContentForARoom(room)?.roomType == MatrixRoomType.MEET;
}

/**
 * Check if is a meet group
 * @param {Room} room Matrix Room
 * @returns {boolean} true if is a meet group
 */
export function isChatGPTRoom(room: Room): boolean {
  return getCreationContentForARoom(room)?.roomType == MatrixRoomType.GPT;
}

/**
 * Get contact id in lower case
 * @param {string} sender Sender complete id
 * @returns {string} Contact id in lower case
 */
export const getContactIdFromMatrixId = (sender: string): string => {
  return sender?.split(':')[0].substring(1);
}

/**
 * Get Msg Type
 * @param {MatrixEvent} mEvent Matrix Event
 * @returns {MsgType} Msg Type
 */
export function getMsgType(mEvent: MatrixEvent): MsgType {
  return mEvent?.getContent()?.msgtype as MsgType;
}

/**
 * Form msg id
 * @param {string} id Event id
 * @param {string} suffix Suffix
 * @returns
 */
export function formMsgId(id: string, suffix?: string): string {
  const msgId = 'message' + id;
  return suffix
    ? msgId + suffix
    : msgId;
}

/**
 * @param {Event} ev
 * @param {string} [targetSelector] element selector for Element.matches([selector])
 */
export function getEventCords(ev, targetSelector) {
  let boxInfo;

  const path = ev.nativeEvent.composedPath();
  const target = targetSelector
    ? path.find((element) => element.matches?.(targetSelector))
    : null;
  if (target) {
    boxInfo = target.getBoundingClientRect();
  } else {
    boxInfo = ev.target.getBoundingClientRect();
  }

  return {
    x: boxInfo.x,
    y: boxInfo.y,
    width: boxInfo.width,
    height: boxInfo.height,
    detail: ev.detail,
  };
}

export function abbreviateNumber(number) {
  if (number > 99) return '99+';
  return number;
}

export function getUrlPrams(paramName) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

export function getScrollInfo(target) {
  const scroll = {
    top: Math.round(target.scrollTop),
    height: Math.round(target.scrollHeight),
    viewHeight: Math.round(target.offsetHeight),
    isScrollable: Math.round(target.scrollHeight) > Math.round(target.offsetHeight),
  };
  return scroll;
}

export function avatarInitials(text) {
  return [...text][0];
}

export function copyToClipboard(text) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const host = document.body;
    const copyInput = document.createElement('input');
    copyInput.style.position = 'fixed';
    copyInput.style.opacity = '0';
    copyInput.value = text;
    host.append(copyInput);

    copyInput.select();
    copyInput.setSelectionRange(0, 99999);
    document.execCommand('Copy');
    copyInput.remove();
  }
}
