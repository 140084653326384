interface Action {
  icon: string;
  iconActive: string;
  txt: string;
  txtActive: string;
  onlyInternal: boolean;
  hideOnMobile: boolean;
  actionName: string;
}

export const extraActions: Action[] = [
  {
    icon: 'pause',
    iconActive: 'play',
    txt: 'callActions.hold',
    txtActive: 'callActions.unhold',
    onlyInternal: false,
    hideOnMobile: false,
    actionName: 'HOLD',
  },
  {
    icon: 'speaker',
    iconActive: 'speaker',
    txt: 'deviceSettings',
    txtActive: 'deviceSettings',
    onlyInternal: false,
    hideOnMobile: false,
    actionName: 'DEVICESETTINGS',
  },
  {
    icon: 'menu_transfer',
    iconActive: 'menu_transfer',
    txt: 'callActions.callTransfer',
    txtActive: 'callActions.callTransfer',
    onlyInternal: true,
    hideOnMobile: false,
    actionName: 'TRANSFER',
  },
  {
    icon: 'menu_sharescreen',
    iconActive: 'menu_unsharescreen',
    txt: 'callActions.shareScreen',
    txtActive: 'callActions.unshareScreen',
    onlyInternal: true,
    hideOnMobile: true,
    actionName: 'SHARESCREEN',
  },
];
