import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callDurationFormat',
})
export class CallDurationPipe implements PipeTransform {
  transform(value: number): string {
    if (value <= 0) return '0s';

    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value - hours * 3600) / 60);
    const seconds: number = value - minutes * 60 - hours * 3600;
    let stgBuilder = '';

    if (hours > 0 && hours < 10) {
      stgBuilder = '0' + hours + 'h ';
    } else if (hours > 0) {
      stgBuilder += hours + 'h ';
    }

    if (minutes > 0 && minutes < 10) {
      stgBuilder += '0' + minutes + 'm ';
    } else if (minutes > 0) {
      stgBuilder += minutes + 'm ';
    }

    if (seconds < 10) {
      stgBuilder += '0' + seconds + 's';
    } else {
      stgBuilder += seconds + 's';
    }

    return stgBuilder;
  }
}
