<mat-form-field
  class="dropdown-input"
  [appearance]="appearance"
  [class.dropdown-disabled]="disabled"
>
  <mat-label>{{ inputLabel }}</mat-label>
  <input
    matInput
    [type]="type"
    autocomplete="off"
    [disabled]="disabled"
    [matAutocomplete]="auto"
    [(ngModel)]="input"
    (ngModelChange)="applySearchFilter($event)"
    (blur)="onTouch()"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let option of options" [value]="option[optionValueKey]">
      {{ option[optionLabelKey] }}
    </mat-option>
  </mat-autocomplete>
  <span matSuffix>
    <mat-icon>expand_more</mat-icon>
  </span>
</mat-form-field>
