import { Injectable } from '@angular/core';
import { AuthService, UtilsService } from '@core/services';
import { CSV_CONTACTS_HEADER, CSV_PHONE_SEPARATOR } from '@shared/constants';
import { ImportUser } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class CsvService {

  constructor(
    private utilsService: UtilsService,
    private authService: AuthService
  ) { }

  /**
   * Generate CSV Model
   * @param {string[]} columns Column attributes
   * @param {string[]} keys Attributes that will be used as data to generate CSV
   * @param {any[]} data Array with all data that will be used to generate CSV
   * @param {string} fileName File name
   */
  public exportCSV(columns: string[], keys: string[], data: any[], fileName: string) {

    // Form the columns
    let csvContent = "";

    //Join every header element in a string and insert a comma in the end
    let row = columns.join() + ',';

    //Add a line break for the header
    csvContent += row + '\r\n';

    // Form the rows
    for (const obj of data) {
      const csvData = []

      keys.forEach(value => {
        if (!Array.isArray(obj[value]))
          csvData.push(obj[value])
        //If it's an array, we will first join it in a string separated by slashes
        else {
          const phoneNumbers = obj[value].join(CSV_PHONE_SEPARATOR)
          csvData.push(phoneNumbers)
        }
      });

      //Join every data element in a string and insert a comma in the end
      row = csvData.join() + ',';

      //Add a line break
      csvContent += row + '\r\n';
    }

    // Generate URL
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);

    // Download file
    this.utilsService.downloadFile(encodedUri, fileName);
  }

  /**
   * Import users from CSV file
   * @param {file} File file to be importe
   */
  public async importContactsCsv(file: File) {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();

      // Read file and return it as string
      reader.readAsText(file);

      // Callback when file is ready
      reader.onload = (e) => {

        // Csv result in a string
        const csv: string = reader.result as string;

        // Split CSV with line breaks
        let csvLines: string[] = csv.split('\r\n');

        if (csvLines.length === 1)
          csvLines = csv.split('\n');

        // CSV headers, aka labels
        let separator: string = ',';

        let containsComma = csvLines[0].includes(',')
        let containsSemiCollon = csvLines[0].includes(';')

        if (containsComma && containsSemiCollon)
          reject('containsCommaAndSemicollon')

        if (containsComma)
          separator = ','
        else if (containsSemiCollon)
          separator = ';'

        // Verification to see if there's a separator (, | ;) at the line end
        csvLines.map((line, index) => {
          const lastCharIndex = line.length - 1
          const lastChar = line.charAt(lastCharIndex)
          if (lastChar == separator)
            csvLines[index] = line.slice(0, lastCharIndex) + line.slice(lastCharIndex + 1);
        })

        const header: string[] = csvLines[0].split(separator);

        // This will send a reject status as the csv columns are not complete
        if (header.length < 5)
          reject('missingCsvField')
        const contacts = []

        // After storing headers in a variable, remove them
        csvLines.shift()

        csvLines.forEach((contact) => {
          if (contact) {
            let separator: string = ',';

            let containsComma = contact.includes(',')
            let containsSemiCollon = contact.includes(';')

            if (containsComma && containsSemiCollon)
              reject('containsCommaAndSemicollon')

            if (containsComma)
              separator = ','
            else if (containsSemiCollon)
              separator = ';'

            const contactData: string[] = contact.split(separator);

            const contactObj = {
            }

            contactData.map((data, index) => {

              // Reject blank cells
              if (data === "" || data === null || data === undefined) {
                reject('blankFieldError')
              }

              // If this is true, it's an array of values
              // Only consider the columns 3 and 4 (phone numers and phone descriptions)
              if (data.includes(CSV_PHONE_SEPARATOR) && (index == 3 || index == 4)) {

                // Treating it as an array
                const phoneElement = data.split(CSV_PHONE_SEPARATOR)

                // Creating an array to store in the object
                const arr = [];

                phoneElement.map(element => arr.push(element))

                // Calling object[n] implies that n is a key of this object
                contactObj[CSV_CONTACTS_HEADER.standard[index]] = arr;
              } else {
                contactObj[CSV_CONTACTS_HEADER.standard[index]] = data
              }
            })
            contactObj['createdBy'] = this.authService.userId;

            // When dealing with phone numbers, exclude every other character that's not digit
            if (contactObj['numbers']) {
              if (Array.isArray(contactObj['numbers'])) {
                contactObj['numbers'].map((num, index) => {
                  contactObj['numbers'][index] = this.extractNumbersFromString(num)
                })
              } else {
                contactObj['numbers'] = this.extractNumbersFromString(contactObj['numbers'])
              }
            }
            contacts.push(contactObj)

          }
        })
        resolve(contacts);
      }
    });
  }

  /**
   * Import users from CSV file
   * @param {File} file File to be imported
   * @returns {Promise<ImportUser[]>} Users imported
   */
  public async importUsersCsv(file: File): Promise<ImportUser[]> {
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        const csv: string = reader.result as string;
        let csvLinesList: string[] = csv.split('\r\n');
        if (csvLinesList.length === 1) {
          csvLinesList = csv.split('\n');
        }
        const header: string[] = csvLinesList[0].split(',');
        const users: ImportUser[] = [];
        csvLinesList.shift();
        csvLinesList.forEach((user) => {
          if (user !== '') {
            const userData: string[] = user.split(',');
            const userObj: any = {};
            userData.map((data, index) => {
              userObj[header[index]] = data;
            });
            users.push(userObj as ImportUser);
          }
        });
        resolve(users);
      };
    });
  }

  public extractNumbersFromString(inputString) {
    // Use a regular expression to match and extract numbers
    const numbersArray = inputString.match(/\d+/g);

    // Join the extracted numbers into a single string
    if (numbersArray) {
      return numbersArray.join('');
    } else {
      return ''; // Return an empty string if no numbers are found
    }
  }

}
