/**
 * Get links from HTML based on a regular expression
 * @param {string} html HTML
 * @param {RegExp} regex Regular expression to match
 * @returns {string[]} Links
 */
export const getLinksFromHTML = (html: string, regex: RegExp): string[] => {
  // Get matches
  const matches = html.matchAll(regex);

  // Check if has matches
  if (!matches) return [];

  // Get in array
  const links = Array.from(matches, (match) => match[1]);

  // Transform in set to remove duplicates
  const linksSet = new Set(links);

  // Return array
  return Array.from(linksSet);
}
