import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-float-dialpad-button',
  templateUrl: './float-dialpad-button.component.html',
  styleUrls: ['./float-dialpad-button.component.scss'],
})
export class FloatDialpadButtonComponent {
  private showDialpad = false;
  @ViewChild('dialpad') dialpad: ElementRef;

  constructor() {}

  toggleDialpad(): void {
    if (!this.showDialpad) {
      this.openDialpad();
    } else {
      this.closeDialpad();
    }

    this.showDialpad = !this.showDialpad;
  }

  private openDialpad(): void {
    this.dialpad.nativeElement.style.height = '100%';
  }

  private closeDialpad(): void {
    this.dialpad.nativeElement.style.height = '0';
  }
}
