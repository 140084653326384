import { environment } from "@environments/environment";

// Storage
export const INITIAL_GIF = 'mobi_phone_initial_gifs';
export const GIF_HISTORY = 'mobi_phone_recent_gifs';

// Tenor Attrs
export const TENOR_BASE_URL = `${environment.tenorURL}/search`; // Tenor search URL
export const TENOR_POPULAR = `${environment.tenorURL}/featured`; // Tenor popular URL
export const TENOR_CLIENT_KEY = `Mobi Phone`; // Client key
export const TENOR_SEARCH_LIMIT = 50; // Limit
export const MAX_GIF_HISTORY = 10; // Max gif on history
