import { Injectable } from '@angular/core';
import { NotifierService, PlatformService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_BUSY_SETTINGS,
  DEFAULT_CALLING_SETTINGS,
  DEFAULT_NOTIFICATION_SETTINGS,
  DEFAULT_RING_SETTINGS
} from '@shared/constants';
import { DEVICES_TYPES } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class UserMediaService {
  // Devices
  public inputDevices: MediaDeviceInfo[] = [];
  public outputDevices: MediaDeviceInfo[] = [];
  public videoDevices: MediaDeviceInfo[] = [];

  constructor(
    public platform: PlatformService,
    private translate: TranslateService,
    private notifier: NotifierService,
  ) { }

  /**
   * Fetch devices
   * https://stackoverflow.com/questions/46648645/navigator-mediadevices-enumeratedevices-not-display-device-label-on-firefox
   */
  public async fetchDevices(video = true): Promise<void> {
    try {
      // Get stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video, });

      // Get devices from user media
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Stop stream
      stream.getTracks().forEach((track) => track.stop());

      // Split devices
      this.inputDevices = devices.filter((device) => device.kind === 'audioinput');
      this.outputDevices = devices.filter((device) => device.kind === 'audiooutput');
      this.videoDevices = devices.filter((device) => device.kind === 'videoinput');
    } catch (error) {
      // If is second try and is not video error, return
      if (!video) return;

      // Check if is dom exception video
      if (error.name === 'NotAllowedError') {
        // Show notification
        this.notifier.showNotification({
          type: 'error',
          message: 'permissionDeniedMessage',
          actionText: 'OK',
          panelClass: 'error',
        });
      } else this.fetchDevices(false); // Fetch devices without video
    }
  }

  /**
   * Play busy audio
   */
  public busySound(): void {
    // Get busy audio
    const busy = document.getElementById('audio-busy') as any;

    // Pause busy audio if is playing
    if (!busy.paused) this.pauseBusy();

    // Set busy audio
    busy.src = DEFAULT_BUSY_SETTINGS.src;
    busy.loop = DEFAULT_BUSY_SETTINGS.loop;
    busy.volume = DEFAULT_BUSY_SETTINGS.volume;
    busy.load();
    busy.play();

    // Set timeout to pause busy audio
    setTimeout(() => this.pauseBusy(), 4000);
  }

  /**
   * Pause all sounds
   */
  public pauseAllSounds(): void {
    this.pauseBusy();
    this.pauseCalling();
    this.pauseRing();
  }

  /**
   * Play calling audio
   */
  public callingSound(): void {
    // Pause busy audio
    this.pauseBusy();

    // Get busy audio
    const calling = document.getElementById('audio-calling') as any;

    // Set calling audio
    calling.src = DEFAULT_CALLING_SETTINGS.src;
    calling.loop = DEFAULT_CALLING_SETTINGS.loop;
    calling.volume = DEFAULT_CALLING_SETTINGS.volume;
    calling.load();
    calling.play();

    // Set timeout to pause busy audio
    setTimeout(() => this.pauseCalling(), 60000);
  }

  /**
   * Play ring audio
   */
  public ringingSound(): void {
    // Pause busy audio
    this.pauseBusy();

    // Play
    Promise
      .allSettled([this.playPrimaryRingtone(), this.playSecondRingtone()]) // Play ring audio
      .then(() => setTimeout(() => this.pauseRing(), 60000)); // Set timeout to pause ring audio
  }

  /**
   * Play ring audio
   */
  private async playPrimaryRingtone(): Promise<void> {
    // Get secondary ring audio
    const ring = document.getElementById('audio-ring') as any;

    // Try to set sink id
    try {
      // Get output device
      const deviceId = this.getDefaultDeviceId('speakerphone');

      // Set sink id
      await ring.setSinkId(deviceId);
    } catch (error) {
      console.warn('Navegador não suporta seleção de dispositivos de saída!');
    }

    // Set secondary ring audio
    ring.src = DEFAULT_RING_SETTINGS.src;
    ring.loop = DEFAULT_RING_SETTINGS.loop;
    ring.volume = DEFAULT_RING_SETTINGS.volume;
    ring?.load();
    ring?.play();
  }

  /**
   * Play secondary ring audio
   */
  private async playSecondRingtone(): Promise<void> {
    // If can ring on additional device is disabled, return
    if (!this.canRingOnAdditionalDevice()) return;

    // Get secondary ring audio
    const secondaryRing = document.getElementById('secondary-audio-ring') as any;

    // Try to set sink id
    try {
      // Get ringtone device
      const deviceId = this.getDefaultDeviceId('ringtone');

      // Set sink id
      await secondaryRing.setSinkId(deviceId);
    } catch (error) {
      console.warn('Navegador não suporta seleção de dispositivos de saída!');
    }

    // Set secondary ring audio
    secondaryRing.src = DEFAULT_RING_SETTINGS.src; // Set src
    secondaryRing.loop = DEFAULT_RING_SETTINGS.loop; // Set loop
    secondaryRing.volume = DEFAULT_RING_SETTINGS.volume; // Set volume
    secondaryRing.load(); // Load audio
    secondaryRing.play(); // Play audio
  }

  /**
   * Check if can ring on additional device
   * @returns {boolean} Can ring on additional device
   */
  public canRingOnAdditionalDevice(): boolean {
    return localStorage.getItem('canRingOnAdditionalDevice') === 'true';
  }

  /**
   * Play notification audio
   */
  public async notificationSound(): Promise<void> {
    // Play notification audio
    await Promise.allSettled([this.playPrimaryNotification(), this.playSecondaryNotification()]);
  }

  /**
   * Play primary notification audio
   */
  private async playPrimaryNotification(): Promise<void> {
    // Get audio notification
    const notification = document.getElementById('audio-notification') as any;

    // Set audio notification
    notification.src = DEFAULT_NOTIFICATION_SETTINGS.src;
    notification.loop = DEFAULT_NOTIFICATION_SETTINGS.loop;
    notification.volume = DEFAULT_NOTIFICATION_SETTINGS.volume;
    notification.load();
    notification.play();
  }

  /**
 * Play secondary notification audio
 */
  private async playSecondaryNotification(): Promise<void> {
    // If can ring on additional device is disabled, return
    if (!this.canRingOnAdditionalDevice()) return;

    // Get secondary ring audio
    const secondaryNotification = document.getElementById('secondary-audio-notification') as any;

    // Try to set sink id
    try {
      // Get ringtone device
      const deviceId = this.getDefaultDeviceId('ringtone');

      // Set sink id
      await secondaryNotification.setSinkId(deviceId);
    } catch (error) {
      console.warn('Navegador não suporta seleção de dispositivos de saída!');
    }

    // Set secondary ring audio
    secondaryNotification.src = DEFAULT_NOTIFICATION_SETTINGS.src; // Set src
    secondaryNotification.loop = DEFAULT_NOTIFICATION_SETTINGS.loop; // Set loop
    secondaryNotification.volume = DEFAULT_NOTIFICATION_SETTINGS.volume; // Set volume
    secondaryNotification.load(); // Load audio
    secondaryNotification.play(); // Play audio
  }


  /**
   * Pause busy audio
   */
  public pauseBusy(): void {
    try {
      // Get busy audio
      const busy = document.getElementById('audio-busy') as any;

      // Disable loop and set volume to 0
      busy.loop = false;
      busy.volume = 0;

      // Pause busy audio
      busy?.pause();
    } catch (error) {
      console.warn("Failed to pause busy audio!")
    }
  }

  /**
   * Pause calling audio
   */
  public pauseCalling(): void {
    try {
      // Get busy audio
      const calling = document.getElementById('audio-calling') as any;

      // Disable loop
      calling.loop = false;
      calling.volume = 0;

      // Pause busy audio
      calling.pause();
    } catch (error) {
      console.warn("Failed to pause calling audio!")
    }
  }

  /**
   * Pause ring audio
   */
  public pauseRing(): void {
    try {
      // Get ring audios
      const ringAudio = document.getElementById('audio-ring') as any;
      const secondaryRingAudio = document.getElementById('secondary-audio-ring') as any;

      // Disable loop and set volume to 0
      ringAudio.loop = false;
      secondaryRingAudio.loop = false;
      ringAudio.volume = 0;
      secondaryRingAudio.volume = 0;

      // Pause ring audios
      ringAudio.pause();
      secondaryRingAudio.pause();
    } catch (error) {
      console.warn("Failed to pause ring audio!")
    }
  }

  /**
   * Get default device type
   * @param {('microphone' | 'speakerphone' | 'video' | 'ringtone')} type Device type
   * @returns {DEVICES_TYPES} Device type
   */
  private getDefaultType(type: ('microphone' | 'speakerphone' | 'video' | 'ringtone')): DEVICES_TYPES {
    switch (type) {
      case 'microphone':
        return DEVICES_TYPES.INPUT;
      case 'speakerphone':
        return DEVICES_TYPES.OUTPUT;
      case 'video':
        return DEVICES_TYPES.VIDEO;
      default:
        return DEVICES_TYPES.SECONDARY_RING;
    }
  }

  /**
   * Check if device is connected
   * @param {string} deviceId Device id
   * @param {MediaDeviceKind} kind Device kind
   * @returns {boolean} True if device is connected
   */
  private isDeviceConnected(deviceId: string, kind: MediaDeviceKind): boolean {
    switch (kind) {
      case 'audioinput':
        return this.inputDevices.some((d) => d.deviceId === deviceId);
      case 'audiooutput':
        return this.outputDevices.some((d) => d.deviceId === deviceId);
      default:
        return this.videoDevices.some((d) => d.deviceId === deviceId);
    }
  }

  /**
   * Get default device id
   * @param {('microphone' | 'speakerphone' | 'video' | 'ringtone')} type Device type
   * @returns {string} Default device id
   */
  public getDefaultDeviceId(type: ('microphone' | 'speakerphone' | 'video' | 'ringtone')): string {
    // Get default device id
    const defaultDeviceId = localStorage.getItem(this.getDefaultType(type));

    // Get default device id
    switch (type) {

      // Microphone device
      case 'microphone':
        // If there is no input devices, return empty string
        if (this.inputDevices.length === 0) return '';

        // If there is no default input device, return first input device
        return this.isDeviceConnected(defaultDeviceId, 'audioinput')
          ? defaultDeviceId
          : this.inputDevices[0].deviceId;

      // Speakerphone device
      case 'speakerphone':
        // If there is no output devices, return empty string
        if (this.outputDevices.length === 0) return '';

        // If there is no default output device, return first output device
        return this.isDeviceConnected(defaultDeviceId, 'audiooutput')
          ? defaultDeviceId
          : this.outputDevices[0].deviceId;

      // Video device
      case 'video':
        // If there is no video devices, return empty string
        if (this.videoDevices.length === 0) return '';

        // If there is no default video device, return first video device
        return this.isDeviceConnected(defaultDeviceId, 'videoinput')
          ? defaultDeviceId
          : this.videoDevices[0].deviceId;

      // Ringtone device
      default:
        // If can ring on additional device is disabled, return empty string
        if (!this.canRingOnAdditionalDevice) return '';

        // If there is no output devices, return empty string
        if (this.outputDevices.length === 0) return '';

        // If there is no default ringtone device, return first output device
        return this.isDeviceConnected(defaultDeviceId, 'audiooutput')
          ? defaultDeviceId
          : this.outputDevices[0].deviceId;
    }
  }

  /**
   * Form media stream constraints
   * @returns {MediaStreamConstraints} Media stream constraints
   */
  public getConstraints(videoMuted: boolean): MediaStreamConstraints {
    // Init constraints
    let constraints: MediaStreamConstraints = null;

    // Get devices
    const audioSource = this.getDefaultDeviceId('microphone');
    const videoSource = this.getDefaultDeviceId('video');

    // If is Firefox
    if (this.platform.isFirefox()) {

      // Format constraints to Firefox
      constraints = {
        audio: audioSource
          ? {
            deviceId: { exact: audioSource },
            echoCancellation: true,
          }
          : true,
        video:
          videoMuted || this.videoDevices.length === 0
            ? false
            : videoSource
              ? {
                deviceId: { exact: videoSource },
              }
              : true,
      };
    } else {
      // Format constraints
      constraints = {
        audio: audioSource
          ? {
            deviceId: { exact: audioSource },
            echoCancellation: true,
          }
          : true,
        video:
          videoMuted || this.videoDevices.length === 0
            ? false
            : videoSource
              ? {
                deviceId: { exact: videoSource },
              }
              : true,
      };
    }

    // Return constraints
    return constraints === null
      ? { audio: true, video: !videoMuted }
      : constraints;
  }
}
