import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as fernet from 'fernet';


@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  private key = 'L3uc0.';

  constructor() {}

  encrypt(data: string, key = this.key): string {
    return CryptoJS.AES.encrypt(data, key).toString();
  }

  decrypt(data: string, key = this.key): string {
    const bytes = CryptoJS.AES.decrypt(data, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  decryptText(data: string, key: string): string {
    try{
        var secret = new fernet.Secret(key);
    
        var token = new fernet.Token({
            secret: secret,
            token: data,
            ttl: 0
        })
        return token.decode();        
    } catch (error){
      console.log(error);
      throw error;
    }
    }
}
