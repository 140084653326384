import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './remove-profile-picture-dialog.component.html',
  styleUrls: ['./remove-profile-picture-dialog.component.scss'],
})
export class RemoveProfilePictureDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<RemoveProfilePictureDialog>) {}

  ngOnInit(): void {}

  public onSubmit(): void {
    this.dialogRef.close(true);
  }
}
