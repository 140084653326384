import { PbxSector, User } from '@shared/models';

export interface TransferData {
  selfData: User;
  remoteData: User;
  userTo: User | PbxSector | string;
  userFrom: User;
  tenantId: string;
  isSector: boolean;
  isAttendedTransfer: boolean;
}

export interface HoldData {
  selfData: User;
  remoteData: User;
  userInHold: User;
  tenantId: string;
}

export interface HangupCauseData {
  hangupcause: {
    code: number;
    exten: string;
    companyId: string;
    causeList: {
      channel: string;
      message: string;
    }[];
  }
}

export interface ToggleVideoData {
  selfData: User;
  remoteData: User;
  watchingUser: User;
  enabled: boolean;
  tenantId: string;
}

export interface MultiLineCallData {
  currentSessionUser: User;
  selfData: User;
  newSessionUser: User | string;
  userFrom: User;
  tenantId: string;
  isExternal?: boolean;
}
