import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type Size = 'small' | 'medium' | 'large';

const config = {
  small: [Breakpoints.Small, Breakpoints.XSmall],
  medium: [Breakpoints.Medium],
  large: [Breakpoints.Large, Breakpoints.XLarge],
};

@Injectable({
  providedIn: 'root',
})
export class ViewSizeService {
  private viewMatch: Subject<boolean> = new Subject<boolean>();
  match: boolean = false;

  constructor(private observer: BreakpointObserver) {
    this.viewMatch.next(false);
  }

  checkViewSize(size: Size[]): void {
    const queries = size.map((value) => config[value]);

    this.observer
      .observe(queries.reduce((acc, val) => acc.concat(val), []))
      .subscribe(({ matches }: BreakpointState) => {
        this.viewMatch.next(matches);
        this.match = matches;
      });
  }

  viewMatches(): Observable<boolean> {
    return this.viewMatch;
  }
}
