import { MeetTimeRange } from "@shared/models";

/**
 * Compare two dates
 * @param {Date} date1 First date
 * @param {Date} date2 Second date
 */
export const compareDates = (date1: Date, date2: Date): boolean => {
  return date1.getFullYear() == date2.getFullYear() &&
    date1.getMonth() == date2.getMonth() &&
    date1.getDate() == date2.getDate();
}

export const checkHoursConflict = (timeRanges: MeetTimeRange[]): boolean => {
  for (let i = 0; i < timeRanges.length; i++) {
    const range1 = timeRanges[i];
    const start1 = parseTime(range1.startTime);
    const end1 = parseTime(range1.endTime);

    for (let j = i + 1; j < timeRanges.length; j++) {
      const range2 = timeRanges[j];
      const start2 = parseTime(range2.startTime);
      const end2 = parseTime(range2.endTime);

      if (start1 < end2 && end1 > start2) {
        // There is a conflict between the time ranges
        return true;
      }
    }
  }

  // There is no conflict between the time ranges
  return false;
};

const parseTime = (time: string): Date => {
  const [hours, minutes] = time.split(':');
  const currentDate = new Date();
  currentDate.setHours(Number(hours), Number(minutes), 0, 0);
  return currentDate;
};

