import { Injectable } from '@angular/core';
import { emojis, EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { EMOJI_LIST, REACTIONS_EMOJI_OPTIONS } from '@shared/constants';
import { Emoji } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class CustomEmojiService {
  private static cachedEmojiRegex: RegExp;
  public emojiList = EMOJI_LIST as Emoji[];
  public defaultChars = ['❤']

  constructor(private emojiService: EmojiService) { }

  /**
   * Get reactions emoji options
   * @param {Emoji[]} emojiList All emojis
   * @returns {Emoji[]} Reactions emoji options
   */
  public getReactionEmojis(emojiList: Emoji[] = this.emojiList): Emoji[] {
    // Initiate reaction list options
    const reactionList: Emoji[] = [];

    // Get only necessary emojis
    emojiList.forEach(emoji => {
      if (!!REACTIONS_EMOJI_OPTIONS.includes(emoji.name))
        reactionList.push(emoji);
    })

    // Return reaction list sorted
    return reactionList.sort((a, b) => REACTIONS_EMOJI_OPTIONS.indexOf(a.name) - REACTIONS_EMOJI_OPTIONS.indexOf(b.name));
  }

  /**
   * Get hex code of emoji
   * @param {string} unicodeEmoji Unicode
   * @returns {string} Emoji hex value
   */
  public getHexCode(unicodeEmoji: string): string {
    const hexCodeSegments = [];
    let i = 0;
    while (i < unicodeEmoji.length) {
      const segment = unicodeEmoji.codePointAt(i).toString(16).toUpperCase();
      hexCodeSegments.push(segment);

      i += Math.ceil(segment.length / 4);
    }
    return hexCodeSegments.join('-');
  }

  /**
   * Find emoji by hex code
   * @param {string} hexCode Hex code
   * @returns {Emoji} Emoji
   */
  public findEmojiByHexCode(hexCode: string): Emoji {
    return this.emojiList.find(emoji => emoji.codes == hexCode);
  }

  /**
   *
   * Find emoji by hex code
   * @param {string} char Char
   * @returns {Emoji} Emoji
   */
  public findEmojiByChar(char: string): Emoji {
    return this.emojiList.find(emoji => emoji.char == char);
  }

  /**
   * Get all emojis on the message
   * @param {string} msg Current msg content
   * @returns {string[]} Emojis on the msg
   */
  public getOnlyEmojis(msg: string): string[] {
    return msg?.match(this.emojiRegex)?.filter(value => !!value.trim());
  }

  /**
   * Check if msg content has only emojis
   * @param {string} msgContent Msg content
   * @returns {boolean} True if msg has only emojis
   */
  public hasOnlyEmojis(msgContent: string): boolean {
    // Check if msg content is valid
    if (!msgContent) return false;

    // Get only emojis
    const emojis = this.getOnlyEmojis(msgContent);

    // Remove <p> and </p> from the msg to compare
    msgContent = msgContent.replace(/<p>/g, '').replace(/<\/p>/g, '');

    // Return true if msg has only emojis
    return !!emojis?.length && !(/[A-Za-z0-9]|\;|\.|\~|\^\+|\^|\(|\)|\!|\'|\@|\#|\*|\%|\"|\_|\-|\=|\§|\º|\?|\/|\`|\,/.test(msgContent));
  }

  /**
   * Regex matching all unicode emojis contained in emoji-mart
   */
  public get emojiRegex(): RegExp {
    if (CustomEmojiService.cachedEmojiRegex) {
      return CustomEmojiService.cachedEmojiRegex;
    }

    let characterRegexStrings: string[] = [...this.defaultChars];
    for (const emoji of emojis) {
      characterRegexStrings.push(this.emojiService.unifiedToNative(emoji.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

      if (emoji.skinVariations) {
        for (const skinVariation of emoji.skinVariations) {
          characterRegexStrings.push(this.emojiService.unifiedToNative(skinVariation.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        }
      }
    }

    characterRegexStrings = characterRegexStrings.sort((a, b) => {
      if (a.length > b.length) {
        return -1;
      }

      if (b.length > a.length) {
        return 1;
      }

      return 0;
    });

    const strings = characterRegexStrings;
    const reString = '(' + strings.join('|') + ')';
    CustomEmojiService.cachedEmojiRegex = new RegExp(reString, 'gu');

    return CustomEmojiService.cachedEmojiRegex;
  }
}
