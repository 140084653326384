import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierService } from '@core/services';
import { environment } from '@environments/environment';
import { MarkdownPipe, ReplaceMsgContentPipe } from '@shared/pipes';
import { AuthTokenInterceptor } from './interceptor/auth-token.interceptor';

@NgModule({
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideStorage(() => getStorage()),
  ],
  providers: [
    DatePipe,
    ReplaceMsgContentPipe,
    MarkdownPipe,
    NotifierService,
    MatSnackBar,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
  ],
  declarations: [
  ],
})
export class CoreModule { }
