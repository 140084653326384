<h1 mat-dialog-title>{{ 'similarContacts' | translate }}</h1>

<mat-dialog-content class="mat-typography column">
  <div class="similar-contacts-wrapper">
    <div class="similar-contacts" *ngFor="let contact of duplicatedContacts">
      <div class="similar-contact">
        <div>
          {{ contact.name }}
        </div>
        <div>
          {{ contact.company }}
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
