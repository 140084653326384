import { EventTimeline } from "matrix-js-sdk";

export function getFirstLinkedTimeline(timeline): EventTimeline  {
  let tm = timeline;

  while (tm.prevTimeline) {
    tm = tm.prevTimeline;
  }

  return tm;
}

export function getLastLinkedTimeline(timeline): EventTimeline {
  let tm = timeline;

  while (tm.nextTimeline) {
    tm = tm.nextTimeline;
  }

  return tm;
}

export function getLastEventsUntilEvent(timeline: EventTimeline, eventId: string): EventTimeline {
  let tm = timeline as any;
  let exist = false;

  while (exist) {
    exist = !!tm.getEvents().find(e => e.getId() == eventId);
    tm = tm.nextTimeline;
  }

  return tm;
}

export function isTimelineLinked(tm1, tm2) {
  let tm = getFirstLinkedTimeline(tm1) as any;
  while (tm) {
    if (tm === tm2) return true;
    tm = tm.nextTimeline;
  }
  return false;
}

export function getUnfilteredTimelineSet(room) {
  return room.getUnfilteredTimelineSet();
}


export function hasEventInTimeline(eventId, timeline, room) {
  const timelineSet = getUnfilteredTimelineSet(room);
  const eventTimeline = timelineSet.getTimelineForEvent(eventId);
  if (!eventTimeline) return false;
  return isTimelineLinked(eventTimeline, timeline);
}

export function getEventInTimeline(eventId, room) {
  const timelineSet = getUnfilteredTimelineSet(room);
  return timelineSet.getTimelineForEvent(eventId);
}

export function findEventByIdInTimelineSet(eventId, room) {
  const eventTimelineSet = getUnfilteredTimelineSet(room)
  return eventTimelineSet.findEventById(eventId);
}

export function iterateLinkedTimelines(timeline, backwards, callback) {
  let tm = timeline;
  while (tm) {
    callback(tm);
    if (backwards) tm = tm.prevTimeline;
    else tm = tm.nextTimeline;
  }
}
