import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router
} from '@angular/router';
import { AuthService } from '@core/services';
import { UserType } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AdministrationGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate() {
    if (this.auth.user.userType == UserType.administrator) return true;
    else this.router.navigate(['/']);
  }
}
