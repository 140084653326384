import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { LcsEventDescriptions, LcsEventsEntryRouteTypes } from '@shared/enums';
import { EntryRoute } from '@shared/models/entryRoute.model';
import { getDiffBetweenObj, getEntryRoutesPath } from '@shared/utils';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { LcsEventsService } from '../lcs-events/lcs-events.service';
import { NotifierService } from '../notifier/notifier.service';

@Injectable({
  providedIn: 'root',
})
export class EntryRouteService {
  private companyId: string = '';

  constructor(
    private lcsEventsService: LcsEventsService,
    private notifier: NotifierService,
    private firestore: Firestore,
    private authService: AuthService
  ) {
    this.companyId =
      this.authService.user.companyId ??
      localStorage.getItem('mobi_phone:tenantId');
  }

  /**
   * Create a new external entryRoute
   * @param {EntryRoute} entryRoute EntryRoute data
   */
  public async create(
    entryRoute: EntryRoute,
    companyId: string = this.companyId
  ): Promise<string> {
    // Get collection reference
    const colRef = collection(this.firestore, getEntryRoutesPath(companyId));

    try {
      // Add entryRoute to firestore
      const newDoc = await addDoc(colRef, entryRoute);

      await this.handleSuccess(LcsEventsEntryRouteTypes.ENTRY_ROUTE_CREATION, {
        created: { ...entryRoute, id: newDoc.id },
      });

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'entryRouteCreatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });

      return newDoc.id;
    } catch (err: any) {
      this.handleError(err, LcsEventsEntryRouteTypes.ENTRY_ROUTE_CREATION);
    }
  }

  /**
   * Update an existing entryRoute
   * @param {any} newEntryRoute Updated entryRoute data
   * @param {string} entryRouteId EntryRoute ID
   */
  public async update(
    newEntryRoute: any,
    entryRouteId: string,
    originalEntryRoute: any
  ) {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getEntryRoutesPath(this.companyId, entryRouteId)
    );

    try {
      // Update entryRoute
      await updateDoc(docRef, { ...newEntryRoute });

      this.handleSuccess(LcsEventsEntryRouteTypes.ENTRY_ROUTE_UPDATE, {
        id: newEntryRoute.id,
        name: newEntryRoute.name,
        diff: getDiffBetweenObj(newEntryRoute, originalEntryRoute, ['id']),
      });

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'entryRouteUpdatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });
    } catch (err) {
      this.handleError(err, LcsEventsEntryRouteTypes.ENTRY_ROUTE_UPDATE);
    }
  }

  /**
   * Get an entryRoute by ID
   * @param {string} id EntryRoute ID
   */
  public async getById(id: string) {
    // Get doc reference
    const docRef = doc(this.firestore, getEntryRoutesPath(this.companyId, id));

    // Get doc
    const document = await getDoc(docRef);
    // Check if doc exists
    if (!document.exists()) return null;

    // Get entryRoute data
    const entryRoute = document.data() as any;

    // Add id to entryRoute
    entryRoute.id = id;

    // Return entryRoute data
    return entryRoute;
  }

  /**
   * Fetch all entryRoutes
   * @param {string} companyId Company ID
   */
  public fetchAll(companyId: string): Observable<any[]> {
    const col = <CollectionReference<any>>(
      collection(this.firestore, getEntryRoutesPath(companyId))
    );

    return collectionData<any>(col, { idField: 'id' });
  }

  /**
   * Delete an entryRoute
   * @param {EntryRoute} entryRoute EntryRoute data
   */
  public async delete(entryRoute: EntryRoute): Promise<void> {
    // Get doc reference
    const docRef = doc(
      this.firestore,
      getEntryRoutesPath(this.companyId, entryRoute.id)
    );

    try {
      // Delete entryRoute from firestore
      await deleteDoc(docRef);

      this.handleSuccess(LcsEventsEntryRouteTypes.ENTRY_ROUTE_DELETION, {
        deleted: {
          id: entryRoute.id,
        },
      });
    } catch (err) {
      this.handleError(err, LcsEventsEntryRouteTypes.ENTRY_ROUTE_DELETION);
    }
  }

  /**
   * Handle success
   * @param {LcsEventsEntryRouteTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(
    type: LcsEventsEntryRouteTypes,
    value: any
  ): Promise<void> {
    return this.lcsEventsService.create(
      { type, description: LcsEventDescriptions.SUCCESS, value },
      true
    );
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventsEntryRouteTypes} type The event type
   */
  public handleError(error: any, type: LcsEventsEntryRouteTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventsService.create(
      { type, description: LcsEventDescriptions.ERROR, value: { error } },
      true
    );

    // Throw the error
    throw error;
  }
}
