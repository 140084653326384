import { Injectable } from '@angular/core';
import {
  ClientService,
  CustomEmojiService,
  RoomService,
  UnreadService
} from '@core/services';
import {
  BASE_EVENTS_LOAD_LIMIT,
  CALL_EVENT,
  INITIAL_EVENTS_NUMBER,
  MAX_TIMELINE_EVENTS,
  MEMBER_ACTIONS_EVENT,
  NOT_MEMBER_STATUS
} from '@shared/constants';
import { MatrixMemberActions } from '@shared/enums';
import { PaginationTimelineResult, Reaction, ReplyItem, ScrollPagination, TimelineItem } from '@shared/models';
import {
  getLimitOfElementsToLoadOnTimeline,
  getMsgType,
  getTheLastVisitedRoom,
  isCallEvent,
  isEditedEvent,
  isReactionEvent,
  isRedactionEvent,
  parseReply
} from '@shared/utils';
import {
  Direction,
  EventTimeline,
  EventType,
  IPaginateOpts,
  IRoomTimelineData,
  MatrixClient,
  MatrixEvent,
  Room,
  RoomMember
} from 'matrix-js-sdk';
import { ReceiptType } from 'matrix-js-sdk/lib/@types/read_receipts';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimelineService {
  // Others Flags
  public timeline = new Map<string, MatrixEvent>();
  private roomId = null;
  private room: Room;
  private matrixClient: MatrixClient;
  private activeTimeline: EventTimeline = null;
  private scrollFocus: ('bottom' | 'float' | 'top') = 'bottom';

  // Sets
  public typingMembers = new Set();

  // Maps
  public reactionList = new Map<string, MatrixEvent>();
  public redactionList = new Map<string, MatrixEvent>();
  public editionList = new Map<string, MatrixEvent>();
  public createdDateList = new Map<string, number>();

  // Listenings
  public timelineEvents = new BehaviorSubject<MatrixEvent>(null);
  public removeTimelineEvent = new Subject<string>();
  public receiptEvents = new BehaviorSubject<any>(null);
  public redactionEvents = new BehaviorSubject<MatrixEvent>(null);
  public editionEvents = new BehaviorSubject<MatrixEvent>(null);
  public callEvents = new Subject<MatrixEvent>();

  constructor(
    private clientService: ClientService,
    private customEmojiService: CustomEmojiService,
    private roomService: RoomService,
    private unreadService: UnreadService,
  ) { }

  /**
   * Init room
   * @param {string} roomId Room ID
   */
  public init(roomId: string) {
    // Get matrix client
    this.matrixClient = this.clientService.getClient();

    // Set room data
    this.setRoomId(roomId);
    this.setRoom();

    // Initiate timeline
    this.setLiveTimeline();
    this.populateTimelines();
  }

  /**
   * Set room id
   * @param {string} roomId New value to room id
   */
  public setRoomId(roomId: string = getTheLastVisitedRoom()) {
    this.roomId = roomId;
  }

  /**
   * Room
   * @param roomId Room id
   */
  public setRoom(roomId: string = this.roomId) {
    this.room = this.matrixClient.getRoom(roomId);
  }

  /**
   * Set live timeline
   */
  public setLiveTimeline() {
    this.activeTimeline = this.room?.getLiveTimeline();
  }

  /**
   * Get next timeline
   */
  public getNextTimeline() {
    return this.room?.getLiveTimeline()?.getNeighbouringTimeline(Direction.Forward);
  }

  getTimelineForEvent(eventId: string): EventTimeline {
    return this.room?.getTimelineForEvent(eventId)
  }

  /**
   * Get previous timeline
   */
  public getPreviousTimeline() {
    return this.room?.getLiveTimeline()?.getNeighbouringTimeline(Direction.Backward);
  }

  /**
   * Load previous messages for a room and add it to the room timeline
   * @param {string} roomId Room id
   * @param {number} limit - defaults
   * @returns {Promise<boolean>} false if there are no more events to load, else true
   */
  public async loadPreviousMessages(roomId: string, limit: number = getLimitOfElementsToLoadOnTimeline()): Promise<PaginationTimelineResult> {
    // Get current room
    const room = this.matrixClient.getRoom(roomId);

    // Check if exist
    if (!room) return;

    // Paginate timeline
    return await this.paginateTimeline(limit)
  }


  /**
   * Check if msg has reactions
   * @param {string} eventId Event id
   * @returns {boolean} True if msg has reactions
   */
  public hasReactions(eventId: string): boolean {
    return !![...this.reactionList.values()]
      .find(e => e.getRelation()?.event_id === eventId)
  }

  /**
   * Form timeline item
   * @param {MatrixEvent} mEvent Matrix Event
   * @returns {TimelineItem} Item
   */
  public formTimelineItem(mEvent: MatrixEvent): TimelineItem {
    // Event id
    const id = mEvent.getId();

    // Get event time
    const time = mEvent.getDate()?.getTime();

    // Get content
    const content = mEvent.getContent();

    // Get event type
    const eventType = mEvent.getType() as EventType;

    // Check if is a member action
    const isMemberAction = MEMBER_ACTIONS_EVENT.includes(eventType);

    // Get msg content
    let msg = content.body;

    // Reply content
    let replyTo: ReplyItem = null;

    // Get users that reads the msg
    const receipts = this.getEventReaders(mEvent);

    // Check if the message was edited
    const wasEdited = this.editionList.has(id);

    // Check if the message was forward
    const wasForward = !!content.forward;

    // Check if the message was deleted
    const wasDeleted = !!mEvent?.event?.unsigned?.redacted_because || this.redactionList.has(id);

    // Get msg type
    const msgType = getMsgType(mEvent);

    // Has only emojis
    const hasOnlyEmojis = this.customEmojiService.hasOnlyEmojis(msg);

    const isRecording = !!content.isRecording;

    // Check if is a reply msg
    if (mEvent.replyEventId) {
      // Get reply from body
      const { replyData, body } = parseReply(msg, mEvent.replyEventId);

      // Update body
      msg = body;

      // Update replyTo
      replyTo = replyData;
    }

    // Form obj
    return new TimelineItem({
      id,
      msg,
      time,
      mEvent,
      roomId: mEvent.getRoomId(),
      content,
      replyTo,
      msgType,
      eventType,
      hasOnlyEmojis,
      isMemberAction,
      name: this.getMsgOwner(mEvent.getSender()),
      memberAction: this.getMemberAction(mEvent),
      read: receipts.length && this.getMembersCount() - 1 === receipts.length,
      markers: {
        deleted: wasDeleted,
        edited: wasEdited,
        forwarded: wasForward,
      },
      isRecording,
    });
  }

  /**
   * Get Members Count
   * @returns {number} Number of valid members in the room
   */
  public getMembersCount(): number {
    // Initiate the counter
    let count = 0;

    // Get all members of the room
    [...this.room.getMembers()]
      .forEach(member => {
        if (!NOT_MEMBER_STATUS.includes(member.membership)) count += 1;
      })

    // Return the result
    return count;
  }

  /**
  * Get member action
  * @param {MatrixEvent} mEvent Matrix Event
  * @returns {MatrixMemberActions} Member Action
  */
  public getMemberAction(mEvent: MatrixEvent): MatrixMemberActions {
    switch (mEvent.getType()) {
      case EventType.RoomName:
        return MatrixMemberActions.name;

      case EventType.RoomAvatar:
        return MatrixMemberActions.avatar;

      case EventType.RoomPowerLevels:
        return MatrixMemberActions.power_levels;

      case CALL_EVENT:
        return MatrixMemberActions.call;

      case EventType.RoomMember:
        return mEvent.getContent().membership as MatrixMemberActions;

    }
  }

  /**
   * Get te Mobi phone user id by Matrix user id
   * @param {string} userId Matrix user id
   * @returns {string} Mobi Phone user id
   */
  public getMsgOwner(userId: string): string {
    return userId.slice(userId.indexOf('@') + 1).split(':')[0]
  }

  /**
   *
   * @param {number} limit - defaults to 60
   * @returns {Promise<boolean>} false if there are no more events to load, else true
   */
  public async paginateTimeline(limit: number): Promise<PaginationTimelineResult> {
    try {
      // Form options
      const options: IPaginateOpts = { backwards: true, limit }

      // Paginate timeline
      const result = await this.matrixClient.paginateEventTimeline(this.activeTimeline, options);

      // Get events
      const newEvents = this.getActiveTimelineEvents().slice(0, limit);

      // Add events to timeline
      this.populateTimelines()

      // Return events
      return {
        hasLoadedAllEvents: !result,
        newEvents,
      };
    } catch (e) {
      console.error(e)
      return {
        hasLoadedAllEvents: false,
        newEvents: [],
      };
    }
  }

  /**
   * Verify if room is encrypted
   * @param {MatrixClient} matrixClient Matrix Client
   * @returns true if room is encrypted
   */
  public isEncrypted(matrixClient: MatrixClient): boolean {
    return matrixClient.isRoomEncrypted(this.roomId);
  }

  /**
   * Reset timeline
   */
  public clearLocalTimelines(): void {
    this.timeline.clear();
    this.editionList.clear();
    this.reactionList.clear();
    this.redactionList.clear();
  }

  /**
   * Reset timeline
   */
  public clearActiveTimeline(): void {
    this.activeTimeline = null;
  }

  /**
   * Add event to timeline
   * @param {MatrixEvent} mEvent Matrix Event
   */
  public addToTimeline(mEvent: MatrixEvent) {
    switch (true) {

      // If is reaction add to map
      case isReactionEvent(mEvent):
        this.reactionList.set(mEvent.getId(), mEvent);
        return;

      // If is edition add to map
      case isEditedEvent(mEvent):
        this.editionList.set(mEvent?.getRelation()?.event_id, mEvent);
        return;

      // Return - Not add
      case isRedactionEvent(mEvent):
        return;

      // Add to timeline
      default:
        this.timeline.set(mEvent.getId(), mEvent);
        break;
    }
  }

  /**
   * Populate current timeline
   */
  private populateTimelines() {
    // Clear local timelines
    this.timeline.clear();

    // Populate not visible timeline
    this.getActiveTimelineEvents()
      .forEach((mEvent) => this.addToTimeline(mEvent));
  }

  /**
   * Get text without emojis
   * @param {string} msg Current msg content
   * @returns {string[]} Emojis on the msg
   */
  public getTextWithoutEmojis(msg: string): string[] {
    return msg
      ?.match(/\P{Extended_Pictographic}/gu)
      ?.filter((value) => !!value.trim());
  }

  /**
   * Load timeline to event
   * @param {string} eventId Event id
   * @returns {Promise<MatrixEvent[]>} Timeline with 30 events from this event
   */
  public async loadEventTimeline(eventId: string, paginationIndex: number, eventsToLoad = BASE_EVENTS_LOAD_LIMIT): Promise<{
    events: MatrixEvent[],
    paginationIndex: number
  }> {
    // Get event index
    let eventIndex = this.getActiveTimelineEvents()
      .findIndex(e => e.getId() === eventId);

    try {
      // Load events until find event
      if (eventIndex == -1) {

        // New events
        await this.loadPreviousMessages(this.roomId, eventsToLoad);

        // Update pagination index
        paginationIndex += 1;

        // Update event index
        eventIndex = this.getActiveTimelineEvents().findIndex(e => e.getId() === eventId);

        // Return events
        return eventIndex !== -1
          ? this.getTimelineForEventIndex(eventIndex, paginationIndex)
          : await this.loadEventTimeline(eventId, paginationIndex, eventsToLoad);
      } else return this.getTimelineForEventIndex(eventIndex, paginationIndex);

    } catch (e) {
      console.error(e);
      return {
        paginationIndex,
        events: this.getActiveTimelineEvents().slice(-INITIAL_EVENTS_NUMBER)
      }
    }
  }

  /**
   * Get timeline for event index
   * @param {number} eventIndex Event index
   * @returns {MatrixEvent[]} Timeline with events from this event
   */
  private getTimelineForEventIndex(eventIndex: number, paginationIndex: number): { events: MatrixEvent[], paginationIndex: number } {
    // Return events
    return {
      paginationIndex,
      events: this.getActiveTimelineEvents()
        .slice(eventIndex, eventIndex + MAX_TIMELINE_EVENTS - 1)
    }
  }

  /**
   * Check if has loaded all events
   * @returns {boolean} True if loaded all events
   */
  public hasLoadedAllEvents(): boolean {
    return this.getActiveTimelineEvents()
      .findIndex(e => e.getType() == 'm.room.create') !== -1;
  }

  /**
   * Load most recent timeline
   * @returns {Promise<MatrixEvent[]>} Timeline with 30 events
   */
  public async loadMostRecentTimeline(): Promise<MatrixEvent[]> {
    return this.getActiveTimelineEvents().slice(-INITIAL_EVENTS_NUMBER);
  }

  /**
   *
   * @param eventTimeline
   * @returns
   */
  public decryptAllEventsOfTimeline(eventTimeline: any) {
    const decryptionPromises = eventTimeline
      .getEvents()
      .filter((event) => event.isEncrypted() && !event.clearEvent)
      .reverse()
      .map((event) =>
        event.attemptDecryption(this.matrixClient.crypto, { isRetry: true })
      );

    return Promise.allSettled(decryptionPromises);
  }

  /**
   * Get reactions
   * @param {string} eventId Event id
   * @returns {Reaction[]} Reactions of this msgs
   */
  public getReactionsToEventId(eventId: string): Reaction[] {
    return [...this.reactionList.values()]
      .filter(e => e.getRelation()?.event_id == eventId)
      .map(e => {
        return {
          user: e.getSender(),
          emoji: e.getRelation().key,
          event: e,
        }
      })
  }

  /**
   * Get my reaction to this event
   * @param {string} eventId Event id
   * @returns {Reaction} My reaction to this event
   */
  public getMyReactionToEvent(eventId: string): Reaction {
    const rEvents = this.getReactionsToEventId(eventId);
    return rEvents?.find((r) => r.user === this.matrixClient.getUserId());
  }

  /**
   * Send reaction
   * @param {string} roomId Room id
   * @param {MatrixEvent} toEvent Event
   * @param {string} reaction Emoji
   */
  public async sendReaction(roomId: string, toEvent: MatrixEvent, reaction: string) {
    try {
      // Get my reaction to this event
      const rEvent = this.getMyReactionToEvent(toEvent.getId());

      // Delete reaction if I have a reaction to this event
      if (rEvent) {
        await this.roomService.redactEvent(rEvent.event.getId(), roomId, 'reaction');
        if (rEvent.emoji == reaction) return;
      }

      // Send reaction
      await this.matrixClient.sendEvent(roomId, 'm.reaction', {
        'm.relates_to': {
          event_id: toEvent.getId(),
          key: reaction,
          rel_type: 'm.annotation',
        },
      });

    } catch (e) {
      console.error(e)
      throw new Error(e);
    }
  }

  /**
   * Get readers for this event
   * @param {MatrixEvent} mEvent Matrix Event
   * @returns {string[]} Readers ids for this event
   */
  public getEventReaders(mEvent: MatrixEvent): string[] {
    const readers = []; // Init readers
    const liveEvents = this.getActiveTimelineEvents(); // Get all events
    const myUserId = this.matrixClient.credentials.userId; // My user id

    // if event not exist
    if (!mEvent) return [];

    for (let i = liveEvents.length - 1; i >= 0; i -= 1) {
      this.room?.getReceiptsForEvent(liveEvents[i]).forEach((r) => {
        if (
          !r.userId ||
          ![ReceiptType.Read, ReceiptType.ReadPrivate].includes(r.type) ||
          r.userId === myUserId
        ) {
          return; // ignore non-read receipts and receipts from self.
        }
        if (
          this.matrixClient.isUserIgnored(r.userId) ||
          !this.isMemberOfTheRoom(r.userId)
        ) {
          return; // ignore ignored users
        }
        readers.push(r.userId);
      });
      if (mEvent === liveEvents[i]) break;
    }

    return [...new Set(readers)];
  }

  /**
   * Check if user is member of the room
   * @param {string} userId User id
   * @returns {boolean} True if user is a member of the room
   */
  public isMemberOfTheRoom(userId: string): boolean {
    const member = [...this.room.getMembers()]
      .find((member) => member.userId == userId);
    return !NOT_MEMBER_STATUS.includes(member?.membership);
  }

  /**
   * Get all events loaded
   * @returns {MatrixEvent[]} Matrix Events
   */
  public getActiveTimelineEvents(): MatrixEvent[] {
    return [...(this.activeTimeline?.getEvents() ?? [])];
  }

  /**
   * Check if event is on focus
   * @param {MatrixEvent} event Matrix Event
   * @returns {boolean} True if event is on focus
   */
  private isOnFocus(event: MatrixEvent): boolean {
    return this.activeTimeline
      && event.getSender() !== this.matrixClient.getUserId()
      && window.document.hasFocus()
      && this.scrollFocus == 'bottom'
  }

  /**
   * Scroll Handler
   * @param {ScrollPagination} event Scroll direction
   */
  public setScrollFocus(event: ScrollPagination) {
    switch (event) {
      case 'top':
        this.scrollFocus = 'top';
        break;

      case 'bottom':
        this.scrollFocus = 'bottom';
        break;

      case 'middle':
        this.scrollFocus = 'float';
        break;
    }
  }

  /**
   * Listen room timeline
   */
  public listenRoomTimeline(
    event: MatrixEvent,
    room: Room,
    toStartOfTimeline: boolean,
    removed: boolean,
    data: IRoomTimelineData
  ) {
    // We only process live events here
    if (!data.liveEvent) return;

    // Check if is a call event
    if (isCallEvent(event)) this.callEvents.next(event);

    // Check if is current room
    if (room.roomId !== this.roomId) return;

    // Is Encrypted
    if (event.isEncrypted()) return;

    // Edited msg
    if (isEditedEvent(event)) {
      this.editionEvents.next(event);
      return;
    }

    // Add to timeline
    this.addToTimeline(event);

    // // Mark as read if event is on focus
    if (this.isOnFocus(event))
      this.unreadService.markAsRead(this.room);

    // Update timeline events
    this.timelineEvents.next(event);

    // Update last event
    this.roomService.setNewLastEvent(event);
  }

  /**
   * Listen typing events
   */
  public listenTypingEvent(event: MatrixEvent, member: RoomMember) {
    // Check if is current room
    if (member.roomId !== this.roomId) return;

    // Check if is typing
    member.typing
      ? this.typingMembers.add(member.userId) // If is typing add to set
      : this.typingMembers.delete(member.userId); // Remove from set
  }

  /**
   * Listen redaction
   * @param {MatrixEvent} mEvent Matrix Event
   * @param {Room} room Room
   */
  public listenRedaction(mEvent: MatrixEvent, room: Room) {
    // Check if is current room
    if (room.roomId !== this.roomId) return;

    // Remove event from reactions
    this.reactionList.delete(mEvent.event.redacts);

    // Remove event from editions
    this.editionList.delete(mEvent.event.redacts);

    // Save redaction event using deleted event id as key
    this.redactionList.set(mEvent.event.redacts, mEvent);

    // Call redaction observable
    this.redactionEvents.next(mEvent)
  }

  listenReceiptEvent(event: MatrixEvent, room: Room) {
    // we only process receipt for latest message here.
    if (room.roomId !== this.roomId) return;
    this.receiptEvents.next(event);
  }
}
