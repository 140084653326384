import { Pipe, PipeTransform } from '@angular/core';
import { GroupsService } from '@core/services';
import { Group } from '@shared/models';

@Pipe({
  name: 'groupName',
})
export class GroupNamePipe implements PipeTransform {
  constructor(private groupService: GroupsService) { }

  transform(groups: string[]): string {
    if (!groups?.length) return 'noRelatedTag'
    const filteredGroups: Group[] = this.groupService.getGroups().filter(value => groups.includes(value.id));
    const names: string[] = filteredGroups.filter(value => value.status).map(value => value.name);
    return names.length ? names.join(', ') : 'noRelatedTag'
  }
}
