import { findMutedRule, getCurrentPushRule, isChatGPTRoom } from "@shared/utils";
import { MatrixClient, Room } from "matrix-js-sdk";

/**
 * Check if room is muted
 * @param {string} roomId Room id
 * @returns {boolean} True if room is muted
 */
export const isRoomMuted = (mxc: MatrixClient, roomId: string): boolean => {
  const pushRule = getCurrentPushRule(mxc, roomId);

  if (pushRule === undefined) {
    const overrideRules = mxc.getAccountData('m.push_rules')?.getContent()?.global?.override;
    if (overrideRules === undefined) return false;
    return findMutedRule(overrideRules, roomId);
  }

  if (pushRule.actions[0] === 'notify') {
    return false;
  }
  return true;
}

/***
 * Check if matrix room is a DM
 */
export const isDMRoom = (room: Room): boolean => {
  // Get members names
  const membersNames = room?.getMembers()?.map((member) => member.name) ?? [];

  // For of in membersNames - Check if room has name of the user
  for (const name of membersNames)
    if (room?.name?.includes(name)) return true;

  // Check if room has name of the user
  return false;
}

/**
 * Check if matrix room is a boot room
 */
export const isBootRoom = (room: Room): boolean => {
  // Check if room is a boot room
  return isChatGPTRoom(room);
}
