
import Quill from 'quill';

export class CustomLinkSanitizer extends Quill.import('formats/link') {
  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = ['http', 'https'].some(function (protocol: string) {
      return sanitizedUrl.startsWith(protocol);
    });

    // If it does, then return the URL as it is
    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'https' to not to be a relative URL
    return `https://${sanitizedUrl}`;
  }
}
