<div style="display: flex; gap: 10px;">
  <div class="copy-area">
    <div class="text-to-copy">{{textToCopy}}</div>
    <mat-icon *ngIf="!isExternalButton" (click)="copy()">file_copy</mat-icon>
  </div>

  <button *ngIf="isExternalButton" (click)="copy()" color="primary" mat-raised-button type="submit" class="apply-btn">
    {{ "copy" | translate }}
  </button>
</div>
