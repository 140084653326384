import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, map, merge, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  networkStatus$: Subscription = Subscription.EMPTY;
  public isOnline = new BehaviorSubject<boolean>(true);

  constructor() { }

  /**
   * Check if the user is online or offline
   */
  public checkNetworkStatus() {
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(
        map(() => navigator.onLine)
      ).subscribe(status => { this.isOnline.next(status); });
  }

  /**
   * Check if is offline
   * @returns {boolean} True if is offline
   */
  public isOffline(): boolean {
    return !this.isOnline.value;
  }
}
