import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private previousUrl: string;
  private currentUrl: string;

  routeEvents = new BehaviorSubject<string>('');

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.routeEvents.next(this.currentUrl);
      }
    });
  }
  public getCurrentUrl(): string {
    return this.currentUrl;
  }
  public getPreviousUrl(): string {
    return this.previousUrl;
  }
}
