export const environment = {
  production: false,
  enableMatrixLogs: false,
  enableJsSipLogs: false,
  indexedDBVersion: 1,
  firebaseConfig: {
    apiKey: 'AIzaSyBWfWtTW1YRG-P5pEyjbZhgbVgPDoH-i10',
    authDomain: 'lcs-dev-940.firebaseapp.com',
    projectId: 'lcs-dev-940',
    storageBucket: 'lcs-dev-940.appspot.com',
    messagingSenderId: '980648121603',
    appId: '1:980648121603:web:cb6552dd5b94c6bf9cc536',
    measurementId: 'G-QCZW7K8HWB',
  },
  gifKey: 'AIzaSyD_hDZu7-Q-zUs0bw4268QX9UirW8F-1bY',
  tenorURL: 'https://tenor.googleapis.com/v2',
  //baseURL: 'http://localhost:5001/lcs-dev-940/us-central1',
  baseURL: 'https://us-central1-lcs-dev-940.cloudfunctions.net',
  appVersion: require("../../package.json").version,
  termsVersion: '0.0.1',
  socketUrl: 'https://lcs-services-dev.leucotron.com.br:9049',
  matrixUrl: 'https://lcs-services-dev.leucotron.com.br',
  jitsiDomain: 'lcs-services-dev.leucotron.com.br:8443',
  chatGPTDomain: 'https://shldemo.leucotron.com.br:3103/api/v1',
  aiApiURL: 'https://lcs-services-dev.leucotron.com.br:9062',
  // aiApiURL: 'http://0.0.0.0:9062',
  criptokey: 'LrtsttveOWCy9ampslsbHUqhv-u3VS2jqONEcIWmz2w='
};
