import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateTimelinePanel',
  pure: false
})
export class DateTimelinePanelPipe implements PipeTransform {
  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
  ) { }

  transform(value: string): string {
    return this.formatDate(new Date(value));
  }

  /**
   * Format the date by lang
   * @returns {string} Formatted date
   */
  private formatDate(date: Date): string {
    // Pipe
    const datePipe: DatePipe = new DatePipe(this.translate.currentLang);

    switch (true) {
      // Today
      case this.isToday(date):
        return this.translate.instant('today');

      // Yesterday
      case this.isYesterday(date):
        return this.translate.instant('yesterday');

      // Default
      default:
        const of = this.translate.instant('of');
        const day = this.translate.instant(this.utils.getDayByDate(date));
        const normalizedDate = datePipe
          .transform(date, `d , MMMM , YYYY`)
          .replace(',', of)
          .replace(',', of);
        return day + ', ' + normalizedDate;
    }
  }

  /**
   * Check if is yesterday
   * @param {Date} date Date
   * @returns {boolean} True if is yesterday
   */
  private isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toDateString() === date.toDateString();
  }

  /**
   * Check if is today
   * @param {Date} date Date
   * @returns {boolean} True if is today
   */
  private isToday(date: Date): boolean {
    const today = new Date()
    return today.toDateString() === date.toDateString();
  }

}
