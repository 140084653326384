import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { StorageService } from '@core/services';
import { PlayerStatus } from '@shared/models';
import { ElectronService } from 'ngx-electron';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit {
  volume = 1;
  previusVolume = 1;
  isMuted = false;
  isPlaying = false;
  durationTime: string;
  currentTime$ = new Subject();
  currentProgress$ = new BehaviorSubject(0);

  @Input() url: string;
  @Input() whitePlayer = false;
  @Input() isChatPlayer: boolean;
  @Output() fileNotFound = new EventEmitter<boolean>();
  @Output() playerStatus = new EventEmitter<PlayerStatus>();
  @ViewChild('player', { static: true }) player: ElementRef<HTMLAudioElement>;

  constructor(
    private storage: StorageService,
    private electronService: ElectronService
  ) { }

  ngOnInit(): void {
    if (this.isChatPlayer) {
      this.player.nativeElement.src = this.url;
      this.player.nativeElement.load();
      this.player.nativeElement.onplay = () => { this.isPlaying = true; };
      this.player.nativeElement.onloadedmetadata = () => { this.setAudioDuration() };
      this.player.nativeElement.onended = () => { this.playerStatus?.emit('ended') };
    }
  }

  /**
   * Format recordings path
   * @param path Current path
   * @returns Formetted path with /recordings/
   */
  private formattedRecordingsPath(path: string): string {
    // Return if already includes
    if (path.includes('/recordings/')) return path;
    const companyId = path.substring(0, path.indexOf('/'));
    const filename = path.substring(path.indexOf('/') + 1);
    return companyId + '/recordings/' + filename;
  }

  loadFile(path: string): void {
    path = this.formattedRecordingsPath(path);

    this.storage.getDownloadURL(path).then(
      (url) => {
        this.onPlay(url);
      },
      (err) => {
        if (err.code === 'storage/object-not-found') {
          this.fileNotFound.emit();
        }
      }
    );
  }

  onPlay(url: string): void {
    if (url) {
      this.durationTime = null;
      this.player.nativeElement.src = url;
      this.player.nativeElement.load();
    }

    this.player.nativeElement.play();
    this.isPlaying = true;
  }

  onPause(): void {
    this.isPlaying = false;
    this.player.nativeElement.pause();
  }

  onSliderChange(event): void {
    this.player.nativeElement.currentTime =
      (event.value * this.player.nativeElement.duration) / 100;
  }

  onVolumeSliderChange(event): void {
    if (event.value === 0) {
      return this.onToggleMute();
    }

    if (this.isMuted && event.value > 0) {
      this.isMuted = !this.isMuted;
      this.player.nativeElement.muted = this.isMuted;
    }

    this.volume = event.value;
    this.previusVolume = event.value;
    this.player.nativeElement.volume = event.value;
  }

  onToggleMute(): void {
    this.isMuted = !this.isMuted;
    this.player.nativeElement.muted = this.isMuted;

    if (this.isMuted) {
      this.volume = 0;
    } else {
      this.volume = this.previusVolume;
    }
  }

  onTimeUpdate() {
    if (!this.durationTime) {
      this.setAudioDuration();
    }

    const currentMinutes = this.generateMinutes(
      this.player.nativeElement.currentTime
    );
    const currentSeconds = this.generateSeconds(
      this.player.nativeElement.currentTime
    );
    this.currentTime$.next(
      this.generateTimeToDisplay(currentMinutes, currentSeconds)
    );

    const percents = this.generatePercentage(
      this.player.nativeElement.currentTime,
      this.player.nativeElement.duration
    );
    if (!isNaN(percents)) {
      this.currentProgress$.next(percents);
    }
  }

  setAudioDuration(): void {
    if (this.player.nativeElement.duration === Infinity) {
      // set it to bigger than the actual duration, set 24hrs on electron
      this.player.nativeElement.currentTime = this.electronService.isElectronApp ? 863999 : 1e101;
      this.player.nativeElement.ontimeupdate = () => {
        this.player.nativeElement.ontimeupdate = () => {
          return;
        }
        this.player.nativeElement.currentTime = 0;
      }

      return;
    }

    const durationInMinutes = this.generateMinutes(
      this.player.nativeElement.duration
    );

    const durationInSeconds = this.generateSeconds(
      this.player.nativeElement.duration
    );

    if (!isNaN(this.player.nativeElement.duration)) {
      this.durationTime = this.generateTimeToDisplay(
        durationInMinutes,
        durationInSeconds
      );
    }
  }

  generateMinutes(currentTime: number): number {
    return Math.floor(currentTime / 60);
  }

  generateSeconds(currentTime: number): number | string {
    const secsFormula = Math.floor(currentTime % 60);
    return secsFormula < 10 ? '0' + String(secsFormula) : secsFormula;
  }

  generateTimeToDisplay(currentMinutes, currentSeconds): string {
    return `${currentMinutes}:${currentSeconds}`;
  }

  generatePercentage(currentTime: number, duration: number): number {
    return Math.round((currentTime / duration) * 100);
  }
}
