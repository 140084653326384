import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { SOCKET_TOPIC } from '@shared/enums';
import { JitsiSocketData } from '@shared/models';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { io, Socket } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;

  constructor() {
    this.start();
  }

  /**
   * Start the communication with the socket
   */
  public start(): void {
    // URL for test 'http://localhost:9047'
    const socketUrl = environment.socketUrl;
    if (!this.socket) {
      this.socket = io(socketUrl, { secure: true });
    }
  }

  /**
   * Connect with the socket previous started
   */
  public connect(): void {
    if (this.socket) {
      this.socket.connect();
    }
  }

  /**
   * Close de connection
   */
  public stop(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.removeAllListeners(); // Remove os ouvintes
    }
  }

  /**
   * Remove listening from topics
   * @param {string[]} topics Contain the topics that will be removed
   */
  public unsubscribeAll(topics: string[] = []): void {
    topics.length
      ? topics.forEach(topic => this.socket.removeListener(topic))
      : this.socket.removeAllListeners();
  }

  /**
   * Send a message to a topic
   * @param {string} topic Contain the topic event
   * @param data Contain the data that will be sent to the topic
   */
  public sendMessage(data: [string, any]): void {
    this.socket.emit('events', data);
  }

  /**
   * Define the topic and the action that will be executed
   * @param {string} topic Contain the topic event
   * @param {Function} action Callback function that represents the action of the topic
   */
  public listenToTopic(topic: string, action: (...args: any[]) => void): void {
    this.socket.on(topic, action);
  }

  /**
   * Send Jitsi Socket Event
   * @param {SOCKET_TOPIC} topic Socket topic
   * @param {JitsiSocketData} data Jitsi Socket Data
   */
  public sendJitsiEvent(topic: SOCKET_TOPIC, data: JitsiSocketData): void {
    this.sendMessage([topic, data]);
  }

}
