<div class="wrapper">
  <div class="container">
    <div class="text-center">
      <mat-form-field class="phone-input-field large-text-regular">
        <input
          matInput
          class="dial-input"
          autocomplete="off"
          (keypress)="keyPressed($event)"
          (paste)="onPaste($event)"
          (focus)="focusInInput = true"
          (blur)="focusInInput = false"
          [readonly]="platform.isMobile()"
          type="tel"
          [(ngModel)]="phone"
          #input
        />
        <button
          matSuffix
          class="control-btn backspace"
          mat-icon-button
          (click)="backspaceInput()"
          id="backspace-addon"
        >
          <mat-icon svgIcon="backspace"></mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="container">
    <app-dialpad class="keyboard" (dial)="dial($event)"></app-dialpad>
  </div>
  <div class="container">
    <div class="controls">
      <div></div>
      <app-dial-voice-call-button
        class="control-btn call-btn"
        id="call-btn"
        #voiceCallBtn
        [(target)]="phone"
      >
      </app-dial-voice-call-button>
      <div></div>
    </div>
  </div>
</div>
