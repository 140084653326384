import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Cdr, CdrFilterParams } from '@shared/models';
import { formParams } from '@shared/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalRecordingsService {
  private url = `${environment.baseURL}/cdr`

  constructor(private http: HttpClient) { }

  /**
   * Get all tickets
   * @returns {Cdr[]} All tickets
   */
  public fetchAll(filter: CdrFilterParams): Observable<Cdr[]> {

    // Get path
    const path = this.url + '/calls';

    // Return the tickets
    return this.http.get<Cdr[]>(path, {
      params: formParams(filter),
    });
  }


  /**
   * Get all tickets
   * @returns {Cdr[]} All tickets
   */
  public rawFetchAll(filter: CdrFilterParams): Observable<HttpResponse<Cdr[]>> {

    // Get path
    const path = this.url + '/calls';

    // Return the tickets
    return this.http.get<Cdr[]>(path, {
      params: formParams(filter),
      observe: 'response'
    });
  }


  public rawFetchCount(filter: CdrFilterParams): Observable<HttpResponse<any>> {

    // Get path
    const path = this.url + '/calls/count/specific';

    // Return the tickets
    return this.http.get<Cdr[]>(path, {
      params: formParams(filter),
      observe: 'response'
    });
  }
}
