<button
  mat-icon-button
  (mouseover)="mouseOver = true"
  (mouseleave)="mouseOver = false"
  (click)="onClick()"
>
  <mat-icon
    [svgIcon]="!mouseOver ? 'play_audio' : 'play_audio_hover'"
  ></mat-icon>
</button>
