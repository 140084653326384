<h1 mat-dialog-title>
  {{ "editImage" | translate }}
</h1>

<mat-dialog-content class="mat-typography column">
  <div class="image-container">
    <image-cropper
      [imageURL]="imageUrl"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [canvasRotation]="canvasRotation"
      format="png, jpeg"
      alignImage="center"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <!-- <edit-menu-bar
    (transformData)="transform($event)"
    (rotate)="canvasRotation = $event"
    [canvasRotation]="canvasRotation"
  ></edit-menu-bar> -->
</mat-dialog-content>

<mat-dialog-actions class="cropper-actions" align="end">
  <button mat-button mat-dialog-close>
    {{ "cancel" | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="save()" type="submit">
    {{ "select" | translate }}
  </button>
</mat-dialog-actions>
