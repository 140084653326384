import { Injectable } from '@angular/core';
import { Database, onValue, ref, set } from '@angular/fire/database';

@Injectable({
  providedIn: 'root',
})
export class HandleVersionService {
  constructor(private database: Database) {}

  async getVersion(): Promise<string> {
    return new Promise((resolve, reject) => {
      const databaseRef = ref(this.database, 'version');
      onValue(
        databaseRef,
        (snapshot) => {
          resolve(snapshot.val());
        },
        { onlyOnce: true }
      );
    });
  }

  async setVersion(version: string): Promise<void> {
    const databaseRef = ref(this.database, 'version');
    await set(databaseRef, version);
  }
}
