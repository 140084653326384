<h1 mat-dialog-title>{{ 'deleteUserPhoto' | translate }}</h1>

<mat-dialog-content class="mat-typography column">
  <p>{{ 'deleteUserPhotoInfo' | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close style="text-transform: uppercase;">{{'cancel' | translate }}</button>
  <button mat-raised-button (click)="onSubmit()" type="submit" class="btn-warning" color="warn"
    style="text-transform: uppercase;">
    {{ 'delete' | translate }}
  </button>
</mat-dialog-actions>
