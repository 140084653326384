export type ProcessStatus = 'loading'| 'done';

export interface TranscriptionResponse{
    processStatus: string;
};

export interface AiResponse{
    uuid?: string;
    userEmail?: string;
    fileName: string;
    processStatus: ProcessStatus;
    transcriptionSummary?: string;
    loadingValue?: number;
};

export interface AiInputContent{
    aiInput: boolean;
    aiTextDone: boolean;
    originalText: string;
    newTextWithAI: string;
}