<form [formGroup]="form">
  <mat-form-field class="full-width" [appearance]="appearance">
    <mat-label *ngIf="label"> {{ label | translate }}: </mat-label>
    <input [class.dark]="themeService.isDarkTheme()" [disabled]="disabled" (keydown)="eventHandler()" autocomplete="off"
      matInput [placeholder]="dateformat?.toLowerCase()" (click)="picker.open();"
      [value]="childControl.value | date:dateformat">
    <input [disabled]="disabled" #dateInput matInput autocomplete="off" [ngxMatDatetimePicker]="picker"
      class="hidden-date-input" [min]="minDate" [max]="maxDate" [formControlName]="controlName">
    <mat-datepicker-toggle matSuffix [for]="$any(picker)" *ngIf="showClear">
      <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
    </mat-datepicker-toggle>
    <ngx-mat-datetime-picker [startView]="onlyMonth ? 'multi-year' : 'month'"
      (monthSelected)="closeDatePicker($event, picker)" [hideTime]="hideTime" #picker>
    </ngx-mat-datetime-picker>
  </mat-form-field>
</form>
