// Body options
export const DEFAULT_TEMPERATURE = "0.6";
export const DEFAULT_MAX_TOKENS = "500";

// Credentials
export const DEFAULT_TOKEN_HEADER = "token_access";
export const DEFAULT_CHATGPT_USERNAME = 'Leucotron_manager';
export const DEFAULT_CHATGPT_PASSWORD = 'OpenAi@l3uc0tr0n';

// Routes
export const DEFAULT_CHATGPT_LOGIN_ROUTE = "/login";
export const DEFAULT_CHATGPT_QUESTION_ROUTE = "/completion/chat";

// Others
export const DEFAULT_CHATGPT_IDENTIFIER = 'IsChatGPT';
export const DEFAULT_ERROR_LIMIT = 3;
