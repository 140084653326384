import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CsvService,
  ThemeService
} from '@core/services';

type Data = {
  title: string;
  desc: string;
  limit: number;
  example: {
    fileName: string;
    header: string[];
    body: string[];
  }
}

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent {
  // Files
  public files: File[] = [];

  constructor(
    private csv: CsvService,
    public themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public dialogRef: MatDialogRef<ImportDialogComponent>,
  ) { }

  /**
   * Download the template
   */
  public downloadTemplate(): void {
    // Get example data
    const { header, body, fileName } = this.data.example;

    // Export example csv file
    this.csv.exportCSV(header, header, body, `${fileName}.csv`)
  }


  /**
   * Handle drop event
   * @param {FileList} fileList File list
   */
  public handleDrop(fileList: FileList) {
    Array.from(fileList).forEach((file) => this.files.push(file));
  }

  /**
   * File browse handler
   * @param {any} event
   */
  public fileBrowseHandler(event: any) {
    this.files.push(event.target.files[0]);
    event.target.value = ''
  }

  /**
   * Delete a file
   * @param {number} index File index
   */
  public deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Submit the files
   */
  public submit() {
    this.dialogRef.close(this.files);
  }
}
