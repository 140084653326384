<h2 mat-dialog-title class="dialog-title">
  <div class="m-h-auto">
    {{ "exceededSize" | translate }}
  </div>
</h2>

<div class="small-text-regular">
  {{ "maxSizeInfo" | translate}}
</div><br>

<mat-dialog-actions align="end">
  <button mat-button class="call-btn" mat-dialog-close>OK</button>
</mat-dialog-actions>
