export enum SOCKET_TOPIC {
  hold = 'holding',
  unhold = 'unholding',
  transfer = 'transferred',
  video = 'toggleVideo',
  cdr = 'cdr',
  transfer_device = 'transferToDevice',
  multiLine_call = 'multiLineCall',

  meeting_ended = 'meetingEnded',
  meeting_hangup = 'meetingHangup',
  meeting_event_id = 'meetingEventId',
  meeting_started = 'meetingStarted',
  recording_meeting = 'recordingMeeting',
  hangup_cause = 'hangup-cause',
  monitor = 'monitor',
  statusok = 'statusok',
}

export enum SOCKET_HANGUP_CAUSE {
  NO_ANSWER_USER_REJECT_CALL = "noanswer/user-reject-call",
  NO_ANSWER_USER_UNAVAILABLE = "noanswer/user-unavailable",
  NO_ANSWER_NO_RESPONSE = "noanswer/no-response",
  NO_ANSWER_USER_NO_REGISTRY = "noanswer/user-no-registry",
}
