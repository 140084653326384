import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatgptService, RoomService, ThemeService, UserPresenceService } from '@core/services';
import { User } from '@shared/models';
import { RoomFullInfoDialogComponent } from '../room-full-info-dialog/room-full-info-dialog.component';

type Info = 'email' | 'extension' | 'meet' | 'status' | 'sector';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  // Contact to show info
  @Input() contact: User;
  @Input() clickable: boolean = false;

  // Info to show in the contact
  @Input() infos: Info[] = [];
  @Input() showStatus: boolean = true;

  // Text size
  @Input() isSmall: boolean = true;
  @Input() isBold: boolean = false;

  // Room header
  @Input() isRoomHeader: boolean = false;

  // Typing
  @Input() contacts: User[] = [];
  @Input() roomId: string;

  constructor(
    private roomService: RoomService,
    public themeService: ThemeService,
    public dialog: MatDialog,
    private chatGPT: ChatgptService,
    private userPresenceService: UserPresenceService,
  ) { }

  ngOnInit(): void {}

  /**
   * Checks if someone is currently typing in the chat room.
   * @returns A boolean value indicating whether someone is typing.
   */
  public isTyping(): boolean {
    const someOneIsTyping = this.roomService.isTyping(this.roomId) || this.chatGPT.isChatGPTTyping;
    return this.isRoomHeader && someOneIsTyping && this.roomId === this.roomService.roomId?.value;
  }

  /**
   * Get Display name
   * @returns {string} Display name
   */
  public getDisplayName(): string {
    return this.roomService.getDisplayName(this.contact)
  }

  /**
   * Get user status
   * @returns {string} Status
   */
  public getUserStatus(): string {
    return this.userPresenceService.getPresence(this.contact?.id).status;
  }

  /**
   * Show user info
   */
  public showUserInfo(): void {
    if (this.canClick()) this.dialog.open(RoomFullInfoDialogComponent, {
      panelClass: "room-full-info-dialog",
      data: {
        type: 'user',
        user: this.contact
      }
    });
  }

  /**
   * Check if can click
   * @returns {boolean} True if can click
   */
  public canClick(): boolean {
    return this.clickable && !!this.contact?.id;
  }
}
