export enum DAYS_OF_WEEK {
  sunday = 'SUNDAY',
  monday = 'MONDAY',
  tuesday = 'TUESDAY',
  wednesday = 'WEDNESDAY',
  thursday = 'THURSDAY',
  friday = 'FRIDAY',
  saturday = 'SATURDAY',
}

export type DAYS_OF_WEEK_TYPE = keyof typeof DAYS_OF_WEEK;
