import { canNotShowPABX, hasEquipmentAssociated, isLcsPanelPage, isMultiTalk } from "./common";

export const AdminTabs = [
  {
    label: 'analytics',
    routes: [
      {
        label: 'dashboard',
        path: 'dashboard',
        icon: 'icon-dashboard',
        isHidden: () => false,
      },
      {
        label: 'eventsRegister',
        path: 'logs',
        icon: 'icon-logs',
        isHidden: () => false,
      },
      {
        label: 'callRegisters',
        path: 'call-registers',
        icon: 'icon-call-register',
        isHidden: () => true,
      },
    ]
  },
  {
    label: 'companySettings',
    routes: [
      {
        label: 'sectors',
        path: 'sectors',
        icon: 'icon-company-sectors',
        isHidden: () => false,

      },
      {
        label: 'users',
        path: 'users',
        icon: 'icon-user',
        isHidden: () => false,

      },
      {
        label: 'shortcuts',
        path: 'integrations',
        icon: 'icon-shortcuts',
        isHidden: () => false,
      },
      {
        label: 'deviations',
        path: 'deviations',
        icon: 'icon-deviations',
        isHidden: () => true,
      },
      {
        label: 'captureGroups',
        path: 'capture-groups',
        icon: 'icon-capture-groups',
        isHidden: () => true,
      },
    ]
  },

  {
    label: 'telephonySettings',
    routes: [
      {
        label: 'pabxIntegration',
        path: 'pabx',
        icon: 'icon-pabx-integrations',
        isHidden: () => canNotShowPABX(),
      },
      {
        label: 'basicConfigurations',
        path: 'pabx/equipment/config',
        icon: 'icon-basic-configurations',
        isHidden: () => !hasEquipmentAssociated(),
      },
      {
        label: 'extensions',
        path: 'pabx/equipment/extension',
        icon: 'icon-extensions',
        isHidden: () => !hasEquipmentAssociated(),
      },
      {
        label: 'trunks',
        path: 'pabx/equipment/providers',
        icon: 'icon-providers',
        isHidden: () => !hasEquipmentAssociated(),
      },
      {
        label: 'advancedConfigurations',
        path: 'pabx/equipment/advanced',
        icon: 'icon-advanced-configurations',
        isHidden: () => !(hasEquipmentAssociated() && isLcsPanelPage()), // Check if is LCS panel page and has equipment associated
      },
      { // Ocultar para LANÇAMENTO
        label: 'trunks',
        path: 'trunks',
        icon: 'icon-trunks',
        isHidden: () => !isMultiTalk(),
      },
      { // Ocultar para LANÇAMENTO
        label: 'ringGroups',
        path: 'ring-groups',
        icon: 'icon-ring-groups',
        isHidden: () => !isMultiTalk(),
      },
      { // Ocultar para LANÇAMENTO
        label: 'entryRoutes',
        path: 'entry-routes',
        icon: 'icon-entry-route',
        isHidden: () => !isMultiTalk(),
      },
      { // Ocultar para LANÇAMENTO
        label: "scheduleGroups",
        path: "schedule-groups",
        icon: "icon-schedule-groups",
        isHidden: () => true
      },
      { // Ocultar para LANÇAMENTO
        label: "timeConditions",
        path: "time-conditions",
        icon: "icon-timetable-conditions",
        isHidden: () => !isMultiTalk()
      },
      { // Ocultar para LANÇAMENTO
        label: "audioLibrary",
        path: "audio-library",
        icon: "icon-audio-library",
        isHidden: () => true
      },
      { // Ocultar para LANÇAMENTO
        label: "urasAndAnnouncements",
        path: "uras",
        icon: "icon-uras",
        isHidden: () => true
      },
      { // Ocultar para LANÇAMENTO
        label: "systemCodes",
        path: "system-codes",
        icon: "icon-system-codes",
        isHidden: () => true
      },
    ]
  }
];
