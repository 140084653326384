import { Injectable } from '@angular/core';
import {
  Auth,
  AuthProvider,
  EmailAuthProvider,
  FacebookAuthProvider,
  User as FirebaseUser,
  OAuthProvider,
  TwitterAuthProvider,
  UserCredential,
  authState,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  idToken,
  linkWithCredential,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  updateProfile
} from '@angular/fire/auth';
import { Database, onValue, ref } from '@angular/fire/database';
import { Router } from '@angular/router';
import {
  ClusterService,
  ContactService,
  CryptoService,
  EmailService,
  FirestoreService,
  LcsEventsService,
  LoadingSpinnerService,
  PbxService,
  StorageService,
  TenantService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LcsEventDescriptions, LcsEventUserTypes, ReleaseDate, UserPresence } from '@shared/enums';
import { PABX, PREFIX_STORAGE, ReleaseDateData, User } from '@shared/models';
import { setLSTenantId, setLSUserEmail, setLSUserId } from '@shared/utils';
import { Observable, Subject, lastValueFrom, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User>;
  extension: string;
  userId: string;
  userEmail: string;
  user: User;
  firebaseUser: FirebaseUser;
  private userPBXData: PABX;
  public redirectUrl: string = '';
  public lastReleaseDates = new Map<ReleaseDate, ReleaseDateData>();
  public logout$ = new Subject<void>();
  functionUrl = `${environment.baseURL}/users`;

  constructor(
    private lcsEventsService: LcsEventsService,
    private translate: TranslateService,
    private router: Router,
    private tenant: TenantService,
    private cluster: ClusterService,
    private auth: Auth,
    private firestore: FirestoreService,
    private storage: StorageService,
    private crypto: CryptoService,
    private pbxService: PbxService,
    private spinner: LoadingSpinnerService,
    private contactsService: ContactService,
    private emailService: EmailService,
    private database: Database,
  ) {
    this.user$ = authState(this.auth).pipe(
      switchMap((user) => {
        if (user) {
          this.firebaseUser = user;
          this.userId = user.uid;
          this.userEmail = user.email;
          const tenantId = user.tenantId;

          // Set user data on local storages
          setLSTenantId(tenantId);
          setLSUserId(this.userId);
          setLSUserEmail(this.userEmail);

          if (!this.tenant.tenant) {
            this.tenant.setTenantId(tenantId);
          }

          if (!this.cluster.cluster) {
            this.cluster.setClusterId(
              localStorage.getItem(`${PREFIX_STORAGE}:clusterId`)
            );
          }

          const obs = this.firestore.docWithId$<User>(
            `companies/${tenantId}/users/${user.uid}`
          );

          obs.subscribe(async (usera) => {
            this.user = usera;
            if (this.user?.pbxId) {
              this.pbxService
                .getById(this.user.pbxId)
                .then((value) => (this.userPBXData = value));
            }
          });
          return obs;
        }

        return of(null);
      })
    );
  }

  /**
   * Create a new event
   */
  private createEvent(
    type: LcsEventUserTypes,
    description: string = '',
    value = {},
  ): Promise<void> {
    return this.lcsEventsService.create({ type, description, value }, true);
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<UserCredential | void> {
    return signInWithEmailAndPassword(this.auth, email, password).then(
      async (credential) => {
        // Set user data
        this.userId = credential.user.uid;
        this.userEmail = credential.user.email;

        // Set on local storages
        setLSTenantId(credential.user.tenantId);
        setLSUserId(this.userId);
        setLSUserEmail(this.userEmail);

        // Create event
        await this.createEvent(LcsEventUserTypes.USER_LOGIN, LcsEventDescriptions.NORMAL_LOGIN);
      }
    );
  }

  /**
   * Get Company Id
   * @returns {string} Tenant (Company) Id
   */
  public getTenantId(): string {
    return this.user.companyId ?? localStorage.getItem(`${PREFIX_STORAGE}:tenantId`);
  }

  /**
   * Fetch Release Dates
   */
  public fetchReleaseDates(): void {
    // Ref
    const releaseDateRef = ref(this.database, 'releaseDate');

    // Get inicial values
    onValue(releaseDateRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key as ReleaseDate;
        const childData: ReleaseDateData = childSnapshot.val();
        this.lastReleaseDates.set(childKey, childData);
      });
    }, { onlyOnce: true });
  }

  /**
   * Get Release Dates
   */
  public getReleaseDates() {
    return this.lastReleaseDates;
  }

  /**
   * Make logout
   */
  private makeLogout(): void {
    this.logout$?.next();
    this.logout$?.complete();
  }

  /**
   * Check if user is in presentation
   */
  public isUserInPresentation(): boolean {
    return this.user?.presence?.status == UserPresence.IN_PRESENTATION;
  }

  /**
   * Check if user is not disturb
   */
  public isUserNotDisturb(): boolean {
    return this.user?.presence?.status == UserPresence.DONOTDISTURB;
  }

  signInWithMicrosoft(email: string, tenantId: string): void {
    this.spinner.show();
    const provider = new OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      login_hint: email,
    });

    signInWithPopup(this.auth, provider)
      .then(async (result) => {
        await this.createEvent(LcsEventUserTypes.USER_LOGIN, LcsEventDescriptions.MICROSOFT_LOGIN);
        this.spinner.hide();
        this.router.navigate([this.redirectUrl]);
      })
      .catch((err) => {
        this.spinner.hide();

        if (err.code === 'auth/account-exists-with-different-credential') {
          const email = err.customData.email;
          const pendingCred = OAuthProvider.credentialFromError(err);

          fetchSignInMethodsForEmail(this.auth, email).then((methods) => {
            if (methods[0] === 'password') {
              this.contactsService
                .getByEmail(email)
                .pipe(first())
                .subscribe((contact) => {
                  const encryptedPassword = contact.pass;
                  const password = this.crypto.decrypt(
                    encryptedPassword,
                    tenantId
                  );

                  signInWithEmailAndPassword(this.auth, email, password)
                    .then((result) => {
                      return linkWithCredential(result.user, pendingCred);
                    })
                    .then(async () => {
                      this.spinner.hide();
                      this.router.navigate([this.redirectUrl]);
                    });
                });

              return;
            }

            const provider = this.getProviderForProviderId(methods[0]);
            signInWithPopup(this.auth, provider).then((result) => {
              linkWithCredential(result.user, pendingCred).then(async () => {
                this.spinner.hide();
                this.router.navigate([this.redirectUrl]);
              });
            });
          });
        }
      });
  }

  /**
   * Set a profile picture to User
   * @param {string | ArrayBuffer} picture file url
   */
  async setUserPhoto(picture: string | ArrayBuffer): Promise<void> {
    const tenantId: string = this.tenant.tenant.tenantId;
    const userId: string = this.user.id;

    // Upload to Firebase Storage
    const storagePath = `${tenantId}/profilePictures/${userId}`;
    await this.storage.uploadFile(storagePath, picture);

    // Get photo url
    const photoUrl: string = await this.storage.getDownloadURL(storagePath);

    // Update firestore user photo url
    const firestorePath = `companies/${tenantId}/users/${userId}`;
    await this.firestore.updateDoc(firestorePath, { photoUrl });

    // Update firebase auth user photo url
    await updateProfile(this.auth.currentUser, { photoURL: photoUrl });
  }

  async updateUserProfile(displayName: string): Promise<void> {
    const tenantId: string = this.tenant.tenant.tenantId;
    const userId: string = this.user.id;

    // Update firestore user photo displayName
    const firestorePath = `companies/${tenantId}/users/${userId}`;
    await this.firestore.updateDoc(firestorePath, { displayName });

    // Update firebase auth user photo url
    await updateProfile(this.auth.currentUser, { displayName });
  }

  async changeUserPassword(
    currentPassword: string,
    newPassword: string
  ): Promise<void> {
    const user = this.auth.currentUser;
    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      throw this.translate.instant('wrongCurrentPassword');
    }

    try {
      await updatePassword(user, newPassword);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Removes logged user profile picture
   */
  async removeUserPhoto(): Promise<void> {
    const tenantId: string = this.tenant.tenant.tenantId;
    const userId: string = this.user.id;

    // Removes from Firebase Storage
    const storagePath = `${tenantId}/profilePictures/${userId}`;
    await this.storage
      .removeFile(storagePath)
      .catch(() => { })
      .finally(async () => {
        // Removes from firestore
        const firestorePath = `companies/${tenantId}/users/${userId}`;
        await this.firestore.updateDoc(firestorePath, { photoUrl: null });

        // Removes from firebase auth user
        await updateProfile(this.auth.currentUser, { photoURL: null });
      });
  }

  /**
   * Get the value of External Route attr
   * @returns {number} External Route value
   */
  public getExternalRoute(): number | string[] {
    return this.userPBXData?.externalRoute ?? null;
  }

  /**
   * Get the value of External Route attr
   * @returns {string} External Route value
   */
  public getBlindTransferCode(): string {
    return this.userPBXData?.blindTransferCode ?? null;
  }

  /**
   * Get the value of attended transfer code attr
   * @returns {string} Attended Transfer Code value
   */
  public getAttendedTransferCode(): string {
    return this.userPBXData?.attendedTransferCode ?? null;
  }

  sendForgotPasswordEmail(email: string): Promise<string> {
    return this.emailService.sendForgotPasswordEmail(
      email,
      this.auth.tenantId,
      this.translate.currentLang
    );
  }

  confirmPasswordReset(actionCode: string, newPassword: string): Promise<void> {
    return confirmPasswordReset(this.auth, actionCode, newPassword);
  }

  async updatePasswordHash(
    email: string,
    tenantId: string,
    newPassword: string
  ): Promise<void> {
    const encryptedPassword = this.crypto.encrypt(newPassword, tenantId);
    const contact = await lastValueFrom(
      this.contactsService.getByEmail(email, tenantId).pipe(first())
    ).catch((err) => {
      console.error(err);
    });

    const userId: string = (contact as User)?.id;
    const path = `companies/${tenantId}/users/${userId}`;
    return this.firestore.updateDoc(path, {
      pass: encryptedPassword,
      firstLogin: false,
    });
  }

  private getProviderForProviderId(id: string): AuthProvider {
    switch (id) {
      case TwitterAuthProvider.PROVIDER_ID:
        return new TwitterAuthProvider();
      case EmailAuthProvider.PROVIDER_ID:
        return new EmailAuthProvider();
      case FacebookAuthProvider.PROVIDER_ID:
        return new FacebookAuthProvider();
    }
  }

  idToken(): Observable<string> {
    return idToken(this.auth);
  }

  getEmail(): string {
    return this.auth.currentUser.email;
  }

  async signOut(): Promise<void> {
    await this.createEvent(LcsEventUserTypes.USER_LOGOUT);
    this.makeLogout();
    await signOut(this.auth);
  }

  getCompany() {
    return this.user.companyId;
  }
}
