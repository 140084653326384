<h2 mat-dialog-title>
  {{ 'linkUserToPBX' | translate }}
</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row fx-gap" style="justify-content: center; align-items: center;">
    <mat-form-field class="full-width">
      <mat-label>PABX</mat-label>
      <mat-select formControlName="pbxId">
        <mat-option>{{ 'none' | translate }}</mat-option>
        <mat-option *ngFor="let media of data.pbx" [value]="media.id">
          {{ media.namePbx }} ({{ media.domainPbx }})
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ 'no' | translate }}
    </button>
    <button mat-raised-button type="submit" color="primary">
      {{ "save" | translate }}
    </button>
  </mat-dialog-actions>
</form>
