export enum DEVICES_TYPES {
  INPUT = 'inputDeviceId',
  OUTPUT = 'outputDeviceId',
  VIDEO = 'videoDeviceId',
  SECONDARY_RING = 'secondaryRingDeviceId',
};

export enum BrowsersDevices {
  MICROSOFT_EDGE = 'Microsoft Edge',
  MICROSOFT_EDGE_CHROMIUM = 'Microsoft Edge (Chromium)',
  OPERA = 'Opera',
  GOOGLE_CHROME = 'Google Chrome',
  SAFARI = 'Safari',
  MOZILLA_FIREFOX = 'Mozilla Firefox',
  INTERNET_EXPLORER = 'Internet Explorer',
  UC_BROWSER = 'UC Browser',
  YANDEX = 'Yandex',
}

export enum MobileDevices {
  ANDROID = 'Android',
  IOS = 'iOS',
}

export enum OSDevices {
  WINDOWS = 'Windows',
  LINUX = 'Linux',
}

export enum UnknownDevice {
  UNKNOWN = 'Unknown',
}
