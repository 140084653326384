import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ThemeService } from '@core/services';

@Component({
  selector: 'app-form-props',
  templateUrl: './form-props.component.html',
  styleUrls: ['./form-props.component.scss']
})
export class FormPropsComponent implements OnInit {
  // Input
  @Input() formGroup: FormGroup;
  @Input() infoTitle: string = 'note';
  @Input() infoText: string = 'integrationsWarning';
  @Input() canShowInfo: boolean = false;


  constructor(public themeService: ThemeService) { }

  ngOnInit(): void {
  }

}
