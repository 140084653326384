export interface UaSettings {
  webSocketUrl: string;

  /// May not need to register if on a static IP, just Auth
  /// Default is true
  register: boolean;

  /// Default is 600 secs in config.dart
  register_expires: number;
  connection_recovery_max_interval: number;
  connection_recovery_min_interval: number;
  /// `User Agent` field for sip message.
  userAgent: string;
  uri: string;
  authorizationUser: string;
  password: string;
  ha1: string;
  displayName: string;

  /// DTMF mode, in band (rfc2833) or out of band (sip info)
  dtmfMode: string;
}
