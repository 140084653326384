import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@core/services';
import { normalizeTarget } from '@shared/utils';

@Pipe({
  name: 'phoneMask',
})
export class PhoneMaskPipe implements PipeTransform {
  constructor(
    private auth: AuthService
  ) { }

  // transform(value: string, isReceivedCall: boolean = false): string {

  //   // Get external route
  //   const externalRoute = this.auth.getExternalRoute();
  //   const routes = Array.isArray(externalRoute) ? externalRoute : [`${externalRoute}`]

  //   // Remove external routes
  //   value = removeExternalRoute(value, !isReceivedCall && routes);

  //   if (value.substring(0, 1) === '+' || value.substring(0, 2) === '00') {
  //     return value;
  //   } else if (value.substring(0, 3) === '000') {
  //     return value.substring(1);
  //   } else if (value.length === 8) {
  //     return `${value.substring(0, 4)}-${value.substring(4, value.length)}`;
  //   } else if (value.length === 9 && value.indexOf('0') === 0) {
  //     return `${value.substring(1, 5)}-${value.substring(5, value.length)}`;
  //   } else if (value.length === 9) {
  //     return `${value.substring(0, 5)}-${value.substring(5, value.length)}`;
  //   } else if (value.length === 10 && value.substring(0, 4) === '0800') {
  //     return `${value.substring(0, 4)} ${value.substring(4, 7)} ${value.substring(7, value.length)}`;
  //   } else if (value.length === 10 && value.indexOf('0') === 0) {
  //     return `${value.substring(1, 2)}${value.substring(2, 6)}-${value.substring(6, value.length)}`;
  //   } else if (value.length === 10) {
  //     return `(${value.substring(0, 2)}) ${value.substring(2, 6)}-${value.substring(6, value.length)}`;
  //   } else if (value.length === 11 && value.substring(0, 4) === '0800') {
  //     return `${value.substring(0, 4)} ${value.substring(4, 7)} ${value.substring(7, value.length)}`;
  //   } else if (value.length === 11 && value.indexOf('0') === 0) {
  //     return `(${value.substring(1, 3)}) ${value.substring(3, 7)}-${value.substring(7, value.length)}`;
  //   } else if (value.length === 11) {
  //     return `(${value.substring(0, 2)}) ${value.substring(2, 7)}-${value.substring(7, value.length)}`;
  //   } else if (value.length === 12 && value.indexOf('0') === 0 && value.substring(1, 5) === '0800') {
  //     return `${value.substring(1, 5)} ${value.substring(5, 8)} ${value.substring(8, value.length)}`;
  //   } else if (value.length === 12 && value.substring(0, 2) === '00') {
  //     return `(${value.substring(2, 4)}) ${value.substring(4, 8)}-${value.substring(8, value.length)}`;
  //   } else if (value.length === 12 && value.indexOf('0') === 0) {
  //     return `(${value.substring(1, 3)}) ${value.substring(3, 8)}-${value.substring(8, value.length)}`;
  //   } else if (value.length === 13 && value.substring(0, 1) === '+') {
  //     return `${value.substring(1, 3)} ${value.substring(3, 5)} ${value.substring(5, 9)}-${value.substring(9, value.length)}`;
  //   } else if (value.length === 13 && value.substring(0, 2) === '00') {
  //     return `(${value.substring(2, 4)}) ${value.substring(4, 9)}-${value.substring(9, value.length)}`;
  //   }

  //   return value;
  // }

  transform(value: string, isReceivedCall: boolean = false): string {
    if(value.includes(`lcs`) && value.includes('-')) value = value.split('-')[1];
    const externalRoute = this.auth.getExternalRoute();
    const routes = Array.isArray(externalRoute) ? externalRoute : [`${externalRoute}`]
    return normalizeTarget(value, !isReceivedCall && routes);
  }
}
