import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SocketService } from '@core/services';
import { SOCKET_TOPIC } from '@shared/enums';
import { JitsiSocketData } from '@shared/models';

@Component({
  selector: 'app-jitsi-recording-status',
  templateUrl: './jitsi-recording-status.component.html',
  styleUrls: ['./jitsi-recording-status.component.scss']
})
export class JitsiRecordingStatusComponent implements OnInit, OnDestroy {
  @Input() userId: string;
  @Input() roomId: string;
  @Input() canShow: boolean;

  public conferenceRecording = new Map<string, Set<string>>();
  public closeStatus = false;

  constructor(private socket: SocketService) { }

  ngOnDestroy(): void {
    this.socket?.stop()
  }

  ngOnInit(): void {
    this.listeningSocketJitsiEvents();
  }

  /**
   * Listening Socket Jitsi Events
   */
  private listeningSocketJitsiEvents(): void {
    this.socket.listenToTopic(SOCKET_TOPIC.recording_meeting, (e: JitsiSocketData) => {
      switch (true) {
        // If user is yourself return
        case this.userId === e.userId:
          break;

        // If already exist
        case this.conferenceRecording.has(e.roomId):
          // Get current display names
          let values = this.conferenceRecording.get(e.roomId);

          // Get old value size
          const oldValuesSize = values.size;

          // Save new values or remove if is not recording
          e.recordingStatus
            ? values.add(e.displayName)
            : values.delete(e.displayName)

          // Update or remove if is empty
          values.size
            ? this.conferenceRecording.set(e.roomId, values)
            : this.conferenceRecording.delete(e.roomId)

          // Check if can show status
          if (oldValuesSize > values.size && e.roomId == this.roomId)
            this.closeStatus = false;
          break;

        // New
        case !this.conferenceRecording.has(e.roomId) && e.recordingStatus:
          // Init set
          const names = new Set<string>();

          // Add name to set
          names.add(e.displayName);

          // Save set
          this.conferenceRecording.set(e.roomId, names);

          // Can show status
          if (e.roomId == this.roomId)
            this.closeStatus = false;
          break;
      };
    })
  }

  /**
   * Get users recording
   * @returns {string} Users names
   */
  public getUsersRecording(): string {
    if (!this.canShow || this.closeStatus) return;
    const names = this.conferenceRecording?.get(this.roomId);
    return names ? Array.from(names).join(', ') : null;
  }
}
