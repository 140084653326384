import { Injectable } from '@angular/core';
import { FirestoreService, LcsEventsService } from '@core/services';
import { LcsEventDescriptions, LcsEventPABXTypes } from '@shared/enums';
import { EquipmentExtData, PBXExtension } from '@shared/models';
import { getDeviceExtensionPath, getDiffBetweenObj, getExtensionPathByCompanyId } from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExtensionService {

  constructor(
    private firestore: FirestoreService,
    private lcsEventService: LcsEventsService,
  ) { }

  /**
   * Listen to all extensions from company
   */
  public getExtensions(): Observable<PBXExtension[]> {
    return this.firestore.colWithIds$<PBXExtension>(getExtensionPathByCompanyId())
  }

  /**
   * Get equipment extensions
   * @param {string} equipmentId Equipment ID
   * @returns {Promise<EquipmentExtData[]>} Equipment extensions
   */
  public getEquipExtensions(equipmentId: string): Promise<EquipmentExtData[]> {
    if (!equipmentId) return Promise.resolve([]);
    const path = getDeviceExtensionPath(equipmentId);
    return lastValueFrom(this.firestore.colWithIds$<EquipmentExtData>(path).pipe(first()));
  }

  /**
   * Get equipment extension
   * @param {string} equipmentId Equipment ID
   * @param {string} extensionId Extension ID
   * @returns {Promise<EquipmentExtData>} Equipment extension
   */
  public getEquipExtension(equipmentId: string, extensionId: string): Promise<EquipmentExtData> {
    const path = getDeviceExtensionPath(equipmentId, extensionId);
    return lastValueFrom(this.firestore.docWithId$<EquipmentExtData>(path).pipe(first()));
  }

  /**
   * Update equipment extension
   * @param {string} equipmentId Equipment ID
   * @param {Partial<EquipmentExtData>} data Extension data
   * @param {Partial<EquipmentExtData>} original Original extension data
   */
  public async updateEquipExtension(
    equipmentId: string,
    data: Partial<EquipmentExtData>,
    original: Partial<EquipmentExtData>
  ): Promise<void> {
    try {
      // Get extension path
      const path = getDeviceExtensionPath(equipmentId, data.id);

      // Update extension
      await this.firestore.updateDoc(path, data);

      // Handle success
      this.handleSuccess(LcsEventPABXTypes.EXTENSION_UPDATE, {
        sn: equipmentId,
        extension: original.id,
        diff: getDiffBetweenObj(data, original, ['userId'])
      });
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.EXTENSION_UPDATE);
    }
  }

  /**
   * Remove extension
   * @param {string} equipmentId Equipment ID
   * @param {string} extensionId Extension ID
   */
  public async removeEquipExtension(equipmentId: string, extensionId: string): Promise<void> {
    try {
      // Get extension path
      const path = getDeviceExtensionPath(equipmentId, extensionId);

      // Delete
      await this.firestore.deleteDoc(path);

      // Handle success
      this.handleSuccess(LcsEventPABXTypes.EXTENSION_DELETION, {
        sn: equipmentId,
        extension: extensionId,
      });
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.EXTENSION_DELETION);
    }
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventPABXTypes} type The event type
   */
  public handleError(error: any, type: LcsEventPABXTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, true);

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsEventPABXTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventPABXTypes, value: any): void {
    this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, true);
  }
}
