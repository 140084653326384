import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'edit-menu-bar',
  templateUrl: './edit-menu-bar.component.html',
  styleUrls: ['./edit-menu-bar.component.scss'],
})
export class EditMenuBarComponent implements OnInit {
  @Input() canvasRotation: number;

  @Output() transformData: EventEmitter<ImageTransform> =
    new EventEmitter<ImageTransform>();
  @Output() rotate: EventEmitter<number> = new EventEmitter<number>();

  transform: ImageTransform = {
    scale: 1,
    flipH: false,
    flipV: false,
  };

  constructor() {}

  ngOnInit(): void {}

  zoomIn() {
    // console.log('Zoom IN');
    if (this.transform.scale < 2) {
      this.transform.scale += 0.1;
      this.transform.scale = Number(this.transform.scale.toFixed(1));
    }
    // console.log(this.transform);
    this.transformData.emit(this.transform);
  }

  zoomOut() {
    // console.log('Zoom OUT');
    if (this.transform.scale > 0) {
      this.transform.scale -= 0.1;
      this.transform.scale = Number(this.transform.scale.toFixed(1));
    }
    // console.log(this.transform);
    this.transformData.emit(this.transform);
  }

  flipH() {
    this.transform.flipH = !this.transform.flipH;
    this.transformData.emit(this.transform);
  }

  flipV() {
    this.transform.flipV = !this.transform.flipV;
    this.transformData.emit(this.transform);
  }

  rotateLeft() {
    this.canvasRotation--;
    this.rotate.emit(this.canvasRotation);
  }

  rotateRight() {
    this.canvasRotation++;
    this.rotate.emit(this.canvasRotation);
  }

  preview() {}
}
