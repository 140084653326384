<h1 mat-dialog-title> {{ (data.isAdd ? 'addTags' : "removeTags") | translate }} </h1>

<form (ngSubmit)="onSubmit()">

  <mat-dialog-content class="mat-typography column">
    <app-group-autocomplete-chip class="full-width" inputLabel="permissionTag" appearance="outline"
      inputPlaceholder="newTag" (addValue)="addGroupOnForm($event)" (removeValue)="removeGroupOnForm($event)"
      [currentValues]="form.get('tags').value" [allValues]="data.tags">
    </app-group-autocomplete-chip>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> {{ "cancel" | translate }} </button>
    <button mat-raised-button type="submit" color="primary"> {{ "apply" | translate }} </button>
  </mat-dialog-actions>

</form>
