import { Injectable } from '@angular/core';
import { AuthService } from '@core/services';
import { environment } from '@environments/environment';
import {
  CALL_EVENT,
  INITIAL_EVENTS_NUMBER,
  MATRIX_DEVICE_NAME_PREFIX,
  MATRIX_LOGIN_IDENTIFIER_TYPE,
  MATRIX_LOGIN_TYPE,
  MATRIX_PASS_CLIENT_SUFFIX
} from '@shared/constants';
import { MatrixLoginCredentials } from '@shared/models';
import { clearMatrixLocalStore, getMatrixCredentials, isAuthenticatedInMatrix, updateMatrixLocalStore } from '@shared/utils';
import { EventType, Filter, MatrixClient, createClient } from 'matrix-js-sdk';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private url = environment.matrixUrl;
  private matrixClient: MatrixClient;
  private cred: MatrixLoginCredentials;
  private mUserId: string;
  public makeLogoutByAPI = new Subject<boolean>();
  public hasLogout = false;

  constructor(private authService: AuthService) {
    this.mUserId = this.authService.user.id.toLocaleLowerCase();
  }

  /**
   * Start matrix client
   */
  public async createMClient(): Promise<MatrixClient> {
    // Return if already started
    if (this.matrixClient) return this.matrixClient;

    // Form matrix user pass
    const pass = `${this.authService.user.id.toLocaleLowerCase()}${MATRIX_PASS_CLIENT_SUFFIX}`;

    // When login Mobi phone user
    await this.login(this.mUserId, pass);

    // Create matrix client
    this.createClient();

    // Return created matrix client
    return this.matrixClient;
  }

  /**
   * Login
   * @param {string} username Username
   * @param {string} password Password
   */
  private async login(username: string, password: string): Promise<void> {

    // Form identifier
    const identifier = {
      type: MATRIX_LOGIN_IDENTIFIER_TYPE,
      user: username,
    };

    // Create temporary client
    const client = this.createTemporaryClient(this.url);

    // Login credentials
    const loginCredentials = {
      identifier,
      password,
      initial_device_display_name: MATRIX_DEVICE_NAME_PREFIX,
    }

    // Login
    this.cred = isAuthenticatedInMatrix()
      ? getMatrixCredentials()
      : await client.login(MATRIX_LOGIN_TYPE, loginCredentials);

    //  Update local matrix credentials
    updateMatrixLocalStore(this.cred.access_token, this.cred.device_id, this.cred.user_id);
  }

  /**
   * Create client
   */
  private createClient() {
    // // Create store
    // const indexedDBStore = new IndexedDBStore({
    //   indexedDB: indexedDB,
    //   localStorage: localStorage,
    //   dbName: 'matrix-web-sync-store',
    // });

    // // Start store
    // await indexedDBStore.startup();

    // Create client
    this.matrixClient = createClient({
      baseUrl: this.url,
      accessToken: this.cred.access_token,
      userId: this.cred.user_id,
      deviceId: this.cred.device_id,
      // store: indexedDBStore,
      timelineSupport: true,
    });
  }

  /**
   * Start matrix client
   */
  public async startMClient() {
    // Start client
    await this.matrixClient.startClient({
      threadSupport: true,
      disablePresence: true,
      // lazyLoadMembers: true,
      // filter: this.createStartClientFilter(),
      initialSyncLimit: INITIAL_EVENTS_NUMBER, // initial events number
      // pendingEventOrdering: PendingEventOrdering.Detached
    });

    // Log
    console.log('Matrix client started');
  }

  /**
   * Create start client filter
   * @returns {Filter} Start client filter
   */
  private createStartClientFilter(): Filter {
    // Create filter
    const filter: Filter = new Filter(this.cred.user_id)

    // Set filter
    filter.setDefinition({
      room: {
        include_leave: false,
        ephemeral: {
          types: [
            EventType.Typing,
            EventType.Receipt,
          ],
        },
        timeline: {
          types: [
            EventType.RoomMessage,
            EventType.RoomCreate,
            EventType.RoomMember,
            EventType.RoomAvatar,
            EventType.RoomName,
            EventType.RoomPowerLevels,
            EventType.Reaction,
            EventType.RoomRedaction,
            CALL_EVENT,
          ],
        },
      },
    });

    // Return filter
    return filter;
  }

  /**
   * Create Temporary Client
   * @param {string} baseUrl Server url
   * @returns {MatrixClient} Temporary Client
   */
  private createTemporaryClient(baseUrl: string): MatrixClient {
    return createClient({ baseUrl });
  }

  /**
   * Get matrix client
   * @returns {MatrixClient} Matrix client
   */
  public getClient(): MatrixClient {
    return this.matrixClient;
  }

  /**
   * Logout
   */
  public async logout() {
    try {
      // Set has logout
      this.hasLogout = true;

      // Matrix client logout
      try {
        await this.matrixClient.logout(true);
      } catch (error) { }

      // Clear matrix stores
      this.matrixClient.clearStores();

      // Clear local store
      clearMatrixLocalStore();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Make logout by other device
   */
  public makeLogoutByOtherDevice() {
    if (this.hasLogout) return;
    this.makeLogoutByAPI.next(true);
  }
}
