import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@core/services';

@Pipe({
  name: 'dateFormatPipe',
  pure: false
})
export class DateFormatPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private utilsService: UtilsService
  ) { }

  transform(value: string): string {
    return value ? this.formatDate(value) : '';
  }

  /**
   * Format the date by lang
   * @param {string} value Date
   * @returns Formatted date
   */
  private formatDate(value: string): string {
    const date = new Date(value);
    let localeLang = this.utilsService.getDateFormatByLocale(true);
    let resultDate = this.datePipe.transform(date, localeLang);
    return resultDate;
  }
}
