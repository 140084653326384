import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-form-advanced-options',
  templateUrl: './form-advanced-options.component.html',
  styleUrls: ['./form-advanced-options.component.scss']
})
export class FormAdvancedOptionsComponent implements OnInit {
  // Input
  @Input() show: boolean = true;
  @Input() showLabel: string = 'advancedSettings';

  // Output
  @Output() showChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Toggles the visibility of the advanced options.
   */
  public toggleShow(): void {
    this.show = !this.show;
    this.showChange.emit(this.show);
  }
}
