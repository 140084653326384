import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '@core/services';
import { goBack } from '@shared/utils';

@Component({
  selector: 'app-footer-props',
  templateUrl: './footer-props.component.html',
  styleUrls: ['./footer-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterPropsComponent {
  // Functions
  public goBack = goBack;

  // Input
  @Input() public canShowCancel = true;
  @Input() public isSubmitDisabled = false;
  @Input() public submitText = 'save';
  @Input() public cancelText = 'cancel';

  // Output
  @Output() public confirm = new EventEmitter<void>();

  constructor(public themeService: ThemeService) { }
}
