import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  updateDoc,
} from '@angular/fire/firestore';
import { LcsEventDescriptions, LcsEventsTrunkTypes } from '@shared/enums';
import { Trunk } from '@shared/models/trunk.model';
import { getDiffBetweenObj, getTrunksPath } from '@shared/utils';
import { first, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { LcsEventsService } from '../lcs-events/lcs-events.service';
import { NotifierService } from '../notifier/notifier.service';

@Injectable({
  providedIn: 'root',
})
export class TrunkService {
  private companyId: string = '';

  constructor(
    private lcsEventsService: LcsEventsService,
    private notifier: NotifierService,
    private firestore: Firestore,
    private authService: AuthService
  ) {
    this.companyId =
      this.authService.user.companyId ??
      localStorage.getItem('mobi_phone:tenantId');
  }

  /**
   * Create a new external trunk
   * @param {Trunk} trunk Trunk data
   */
  public async create(
    trunk: Trunk,
    companyId: string = this.companyId
  ): Promise<string> {
    // Get collection reference
    const colRef = collection(this.firestore, getTrunksPath(companyId));

    try {
      // Add trunk to firestore
      const newDoc = await addDoc(colRef, trunk);

      await this.handleSuccess(LcsEventsTrunkTypes.TRUNK_CREATION, {
        created: { ...trunk, id: newDoc.id },
      });

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'trunkCreatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });

      return newDoc.id;
    } catch (err: any) {
      this.handleError(err, LcsEventsTrunkTypes.TRUNK_CREATION);
    }
  }

  public async update(newTrunk: any, trunkId: string, originalTrunk: any) {
    // Get doc reference
    const docRef = doc(this.firestore, getTrunksPath(this.companyId, trunkId));

    try {
      // Update trunk
      await updateDoc(docRef, { ...newTrunk });

      this.handleSuccess(LcsEventsTrunkTypes.TRUNK_UPDATE, {
        id: newTrunk.id,
        name: newTrunk.namePbx,
        diff: getDiffBetweenObj(newTrunk, originalTrunk, ['id']),
      });

      // Show notification
      this.notifier.showNotification({
        type: 'success',
        message: 'trunkUpdatedSuccessfully',
        actionText: 'OK',
        panelClass: 'success',
      });
    } catch (err) {
      this.handleError(err, LcsEventsTrunkTypes.TRUNK_UPDATE);
    }
  }

  /**
   * Delete a trunk
   * @param {Trunk} trunk Trunk data
   */
  public async delete(trunk: Trunk): Promise<void> {
    // Get doc reference
    const docRef = doc(this.firestore, getTrunksPath(this.companyId, trunk.id));

    try {
      // Delete trunk from firestore
      await deleteDoc(docRef);

      this.handleSuccess(LcsEventsTrunkTypes.TRUNK_DELETION, {
        deleted: {
          id: trunk.id,
        },
      });
    } catch (err) {
      this.handleError(err, LcsEventsTrunkTypes.TRUNK_DELETION);
    }
  }

  public async getById(id: string) {
    // Get doc reference
    const docRef = doc(this.firestore, getTrunksPath(this.companyId, id));

    // Get doc
    const document = await getDoc(docRef);
    // Check if doc exists
    if (!document.exists()) return null;

    // Get trunk data
    const trunk = document.data() as any;

    // Add id to trunk
    trunk.id = id;

    // Return trunk data
    return trunk;
  }
  /**
   * Update user data
   * @param {object} data { [key: string]: any } Some user data to update
   * @param {string} userId User id
   * @returns {Promise<void>}
   */
  public fetchAll(companyId: string): Observable<any[]> {
    const col = <CollectionReference<any>>(
      collection(this.firestore, getTrunksPath(companyId))
    );

    return collectionData<any>(col, { idField: 'id' });
  }

  public async alreadyHasPrioritaryTrunk(currentTrunk: Trunk) {
    let priority: boolean = false;
    (await lastValueFrom(this.fetchAll(this.companyId).pipe(first()))).map(
      (trunk: Trunk) => {
        if (currentTrunk) {
          if (trunk.priority == true && trunk.id != currentTrunk.id)
            priority = true;
        } else {
          if (trunk.priority == true) priority = true;
        }
      }
    );
    return priority;
  }

  /**
   *
   * @param currentTrunk If it's edition, the current trunk edited
   * @returns true or false, if it should force the trunk's priority to true.
   *
   */
  public async shouldForcePriorityToTrue(currentTrunk?: Trunk) {
    const trunks: Trunk[] = await lastValueFrom(
      this.fetchAll(this.companyId).pipe(first())
    );

    // If there's one trunk, and I'm editing it, maintain priority = true
    if (trunks.length == 1 && currentTrunk) {
      const isCurrentTrunk = trunks.some(
        (trunk) => trunk.id == currentTrunk.id
      );
      if (isCurrentTrunk) return true;
    }

    // If there are no trunks (i'm creating the first) maintain priority = true
    if (trunks.length <= 0) return true;

    if (trunks.length > 1) {
      if (currentTrunk) {
        const IHavePriority = trunks.some(
          (trunk) => trunk.priority == true && trunk.id == currentTrunk.id
        );
        if (IHavePriority) return true;
      }
    }
    // Otherwise, false XD
    return false;
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventsTrunkTypes} type The event type
   */
  public handleError(error: any, type: LcsEventsTrunkTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventsService.create(
      { type, description: LcsEventDescriptions.ERROR, value: { error } },
      true
    );

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsEventsTrunkTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventsTrunkTypes, value: any): Promise<void> {
    return this.lcsEventsService.create(
      { type, description: LcsEventDescriptions.SUCCESS, value },
      true
    );
  }
}
