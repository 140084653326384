import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivate } from "@angular/router";
import { ExitingConfirmationComponent } from "@shared/components";
import { MeetPropsComponent } from "app/features/meeting/page/components/meet-props/meet-props.component";
import { first, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesMeetPropsGuard implements CanDeactivate<MeetPropsComponent> {
    constructor(
        private dialog: MatDialog,
    ) {}

    /**
     * Determines whether the component can be deactivated (navigated away) or not.
     *
     * @param component - The MeetPropsComponent instance being deactivated.
     * @returns An Observable emitting a boolean value or a boolean indicating whether the deactivation is allowed.
     */
    public canDeactivate(component: MeetPropsComponent): Observable<boolean> | boolean {

        if (this.isNotDirty(component)) return true;

        // Open a dialog to confirm the user's intention to navigate away from the component
        const dialogRef = this.dialog.open(ExitingConfirmationComponent, {
            disableClose: true,
            panelClass: 'confirm-change-dialog',
            data: {
                save: () => component.submitMeeting()
            }
        });

        // Wait for the dialog to be closed and return the result as an Observable emitting a boolean value
        return dialogRef.afterClosed().pipe(first());
    }

    /**
     * Checks whether the MeetPropsComponent instance has unsaved changes.
     *
     * @param component - The MeetPropsComponent instance to check for unsaved changes.
     * @returns A boolean indicating whether the component has unsaved changes (is not dirty).
     */
    private isNotDirty(component: MeetPropsComponent): boolean {
        return component.isNotDirty();
    }
}
