/**
 * Fills an array with hours and minutes, with the specified minute increment
 * @param incrementMinutes - The increment in minutes
 * @returns The array of formatted hours and minutes
 */
export function populateHoursArray(incrementMinutes: number): string[] {
  const hourFormat = "HH:mm";
  let hoursArray: string[] = [];

  // Determines the start time and end time of the loop based on the hour format
  const startTime = 0;
  const endTime = 24;

  for (let hour = startTime; hour < endTime; hour++) {
    for (let minute = 0; minute < 60; minute += incrementMinutes) {
      const formattedHour = formatHour(hour, minute, hourFormat);
      hoursArray.push(formattedHour);
    }
  }

  return hoursArray;
}

/**
 * Formats an hour and minute as a string according to the specified format
 * @param hour - The hour (0-23 or 1-12)
 * @param minute - The minute (0-59)
 * @param format - The hour format (e.g. "HH:mm" for 24h, "hh:mm A" for 12h)
 * @returns The formatted hour and minute
 */
function formatHour(hour: number, minute: number, format: string): string {
  const formattedHour = format
    .replace("HH", padLeftZero(hour, 2))
    .replace("hh", padLeftZero((hour + 11) % 12 + 1, 2))
    .replace("mm", padLeftZero(minute, 2))
    .replace("A", hour <= 13 ? "AM" : "PM");

  return formattedHour;
}

/**
 * Pads a number with zeros on the left until it reaches the specified length
 * @param number - The number to be padded
 * @param length - The desired final length
 * @returns The number padded with zeros on the left
 */
function padLeftZero(number: number, length: number): string {
  let numberString = number.toString();
  while (numberString.length < length) {
    numberString = "0" + numberString;
  }
  return numberString;
}

export function validateTime(time: string, is24hFormat: boolean): boolean {
  const regex24h = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; // regex for 24-hour format
  const regex12h = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|am|PM|pm)$/; // regex for 12-hour format
  const regex = is24hFormat ? regex24h : regex12h; // selects the appropriate regex based on the specified format
  return regex.test(time);
}
