// CELL PHONE
export const ACCEPTED_CELL_PHONE_INITIALS = ['91', '92', '93', '94', '95', '96', '97', '98', '99'];
export const CELL_PHONE_LENGTH = 9;

// LANDLINE
export const ACCEPTED_LANDLINE_INITIALS = ['2', '3', '4', '5'];
export const LANDLINE_LENGTH = 8;

// TELEPHONE SERVICES
export const ACCEPTED_TELEPHONE_SERVICES = ['0800', '0300', '0500', '0900'];
export const TELEPHONE_SERVICES = 11;

// CARRIER CODE
export const ACCEPTED_CARRIER_CODE = ['012', '014', '015', '017', '021', '025', '031', '041', '043'];
export const CARRIER_CODE_LENGTH = 3;
