<h1 mat-dialog-title>{{ data.title | translate }}</h1>

<mat-dialog-content class="mat-typography column" *ngIf="data.desc">
  <p>{{ data.desc | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="!data.hideCancel" mat-button mat-dialog-close style="text-transform: uppercase;">
    {{'cancel' | translate }}
  </button>
  <button mat-raised-button (click)="onSubmit()" type="submit" class="btn-warning" [color]="buttonColor"
    style="text-transform: uppercase;">
    {{ (data.btnText ?? 'OK') | translate }}
  </button>
</mat-dialog-actions>
