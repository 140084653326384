<mat-form-field class="full-width" [appearance]="appearance">
  <mat-label>{{inputLabel | translate }} <span *ngIf="isRequired"> *</span></mat-label>
  <mat-chip-list #chipList>
    <div *ngFor="let value of currentValues">
      <mat-chip (removed)="remove(value)" [class.invalid]="isInvalidValue(value)">
        {{parseIdToName(value)}}
        <mat-icon matChipRemove>close</mat-icon>
      </mat-chip>
    </div>
    <input [placeholder]="inputPlaceholder | translate" #formInput [formControl]="formCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let value of filteredValues | async" [value]="value.id">
      {{value.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
