import { Injectable } from '@angular/core';
import { InputCache } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class InputCacheService {
  public inputCache = new Map<string, InputCache>();

  constructor() { }

  /**
   * Save input cache from room id
   * @param {string} roomId Room id
   * @param {InputCache} inputCache Input cache
   */
  public saveInputCache(roomId: string, inputCache: InputCache) {
    this.inputCache.set(roomId, inputCache);
  }

  /**
   * Get input cache from room id
   * @param {string} roomId Room id
   * @returns {InputCache} Input cache
   */
  public getInputCache(roomId: string): InputCache {
    return this.inputCache.get(roomId);
  }

  /**
   * Check if has input cache from room id
   * @param {string} roomId Room id
   * @returns {boolean} True if has input cache
   */
  public hasInputCache(roomId: string): boolean {
    return this.inputCache.has(roomId);
  }

  public deleteInputCache(roomId: string): boolean {
    return this.inputCache.delete(roomId)
  }

}
