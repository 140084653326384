import { Injectable } from '@angular/core';
import { Database, DatabaseReference, off, onValue, ref, set } from '@angular/fire/database';
import { AuthService, TenantService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class MissingCallsService {
  private missingCallsRef: DatabaseReference;
  private hasMissingCalls: boolean = false;

  constructor(
    private tenantService: TenantService,
    private database: Database,
    private authService: AuthService,
  ) { }

  /**
   * Get Database Missing Calls Path
   * @returns {string} Missing calls path
   */
  public getMissingCallsPath(): string {
    return `companies/${this.tenantService.tenant.id}/users/${this.authService.userId}/missingCalls`
  }

  /**
   * Update Has Missing Calls
   * @param {boolean} hasMissingCalls Has Missing Calls
   */
  public updateHasMissingCalls(hasMissingCalls: boolean): Promise<void> {
    // Set has missing calls
    return set(this.missingCallsRef, hasMissingCalls);
  }

  /**
   * Listening missing calls
   */
  public listeningMissingCalls(): void {
    // Form path
    const path = this.getMissingCallsPath();

    // Database connections ref
    this.missingCallsRef = ref(this.database, path);

    // Get inicial values
    onValue(this.missingCallsRef, (snapshot) => this.hasMissingCalls = !!snapshot.val());
  }

  /**
   * Check if user has missing calls
   * @returns {boolean} Has Missing Calls
   */
  public getHasMissingCalls(): boolean {
    return this.hasMissingCalls;
  }


  /**
   * Stop Listening Rooms In Meeting
   */
  public stopListeningMissingCalls(): void {
    try {
      off(this.missingCallsRef);
    } catch (error) { }
  }

}
