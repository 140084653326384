import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(public platform: Platform) { }

  isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  isIOS(): boolean {
    return this.platform.IOS;
  }

  isChrome(): boolean {
    return (
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
    );
  }

  isFirefox(): boolean {
    return /Firefox/.test(navigator.userAgent);
  }

  isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  isOpera(): boolean {
    const isOpera: boolean =
      /OPR/.test(navigator.userAgent) ||
      /Coast/.test(navigator.userAgent) ||
      /OPiOS/.test(navigator.userAgent);

    return isOpera;
  }

  isOperaMobile(): boolean {
    const isOperaHigh: boolean =
      /OPR/.test(navigator.userAgent) &&
      /Mobile/.test(navigator.userAgent) &&
      !/Presto/.test(navigator.userAgent);

    const isOperaExtreme: boolean =
      /Opera Mini/.test(navigator.userAgent) &&
      /Presto/.test(navigator.userAgent);

    return isOperaHigh || isOperaExtreme;
  }

  /**
   * Get Browser name
   * @returns {string} Browser name
   */
  public getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'Ie';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }
}
