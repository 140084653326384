import { Component, OnInit } from '@angular/core';
import { JsSIPService } from '@core/services';

@Component({
  selector: 'app-hangup-button',
  templateUrl: './hangup-button.component.html',
  styleUrls: ['./hangup-button.component.scss'],
})
export class HangupButtonComponent implements OnInit {
  constructor(private jssip: JsSIPService) {}

  ngOnInit(): void {}

  onHangup(): void {
    this.jssip.hangup();
  }
}
