import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Group } from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-group-autocomplete-chip',
  templateUrl: './group-autocomplete-chip.component.html',
  styleUrls: ['./group-autocomplete-chip.component.scss']
})
export class GroupAutocompleteChipComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('formInput') formInput: ElementRef<HTMLInputElement>;
  @Input() currentValues: string[] = [];
  @Input() allValues: Group[] = [];
  @Input() inputPlaceholder: string = '';
  @Input() appearance?: MatFormFieldAppearance;
  @Input() isRequired?: boolean = false;
  @Input() inputLabel: string = '';
  @Output() addValue = new EventEmitter<string>();
  @Output() removeValue = new EventEmitter<string>();

  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];
  public formCtrl = new UntypedFormControl();
  public filteredValues: Observable<Group[]>;
  public allInvalidValues: string[] = [];
  private destroy$ = new Subject<void>();

  constructor() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allValues) {
      this.allInvalidValues = changes.allValues.currentValue
        ?.filter((value: { status: boolean; }) => !value.status)
        .map((value: { id: string; }) => value.id)
    }
  }

  ngOnInit(): void {
    this.filteredValues = this.formCtrl
    .valueChanges
    .pipe(
      takeUntil(this.destroy$),
      startWith(null),
      map((value: string | null) => (value
        ? this.filter(value)
        : this.allValues
          .filter(x => x.status)
          .filter(x => !this.currentValues.includes(x.id))
      )),
    );
  }

  /**
   * Add new value
   * @param {MatChipInputEvent} event Input element
   */
  public add(event: MatChipInputEvent): void {
    const value = this.getIdByName(event.value.toUpperCase())

    if (value) this.addValue.emit(value);

    event.chipInput!.clear();

    this.formCtrl.setValue(null);
  }

  /**
   * Remove value
   * @param {string} value The string that will be removed from the array
   */
  public remove(value: string): void {
    this.removeValue.emit(value)
  }

  /**
   * Select a new value
   * @param {MatAutocompleteSelectedEvent} event Autocomplete element
   */
  public selected(event: MatAutocompleteSelectedEvent): void {
    const newGroup = this.getIdByName(event.option.viewValue);
    if (!this.currentValues.includes(newGroup) && !this.isInvalidValue(newGroup)) {
      this.addValue.emit(newGroup)
    }
    this.formInput.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  /**
   * Implements or not invalid class
   * @param {string} value Current value
   * @returns {boolean} Whether the invalid class should be implemented
   */
  public isInvalidValue(value: string): boolean {
    return this.allInvalidValues.includes(value)
  }

  /**
   * Filter
   * @param {string} value Term that will be use to filter
   * @returns {Group[]} the new array
   */
  private filter(value: string): Group[] {
    const filterValue = value.toLowerCase();

    return this.allValues
      .filter(value => value.status)
      .filter(value => value.name.toLowerCase().includes(filterValue) && !this.currentValues.includes(value.id));
  }

  public canShowGroup(id: string): boolean {
    return !!this.allValues.find(value => value.id === id)
  }

  /**
   * Change ID to name
   * @returns {string} name list
   */
  public parseIdToName(group: string): string {
    return this.allValues.find(value => value.id === group)?.name ?? group;
  }

  /**
   * Get Id by group name
   * @param {string} name group name
   * @returns group id
   */
  public getIdByName(name: string): string {
    return this.allValues.find(group => group.name === name)?.id ?? name
  }
}
