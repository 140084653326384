import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-permission',
  templateUrl: './request-permission.component.html',
  styleUrls: ['./request-permission.component.scss'],
})
export class RequestPermissionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
