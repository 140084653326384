import { DAYS_OF_WEEK_TYPE } from "@shared/enums";
import { Timestamp } from "firebase/firestore";

function convertTo24Format(time: string): string {
  const timeArray = time.split(' ');
  const [hour, minute] = timeArray[0].split(':');
  let newHour = parseInt(hour);

  if (timeArray[1] === 'PM' && newHour !== 12) {
    newHour += 12;
  } else if (timeArray[1] === 'AM' && newHour === 12) {
    newHour = 0;
  }

  const newHourString = newHour.toString().padStart(2, '0');
  const minuteString = minute.padStart(2, '0');

  return `${newHourString}:${minuteString}`;
}


function convertTo12Format(time: string): string {
  const [hour, minute] = time.split(':');
  let newHour = parseInt(hour);
  let period = 'AM';

  if (newHour >= 12) {
    newHour -= 12;
    period = 'PM';
  }

  if (newHour === 0) newHour = 12;

  return newHour.toString().length == 1
    ? `0${newHour}:${minute} ${period}`
    : `${newHour}:${minute} ${period}`;
}

export function convertHour(time: string, outputFormat: 12 | 24): string {
  // Regular expression to match 24-hour format
  const time24Regex = /^(2[0-3]|[01][0-9]):([0-5][0-9])$/;

  // Regular expression to match 12-hour format
  const time12Regex = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(am|AM|pm|PM)?$/;

  switch (true) {
    // Convert 24-hour format to 12-hour format
    case outputFormat === 12 && time24Regex.test(time):
      return convertTo12Format(time);

    // Convert 12-hour format to 24-hour format
    case outputFormat === 24 && time12Regex.test(time):
      return convertTo24Format(time);

    default:
      return time;
  };
}

export function parseDateToFTimestamp(date: Date): Timestamp {
  return new Timestamp(date.getTime() / 1000, 0);
}


/**
 * Get days name
 * @param {Date} date Date
 * @returns {DAYS_OF_WEEK_TYPE} Day name in lowercase
 */
export function getDayName(date: Date): DAYS_OF_WEEK_TYPE {
  return date.toLocaleString('en-us', { weekday: 'long' })?.toLowerCase() as DAYS_OF_WEEK_TYPE;
}
