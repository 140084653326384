
export const ADM_DASHBOARD_DATA = [
    {
        value: 'totalCalls',
        icon: 'icon_total',
        color: '#e5e5e5'
    },{
        value: 'externalReceived',
        icon: 'incoming',
        color: '#e5e5e5'
    }, {
        value: 'externalOutbound',
        icon: 'outcoming',
        color: '#e5e5e5'
    },{
        value: 'internal.plural',
        icon: 'internal_calls',
        color: '#e5e5e5'
    }
]
  

