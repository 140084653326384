import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { PlatformService, UtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'niceDateFormatPipe',
  pure: false
})
export class NiceDateFormatPipePipe implements PipeTransform {
  constructor(
    private utils: UtilsService,
    private platform: PlatformService,
    private translate: TranslateService,
  ) { }

  transform(value: string): string {
    const date = new Date(value);
    const diffInMs = Math.abs(Date.now() - Number(date));
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return this.formatDate(date, diffInDays);
  }

  /**
   * Format the date by lang
   * @param value Date
   * @param diffInDays Difference in days between CDR date and current date
   * @returns Formatted date
   */
  private formatDate(date: Date, diffInDays: number): string {
    const datePipe: DatePipe = new DatePipe(this.translate.currentLang);

    if (diffInDays < 1) {
      return datePipe.transform(date, 'HH:mm');
    } else if (diffInDays >= 1 && diffInDays < 2) {
      return this.translate.instant('yesterday') + ' ' + datePipe.transform(date, 'HH:mm');
    } else if (diffInDays >= 2 && diffInDays < 7) {
      if (this.platform.isMobile()) {
        return datePipe.transform(date, 'E HH:mm');
      } else {
        return datePipe.transform(date, 'EEEE HH:mm');
      }
    } else {
      return datePipe.transform(date, this.utils.getDateFormatByLocale());
    }
  }
}
