import { Injectable } from '@angular/core';
import { FirestoreService, LcsEventsService } from '@core/services';
import { LcsEventDescriptions, LcsEventPABXTypes } from '@shared/enums';
import { PABX } from '@shared/models';
import { getDiffBetweenObj, getPABXPath } from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PbxService {
  constructor(
    private firestore: FirestoreService,
    private lcsEventService: LcsEventsService
  ) { }

  /**
   * Get all PBX data
   * @returns {Observable<PABX[]>} PBX data
   */
  public fetchAll(): Observable<PABX[]> {
    const path = getPABXPath();
    return this.firestore.colWithIds$<PABX>(path);
  }

  /**
   * Get the PBX data by ID
   * @param {string} id PBX id
   * @returns {Promise<PABX>} PBX data
   */
  public getById(id: string): Promise<PABX> {
    const path = getPABXPath(id);
    return lastValueFrom(this.firestore.docWithId$(path).pipe(first())) as Promise<PABX>;
  }

  /**
   * Create a new pabx
   * @param {PABX} pabx Obj that contains the pabx data
    * @returns {Promise<string>} PABX id
   */
  public async create(pabx: PABX): Promise<string> {
    try {
      // Add
      const result = await this.firestore.addDoc(getPABXPath(), pabx);

      // Create a new event
      await this.handleSuccess(LcsEventPABXTypes.PABX_CREATION, { created: { ...pabx, id: result.id } });

      // Return the id
      return result.id;
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.PABX_CREATION);
    }
  }

  /**
   * Delete a pabx
   * @param {any} pabx PABX id
   */
  public async deletePABX(pabx: any): Promise<void> {
    try {
      // Delete
      await this.firestore.deleteDoc(getPABXPath(pabx.id));

      // Create a new event
      this.handleSuccess(LcsEventPABXTypes.PABX_DELETION, {
        deleted: {
          id: pabx.id,
          name: pabx.name
        }
      });
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.PABX_DELETION);
    }
  }

  /**
   * Update a pabx
   * @param {Partial<PABX>} pabx Obj that contains the pabx data
   * @param {Partial<PABX>} original The original pabx data
   */
  public async updatePABX(pabx: Partial<PABX>, original: Partial<PABX>): Promise<void> {
    try {
      // Update
      await this.firestore.updateDoc(getPABXPath(pabx.id), pabx);

      // Create a new event
      this.handleSuccess(LcsEventPABXTypes.PABX_UPDATE, {
        id: pabx.id,
        name: pabx.namePbx,
        diff: getDiffBetweenObj(pabx, original, ['id'])
      })
    } catch (error) {
      this.handleError(error, LcsEventPABXTypes.PABX_UPDATE);
    }
  }

  /**
 * Handle error
 * @param {string} error The error message
 * @param {LcsEventPABXTypes} type The event type
 */
  public handleError(error: any, type: LcsEventPABXTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, true);

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsEventPABXTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventPABXTypes, value: any): Promise<void> {
    return this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, true);
  }
}
