import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService, UtilsService } from '@core/services';
import { environment } from '@environments/environment';
import { LcsEventTypes } from '@shared/enums';
import { LcsEvent, LcsEventsFilter, LcsEventsSearchResult } from '@shared/models';
import {
  formParams,
  getDesktopVersion,
  getLCSEventPath,
  getLSTenantId,
  getLSUserEmail,
  getLSUserId,
  isLcsPanelPage
} from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

type LcsEventCreateData = {
  companyId?: string;
  userId?: string;
  userEmail?: string;
  type?: LcsEventTypes;
  description?: string;
  value?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LcsEventsService {
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private spinner: LoadingSpinnerService,
  ) { }

  /**
   * Create a new event
   * @param {LcsEventCreateData} data Event data
   * @param {boolean} showSpinner Show spinner
   */
  public async create(data: LcsEventCreateData, showSpinner: boolean = false): Promise<void> {
    // Show the spinner
    if (showSpinner) this.spinner.show();

    // Get the path to the event creation endpoint
    const url = getLCSEventPath('create');

    // Get event data
    const event = this.baseLcsEvent(data);

    // Send the request to the endpoint
    await lastValueFrom(this.http.post(url, event).pipe(first()))
      .finally(() => {
        if (showSpinner) this.spinner.hide();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Create the base event object
   * @param {LcsEventCreateData} data Event data
   * @returns {LcsEvent} Event object
   */
  public baseLcsEvent(data: LcsEventCreateData): LcsEvent {
    if (!data.type) {
      throw new Error('Type is required');
    }

    return {
      companyId: data.companyId ?? getLSTenantId(),
      userId: data.userId ?? getLSUserId(),
      userEmail: data.userEmail ?? getLSUserEmail(),
      device: this.utilsService.getDeviceName(),
      description: data.description ?? '',
      type: data.type,
      value: {
        platform: this.getPlatform(),
        mobi_version: environment.appVersion,
        ...(getDesktopVersion() && { desktop_version: getDesktopVersion() }),
        ...(data.value ?? {}),
      },
    };
  }

  /**
   * Get Platform
   * @returns {string} Platform
   */
  public getPlatform(): string {
    return isLcsPanelPage() ? 'LCS' : 'Mobi';
  }

  /**
  * Get events
  * @param {LcsEventsFilter} options Events filter
  * @returns {Promise<{ events: LcsEvent[], count: number }>} List of events
  */
  public httpList(options: LcsEventsFilter): Observable<HttpResponse<LcsEventsSearchResult>> {
    // Get the path to the event list endpoint
    const url = getLCSEventPath('list');


    // Send the request to the endpoint
    return this.http.get<LcsEventsSearchResult>(url, {
      observe: 'response',
      params: formParams(options),
    });
  }
}
