import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact } from '@shared/models';

type Data = {
  externalContacts: Contact[];
}

@Component({
  selector: 'app-similar-contacts',
  templateUrl: './similar-contacts.component.html',
  styleUrls: ['./similar-contacts.component.scss']
})
export class SimilarContactsComponent implements OnInit {

  public duplicatedContacts: Contact[] = [];

  constructor(
    public dialogRef: MatDialogRef<SimilarContactsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
  ) { }

  ngOnInit(): void {
    this.duplicatedContacts = this.data.externalContacts;
  }

}
