<div class="content-wrapper" [ngClass]="{ dark: themeService.isDarkTheme() }">
  <mat-form-field appearance="outline">
    <mat-icon matPrefix>search&nbsp;</mat-icon>
    <input
      type="search"
      matInput
      id="search"
      #searchInput
      placeholder="{{ placeholder | translate }}"
      autocomplete="off"
      (keyup)="debounce()"

    />
    <button
      mat-icon-button
      matSuffix
      aria-label="Cancel search"
      *ngIf="searchInput.value && canShowClearButton"
      (click)="onCancel()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-form-field>
</div>
