import { MatrixNotificationType } from "@shared/enums";
import { IPushRule, MatrixClient } from "matrix-js-sdk";

/**
 * Get Current Push Rule
 */
export const getCurrentPushRule = (mxc: MatrixClient, roomId: string): IPushRule => {
  // Initiate variables
  let roomPushRule = undefined;

  // Try get current push rule
  try {
    roomPushRule = mxc.getRoomPushRule('global', roomId);
  } catch {
    roomPushRule = undefined;
  }

  // Return IPushRule
  return roomPushRule;
}

/**
 * Find muted rule
 * @param {any} overrideRules Override Rules
 * @param {string} roomId Room id
 */
export const findMutedRule = (overrideRules: any, roomId: string) => {
  return overrideRules.find((rule) => (
    rule.rule_id === roomId
    && isMutedRule(rule)
  ));
}

/**
 * Check if rule is muted
 */
const isMutedRule = (rule: any) => {
  return rule.actions[0] === 'dont_notify' && rule.conditions[0].kind === 'event_match';
}

/**
 * Get Notification Type
 * @param {MatrixClient} mx Matrix Client
 * @param {string} roomId Room Id
 * @returns {MatrixNotificationType} Matrix Notification Type
 */
export const getNotiType = (mx: MatrixClient, roomId: string): MatrixNotificationType => {
  // Initiate variables
  let pushRule: IPushRule;

  try {
    pushRule = mx.getRoomPushRule('global', roomId); // Get current push rule
  } catch {
    pushRule = undefined; // Set push rule to undefined if error
  }

  // If push rule is undefined then get override rules
  if (pushRule === undefined) {
    const overrideRules = mx.getAccountData('m.push_rules')?.getContent()?.global?.override;

    // If override rules is undefined then return default
    if (overrideRules === undefined) return MatrixNotificationType.DEFAULT;

    // If override rules is not undefined then check if muted rule exists
    return findMutedRule(overrideRules, roomId)
      ? MatrixNotificationType.MUTE
      : MatrixNotificationType.DEFAULT;
  }

  // If push notification is notify then return all messages
  if (pushRule.actions[0] === 'notify') return MatrixNotificationType.ALL_MESSAGES;

  // Notify is not set then check if mentions only
  return MatrixNotificationType.MENTIONS_AND_KEYWORDS;
}


