<span *ngIf="roomMeet">
  | {{ 'meeting' | translate }}:
  {{ roomMeet.type !== 'recurrent' ? (getMeetingDate() | timelineDateFormatPipe:'date':true) : ''}}
  <ng-container *ngIf="roomMeet?.recurrentDays?.length === 7">
    {{ 'everyday' | translate }}
  </ng-container>
  <ng-container
    *ngIf="roomMeet?.recurrentDays?.length === 5 && roomMeet?.recurrentDays?.indexOf('SATURDAY') === -1 && roomMeet?.recurrentDays?.indexOf('SUNDAY') === -1">
    {{ 'everyWeekDay' | translate }}
  </ng-container>
  <span
    *ngIf="roomMeet?.recurrentDays?.length !== 7 && !(roomMeet?.recurrentDays?.length === 5 && roomMeet?.recurrentDays?.indexOf('SATURDAY') === -1 && roomMeet?.recurrentDays?.indexOf('SUNDAY') === -1)">
    <span *ngFor="let day of roomMeet?.recurrentDays; let last = last">
      {{ day.toLowerCase() | translate }}{{ last ? '' : ','}}
    </span>
  </span>
  {{ '\u2022' }} {{ getMeetingStartHour() | hourFormatPipe }}
  {{ 'hoursTo' | translate }}
  {{ getMeetingFinalHour() | hourFormatPipe}}
</span>
