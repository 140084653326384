import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from '@core/services';
import { ImageCropperDialog } from '../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-img-picker',
  templateUrl: './img-picker.component.html',
  styleUrls: ['./img-picker.component.scss']
})
export class ImgPickerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() defaultImg = 'assets/images/avatar.svg';
  @Output() removePicture: EventEmitter<void> = new EventEmitter<void>();
  public maxMB = 2;

  constructor(
    private dialog: MatDialog,
    private notifier: NotifierService,
  ) { }

  ngOnInit(): void { }

  remove() {
    this.removePicture.emit();
  }

  changeProfilePicture(target: EventTarget): void {
    const files: FileList = (target as HTMLInputElement).files;
    const MB = this.maxMB;
    const maxSize: number = MB * 1024 * 1024;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      if (file.size < maxSize) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async (e) => {
          const picture = e.target.result;
          const dialogRef = this.dialog.open(ImageCropperDialog, {
            width: '50%',
            disableClose: true,
            data: picture,
          });

          dialogRef.afterClosed().subscribe(async (editedImage) => {
            if (editedImage) {
              this.form.controls[this.controlName].patchValue(
                editedImage
              );
            }
            (target as HTMLInputElement).value = null;
          });
        };
      } else {
        this.notifier.showNotification({
          type: 'error',
          message: 'maxSizeExceeded',
          actionText: '',
          panelClass: 'error',
        });
      }
    }
  }

  onImgError(target: EventTarget): void {
    (target as HTMLImageElement).src = 'assets/images/avatar.svg';
  }

}
