import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpTimingMonitorService } from '@core/services/http-timing-monitor/http-timing-monitor.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class MonitorInterceptor implements HttpInterceptor {
    constructor(private httpTimingMonitorService: HttpTimingMonitorService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.httpTimingMonitorService.getInterceptorActive())
            return next.handle(request);

        if (environment.production)
            return next.handle(request);

        const startTime = performance.now();
        return next.handle(request).pipe(
            finalize(() => {
                const endTime = performance.now();
                const duration = endTime - startTime;

                this.httpTimingMonitorService.registerNewCall(request.url, duration)

                const routeFilter = this.httpTimingMonitorService.filteredRoute$.value
                if (!request.url.includes('assets')) {
                    if (routeFilter) {
                        if (request.url.includes(routeFilter)) {
                            const url = request.url.slice(request.url.indexOf('.net'), request.url.length - 1)
                            console.log(`%c HTTP ${request.method} ${url} - ${duration.toFixed(3)} milliseconds`, 'background: #3d6db9; color: white');
                        }
                    } else if (request.url.includes('us-central1')) {
                        const url = request.url.slice(request.url.indexOf('.net'), request.url.length - 1)
                        console.log(`%c HTTP ${request.method} ${url} - ${duration.toFixed(3)} milliseconds`, 'background: #3d6db9; color: white');
                    } else {
                        console.log(`%c HTTP ${request.method} ${request.url} - ${duration.toFixed(3)} milliseconds`, 'background: #cadfff; color: black');
                    }
                }
            })
        );
    }
}