import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  query,
  where
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService, ClientService, JitsiService } from '@core/services';
import { MEET_TOLERANCE_TIME } from '@shared/constants';
import { MatrixRoomType } from '@shared/enums';
import { MeetingRoom, MeetingTypes, NavigationToNewMeet, User } from '@shared/models';
import { formMatrixFromUserId, setMeetTabIndex } from '@shared/utils';
import { updateDoc } from 'firebase/firestore';
import { ICreateRoomOpts, Room, Visibility } from 'matrix-js-sdk';
import { collectionData } from 'rxfire/firestore';
import { BehaviorSubject, first, lastValueFrom, Subject, takeUntil, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingsService {
  private companyId: string = '';
  public meetings$ = new BehaviorSubject<MeetingRoom[]>([]);
  public currentMeet = new Subject<MeetingRoom>();

  constructor(
    private clientService: ClientService,
    private firestore: Firestore,
    private authService: AuthService,
    private jitsi: JitsiService,
    private router: Router,
  ) {
    this.companyId = this.authService.user.companyId ?? localStorage.getItem('mobi_phone:tenantId');
  }

  /**
   * Get Meet room from Matrix room
   * @param {Room} room Matrix room or room id
   * @returns {MeetingRoom} Meet room
   */
  public getMeetFromRoom(room: Room | string): MeetingRoom {
    return this.meetings$.value.find(meet => meet.roomId == (typeof room == 'string' ? room : room?.roomId));
  }

  /**
   * Init Jitsi Meet
   * @param {string} roomId Room id
   * @param {string} name Meet name
   */
  public initJitsiMeet(roomId: string, name: string): void {
    this.jitsi.initCall(roomId, 'external', name);
  }

  /**
   * Update current meet
   * @param {Room | string} room Room or room id
   */
  public updateMeet(room?: Room | string): void {
    // If room is not null, get the meet from the room and set the tab index
    if (room) {
      const meet = this.getMeetFromRoom(room);
      const isRecurrent = meet.type == MeetingTypes.Recurrent;
      setMeetTabIndex(isRecurrent ? 0 : 1);
      this.currentMeet.next(meet);
    } else this.currentMeet.next(null);
  }

  /**
   * Create meet path
   * @param {string} id Meet ID
   * @returns {string} path to the meet collection
   */
  private getMeetingPath = (id?: string): string => {
    return `companies/${this.companyId}/meetings/${id ?? ''}`
  }

  /**
   * Get meeting by id
   * @param {string} id Meet ID
   * @returns {Promise<MeetingRoom>} Meet data
   */
  public async getById(id: string): Promise<MeetingRoom> {
    // Get doc reference
    const docRef = doc(this.firestore, this.getMeetingPath(id));

    // Get doc
    const document = await getDoc(docRef);

    // Check if doc exists
    if (!document.exists()) return null;

    // Get meet data
    const meet = document.data() as MeetingRoom;

    // Add id to meet
    meet.id = id;

    // Return meet data
    return meet;
  }

  /**
   * Get meeting by room id
   * @param {string} roomId Room id
   * @returns {MeetingRoom} Meet data
   */
  public async getMeetsFromRoomId(roomId: string): Promise<MeetingRoom[]> {
    // Get collection reference
    const appRef = collection(this.firestore, this.getMeetingPath());

    // Form query
    const appQuery = query(appRef, where('roomId', '==', roomId));

    // Return collection data
    return lastValueFrom(collectionData(appQuery, { idField: 'id' })
      .pipe(first())) as Promise<MeetingRoom[]>;
  }

  /**
   * Get meetings
   * @param {string} id Meet id
   * @returns Meetings in that company
   */
  public init(): void {
    // Get collection reference
    const appRef = collection(this.firestore, this.getMeetingPath());

    // Form query
    const appQuery = query(appRef, where('internalUsers', 'array-contains', this.authService.userId));

    // Return collection data
    collectionData(appQuery, { idField: 'id' })
      .pipe(
        takeUntil(this.authService.logout$),
        tap((meetings: MeetingRoom[]) => this.meetings$.next(meetings))
      )
      .subscribe();
  }

  /**
   * Create a new group
   * @param {MeetingRoom} meet Meet data
   */
  public async create(meet: MeetingRoom): Promise<string> {
    // Get collection reference
    const colRef = collection(this.firestore, this.getMeetingPath());

    // Add meet to firestore
    const newDoc = await addDoc(colRef, meet);

    // Return meet id
    return newDoc.id;
  }

  /**
  * Delete a meet
  * @param {MeetingRoom} meet Meet data
  */
  public async remove(meet: MeetingRoom): Promise<void> {
    // Get doc reference
    const docRef = doc(this.firestore, this.getMeetingPath(meet.id));

    // Delete meet from firestore
    await deleteDoc(docRef);
  }

  /**
   * Update a meet
   * @param {MeetingRoom} newMeetData New meet data
   * @param {string} id Meet id
   */
  public update(newMeetData: Partial<MeetingRoom>, id: string): Promise<void> {
    const docRef = doc(this.firestore, this.getMeetingPath(id));
    return updateDoc(docRef, { ...newMeetData });
  }

  /**
   * Replace users on meet
   * @param {string} roomId Room id
   * @param {string[]} usersIds Users ids to replace
   */
  public replaceUsersOnMeet(roomId: string, usersIds: string[]): Promise<void> {
    // Get meet from room
    const meet = this.getMeetFromRoom(roomId);

    // Check if meet exists
    if (!meet) return;

    // Get doc reference
    const docRef = doc(this.firestore, this.getMeetingPath(meet.id));

    // Replace users to meet
    return updateDoc(docRef, { internalUsers: usersIds });
  }

  /**
   * Check if the user is in the meet
   * @param {MeetingRoom} meet Meet data
   * @param {string} userId User id
   * @returns {boolean} If the user is in the meet
   */
  public isUserInMeet(meet: MeetingRoom, userId: string = this.authService.userId): boolean {
    return !!meet?.internalUsers.includes(userId);
  }

  /**
   * Get meetings for a user
   * @param {string[]} userIds Array of user ids
   * @returns {Promise<MeetingRoom[]>} Meetings for that user
   */
  public getMeetingsByUserIds(userIds: string[]): Promise<MeetingRoom[]> {
    // Get collection reference
    const appRef = collection(this.firestore, this.getMeetingPath());

    // Form query
    const appQuery = query(appRef, where('internalUsers', 'array-contains-any', userIds));

    // Return collection data
    return lastValueFrom(collectionData(appQuery, { idField: 'id' })
      .pipe(first())) as Promise<MeetingRoom[]>;
  }

  /**
   * Check if the room has a meet
   * @param {string} roomId Room id
   * @returns {boolean} If the room has a meet
   */
  public hasMeetToRoom(roomId: string): boolean {
    return !!this.meetings$.value.find(meet => meet.roomId == roomId);
  }

  /**
   * Navigate to meet
   * @param {string} meetId Meet id
   */
  public navigateToMeet(meetId: string): void {
    this.router.navigate([`/meeting/update/${meetId}`])
  }

  /**
   * Navigate to meet by room id
   * @param {string} roomId Room id
   */
  public navigateToMeetByRoomId(roomId: string): void {
    const meet = this.getMeetFromRoom(roomId);
    this.navigateToMeet(meet.id);
  }

  /**
   * Navigate to meet
   * @param {NavigationToNewMeet} data Data to pass to the new meet
   */
  public navigateToNewMeet(data: NavigationToNewMeet): void {
    this.router.navigateByUrl(`/meeting/new`, { state: { ...data } })
  }

  /**
   * Create matrix room for meet
   * @param {User[]} contacts Users that will be invited
   * @param {string} name Meet name
   * @returns {Promise<string>} Room id
   */
  public async createGroupForMeet(contacts: User[], name: string): Promise<string> {
    // Init flag
    let room_id: string;

    try {
      // Initiate variables
      const matrixClient = this.clientService.getClient();
      const usersIds = [];

      // Get all matrix ids
      contacts.forEach((contact) => {
        usersIds.push(formMatrixFromUserId(contact.id));
      });

      // Create Room options
      const options: ICreateRoomOpts = {
        name,
        visibility: Visibility.Private,
        invite: usersIds,
        power_level_content_override: {
          ban: 0,
          invite: 0,
          kick: 0,
          events_default: 0,
          redact: 0,
          users_default: 50,
        },
        creation_content: {
          roomType: MatrixRoomType.MEET
        }
      };

      // Create group
      room_id = (await matrixClient.createRoom(options)).room_id;
    } catch (error) {
      // Show error
      console.error(error);
    }

    // Return room id
    return room_id;
  }

  /**
   * Check if current hour is before end hour and if current hour is between start and end hour
   * @returns {boolean} True if can init and copy link
   */
  public checkHourValidity(meet: MeetingRoom): { isBeforeEnd: boolean, isBetween: boolean } {
    // Check if is recurrent
    if (meet.type == MeetingTypes.Recurrent) {
      // If is recurrent, can init call and copy link
      return { isBeforeEnd: true, isBetween: true };
    } else {
      // Get attr
      const { meetDate, startHour, finalHour } = { ...meet };

      // Init dates
      const currentDate = new Date();
      const start = meetDate.toDate();
      const end = meetDate.toDate();

      // Set final hour
      const finalHourSplitted = finalHour.split(':');
      end.setHours(+finalHourSplitted[0], +finalHourSplitted[1], 0)

      // Set final date in next day
      if (!this.isHourBefore(startHour, finalHour)) end.setDate(end.getDate() + 1)

      // Subtract MEET_TOLERANCE_TIME minutes to start date and add MEET_TOLERANCE_TIME minutes to end date
      start.setMinutes(start.getMinutes() - MEET_TOLERANCE_TIME);
      end.setMinutes(end.getMinutes() + MEET_TOLERANCE_TIME);

      // Check if is before the end date
      const isBeforeEnd = currentDate.getTime() <= end.getTime();

      // Check if is after the start date
      const isAfterStart = currentDate.getTime() >= start.getTime();

      // Check if between start and end
      const isBetween = isAfterStart && isBeforeEnd;

      // Return
      return { isBeforeEnd, isBetween };
    }
  }

  /**
  * Check if first hour is before second hour
  * @param {string} firstHour First hour
  * @param {string} secondHour Second hour
  * @returns {boolean} True if first hour is before second hour
  */
  private isHourBefore(firstHour: string, secondHour: string): boolean {
    const [firstHourNum, firstMinuteNum] = firstHour.split(':').map(Number);
    const [secondHourNum, secondMinuteNum] = secondHour.split(':').map(Number);

    if (firstHourNum < secondHourNum) {
      return true;
    } else if (firstHourNum > secondHourNum) {
      return false;
    } else {
      return firstMinuteNum < secondMinuteNum;
    }
  }
}
