<div class="contact-item" [ngClass]="{ dark: themeService.isDarkTheme(), clickable: canClick() }"
  (click)="showUserInfo()">
  <div *ngIf="showStatus;then avatarWithStatus else avatarWithoutStatus"></div>
 
  <div class="list-text" *ifViewportSize="['medium', 'large']">
    <span class="list-text-header medium-text-regular" [class.small-text-regular]="isSmall"
      [class.medium-text-bold]="isBold" [class.small-text-bold]="isSmall && isBold">
      {{ getDisplayName() }}
    </span>
    <span *ngIf="!isTyping()" class="list-text-desc normal-text-regular" style="display: flex; gap: 2px;"
      [class.small-text-regular]="isSmall">
      <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    </span>
    <app-typing [isDM]="true" [class.small-text-regular]="isSmall" *ngIf="isTyping()" [roomId]="roomId"
      [contacts]="contacts">
    </app-typing>
  </div>

  <div class="list-text" *ifViewportSize="['small']">
    <span class="list-text-header small-text-regular">{{ getDisplayName() }}</span>
    <span *ngIf="!isTyping()" class="list-text-desc small-text-regular small-desc">
      <ng-container *ngTemplateOutlet="subtitle"></ng-container>
    </span>
    <app-typing [isDM]="true" class="list-text-desc small-text-regular" *ngIf="isTyping()" [roomId]="roomId"
      [contacts]="contacts"></app-typing>
  </div>

</div>

<ng-template #avatarWithStatus>
  <app-avatar-with-status class="contact-avatar" [class.img-small]="isSmall" [contact]="contact">
  </app-avatar-with-status>
</ng-template>

<ng-template #avatarWithoutStatus>
  <app-avatar class="contact-avatar" [class.img-small]="isSmall"
    [photoUrl]="contact?.photoUrl ?? 'assets/images/avatar.svg'">
  </app-avatar>
</ng-template>

<ng-template #subtitle>
  <div *ngFor="let info of infos; let i = index" class="info" [class.small-text-regular]="isSmall">
    <div [ngSwitch]="info">
      <!-- Status -->
      <ng-container *ngSwitchCase="'status'">
        <ng-container *ngTemplateOutlet="separator"></ng-container>
        {{ getUserStatus() | translate }}
      </ng-container>

      <!-- Extension -->
      <ng-container *ngSwitchCase="'extension'">
        <ng-container *ngTemplateOutlet="separator"></ng-container>
        {{ 'extension' | translate }}: {{ contact?.extension }}
      </ng-container>

      <!-- Company Sector -->
      <ng-container *ngSwitchCase="'sector'">
        <span *ngIf="!!contact?.companySector">
          <ng-container *ngTemplateOutlet="separator"></ng-container>
          {{ contact?.companySector }}
        </span>
      </ng-container>

      <!-- Email -->
      <ng-container *ngSwitchCase="'email'">
        <ng-container *ngTemplateOutlet="separator"></ng-container>
        {{ contact?.email }}
      </ng-container>

      <!-- Meeting Info -->
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="separator"></ng-container>
        <app-meeting-info [roomId]="roomId"></app-meeting-info>
      </ng-container>
    </div>

    <!-- Ng template to separator (●) -->
    <ng-template #separator>
      <!-- Show if is not the first -->
      <span *ngIf="i !== 0" style="font-size: 10px; margin: 0px 5px;">●</span>
    </ng-template>
  </div>
</ng-template>
