export interface Cluster {
  id: string;
  domain: string;
  port: string;
  name: string;
  active: boolean;
}

export interface ExtensionMonitor {
  bigint: number;
  uuid: string;
  type: string;
  ipserver: string;
  event: string;
  iddomain: string;
  data: {
    peer?: string;
    peerstatus?: string;
    status?: string;
  }
}
