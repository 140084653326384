import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpTimingMonitorService {

  public httpRegisters$ = new BehaviorSubject<any>(null);
  public filteredRoute$ = new BehaviorSubject<any>(null);
  private isInterceptorActive = false; // Default to true

  setInterceptorActive(active: boolean): void {
    this.isInterceptorActive = active;
  }

  getInterceptorActive(): boolean {
    return this.isInterceptorActive;
  }

  registerNewCall(call: any, lastTimeout: number) {
    let val = this.httpRegisters$.value

  }
}
