<div class="container">
    <ng-container *ngIf="checkPlatform()">
        <ng-container *ifViewportSize="['medium', 'large']">
            <span class="text-center small-text-regular">
                {{ "downloadMobiPhone" | translate}}:
            </span>
            <div class="download-buttons">
                <a [href]="windowsDownloadUrl" target="_blank" id="windowns-download">
                    <button type="button" (mouseover)="winMouseOver = true" (mouseleave)="winMouseOver = false"
                        mat-button>
                        <img loading="lazy" [src]="
                      winMouseOver
                        ? 'assets/icons/icone-windows-on.svg'
                        : 'assets/icons/icone-windows-off.svg'
                    " />
                    </button>
                </a>
                <a [href]="linuxDownloadUrl" target="_blank" id="linux-download">
                    <button type="button" (mouseover)="linuxMouseOver = true" (mouseleave)="linuxMouseOver = false"
                        mat-button>
                        <img loading="lazy" [src]="
                      linuxMouseOver
                        ? 'assets/icons/icone-linux-on.svg'
                        : 'assets/icons/icone-linux-off.svg'
                    " />
                    </button>
                </a>
                <a [href]="googlePlayStoreUrl" target="_blank" id="android-download">
                    <button type="button" (mouseover)="androidMouseOver = true" (mouseleave)="androidMouseOver = false"
                        mat-button>
                        <img loading="lazy" [src]="
                      androidMouseOver
                        ? 'assets/icons/icone-android-on.svg'
                        : 'assets/icons/icone-android-off.svg'
                    " />
                    </button>
                </a>
                <a [href]="appStoreUrl" target="_blank">
                    <button type="button" (mouseover)="iosMouseOver = true" (mouseleave)="iosMouseOver = false"
                    mat-button>
                        <img loading="lazy" [src]="
                      iosMouseOver
                        ? 'assets/icons/icone-ios-on.svg'
                        : 'assets/icons/icone-ios-off.svg'
                    " />
                    </button>
                </a>
            </div>
        </ng-container>

        <ng-container *ifViewportSize="['small']">
            <span class="text-center small-text-regular">
                {{ "downloadMobiPhone" | translate}}:
            </span>
            <div class="download-buttons">
                <a [href]="googlePlayStoreUrl" target="_blank" id="android-download">
                    <button type="button" (mouseover)="androidMouseOver = true" (mouseleave)="androidMouseOver = false"
                        mat-button>
                        <img loading="lazy" [src]="
                      androidMouseOver
                        ? 'assets/icons/icone-android-on.svg'
                        : 'assets/icons/icone-android-off.svg'
                    " />
                    </button>
                </a>
                <a [href]="appStoreUrl" target="_blank">
                    <button type="button" (mouseover)="iosMouseOver = true" (mouseleave)="iosMouseOver = false" disabled
                        matTooltip="Em desenvolvimento" mat-button>
                        <img loading="lazy" [src]="
                      iosMouseOver
                        ? 'assets/icons/icone-ios-on.svg'
                        : 'assets/icons/icone-ios-off.svg'
                    " />
                    </button>
                </a>
            </div>
        </ng-container>
    </ng-container>
</div>
