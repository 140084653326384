import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  getDocs,
  query,
  Query,
  QueryConstraint,
  setDoc,
  updateDoc
} from '@angular/fire/firestore';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private firestore: Firestore) { }

  /**
   * Get a Firestore collection reference
   * @param {string} path Firestore path
   * @param {QueryConstraint[]} queryConstraint Query constraints
   * @returns {Query<T>} Firestore query
   */
  private col<T>(path: string, queryConstraint: QueryConstraint[]): Query<T> {
    return query(
      collection(this.firestore, path) as CollectionReference<T>,
      ...queryConstraint
    );
  }

  /**
   * Get a Firestore document reference
   * @param {string} path Firestore path
   * @returns {DocumentReference<T>} Firestore document reference
   */
  private doc<T>(path: string): DocumentReference<T> {
    return doc(this.firestore, path) as DocumentReference<T>;
  }

  /**
   * Get all documents from a collection and return an observable
   * @param {string} path Firestore path
   * @param {QueryConstraint[]} queryConstraint Query constraints
   * @returns {Observable<T[]>} Observable with all documents
   */
  public col$<T>(path: string, queryConstraint: QueryConstraint[]): Observable<T[]> {
    return collectionData(this.col(path, queryConstraint));
  }

  /**
   * Get all documents from a collection and return an observable with id
   * @param {string} path Firestore path
   * @param {QueryConstraint[]} queryConstraint Query constraints
   * @returns {Observable<T[]>} Observable with all documents with id
   */
  public colWithIds$<T>(
    path: string,
    queryConstraint: QueryConstraint[] = []
  ): Observable<T[]> {
    return collectionData(this.col(path, queryConstraint), { idField: 'id' });
  }

  /**
   * Get the first document from a collection and return an observable
   * @param {string} path Firestore path
   * @param {QueryConstraint[]} queryConstraint Query constraints
   * @returns {Observable<T>} Observable with the first document
   */
  public colOneWithId$<T>(
    path: string,
    queryConstraint: QueryConstraint[] = []
  ): Observable<T> {
    return collectionData(
      this.col(path, queryConstraint) as CollectionReference<T>,
      { idField: 'id' }
    ).pipe(
      map((arr) => {
        return arr[0];
      })
    );
  }

  /**
   * Get the first document from a collection
   * @param {string} path Firestore path
   * @param {QueryConstraint[]} queryConstraint Query constraints
   * @returns {Promise<T>} Promise with the first document
   */
  public async getDoc<T>(
    path: string,
    queryConstraint: QueryConstraint[] = []
  ): Promise<T> {
    const querySnapshot = await getDocs(this.col(path, queryConstraint));
    return querySnapshot.docs[0].data() as T;
  }

  /**
   * Get the first document from a collection
   * @param {string} path Firestore path
   * @param {QueryConstraint[]} queryConstraint Query constraints
   * @returns {Promise<T>} Promise with the first document
   */
  public async getDocOrNull<T>(
    path: string,
    queryConstraint: QueryConstraint[] = []
  ): Promise<T> {
    const querySnapshot = await getDocs(this.col(path, queryConstraint));
    if (querySnapshot.docs.length == 0){
      console.log('está vazio')
      return null as T;
    }
    return querySnapshot.docs[0].data() as T;
  }

  /**
   * Get a document with id
   * @param {string} path Firestore path
   * @returns {Observable<T>} Observable with the document with id
   */
  public docWithId$<T>(path: string): Observable<T> {
    return docData(this.doc(path), { idField: 'id' });
  }

  /**
   * Add a document to a collection
   * @param {string} path Firestore path
   * @param {T} data Data to add
   * @returns {Promise<DocumentReference<T>>} Promise with the document reference
   */
  public addDoc<T>(path: string, data: T): Promise<DocumentReference<T>> {
    return addDoc<T>(
      collection(this.firestore, path) as CollectionReference<T>,
      data
    );
  }

  /**
   * Add a document to a collection with specific id
   * @param {string} path Firestore path
   * @param {T} data Data to add
   * @returns {Promise<DocumentReference<T>>} Promise with the document reference
   */
  public setDoc<T>(path: string, data: T, id: string): Promise<void> {
    return setDoc(doc(this.firestore, path, id), data);
  }

  /**
   * Update a document
   * @param {string} path Firestore path
   * @param {T} data Data to update
   * @returns {Promise<void>} Promise with the update
   */
  public updateDoc<T>(path: string, data: T): Promise<void> {
    return updateDoc(this.doc(path), data);
  }

  /**
   * Delete a document
   * @param {string} path Firestore path
   * @returns {Promise<void>} Promise with the delete
   */
  public deleteDoc(path: string): Promise<void> {
    return deleteDoc(this.doc(path));
  }

}
