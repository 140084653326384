<!-- Advanced settings -->
<div class="advanced-settings">

  <!-- Toggle advanced options -->
  <button class="toggle-down half-form-field" (click)="toggleShow()">
    <!-- Text -->
    <span> {{ showLabel | translate }} </span>

    <!-- Arrow -->
    <mat-icon svgIcon="up-arrow" [class.flip-vertically]="!show"></mat-icon>
  </button>

  <!-- Advanced content -->
  <div *ngIf="show">
    <!-- Receive comments from the parent component -->
    <ng-content> </ng-content>
  </div>
</div>
